import React from 'react'
import FormGroup from './FormGroup'
import RadioInput from './RadioInput'
import FormInputErrors from './FormInputErrors'

const JotformRadioInput = ({
    item,
    formState,
    isSubmitting,
    className,
}) => {
    const {
        getValue,
        handleChange,
        getErrorMessages,
    } = formState
    return (
        <div className={className}>
            <FormGroup
                isRequired={item.required}
                label={item.text}
                htmlFor={item.name}
                description={item.subLabel}
            >
                <div className="flex flex-col gap-2">
                    {item.options.map((option) => (
                        <RadioInput
                            key={option}
                            id={`${option}-radio`}
                            className="text-sm mr-8"
                            value={getValue(item.name) === option}
                            onChange={() => handleChange(item.name, option)}
                            disabled={isSubmitting}
                        >
                            {option}
                        </RadioInput>
                    ))}
                </div>
                <FormInputErrors errors={getErrorMessages(item.name)} />
            </FormGroup>
        </div>
    )
}

export default JotformRadioInput
