import React, { useEffect, useMemo } from 'react'
import {
    StringParam,
    withDefault,
    NumberParam,
    useQueryParams,
    DelimitedArrayParam,
} from 'use-query-params'
import dotProp from 'dot-prop-immutable'
import { useLingui } from '@lingui/react'
import * as constants from '@connections/constants'
import { hasPossibleBrandedFares, fareTimeStringToMilliseconds } from '@connections/utils'
import { getPersonPriceFromCharge } from '../../util/prices'
import { getCabinClass, getFareLuggageStatus } from '../../util/booking/flights'
import { useQuickSearchFlightQueryParams } from '../content/useQuickSearchFlightFormState'
import useAppState from './useAppState'
import useFlightSearchExperiment from './useFlightSearchExperiment'

const DEPARTURE_TIME_MORNING = 'morning'
const DEPARTURE_TIME_AFTERNOON = 'afternoon'
const DEPARTURE_TIME_EVENING = 'evening'

const {
    ALL_OPTIONS: ALL,
    OPTION_ADDON: ADDON,
    OPTION_CHECKED: CHECKED,
    OPTION_INCLUDED: INCLUDED,
    OPTION_EXCLUDED: EXCLUDED,
    OPTION_UNCHECKED: UNCHECKED,
    FLIGHT_MODE_MULTI_CITY: MULTI_CITY,
} = constants

const getMinPriceFromFaresList = (list) => {
    if (list.length === 0) {
        return undefined
    }
    const cheapestFare = list.reduce((prev, curr) => {
        const prevPrice = getPersonPriceFromCharge(prev.charge)
        const currPrice = getPersonPriceFromCharge(curr.charge)
        if (prevPrice < currPrice) {
            return prev
        }
        return curr
    })
    return cheapestFare.charge.adult.sum + cheapestFare.charge.adult.tax.total
}

const calculateStops = (airRoutes) => (
    airRoutes.reduce((acc, airRoute) => (
        airRoute.segments.length - 1 > acc ? airRoute.segments.length - 1 : acc
    ), 0).toString()
)

const calculateMaxLayoverTime = (airRoutes) => airRoutes.reduce((prev, { segments }) => {
    if (segments.length > 1) {
        const layoverTimes = segments.reduce((acc, { arrivalTime }, index) => {
            const nextSegment = segments[index + 1]
            if (nextSegment) {
                const layoverTime = new Date(nextSegment.departureTime).getTime() - new Date(arrivalTime).getTime()
                return [...acc, layoverTime]
            }
            return acc
        }, [])
        return Math.max(...layoverTimes)
    }
    return prev
}, 0)

const getDepartureTime = ({ departureTime }) => {
    const departureHour = new Date(departureTime).getUTCHours()
    if (departureHour >= 5 && departureHour < 12) {
        return DEPARTURE_TIME_MORNING
    }
    if (departureHour >= 12 && departureHour < 18) {
        return DEPARTURE_TIME_AFTERNOON
    }
    if (departureHour >= 18 && departureHour <= 23) {
        return DEPARTURE_TIME_EVENING
    }
    return null
}

const getDepartureTimeOptions = (list, options, i18n, filtered) => {
    const filterOptions = []
    if (options.includes(DEPARTURE_TIME_MORNING)) {
        filterOptions.push({
            label: i18n._(/*i18n*/'Morning'),
            value: DEPARTURE_TIME_MORNING,
            secondaryLabel: '05:00 - 12:00',
            disabled: !filtered.fares[`departureTimes-${DEPARTURE_TIME_MORNING}`]?.length,
            minPrice: getMinPriceFromFaresList(list[`departureTimes-${DEPARTURE_TIME_MORNING}`]),
        })
    }
    if (options.includes(DEPARTURE_TIME_AFTERNOON)) {
        filterOptions.push({
            label: i18n._(/*i18n*/'Afternoon'),
            value: DEPARTURE_TIME_AFTERNOON,
            secondaryLabel: '12:00 - 18:00',
            disabled: !filtered.fares[`departureTimes-${DEPARTURE_TIME_AFTERNOON}`]?.length,
            minPrice: getMinPriceFromFaresList(list[`departureTimes-${DEPARTURE_TIME_AFTERNOON}`]),
        })
    }
    if (options.includes(DEPARTURE_TIME_EVENING)) {
        filterOptions.push({
            label: i18n._(/*i18n*/'Evening'),
            value: DEPARTURE_TIME_EVENING,
            secondaryLabel: '18:00 - 00:00',
            disabled: !filtered.fares[`departureTimes-${DEPARTURE_TIME_EVENING}`]?.length,
            minPrice: getMinPriceFromFaresList(list[`departureTimes-${DEPARTURE_TIME_EVENING}`]),
        })
    }
    return filterOptions
}

const AirportLabel = ({ airport }) => (
    <>
        <span>{airport?.name}</span>
        {airport?.nameExtension && (
            <span>{airport?.nameExtension}</span>
        )}
        <span>[{airport.value}]</span>
    </>
)

const getFilterValues = (mode, fares, infants, cabinClass, luggageInQsm) => {
    const filters = {
        carrier: {
            options: [],
            fares: {},
        },
        luggage: {
            options: [],
            fares: {
                [`luggage-${ADDON}`]: [],
                [`luggage-${INCLUDED}`]: [],
                [`luggage-${EXCLUDED}`]: [],
            },
        },
        stops: {
            options: [],
            fares: {},
        },
        fromFilter: {
            options: [],
            fares: {},
        },
        toFilter: {
            options: [],
            fares: {},
        },
        outboundDepartureTime: {
            options: [],
            fares: {},
        },
        inboundDepartureTime: {
            options: [],
            fares: {},
        },
        outboundTravelTime: {
            options: [],
        },
        inboundTravelTime: {
            options: [],
        },
        outboundLayoverTime: {
            options: []
        },
        inboundLayoverTime: {
            options: []
        },
    }

    // Compose fare lists per filter
    fares.forEach((fare) => {
        const { airRoutes, platingCarrier } = fare
        const outboundAirRoute = airRoutes.find(({ airRouteType }) => airRouteType === 0)
        const inboundAirRoutes = airRoutes.filter(({ airRouteType }) => airRouteType === 1)
        const inboundAirRoute = inboundAirRoutes[inboundAirRoutes.length - 1]
        const hasInboundAirRoute = typeof inboundAirRoute !== 'undefined'

        // Carrier
        if (typeof filters.carrier.options.find((carrierOption) => carrierOption === platingCarrier) === 'undefined') {
            filters.carrier.options.push(platingCarrier)
            filters.carrier.fares[`carriers-${platingCarrier}`] = []
        }
        filters.carrier.fares[`carriers-${platingCarrier}`] = [...filters.carrier.fares[`carriers-${platingCarrier}`], fare]

        // Luggage
        if (fare.baggageAllowed) {
            filters.luggage.fares[`luggage-${INCLUDED}`] = [
                ...filters.luggage.fares[`luggage-${INCLUDED}`], fare
            ]
        } else if (luggageInQsm && (fare.isLowCost || hasPossibleBrandedFares(fare, mode, infants, cabinClass))) {
            filters.luggage.fares[`luggage-${ADDON}`] = [
                ...filters.luggage.fares[`luggage-${ADDON}`], fare
            ]
        } else {
            filters.luggage.fares[`luggage-${EXCLUDED}`] = [
                ...filters.luggage.fares[`luggage-${EXCLUDED}`], fare
            ]
        }

        // Stops
        const amountOfStops = calculateStops(airRoutes)
        if (typeof filters.stops.options.find((stops) => stops === amountOfStops) === 'undefined') {
            filters.stops.options.push(amountOfStops)
            filters.stops.fares[`stops-${amountOfStops}`] = []
        }
        filters.stops.fares[`stops-${amountOfStops}`] = [...filters.stops.fares[`stops-${amountOfStops}`], fare]

        // Departure city
        const { code: from } = outboundAirRoute.segments[0].origin
        if (typeof filters.fromFilter.options.find((fromOption) => fromOption === from) === 'undefined') {
            filters.fromFilter.options.push(from)
            filters.fromFilter.fares[`from-${from}`] = []
        }
        filters.fromFilter.fares[`from-${from}`] = [...filters.fromFilter.fares[`from-${from}`], fare]

        // Arrival city
        const { code: to } = outboundAirRoute.segments[outboundAirRoute.segments.length - 1].destination
        if (typeof filters.toFilter.options.find((toOption) => toOption === to) === 'undefined') {
            filters.toFilter.options.push(to)
            filters.toFilter.fares[`to-${to}`] = []
        }
        filters.toFilter.fares[`to-${to}`] = [...filters.toFilter.fares[`to-${to}`], fare]

        // Outbound departure time
        const outboundDepartureTime = getDepartureTime(outboundAirRoute.segments[0])
        if (outboundDepartureTime) {
            if (!filters.outboundDepartureTime.options.includes(outboundDepartureTime)) {
                filters.outboundDepartureTime.options.push(outboundDepartureTime)
                filters.outboundDepartureTime.fares[`departureTimes-${outboundDepartureTime}`] = []
            }
            filters.outboundDepartureTime.fares[`departureTimes-${outboundDepartureTime}`] = [
                ...filters.outboundDepartureTime.fares[`departureTimes-${outboundDepartureTime}`], fare
            ]
        }

        // Inbound departure time
        if (hasInboundAirRoute) {
            const inboundDepartureTime = getDepartureTime(inboundAirRoute.segments[0])
            if (inboundDepartureTime) {
                if (!filters.inboundDepartureTime.options.includes(inboundDepartureTime)) {
                    filters.inboundDepartureTime.options.push(inboundDepartureTime)
                    filters.inboundDepartureTime.fares[`departureTimes-${inboundDepartureTime}`] = []
                }
                filters.inboundDepartureTime.fares[`departureTimes-${inboundDepartureTime}`] = [...filters.inboundDepartureTime.fares[`departureTimes-${inboundDepartureTime}`], fare]
            }
        }

        // Travel time
        const { travelTime: outboundTravelTime } = outboundAirRoute
        const outboundTravelTimeMs = fareTimeStringToMilliseconds(outboundTravelTime)
        if (typeof filters.outboundTravelTime.options.find((travelTimeOption) => travelTimeOption === outboundTravelTimeMs) === 'undefined') {
            filters.outboundTravelTime.options.push(outboundTravelTimeMs)
        }
        if (hasInboundAirRoute) {
            const { travelTime: inboundTravelTime } = inboundAirRoute
            const inboundTravelTimeMs = fareTimeStringToMilliseconds(inboundTravelTime)
            if (typeof filters.inboundTravelTime.options.find((travelTimeOption) => travelTimeOption === inboundTravelTimeMs) === 'undefined') {
                filters.inboundTravelTime.options.push(inboundTravelTimeMs)
            }
        }

        // Layover time
        const maxLayoverTimeForOutboundAirRoute = calculateMaxLayoverTime([outboundAirRoute])
        const existingOutboundOption = filters.outboundLayoverTime.options.find((layoverTimeOption) => layoverTimeOption === maxLayoverTimeForOutboundAirRoute)
        if (maxLayoverTimeForOutboundAirRoute > 0 && typeof existingOutboundOption === 'undefined') {
            filters.outboundLayoverTime.options.push(maxLayoverTimeForOutboundAirRoute)
        }
        if (hasInboundAirRoute) {
            const maxLayoverTimeForInboundAirRoute = calculateMaxLayoverTime([inboundAirRoute])
            const existingInboundOption = filters.inboundLayoverTime.options.find((layoverTimeOption) => layoverTimeOption === maxLayoverTimeForInboundAirRoute)
            if (maxLayoverTimeForInboundAirRoute > 0 && typeof existingInboundOption === 'undefined') {
                filters.inboundLayoverTime.options.push(maxLayoverTimeForInboundAirRoute)
            }
        }
    })

    filters.stops.options.sort((a, b) => a - b)
    filters.outboundTravelTime.options.sort((a, b) => a - b)
    filters.inboundTravelTime.options.sort((a, b) => a - b)
    filters.outboundLayoverTime.options.sort((a, b) => a - b)
    filters.inboundLayoverTime.options.sort((a, b) => a - b)

    return filters
}

const getFilteredFares = ({
    mode,
    fares,
    infants,
    cabinClass,
    filterValues,
    luggageInQsm,
    selectedFare,
}) => {
    const {
        stops,
        luggage,
        carriers,
        toFilter,
        fromFilter,
        consistentCarrier,
        inboundTravelTime,
        outboundTravelTime,
        inboundLayoverTime,
        outboundLayoverTime,
        inboundDepartureTimes,
        outboundDepartureTimes,
    } = filterValues
    let newFilteredFares = fares
    if (consistentCarrier.includes(CHECKED)) {
        newFilteredFares = newFilteredFares.filter(({ airRoutes }) => {
            const allSegments = airRoutes.reduce((acc, { segments }) => [...acc, ...segments], [])
            const [{ carrier }] = allSegments
            return allSegments.every((segment) => segment.carrier === carrier)
        })
    }
    if (carriers.length !== 0 && !carriers.includes(ALL)) {
        newFilteredFares = newFilteredFares.filter(({ platingCarrier }) => carriers.includes(platingCarrier))
    }
    if (luggage !== null) {
        newFilteredFares = newFilteredFares.filter((fare) => {
            const { baggageAllowed } = fare
            if (luggageInQsm) {
                const luggageStatus = getFareLuggageStatus(fare, mode, infants, cabinClass)
                return luggage.includes(ALL) || luggage.includes(luggageStatus)
            }
            if (luggage === ALL) {
                return true
            }
            if (luggage === INCLUDED && baggageAllowed) {
                return true
            }
            if (luggage === EXCLUDED && !baggageAllowed) {
                return true
            }
            return false
        })
    }
    if (stops !== null) {
        newFilteredFares = newFilteredFares.filter((fare) => {
            if (stops === ALL) {
                return true
            }
            const amountOfStops = calculateStops(fare.airRoutes)
            if (amountOfStops === stops) {
                return true
            }
            return false
        })
    }
    if (fromFilter.length !== 0) {
        newFilteredFares = newFilteredFares.filter(({ airRoutes }) => {
            const outboundAirRoute = airRoutes.find(({ airRouteType }) => airRouteType === 0)
            const { code } = outboundAirRoute.segments[0].origin
            return fromFilter.includes(code)
        })
    }
    if (toFilter.length !== 0) {
        newFilteredFares = newFilteredFares.filter(({ airRoutes }) => {
            const outboundAirRoute = airRoutes.find(({ airRouteType }) => airRouteType === 0)
            const { code } = outboundAirRoute.segments[outboundAirRoute.segments.length - 1].destination
            return toFilter.includes(code)
        })
    }
    if (outboundDepartureTimes.length !== 0) {
        newFilteredFares = newFilteredFares.filter(({ airRoutes }) => {
            const outboundAirRoute = airRoutes.find(({ airRouteType }) => airRouteType === 0)
            const departureTime = getDepartureTime(outboundAirRoute.segments[0])
            return outboundDepartureTimes.includes(departureTime)
        })
    }
    if (inboundDepartureTimes.length !== 0) {
        newFilteredFares = newFilteredFares.filter(({ airRoutes }) => {
            const inboundAirRoutes = airRoutes.filter(({ airRouteType }) => airRouteType === 1)
            const inboundAirRoute = inboundAirRoutes[inboundAirRoutes.length - 1]
            const departureTime = getDepartureTime(inboundAirRoute.segments[0])
            return inboundDepartureTimes.includes(departureTime)
        })
    }
    if (outboundTravelTime > 0) {
        newFilteredFares = newFilteredFares.filter(({ airRoutes }) => {
            const outboundAirRoute = airRoutes.find(({ airRouteType }) => airRouteType === 0)
            return fareTimeStringToMilliseconds(outboundAirRoute.travelTime) <= outboundTravelTime
        })
    }
    if (inboundTravelTime > 0) {
        newFilteredFares = newFilteredFares.filter(({ airRoutes }) => {
            const inboundAirRoutes = airRoutes.filter(({ airRouteType }) => airRouteType === 1)
            const inboundAirRoute = inboundAirRoutes[inboundAirRoutes.length - 1]
            return fareTimeStringToMilliseconds(inboundAirRoute.travelTime) <= inboundTravelTime
        })
    }
    if (outboundLayoverTime > 0) {
        newFilteredFares = newFilteredFares.filter(({ airRoutes }) => {
            const outboundAirRoute = airRoutes.find(({ airRouteType }) => airRouteType === 0)
            const maxLayoverTimeForFare = calculateMaxLayoverTime([outboundAirRoute])
            return maxLayoverTimeForFare <= outboundLayoverTime
        })
    }
    if (inboundLayoverTime > 0) {
        newFilteredFares = newFilteredFares.filter(({ airRoutes }) => {
            const inboundAirRoutes = airRoutes.filter(({ airRouteType }) => airRouteType === 1)
            const inboundAirRoute = inboundAirRoutes[inboundAirRoutes.length - 1]
            const maxLayoverTimeForFare = calculateMaxLayoverTime([inboundAirRoute])
            return maxLayoverTimeForFare <= inboundLayoverTime
        })
    }
    // This makes sure the selected fare is always in the list
    if (selectedFare && typeof newFilteredFares.find(({ ticket }) => ticket === selectedFare) === 'undefined') {
        newFilteredFares.unshift(fares.find(({ ticket }) => ticket === selectedFare))
    }
    return newFilteredFares
}

const getTotalOfFilteredFares = (options, filteredValues, string) => (
    options.reduce((total, option) => (
        total + (dotProp.get(filteredValues, `${string}-${option}`)?.length || 0)
    ), 0).toString()
)

const getFilters = ({
    i18n,
    mode,
    fares,
    infants,
    cabinClass,
    filterValues,
    airlineCodes,
    airportCodes,
    luggageInQsm,
    filterParams,
}) => {
    const getFilteredFaresHelper = (specificFilterValues) => {
        const filteredFares = getFilteredFares({
            mode,
            fares,
            infants,
            cabinClass,
            luggageInQsm,
            filterValues: specificFilterValues,
        })
        return getFilterValues(mode, filteredFares, infants, cabinClass, luggageInQsm)
    }

    // Compose filters
    const filters = []
    if (filterValues.carrier.options.length > 1) {
        const filteredValues = getFilteredFaresHelper({
            ...filterParams,
            carriers: [ALL]
        })
        const carrierFilterOptions = filterValues.carrier.options.map((carrierOption) => {
            const length = filteredValues.carrier.fares[`carriers-${carrierOption}`]?.length || '0'
            return {
                value: carrierOption,
                secondaryLabel: length,
                disabled: length === '0',
                label: airlineCodes[carrierOption],
                minPrice: getMinPriceFromFaresList(filterValues.carrier.fares[`carriers-${carrierOption}`]),
            }
        })
        carrierFilterOptions.sort((a, b) => a.minPrice - b.minPrice)
        carrierFilterOptions.unshift({
            value: ALL,
            label: i18n._(/*i18n*/'All airlines'),
            minPrice: getMinPriceFromFaresList(fares),
            secondaryLabel: getTotalOfFilteredFares(filterValues.carrier.options, filteredValues, 'carrier.fares.carriers'),
        })
        filters.push({
            key: 'carrierFilter',
            label: i18n._(/*i18n*/'Airline'),
            filters: [{
                type: 'checkbox',
                value: 'consistentCarrier',
                options: [{
                    value: CHECKED,
                    className: 'font-medium',
                    label: i18n._(/*i18n*/'Same airline on return trip'),
                }],
            }, {
                type: 'checkbox',
                value: 'carriers',
                options: carrierFilterOptions,
                showMoreText: i18n._(/*i18n*/'Show more airlines'),
                showLessText: i18n._(/*i18n*/'Show less airlines'),
            }]
        })
    }
    const luggageAddon = filterValues.luggage.fares[`luggage-${ADDON}`]
    const luggageIncluded = filterValues.luggage.fares[`luggage-${INCLUDED}`]
    const luggageExcluded = filterValues.luggage.fares[`luggage-${EXCLUDED}`]
    if (luggageIncluded.length !== 0 && luggageExcluded.length !== 0) {
        const filteredValues = getFilteredFaresHelper({
            ...filterParams,
            luggage: luggageInQsm ? [ALL] : ALL
        })
        const luggageAddonCount = filterValues.luggage.fares[`luggage-${ADDON}`].length
        const luggageIncludedCount = filteredValues.luggage.fares[`luggage-${INCLUDED}`].length
        const luggageExcludedCount = filteredValues.luggage.fares[`luggage-${EXCLUDED}`].length
        filters.push({
            key: 'luggageFilter',
            label: i18n._(/*i18n*/'Luggage'),
            filters: [{
                value: 'luggage',
                type: luggageInQsm ? 'checkbox' : 'radio',
                options: [
                    {
                        value: ALL,
                        label: i18n._(/*i18n*/'All options'),
                        minPrice: getMinPriceFromFaresList(fares),
                        secondaryLabel: luggageAddonCount + luggageIncludedCount + luggageExcludedCount || '0',
                    },
                    {
                        value: INCLUDED,
                        label: i18n._(/*i18n*/'Luggage included'),
                        disabled: luggageIncludedCount === 0,
                        secondaryLabel: luggageIncludedCount.toString(),
                        minPrice: getMinPriceFromFaresList(luggageIncluded),
                    },
                    luggageInQsm ? {
                        value: ADDON,
                        label: i18n._(/*i18n*/'Optional luggage available'),
                        disabled: luggageAddonCount === 0,
                        secondaryLabel: luggageAddonCount.toString(),
                        minPrice: getMinPriceFromFaresList(luggageAddon),
                    } : null,
                    {
                        value: EXCLUDED,
                        label: i18n._(/*i18n*/'Without luggage'),
                        disabled: luggageExcludedCount === 0,
                        secondaryLabel: luggageExcludedCount.toString(),
                        minPrice: getMinPriceFromFaresList(luggageExcluded),
                    }
                ].filter((option) => option !== null),
            }]
        })
    }
    if (filterValues.stops.options.length > 1) {
        const filteredValues = getFilteredFaresHelper({
            ...filterParams,
            stops: ALL
        })
        filters.push({
            key: 'stopsFilter',
            label: i18n._(/*i18n*/'Amount of stops'),
            filters: [{
                type: 'radio',
                value: 'stops',
                options: [
                    {
                        value: ALL,
                        label: i18n._(/*i18n*/'All options'),
                        minPrice: getMinPriceFromFaresList(fares),
                        secondaryLabel: getTotalOfFilteredFares(filterValues.stops.options, filteredValues, 'stops.fares.stops'),
                    },
                    ...filterValues.stops.options.map((stopsOption) => {
                        const length = filteredValues.stops.fares[`stops-${stopsOption}`]?.length || '0'
                        return {
                            value: stopsOption,
                            secondaryLabel: length,
                            disabled: length === '0',
                            minPrice: getMinPriceFromFaresList(filterValues.stops.fares[`stops-${stopsOption}`]),
                            label: stopsOption === '0' ? i18n._(/*i18n*/'Direct flights') : `${stopsOption} Stop${stopsOption === '1' ? '' : 's'}`,
                        }
                    })
                ],
            }]
        })
    }
    const fromAndToFilters = []
    if (filterValues.fromFilter.options.length > 1) {
        const filteredValues = getFilteredFaresHelper({
            ...filterParams,
            fromFilter: []
        })
        fromAndToFilters.push({
            type: 'checkbox',
            value: 'fromFilter',
            label: i18n._(/*i18n*/'Depart from'),
            options: filterValues.fromFilter.options.map((fromOption) => {
                const airport = airportCodes[fromOption] || { name: fromOption }
                const length = filteredValues.fromFilter.fares[`from-${fromOption}`]?.length || '0'
                return {
                    value: fromOption,
                    secondaryLabel: length,
                    disabled: length === '0',
                    label: <AirportLabel airport={airport} />,
                    minPrice: getMinPriceFromFaresList(filterValues.fromFilter.fares[`from-${fromOption}`]),
                }
            }),
        })
    }
    if (filterValues.toFilter.options.length > 1) {
        const filteredValues = getFilteredFaresHelper({
            ...filterParams,
            toFilter: []
        })
        fromAndToFilters.push({
            type: 'checkbox',
            value: 'toFilter',
            label: i18n._(/*i18n*/'Arrival'),
            options: filterValues.toFilter.options.map((toOption) => {
                const airport = airportCodes[toOption] || { name: toOption }
                const length = filteredValues.toFilter.fares[`to-${toOption}`]?.length || '0'
                return {
                    value: toOption,
                    secondaryLabel: length,
                    disabled: length === '0',
                    label: <AirportLabel airport={airport} />,
                    minPrice: getMinPriceFromFaresList(filterValues.toFilter.fares[`to-${toOption}`]),
                }
            }),
        })
    }
    if (fromAndToFilters.length > 0) {
        filters.push({
            key: 'fromAndToFilter',
            filters: fromAndToFilters,
            label: i18n._(/*i18n*/'Departure & Arrival'),
        })
    }
    const isMultiCity = mode === MULTI_CITY
    const outboundTimeFilters = []
    if (!isMultiCity) {
        if (filterValues.outboundDepartureTime.options.length > 1) {
            const filteredValues = getFilteredFaresHelper({
                ...filterParams,
                outboundDepartureTimes: []
            })
            outboundTimeFilters.push({
                type: 'checkbox',
                value: 'outboundDepartureTimes',
                label: i18n._(/*i18n*/'Departure time'),
                options: getDepartureTimeOptions(
                    filterValues.outboundDepartureTime.fares,
                    filterValues.outboundDepartureTime.options,
                    i18n,
                    filteredValues.outboundDepartureTime,
                ),
            })
        }
        if (filterValues.outboundTravelTime.options.length > 0) {
            const filteredValues = getFilteredFaresHelper({
                ...filterParams,
                outboundTravelTime: 0,
            })
            const { options } = filteredValues.outboundTravelTime
            outboundTimeFilters.push({
                type: 'timeSlider',
                minValue: options[0],
                value: 'outboundTravelTime',
                disabled: options.length <= 1,
                label: i18n._(/*i18n*/'Travel time'),
                maxValue: options[options.length - 1],
            })
        }
        if (filterValues.outboundLayoverTime.options.length > 0) {
            const filteredValues = getFilteredFaresHelper({
                ...filterParams,
                outboundLayoverTime: 0,
            })
            const { options } = filteredValues.outboundLayoverTime
            outboundTimeFilters.push({
                type: 'timeSlider',
                minValue: options[0],
                value: 'outboundLayoverTime',
                disabled: options.length <= 1,
                maxValue: options[options.length - 1],
                label: i18n._(/*i18n*/'Maximum transfer duration'),
            })
        }
    }
    if (outboundTimeFilters.length > 0) {
        filters.push({
            key: 'outboundTimeFilters',
            filters: outboundTimeFilters,
            label: i18n._(/*i18n*/'Outbound time schedule'),
        })
    }
    const inboundTimeFilters = []
    if (!isMultiCity) {
        if (filterValues.inboundDepartureTime.options.length > 1) {
            const filteredValues = getFilteredFaresHelper({
                ...filterParams,
                inboundDepartureTimes: []
            })
            inboundTimeFilters.push({
                type: 'checkbox',
                value: 'inboundDepartureTimes',
                label: i18n._(/*i18n*/'Departure time'),
                options: getDepartureTimeOptions(
                    filterValues.inboundDepartureTime.fares,
                    filterValues.inboundDepartureTime.options,
                    i18n,
                    filteredValues.inboundDepartureTime,
                ),
            })
        }
        if (filterValues.inboundTravelTime.options.length > 0) {
            const filteredValues = getFilteredFaresHelper({
                ...filterParams,
                inboundTravelTime: 0,
            })
            const { options } = filteredValues.inboundTravelTime
            inboundTimeFilters.push({
                type: 'timeSlider',
                minValue: options[0],
                value: 'inboundTravelTime',
                disabled: options.length <= 1,
                label: i18n._(/*i18n*/'Travel time'),
                maxValue: options[options.length - 1],
            })
        }
        if (filterValues.inboundLayoverTime.options.length > 0) {
            const filteredValues = getFilteredFaresHelper({
                ...filterParams,
                inboundLayoverTime: 0,
            })
            const { options } = filteredValues.inboundLayoverTime
            inboundTimeFilters.push({
                type: 'timeSlider',
                minValue: options[0],
                value: 'inboundLayoverTime',
                disabled: options.length <= 1,
                maxValue: options[options.length - 1],
                label: i18n._(/*i18n*/'Maximum transfer duration'),
            })
        }
    }
    if (inboundTimeFilters.length > 0) {
        filters.push({
            key: 'inboundTimeFilters',
            filters: inboundTimeFilters,
            label: i18n._(/*i18n*/'Inbound time schedule'),
        })
    }

    return filters
}

function getDefaultLuggageFilter(includeLuggage, luggageInQsm) {
    if (luggageInQsm) {
        if (includeLuggage) {
            return [ADDON, INCLUDED]
        }
        return [ALL]
    }
    if (includeLuggage) {
        return INCLUDED
    }
    return ALL
}

function getDefaultParams(isDirectFlight, includeLuggage, luggageInQsm) {
    return {
        toFilter: [],
        travelTime: 0,
        fromFilter: [],
        layoverTime: 0,
        carriers: [ALL],
        inboundDepartureTimes: [],
        outboundDepartureTimes: [],
        consistentCarrier: [UNCHECKED],
        stops: isDirectFlight ? '0' : ALL,
        luggage: getDefaultLuggageFilter(includeLuggage, luggageInQsm),
    }
}

function getParamsWithDefaults(isDirectFlight, includeLuggage, luggageInQsm) {
    const defaultParams = getDefaultParams(isDirectFlight, includeLuggage, luggageInQsm)

    return {
        stops: withDefault(StringParam, defaultParams.stops),
        travelTime: withDefault(NumberParam, defaultParams.travelTime),
        layoverTime: withDefault(NumberParam, defaultParams.layoverTime),
        toFilter: withDefault(DelimitedArrayParam, defaultParams.toFilter),
        carriers: withDefault(DelimitedArrayParam, defaultParams.carriers),
        fromFilter: withDefault(DelimitedArrayParam, defaultParams.fromFilter),
        consistentCarrier: withDefault(DelimitedArrayParam, defaultParams.consistentCarrier),
        inboundDepartureTimes: withDefault(DelimitedArrayParam, defaultParams.inboundDepartureTimes),
        luggage: withDefault(luggageInQsm ? DelimitedArrayParam : StringParam, defaultParams.luggage),
        outboundDepartureTimes: withDefault(DelimitedArrayParam, defaultParams.outboundDepartureTimes),
    }
}

const useFilteredFlightResults = (fares, selectedFare) => {
    const { i18n } = useLingui()
    const [{
        mode,
        infants,
        fareType,
        isDirectFlight,
        includeLuggage,
    }] = useQuickSearchFlightQueryParams()
    const cabinClass = getCabinClass(fareType)
    const { luggageInQsm } = useFlightSearchExperiment()
    const { airlineCodes, airportCodes } = useAppState()
    const filterValues = useMemo(() => getFilterValues(mode, fares, infants, cabinClass, luggageInQsm), [fares])
    const [filterParams, setFilterParams] = useQueryParams(getParamsWithDefaults(isDirectFlight, includeLuggage, luggageInQsm), { updateType: 'replaceIn' })
    const filters = useMemo(() => getFilters({
        i18n,
        mode,
        fares,
        infants,
        cabinClass,
        airlineCodes,
        airportCodes,
        filterValues,
        filterParams,
        luggageInQsm,
    }), [fares, filterParams])
    const filteredFares = useMemo(() => getFilteredFares({
        mode,
        fares,
        infants,
        cabinClass,
        luggageInQsm,
        selectedFare,
        filterValues: filterParams,
    }), [fares, filterParams])

    function handleResetFilters() {
        setFilterParams(getDefaultParams(isDirectFlight, includeLuggage, luggageInQsm))
    }

    useEffect(() => {
        if (fares.length > 0) {
            handleResetFilters()
        }
    }, [fares])

    return ({
        filters,
        filterParams,
        filteredFares,
        setFilterParams,
        onResetFilters: handleResetFilters,
        canResetFilters: fares.length > 0 && Object.keys(filterParams).some((key) => {
            const value = filterParams[key]
            const defaultValue = getDefaultParams(isDirectFlight, includeLuggage, luggageInQsm)[key]

            return value.toString() !== defaultValue.toString()
        }),
    })
}

export default useFilteredFlightResults
