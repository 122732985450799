import React from 'react'
import clsx from 'clsx'
import Map, {
    Popup,
    FullscreenControl,
    NavigationControl,
} from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { Trans } from '@lingui/react'
import { AnimatePresence } from 'framer-motion'
import { variant } from '../../util'
import { sectionContainerVariant, sectionSpacingVariant } from '../../util/variants'
import DayByDayMapSectionModal from './DayByDayMapSectionModal'
import DayByDayMapSectionSidebar from './DayByDayMapSectionSidebar'
import DayByDayMapSectionObjects from './DayByDayMapSectionObjects'

const DayByDayMapSection = ({
    zoom,
    title,
    mapRef,
    onLoad,
    spacing,
    markers,
    lastKey,
    dayItems,
    onCenter,
    container,
    onZoomEnd,
    isCentered,
    onClickDay,
    onClickMap,
    isMapLoaded,
    onMoveStart,
    dayItemRefs,
    selectedKey,
    onCloseModal,
    modalContent,
    hoveredMarker,
    onClickMarker,
    size = 'medium',
    onClickMoreInfo,
    initialViewState,
    onMouseEnterMarker,
    onMouseLeaveMarker,
}) => {
    const popupData = hoveredMarker || markers.find((marker) => (
        marker.key === selectedKey && marker.isLayover === false
    ))
    return (
        <section
            className={clsx(
                'flex flex-col-reverse md:flex-row',
                variant(size, {
                    small: 'md:h-[50vh]',
                    medium: 'md:h-[75vh]',
                    large: 'md:h-[95vh]',
                }),
                sectionSpacingVariant({ spacing }),
                sectionContainerVariant(container),
            )}
        >
            <DayByDayMapSectionSidebar
                className="w-full md:w-[26.25rem] overflow-y-auto items-stretch"
                title={title}
                dayItems={dayItems}
                lastKey={lastKey}
                onClickMoreInfo={onClickMoreInfo}
                dayItemRefs={dayItemRefs}
                selectedKey={selectedKey}
                onClickDay={onClickDay}
            />
            <div className="h-56 md:flex-1 md:h-full relative">
                {!isCentered && (
                    <button
                        type="button"
                        onClick={onCenter}
                        className="absolute top-[0.625rem] right-[50px] z-1 mapboxgl-ctrl-group px-2 font-medium h-[29px]"
                    >
                        <Trans id="Center" />
                    </button>
                )}
                <AnimatePresence>
                    <Map
                        ref={mapRef}
                        onLoad={onLoad}
                        scrollZoom={false}
                        touchPitch={false}
                        onClick={onClickMap}
                        onZoomEnd={onZoomEnd}
                        onMoveStart={onMoveStart}
                        initialViewState={initialViewState}
                        style={{ width: '100%', height: '100%' }}
                        mapboxAccessToken={process.env.NEXT_PUBLIC_MAPBOX_TOKEN}
                        mapStyle="mapbox://styles/connectionsbe/cl95fa7fn005s15pixluzd8rp"
                    >
                        <NavigationControl showCompass={false} />
                        <FullscreenControl />
                        {popupData && (
                            <Popup
                                longitude={popupData.longitude}
                                latitude={popupData.latitude}
                                anchor="bottom"
                                offset={35}
                                closeButton={false}
                                className="bg-black text-white p-3 rounded-lg font-medium leading-none"
                            >
                                <span className="text-[0.625rem] uppercase block w-full mb-1">
                                    {popupData.title}
                                </span>
                                <span className="text-sm">
                                    {popupData.name}
                                </span>
                            </Popup>
                        )}
                        {isMapLoaded ? (
                            <DayByDayMapSectionObjects
                                zoom={zoom}
                                mapRef={mapRef}
                                markers={markers}
                                lastKey={lastKey}
                                selectedKey={selectedKey}
                                onClickMarker={onClickMarker}
                                onMouseEnterMarker={onMouseEnterMarker}
                                onMouseLeaveMarker={onMouseLeaveMarker}
                            />
                        ) : null}
                    </Map>
                </AnimatePresence>
            </div>
            {modalContent !== null && (
                <DayByDayMapSectionModal
                    content={modalContent}
                    onClose={onCloseModal}
                />
            )}
        </section>
    )
}

export default DayByDayMapSection
