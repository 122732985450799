import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

const DEFAULT_HEIGHT = 200

const ScrollSentinel = ({
    onIntersect,
    height = DEFAULT_HEIGHT,
}) => {
    const [ref, inView] = useInView()
    useEffect(() => {
        if (inView) {
            onIntersect()
        }
    }, [inView, onIntersect])
    return (
        <div ref={ref} className="absolute bottom-0 left-0 right-0" style={{ height }} />
    )
}
export default ScrollSentinel
