import React, { useState } from 'react'
import { Trans } from '@lingui/react'
import Button from '../../utils/Button'
import ModalSlideUp from '../../utils/ModalSlideUp'
import DateRangeCalendar from '../../utils/DateRangeCalendar'
import ModalSlideUpFooter from '../../utils/ModalSlideUpFooter'
import ModalSlideUpHeader from '../../utils/ModalSlideUpHeader'
import ModalSlideUpContent from '../../utils/ModalSlideUpContent'

const BookDynamicTemplateMobileDateRangeCalendarModal = ({
    onClose,
    onConfirm,
    rangeStartDate,
    initialReturnDate,
    initialDepartureDate,
    dayIsDisabledCondition,
    ...props
}) => {
    const [selectedReturnDate, setSelectedReturnDate] = useState(initialReturnDate)
    const [selectedDepartureDate, setSelectedDepartureDate] = useState(initialDepartureDate)
    return (
        <ModalSlideUp
            isFullScreen
            onClose={onClose}
            {...props}
        >
            <ModalSlideUpHeader
                title={<Trans id="Dates" />}
                onClose={onClose}
            />
            <ModalSlideUpContent>
                <DateRangeCalendar
                    className="pt-4"
                    numberOfMonthButtons={5}
                    rangeStartDate={rangeStartDate}
                    returnDate={selectedReturnDate}
                    departureDate={selectedDepartureDate}
                    dayIsDisabledCondition={dayIsDisabledCondition}
                    onChange={(departure, rtrn) => {
                        setSelectedDepartureDate(departure)
                        setSelectedReturnDate(rtrn)
                    }}
                />
            </ModalSlideUpContent>
            <ModalSlideUpFooter className="flex">
                <Button
                    className="mr-4 w-full"
                    variant="blue-outlined"
                    onClick={onClose}
                >
                    <Trans id="Close" />
                </Button>
                <Button
                    className="w-full"
                    variant="blue-filled"
                    iconAfterName="arrow-right"
                    onClick={() => onConfirm(selectedDepartureDate, selectedReturnDate)}
                    data-testid="BookDynamicTemplateMobileDateRangeCalendarConfirm"
                >
                    <Trans id="Confirm" />
                </Button>
            </ModalSlideUpFooter>
        </ModalSlideUp>
    )
}

export default BookDynamicTemplateMobileDateRangeCalendarModal
