import React from 'react'
import clsx from 'clsx'
import * as constants from '@connections/constants'
import { variant } from '../../util/index'
import WorldMap from './WorldMap'
import { createLoader } from './Image'
import ContinentCircles from './ContinentCircles'

const {
    ASIA,
    EUROPE,
    AFRICA,
    MIDDLE_EAST,
    NORTH_AMERICA,
    LATIN_AMERICA,
    AUSTRALIA_AND_NEW_ZEALAND,
    BACKGROUND_WHITE,
    BACKGROUND_GRAY,
    BACKGROUND_BLUE,
    BACKGROUND_BLACK,
    BACKGROUND_GRAY_LIGHT,
} = constants

const continentVariants = {
    '': 'top-1/2 left-0 w-full',
    [AFRICA]: 'w-[200%] h-[200%] top-[32%] left-[-55%]',
    [NORTH_AMERICA]: 'w-[230%] h-[230%] top-[100%] left-[10%]',
    [AUSTRALIA_AND_NEW_ZEALAND]: 'w-[280%] h-[280%] top-[-20%] right-[25%]',
    [MIDDLE_EAST]: 'w-[480%] h-[480%] top-[105%] left-[-235%]',
    [ASIA]: 'w-[190%] h-[190%] top-[75%] right-[10%]',
    [EUROPE]: 'w-[400%] h-[400%] top-[165%] left-[-148%]',
    [LATIN_AMERICA]: 'w-[210%] h-[210%] top-[8%] left-[-5%]',
}

const WorldMapWithZoom = ({
    experts,
    background,
    isFetching,
    continentCode,
}) => (
    <div className="relative pt-[50%] w-full">
        <WorldMap
            className={
                clsx([
                    'absolute -translate-y-1/2',
                    variant(continentCode, continentVariants),
                ])
            }
            mapClassName={
                clsx([
                    variant(background, {
                        [BACKGROUND_WHITE]: 'text-blue opacity-30',
                        [BACKGROUND_GRAY_LIGHT]: 'text-blue opacity-30',
                        [BACKGROUND_GRAY]: 'text-blue opacity-30',
                        [BACKGROUND_BLACK]: 'text-white opacity-40',
                        [BACKGROUND_BLUE]: 'text-gray-800 opacity-60',
                    })
                ])
            }
        >
            <g className="hidden md:block">
                {!isFetching && (
                    <ContinentCircles
                        continentCode={continentCode}
                    />
                )}
                {experts.length > 0 && (
                    <defs>
                        {experts.map((expert, key) => {
                            const loader = createLoader({ maxWidth: 200 })
                            const { content: { profileImage: { filename: imageUrl } } } = expert

                            return (
                                <image
                                    width="160"
                                    height="160"
                                    id={`image${key}`}
                                    key={`${expert.name}-${key}`}
                                    xlinkHref={loader({ src: imageUrl, width: 200, height: 200 })}
                                />
                            )
                        })}
                    </defs>
                )}
            </g>
        </WorldMap>
    </div>
)

export default WorldMapWithZoom
