import React from 'react'
import SbEditable from 'storyblok-react'
import { splitCodes } from '../../util/index'
import useCheapestDestinationsPrices from '../hooks/useCheapestDestinationsPrices'
import FlightsListerSection from './FlightsListerSection'

const FlightsListerSectionWithState = ({
    content,
}) => {
    const {
        title,
        links,
        variant,
        spacing,
        airline,
        anchorId,
        container,
        background,
        description,
        destinations,
        departureCodes = '',
    } = content
    const {
        error,
        loading,
        cheapestDestinations,
    } = useCheapestDestinationsPrices({
        airline,
        destinations,
        departureCodes: splitCodes(departureCodes),
    })
    return (
        <SbEditable content={content}>
            <FlightsListerSection
                error={error}
                title={title}
                links={links}
                loading={loading}
                variant={variant}
                spacing={spacing}
                container={container}
                background={background}
                description={description}
                anchorId={anchorId || undefined}
                destinations={cheapestDestinations}
            />
        </SbEditable>
    )
}

export default FlightsListerSectionWithState
