import React from 'react'

const ShareTitle = ({
    children,
}) => (
    <h3 className="font-medium text-xl">
        {children}
    </h3>
)

export default ShareTitle
