import React from 'react'
import clsx from 'clsx'
import SbEditable from 'storyblok-react'
import * as constants from '@connections/constants'
import { variant } from '../../util/index'
import AirlinesSectionSquare from './AirlinesSectionSquare'

const {
    CONTAINER_EXTRA_SMALL: EXTRA_SMALL,
    CONTAINER_SMALL: SMALL,
    CONTAINER_DEFAULT: DEFAULT,
} = constants

const AirlinesSection = ({
    content,
}) => {
    const {
        background,
        container,
        spacing,
        anchorId,
    } = content
    return (
        <SbEditable content={content}>
            <section
                id={anchorId || undefined}
                data-testid="AirlinesSection"
                className={clsx(
                    variant(spacing, {
                        small: 'py-12',
                        medium: 'py-24',
                        large: 'py-36',
                    }),
                    variant(background, {
                        white: 'bg-white',
                        'gray-50': 'bg-gray-50',
                        'gray-100': 'bg-gray-100',
                        black: 'bg-black',
                        blue: 'bg-blue',
                    })
                )}
            >
                <div
                    className={clsx(
                        variant(container, {
                            [EXTRA_SMALL]: 'lg:container-xs',
                            [SMALL]: 'lg:container-sm',
                            [DEFAULT]: 'lg:container',
                        })
                    )}
                >
                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-[1px] bg-gray-100">
                        {content.airline.map((airline) => (
                            <AirlinesSectionSquare
                                key={airline.uuid}
                                background={background}
                                airline={airline}
                            />
                        ))}
                    </div>
                </div>
            </section>
        </SbEditable>
    )
}

export default AirlinesSection
