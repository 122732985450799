import React from 'react'
import clsx from 'clsx'
import { render } from 'storyblok-rich-text-react-renderer'
import RichTextIcon from '../utils/RichTextIcon'
import { sectionTextOnBackgroundVariant } from '../../util/variants'
import { resolvers } from './RichTextField'
import TableWithContent from './TableWithContent'
import RichTextIconList from '../utils/RichTextIconList'

const RichTextFieldWithComponents = ({
    content,
    className,
    background,
}) => (
    <div
        className={clsx([
            'prose max-w-none',
            sectionTextOnBackgroundVariant(background),
            className,
        ])}
    >
        {render(content, {
            ...resolvers,
            blokResolvers: {
                IconText: (blok) => <RichTextIcon content={blok} />,
                IconList: (blok) => <RichTextIconList content={blok} />,
                Table: (blok) => <TableWithContent content={blok} className="not-prose" />,
            }
        })}
    </div>
)

export default RichTextFieldWithComponents
