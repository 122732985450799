import React from 'react'
import { Trans } from '@lingui/react'
import useCarousel from '@dashdot/react-hooks-slider'
import Slide from '../../content/Slide'
import CarouselCount from '../../utils/CarouselCount'
import SliderContainer from '../../utils/CarouselContainer'
import BookTemplateModalTour from './BookTemplateModalTour'

const BookTemplateModalTours = ({
    name,
    tours,
    onChangeTour,
    numberOfRooms,
    selectedTourId,
    numberOfAdults,
    numberOfChildren,
}) => {
    const {
        position,
        slideRefs,
        isSwiping,
        isDisabled,
        activeSlide,
        totalSlides,
        hasNextSlide,
        containerRef,
        swipingProps,
        handleNextSlide,
        hasPreviousSlide,
        handlePreviousSlide,
    } = useCarousel()
    return (
        <>
            <div className="flex justify-between items-center mb-6">
                <h2 className="text-lg font-medium">
                    <Trans id="Tour" />
                </h2>
                {totalSlides > 1 && (
                    <CarouselCount
                        total={totalSlides}
                        current={activeSlide + 1}
                        hasNextSlide={hasNextSlide}
                        onNextSlide={handleNextSlide}
                        hasPreviousSlide={hasPreviousSlide}
                        onPreviousSlide={handlePreviousSlide}
                    />
                )}
            </div>
            <div className="overflow-hidden">
                <SliderContainer
                    position={position}
                    isDisabled={isDisabled}
                    containerRef={containerRef}
                    swipingProps={swipingProps}
                >
                    {tours.filter(({ productId }) => productId === selectedTourId).map((tour, index) => (
                        <Slide
                            key={tour.startDate}
                            isSwiping={isSwiping}
                            ref={(ref) => { slideRefs.current[index] = ref }}
                        >
                            <BookTemplateModalTour
                                name={name}
                                tour={tour}
                                tours={tours}
                                onChangeTour={onChangeTour}
                                numberOfRooms={numberOfRooms}
                                selectedTourId={selectedTourId}
                                numberOfAdults={numberOfAdults}
                                numberOfChildren={numberOfChildren}
                            />
                        </Slide>
                    ))}
                </SliderContainer>
            </div>
        </>
    )
}

export default BookTemplateModalTours
