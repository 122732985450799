import clsx from 'clsx'
import React from 'react'
import SbEditable from 'storyblok-react'
import { getHrefFromLink } from '../../util/index'
import TextLink from '../utils/TextLink'
import usePathnameLocale from '../hooks/usePathnameLocale'

const CardsSectionLink = ({
    content,
    className,
}) => {
    const {
        link,
        text,
        iconBeforeName,
        iconAfterName,
        openInNewTab,
    } = content
    const locale = usePathnameLocale()
    return (
        <SbEditable content={content}>
            <TextLink
                className={
                    clsx(
                        'text-blue-bright hover:underline',
                        className,
                    )
                }
                href={getHrefFromLink(link, locale)}
                iconBeforeName={iconBeforeName}
                iconAfterName={iconAfterName}
                openInNewTab={openInNewTab}
            >
                {text}
            </TextLink>
        </SbEditable>
    )
}

export default CardsSectionLink
