import React from 'react'
import clsx from 'clsx'
import SbEditable from 'storyblok-react'
import Icon from '../utils/Icon'

const DayItemTabGroupTab = ({
    content,
    isActive,
    onClick,
}) => {
    const {
        icon,
        title,
    } = content

    return (
        <SbEditable content={content}>
            <button
                type="button"
                className={clsx(
                    'py-4 mr-4 col-span-1 inline-block border-b-2 transition-colors whitespace-nowrap',
                    'last:mr-10 md:last:mr-0',
                    'md:flex md:items-center md:px-8 md:flex-1 md:mr-0',
                    'hover:border-primary hover:text-primary',
                    isActive ? 'border-primary text-primary' : 'border-transparent'
                )}
                onClick={onClick}
            >
                <Icon
                    name={icon}
                    className="mr-2 hidden md:block"
                />
                <h4 className="uppercase text-sm font-medium tracking-wider">
                    {title}
                </h4>
            </button>
        </SbEditable>
    )
}

export default DayItemTabGroupTab
