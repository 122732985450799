import React from 'react'
import SbEditable from 'storyblok-react'
import Table from '../utils/Table'

const TableWithContent = ({
    content,
    className,
}) => {
    const {
        table,
        isHorizontalScrollable = false,
        isVerticalScrollable = false,
    } = content
    const {
        thead = [],
        tbody = [],
    } = table
    return (
        <SbEditable content={content}>
            <Table
                className={className}
                isHorizontalScrollable={isHorizontalScrollable}
                isVerticalScrollable={isVerticalScrollable}
            >
                <Table.Head>
                    <Table.HeadRow>
                        {thead.map((cell) => (
                            <Table.HeadCell key={cell._uid}>
                                {cell.value}
                            </Table.HeadCell>
                        ))}
                    </Table.HeadRow>
                </Table.Head>
                <Table.Body>
                    {tbody.map((row) => (
                        <Table.Row key={row._uid}>
                            {row.body.map((cell) => (
                                <Table.Cell key={cell._uid}>
                                    {cell.value}
                                </Table.Cell>
                            ))}
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </SbEditable>
    )
}

export default TableWithContent
