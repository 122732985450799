import React from 'react'
import SbEditable from 'storyblok-react'
import { splitCodes } from '../../util/index'
import QuickSearchSectionWithState from './QuickSearchSectionWithState'

const QuickSearchSectionWithContent = ({
    content,
}) => {
    const {
        _uid,
        tabs,
        spacing,
        anchorId,
        container,
        background,
        toIataCode,
        fromIataCode,
        promoToIataCode,
        promoCabinClass,
        promoFromIataCode,
        promoMinTravelers,
        openTab = 'flight',
        toDefaultIataCodes,
        isStandalone = false,
        fromDefaultIataCodes,
        promoAirlineIataCodes,
        promoToDefaultIataCodes,
        promoFromDefaultIataCodes,
        disableScrollIntoView = false,
    } = content
    const values = {
        toIataCode,
        fromIataCode,
        toDefaultIataCodes: splitCodes(toDefaultIataCodes),
        fromDefaultIataCodes: splitCodes(fromDefaultIataCodes),
    }
    const promoValues = {
        promoToIataCode,
        promoCabinClass,
        promoFromIataCode,
        promoMinTravelers: Number(promoMinTravelers) || 1,
        promoAirlineIataCodes,
        promoToDefaultIataCodes: splitCodes(promoToDefaultIataCodes),
        promoFromDefaultIataCodes: splitCodes(promoFromDefaultIataCodes),
    }
    const availableOpenTab = tabs.includes(openTab)
        ? openTab
        : tabs[0]

    return (
        <SbEditable content={content}>
            <QuickSearchSectionWithState
                uid={_uid}
                tabs={tabs}
                values={values}
                spacing={spacing}
                container={container}
                background={background}
                promoValues={promoValues}
                openTab={availableOpenTab}
                isStandalone={isStandalone}
                disableScrollIntoView={disableScrollIntoView}
                anchorId={anchorId || `${_uid}-quick-search-section`}
            />
        </SbEditable>
    )
}

export default QuickSearchSectionWithContent
