import React from 'react'
import { Trans } from '@lingui/react'
import Date from '../utils/Date'
import Icon from '../utils/Icon'
import Price from '../utils/Price'
import Tag, { SIZE_LARGE, VARIANT_BLUE_OUTLINED } from '../utils/Tag'

const DestinationFlightsListerCardDesktop = ({
    price,
    airline,
    hasPrice,
    onSelect,
    returnDate,
    arrivalCity,
    arrivalCode,
    isRoundTrip,
    departureDate,
    departureCity,
    departureCode,
    arrivalAirport,
    departureAirport,
}) => (
    <button
        type="button"
        onClick={onSelect}
        className="hidden lg:flex justify-between gap-x-4 rounded-lg shadow-md bg-white hover:shadow-lg"
    >
        <div className="flex flex-1 justify-between gap-x-8 py-3 px-4">
            <div className="relative w-12 h-12 my-auto">
                <img
                    alt={airline.name}
                    src={airline.imageSquareUrl}
                    className="absolute inset-0 object-cover h-full w-full"
                />
            </div>
            <div className="flex-1 grid grid-cols-5">
                <div className="col-span-2 flex flex-col justify-center">
                    <div className="text-lg text-blue font-bold truncate">
                        {departureCity}
                    </div>
                    <div className="text-sm text-gray-600 truncate mt-1">
                        {departureAirport} <span className="uppercase">[{departureCode}]</span>
                    </div>
                </div>
                <Icon
                    size="small"
                    className="text-blue my-auto mx-auto"
                    name={isRoundTrip ? 'change-directions' : 'arrow-from-right'}
                />
                <div className="col-span-2 flex flex-col justify-center">
                    <div className="text-lg text-blue font-bold truncate">
                        {arrivalCity}
                    </div>
                    <div className="text-sm text-gray-600 truncate mt-1">
                        {arrivalAirport} <span className="uppercase">[{arrivalCode}]</span>
                    </div>
                </div>
            </div>
        </div>
        <div className="border-l border-gray-100 py-4 px-8">
            <Tag
                size={SIZE_LARGE}
                className="!lowercase"
                variant={VARIANT_BLUE_OUTLINED}
            >
                {hasPrice ? (
                    <Price price={price} />
                ) : (
                    <Trans id="No pricing found" />
                )}
            </Tag>
            <div className="text-sm text-gray-600 truncate text-right mt-1">
                {(() => {
                    if (hasPrice) {
                        if (isRoundTrip) {
                            return (
                                <>
                                    <Date dateString={departureDate} format="d/M" />
                                    {' - '}
                                    <Date dateString={returnDate} format="d/M" />
                                </>
                            )
                        }
                        return <Date dateString={departureDate} format="d/M" />
                    }
                    return <Trans id="No date found" />
                })()}
            </div>
        </div>
    </button>
)

export default DestinationFlightsListerCardDesktop
