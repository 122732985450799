import React, { useState } from 'react'
import DateRangeButton from '../../utils/DateRangeButton'
import BookDynamicTemplateMobileDateRangeCalendarModal from './BookDynamicTemplateMobileDateRangeCalendarModal'

const BookDynamicTemplateMobileDateRangeCalendar = ({
    hasError,
    onChange,
    className,
    returnDate,
    departureDate,
    rangeStartDate,
    dayIsDisabledCondition,
    isOpen: isOpenFromProps = false,
}) => {
    const [isOpen, setIsOpen] = useState(isOpenFromProps)

    return (
        <div
            className="lg:hidden"
            data-testid="BookDynamicTemplateMobileDateRangeCalendarContainer"
        >
            {isOpen && (
                <BookDynamicTemplateMobileDateRangeCalendarModal
                    initialReturnDate={returnDate}
                    rangeStartDate={rangeStartDate}
                    onClose={() => setIsOpen(false)}
                    initialDepartureDate={departureDate}
                    dayIsDisabledCondition={dayIsDisabledCondition}
                    data-testid="BookDynamicTemplateMobileDateRangeCalendar"
                    onConfirm={(departure, returnTemp) => {
                        onChange(departure, returnTemp)
                        setIsOpen(false)
                    }}
                />
            )}
            <DateRangeButton
                hasError={hasError}
                className={className}
                returnDate={returnDate}
                departureDate={departureDate}
                onClickReturnDate={() => setIsOpen(true)}
                onClickDepartureDate={() => setIsOpen(true)}
            />
        </div>
    )
}

export default BookDynamicTemplateMobileDateRangeCalendar
