import React, { useEffect, useState } from 'react'
import { whereId } from '@connections/utils'
import { useRouter } from 'next-nprogress-bar'
import { getAbsoluteEndDateFromFares } from '../../../util/booking'
import { getActivitiesPrice } from '../../../util/booking/activities'
import { getBreakMetaData } from '../../../util/analytics/transformers'
import useJourney from '../../hooks/useJourney'
import useLogEvent from '../../hooks/useLogEvent'
import useNotifications from '../../hooks/useNotifications'
import usePathnameLocale from '../../hooks/usePathnameLocale'
import useBreakAnalyticsValues from '../../hooks/useBreakAnalyticsValues'
import useGuestsState from '../../utils/useGuestsState'
import useTemplateQuery from './useTemplateQuery'
import useTemplateActivities from './useTemplateActivities'
import BookDynamicTemplateModal from './BookDynamicTemplateModal'
import useBookDynamicTemplateQuery from './useBookDynamicTemplateQuery'
import useCreateBookDynamicTemplateMutation from './useCreateBookDynamicTemplateMutation'

const getCheapestAvailablePriceLevelFromList = (priceLevels = []) => priceLevels.reduce((cheapestPriceLevel, priceLevel) => {
    if (priceLevel.availabilityStatus === 'Available' && (cheapestPriceLevel === null || priceLevel.priceInfo.totalPrice <= cheapestPriceLevel.priceInfo.totalPrice)) {
        return priceLevel
    }
    return cheapestPriceLevel
}, null)

const BookDynamicTemplateModalWithState = ({
    type,
    content,
    onClose,
    templateSettings,
}) => {
    const {
        uuid,
        content: {
            name,
            collection,
            maxChildAge,
            // The available dates are fetched from TravelBooster through our own api.
            // This is done during static generation of the pages containing these components.
            availableDates,
            lastPossibleReturnDate,
            travelBoosterTemplateId,
            isFreeDateChoiceTemplate,
            firstPossibleDepartureDate,
        }
    } = content
    const {
        adults,
        children,
        numberOfRooms,
        handleChangeGuestState,
    } = useGuestsState()
    const router = useRouter()
    const logEvent = useLogEvent()
    const fullLocale = usePathnameLocale(true)
    const templateId = travelBoosterTemplateId.split(',')[0]
    const { dispatchError } = useNotifications()
    const [modalHasError, setModalHasError] = useState(false)
    const [selectedFareId, setSelectedFareId] = useState(null)
    const [hasLoggedError, setHasLoggedError] = useState(false)
    const [[startDate, endDate], setDates] = useState([null, null])
    const [hasChosenTravelers, setHasChosenTravelers] = useState(false)
    const [hasLoggedModalOpen, setHasLoggedModalOpen] = useState(false)
    const [selectedHotelRoomId, setSelectedHotelRoomId] = useState(null)
    const { journeyUid, closeJourney, error: journeyError } = useJourney()
    const [hasLoggedInitialSearch, setHasLoggedInitialSearch] = useState(false)
    const [hasLoggedInputSelected, setHasLoggedInputSelected] = useState(false)
    const { getTimingAnalyticsValues, getTemplateAnalyticsValues } = useBreakAnalyticsValues()
    const { mutateAsync: createBookingDynamicTemplate, isLoading: isCreatingBookingTemplate } = useCreateBookDynamicTemplateMutation()
    const {
        allAvailableDates,
        error: templateError,
        isFetching: isFetchingTemplate,
    } = useTemplateQuery(journeyUid, templateId, isFreeDateChoiceTemplate, availableDates)
    const {
        searchResultId,
        templatePricing,
        error: templatePricingError,
        isFetching: isFetchingTemplatePricing,
    } = useBookDynamicTemplateQuery({
        adults,
        endDate,
        children,
        startDate,
        journeyUid,
        numberOfRooms,
        id: templateId,
        hasChosenTravelers,
    })
    const {
        selectedActivities,
        handleChangeActivity,
        handleChangeActivityOptions,
    } = useTemplateActivities(endDate, startDate, templatePricing?.activities)

    useEffect(() => {
        if (!hasLoggedModalOpen) {
            const metaData = getBreakMetaData({ route: !!collection || name }, 'modal-open', 'DYNAMIC_PACKAGE')
            logEvent('ModalOpen', metaData, journeyUid)
            setHasLoggedModalOpen(true)
        }
    }, [])
    useEffect(() => {
        if (!hasLoggedInputSelected && hasChosenTravelers && !!endDate && !!startDate) {
            const values = {
                route: !!collection || name,
                numberOfAdults: adults.length,
                ...getTimingAnalyticsValues(endDate, startDate)
            }
            const metaData = getBreakMetaData(values, 'search-started', 'DYNAMIC_PACKAGE')
            logEvent('SearchStarted', metaData, journeyUid)
            setHasLoggedInputSelected(true)
        }
    }, [endDate, startDate, hasChosenTravelers])
    useEffect(() => {
        if (journeyError || templateError || templatePricingError) {
            if (!hasLoggedError) {
                const values = {
                    route: !!collection || name,
                    numberOfAdults: adults.length,
                    ...getTimingAnalyticsValues(endDate, startDate)
                }
                const metaData = getBreakMetaData(values, 'search-error', 'DYNAMIC_PACKAGE')
                logEvent('SearchError', metaData, journeyUid)
                setHasLoggedError(true)
            }
            setModalHasError(true)
        } else {
            setModalHasError(false)
        }
    }, [journeyError, templateError, templatePricingError])
    useEffect(() => {
        if (templatePricing) {
            // Set included fare
            const defaultFlight = templatePricing.flights.find(({ isAlternative }) => !isAlternative)
            setSelectedFareId(defaultFlight?.id)
            // Set included hotel
            const defaultHotel = templatePricing.hotels.find(({ isAlternative }) => !isAlternative)
            let hotelId = null
            templatePricing.alternativeHotels.some(({ priceLevels }) => {
                // When the default hotel is an external hotel the price level will be present in the additionalHotels
                // but there is no guarantee that the product ids match. For that reason we match them on price and name.
                const priceLevel = priceLevels.find(({ priceInfo, roomName }) => priceInfo.totalPrice === defaultHotel.price && roomName === defaultHotel.priceLevelName)
                if (priceLevel && priceLevel.availabilityStatus === 'Available') {
                    hotelId = priceLevel.id
                    return true
                }
                return false
            })
            // It is possible that the default room is not available or was filtered out as a duplicate
            // In this case we take the cheapest room of the standard hotel
            if (!hotelId) {
                const matchingAlternativeHotel = templatePricing.alternativeHotels.find((alternativeHotel) => alternativeHotel.name === defaultHotel.name)
                const room = getCheapestAvailablePriceLevelFromList(matchingAlternativeHotel?.priceLevels)
                if (room) {
                    hotelId = room.id
                } else {
                    // If the default hotel has no available rooms we search for one in the alternative hotels
                    const alternativeHotels = templatePricing.alternativeHotels.filter((alternativeHotel) => alternativeHotel.name !== defaultHotel.name)
                    const alternativeRooms = alternativeHotels.reduce((rooms, { priceLevels }) => [...rooms, ...priceLevels], [])
                    const alternativeRoom = getCheapestAvailablePriceLevelFromList(alternativeRooms)
                    if (alternativeRoom) {
                        hotelId = alternativeRoom.id
                    }
                }
            }
            setSelectedHotelRoomId(hotelId)
            // Log initial search
            if (!!defaultFlight && !hasLoggedInitialSearch) {
                const values = getTemplateAnalyticsValues(startDate, endDate, templatePricing, [hotelId], null, selectedActivities, adults, name, collection)
                const metaData = getBreakMetaData(values, 'search', 'DYNAMIC_PACKAGE')
                logEvent('InitialSearch', metaData, journeyUid)
                setHasLoggedInitialSearch(true)
            }
        }
    }, [templatePricing])
    const handleCreateBookingTemplate = async () => {
        try {
            const input = {
                adults,
                endDate,
                children,
                startDate,
                journeyUid,
                cmsId: uuid,
                templateType: type,
                fareId: selectedFareId,
                activities: selectedActivities,
                hotelIds: [selectedHotelRoomId],
                templatePricingSearchResultId: searchResultId,
            }
            const data = await createBookingDynamicTemplate({ input, id: templateId })
            const values = getTemplateAnalyticsValues(startDate, endDate, templatePricing, [selectedHotelRoomId], null, selectedActivities, adults, name, collection)
            const metaData = getBreakMetaData(values, 'selected', 'DYNAMIC_PACKAGE')
            logEvent('SelectedFlight', metaData, journeyUid)
            const { uid } = data.createBookingDynamicTemplate.booking
            router.push(`/${fullLocale}/checkout/${uid}/travelers`)
        } catch (e) {
            dispatchError(e.message)
        }
    }
    const handleClose = () => {
        closeJourney()
        onClose()
    }

    const selectedFare = templatePricing?.alternativeFares?.find(whereId(selectedFareId))
    const defaultHotel = templatePricing?.hotels.find(({ isAlternative }) => !isAlternative)
    const selectedHotel = templatePricing?.alternativeHotels?.find(({ priceLevels }) => priceLevels.some(whereId(selectedHotelRoomId)))
    // Set better quality images from default hotel if available and possible
    if (selectedHotel?.priceLevels.some(({ priceInfo, roomName }) => priceInfo.totalPrice === defaultHotel.price && roomName === defaultHotel.priceLevelName)) {
        selectedHotel.additionalPictures = defaultHotel.additionalPictures
    }
    const absoluteEndDate = getAbsoluteEndDateFromFares([selectedFare])

    let price = templatePricing?.price
    if (selectedHotel) {
        const selectedRoom = selectedHotel.priceLevels.find(whereId(selectedHotelRoomId))
        price -= defaultHotel.price
        price += selectedRoom.priceInfo.totalPrice
    }
    if (selectedFare) {
        const defaultFlight = templatePricing.flights.find(({ isAlternative }) => !isAlternative)
        price -= defaultFlight.charge.total
        price += selectedFare.charge.total
    }
    price += getActivitiesPrice(templatePricing?.activities, selectedActivities)
    const hasResults = !!templatePricing && !!selectedFare && !!selectedHotel
    return (
        <BookDynamicTemplateModal
            name={name}
            type={type}
            endDate={endDate}
            onClose={handleClose}
            listOfAdults={adults}
            startDate={startDate}
            templateId={templateId}
            hasResults={hasResults}
            listOfChildren={children}
            selectedFare={selectedFare}
            modalHasError={modalHasError}
            selectedHotel={selectedHotel}
            numberOfRooms={numberOfRooms}
            onChangeFare={setSelectedFareId}
            price={hasResults ? price : null}
            absoluteEndDate={absoluteEndDate}
            templatePricing={templatePricing}
            availableDates={allAvailableDates}
            templateSettings={templateSettings}
            isFetchingTemplate={isFetchingTemplate}
            selectedActivities={selectedActivities}
            onChangeActivity={handleChangeActivity}
            hasChosenTravelers={hasChosenTravelers}
            selectedHotelRoomId={selectedHotelRoomId}
            onChangeHotelRoom={setSelectedHotelRoomId}
            lastPossibleReturnDate={lastPossibleReturnDate}
            onChangeDates={([_, ...dates]) => setDates(dates)}
            isFreeDateChoiceTemplate={isFreeDateChoiceTemplate}
            isFetchingTemplatePricing={isFetchingTemplatePricing}
            isCreatingBookingTemplate={isCreatingBookingTemplate}
            onCreateBookingTemplate={handleCreateBookingTemplate}
            onChangeActivityOptions={handleChangeActivityOptions}
            maxChildAge={maxChildAge && parseInt(maxChildAge, 10)}
            firstPossibleDepartureDate={firstPossibleDepartureDate}
            hasValidInputs={startDate && endDate && hasChosenTravelers}
            onCloseGuestSelect={(newGuestState) => {
                handleChangeGuestState(newGuestState)
                setHasChosenTravelers(true)
            }}
        />
    )
}

export default BookDynamicTemplateModalWithState
