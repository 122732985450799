import React, { useState } from 'react'
import Button from '../utils/Button'
import JotformModal from '../utils/JotformModal'

const FormButtonWithState = ({
    formId,
    formType,
    formTitle,
    formSubTitle,
    text,
    size,
    width,
    variant,
    iconAfterName,
    iconBeforeName,
}) => {
    const [modelIsOpen, setModelIsOpen] = useState(false)
    return (
        <>
            {modelIsOpen && (
                <JotformModal
                    formId={formId}
                    formType={formType}
                    formTitle={formTitle}
                    formSubTitle={formSubTitle}
                    onClose={() => setModelIsOpen(false)}
                />
            )}
            <Button
                size={size}
                width={width}
                variant={variant}
                iconAfterName={iconAfterName}
                iconBeforeName={iconBeforeName}
                onClick={() => setModelIsOpen(true)}
            >
                {text}
            </Button>
        </>
    )
}

export default FormButtonWithState
