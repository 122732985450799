import React from 'react'
import * as constants from '@connections/constants'
import Input from './Input'
import FormGroup from './FormGroup'
import FormInputErrors from './FormInputErrors'

const {
    JOTFORM_CONTROL_EMAIL,
    JOTFORM_CONTROL_PHONE,
    JOTFORM_CONTROL_NUMBER,
} = constants

const getInputType = (itemType) => {
    let type = 'text'
    if (itemType === JOTFORM_CONTROL_EMAIL) {
        type = 'email'
    }
    if (itemType === JOTFORM_CONTROL_PHONE) {
        type = 'phone'
    }
    if (itemType === JOTFORM_CONTROL_NUMBER) {
        type = 'number'
    }
    return type
}

const JotformInput = ({
    item,
    formState,
    isSubmitting,
    className,
}) => {
    const type = getInputType(item.type)
    const {
        getErrorMessages,
        getNativeInputProps,
    } = formState
    return (
        <div className={className}>
            <FormGroup
                isRequired={item.required}
                label={item.text}
                htmlFor={item.qid}
                description={item.subLabel}
            >
                <Input
                    id={item.qid}
                    type={type}
                    disabled={isSubmitting}
                    {...getNativeInputProps(item.name)}
                />
                <FormInputErrors errors={getErrorMessages(item.name)} />
            </FormGroup>
        </div>
    )
}

export default JotformInput
