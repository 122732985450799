import { gql } from 'graphql-request'
import useGraphqlMutation from '../hooks/useGraphqlMutation'

const MUTATION = gql`
    mutation createBookingGiftcard ($input: CreateBookingGiftcardInput!) {
        createBookingGiftcard(input: $input) {
            booking {
                id
                uid
            }
        }
    }
`

const useCreateBookingGiftcardMutation = (options) => useGraphqlMutation(MUTATION, options)

export default useCreateBookingGiftcardMutation
