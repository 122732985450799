import React from 'react'
import clsx from 'clsx'
import JotformAddressFormInput from './JotformAddressFormInput'

const SUBFIELD_STREET1 = 'st1'
const SUBFIELD_STREET2 = 'st2'
const SUBFIELD_COUNTRY = 'country'
const SUBFIELD_CITY = 'city'
const SUBFIELD_STATE = 'state'
const SUBFIELD_ZIP = 'zip'

export const SUBLABELS = {
    [SUBFIELD_STREET1]: 'addr_line1',
    [SUBFIELD_STREET2]: 'addr_line2',
    [SUBFIELD_CITY]: 'city',
    [SUBFIELD_COUNTRY]: 'country',
    [SUBFIELD_ZIP]: 'postal',
    [SUBFIELD_STATE]: 'state',
}

const JotformAddressInput = ({
    item,
    formState,
    isSubmitting,
    className,
}) => {
    const {
        qid,
        text,
        subfields,
        sublabels,
        compoundHint,
        requiredInputs,
    } = item
    if (subfields.length <= 0) {
        return 'No address subfields were selected.'
    }

    const HAS_STREET1 = typeof subfields.find((f) => f === SUBFIELD_STREET1) !== 'undefined'
    const HAS_STREET2 = typeof subfields.find((f) => f === SUBFIELD_STREET2) !== 'undefined'
    const HAS_CITY = typeof subfields.find((f) => f === SUBFIELD_CITY) !== 'undefined'
    const HAS_COUNTRY = typeof subfields.find((f) => f === SUBFIELD_COUNTRY) !== 'undefined'
    const HAS_ZIP = typeof subfields.find((f) => f === SUBFIELD_ZIP) !== 'undefined'
    const HAS_STATE = typeof subfields.find((f) => f === SUBFIELD_STATE) !== 'undefined'

    const formItems = subfields.reduce((items, field) => ({
        ...items,
        [field]: {
            id: `${qid}_${field}`,
            name: `${qid}_${SUBLABELS[field]}`,
            label: sublabels[SUBLABELS[field]],
            disabled: isSubmitting,
            isRequired: typeof requiredInputs.find((f) => f === field) !== 'undefined',
        }
    }), {})

    return (
        <div
            className={
                clsx(
                    'grid grid-cols-2 gap-4 md:gap-8',
                    className
                )
            }
        >
            {text && (
                <h4 className="font-medium mb-2 text-3xl">
                    {text}
                </h4>
            )}
            {HAS_STREET1 && (
                <JotformAddressFormInput
                    formItem={formItems[SUBFIELD_STREET1]}
                    formState={formState}
                    placeholder={compoundHint[0]}
                    className="col-span-2"
                />
            )}
            {HAS_STREET2 && (
                <JotformAddressFormInput
                    formItem={formItems[SUBFIELD_STREET2]}
                    formState={formState}
                    placeholder={compoundHint[1]}
                    className="col-span-2"
                />
            )}
            {HAS_CITY && (
                <JotformAddressFormInput
                    formItem={formItems[SUBFIELD_CITY]}
                    formState={formState}
                    placeholder={compoundHint[2]}
                    className={HAS_STATE ? 'col-span-1' : 'col-span-2'}
                />
            )}
            {HAS_STATE && (
                <JotformAddressFormInput
                    formItem={formItems[SUBFIELD_STATE]}
                    formState={formState}
                    placeholder={compoundHint[3]}
                    className={HAS_CITY ? 'col-span-1' : 'col-span-2'}
                />
            )}
            {HAS_ZIP && (
                <JotformAddressFormInput
                    formItem={formItems[SUBFIELD_ZIP]}
                    formState={formState}
                    placeholder={compoundHint[4]}
                    className={HAS_COUNTRY ? 'col-span-1' : 'col-span-2'}
                />
            )}
            {HAS_COUNTRY && (
                <JotformAddressFormInput
                    formItem={formItems[SUBFIELD_COUNTRY]}
                    formState={formState}
                    placeholder={compoundHint[5]}
                    className={HAS_ZIP ? 'col-span-1' : 'col-span-2'}
                />
            )}
        </div>
    )
}

export default JotformAddressInput
