import React from 'react'
import clsx from 'clsx'
import { motion } from 'framer-motion'
import Icon from './Icon'
import { variant } from '../../util'

export const NEXT = 'NEXT'
export const PREVIOUS = 'PREVIOUS'

const CarouselButton = ({
    direction,
    className,
    size = 'medium',
    onClick = () => { },
}) => (
    <motion.button
        exit="hidden"
        type="button"
        initial="hidden"
        animate="visible"
        onClick={onClick}
        variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
        }}
        className={clsx(
            'rounded-full bg-white bg-opacity-60 text-blue shadow-card flex items-center justify-center',
            variant(size, {
                small: 'w-9 h-9',
                medium: 'w-12 h-12',
            }),
            className,
        )}
    >
        {direction === PREVIOUS ? (
            <Icon name="arrow-left" />
        ) : (
            <Icon name="arrow-right" />
        )}
    </motion.button>
)

export default CarouselButton
