import React, { useState } from 'react'
import SbEditable from 'storyblok-react'
import useStoryblokBridge from '../../hooks/useStoryblokBridge'
import BlogPostSearchPage from './BlogPostSearchPage'
import useBlogPostSearchPageQuery from './useBlogPostSearchPageQuery'

const BlogPostSearchPageWithState = ({ data, isPreview, resolveRelations }) => {
    const [loadMoreOnPageEnd, setLoadMoreOnPageEnd] = useState(false)
    const { content } = useStoryblokBridge(data.pageStory, resolveRelations)
    const {
        hasMore,
        isFetching,
        searchResults,
        isFetchingMore,
        handleFetchMore,
    } = useBlogPostSearchPageQuery(isPreview)

    return (
        <SbEditable content={content}>
            <BlogPostSearchPage
                {...content}
                hasMore={hasMore}
                isFetching={isFetching}
                searchResults={searchResults}
                onFetchMore={handleFetchMore}
                isFetchingMore={isFetchingMore}
                loadMoreOnPageEnd={loadMoreOnPageEnd}
                onLoadMore={() => setLoadMoreOnPageEnd(true)}
            />
        </SbEditable>
    )
}

export default BlogPostSearchPageWithState
