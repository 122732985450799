import React, { useState } from 'react'
import clsx from 'clsx'
import dynamic from 'next/dynamic'
import SbEditable from 'storyblok-react'
import DestinationMapSectionCard from './DestinationMapSectionCard'
import { sectionSpacingVariant } from '../../util/variants'

const DynamicMapSectionMap = dynamic(
    () => import('./DestinationMapSectionMap'),
    { ssr: false }
)

const DestinationMapSection = ({
    content
}) => {
    const [activeContinentCode, setActiveContinentCode] = useState(null)
    const {
        anchorId,
        title,
        description,
        continents,
        bottomText,
        spacing
    } = content
    const activeContinent = continents.find(({ continentCode }) => (
        continentCode === activeContinentCode
    )) || null
    return (
        <SbEditable content={content}>
            <section id={anchorId || undefined}>
                <div
                    className={clsx(
                        'lg:flex lg:container',
                        sectionSpacingVariant({ spacing }),
                    )}
                >
                    <div className="flex-shrink flex items-center justify-center">
                        <DestinationMapSectionCard
                            title={title}
                            description={description}
                            activeContinent={activeContinent}
                            bottomText={bottomText}
                        />
                    </div>
                    <div className="relative flex-1">
                        <DynamicMapSectionMap
                            onChangeContinent={(code) => setActiveContinentCode(code)}
                            activeContinentCode={activeContinentCode}
                        />
                    </div>
                </div>
            </section>
        </SbEditable>
    )
}

export default DestinationMapSection
