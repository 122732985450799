import React from 'react'
import { Trans } from '@lingui/react'
import Modal from './Modal'
import Button, { VARIANT_BLUE_LINK } from './Button'

const ShareModal = ({
    onClose,
    onShareToFacebook,
    onShareToTwitter,
}) => (
    <Modal
        className="m-4 p-6 w-full lg:w-[24rem] min-h-[12rem] rounded-lg self-center mt-0 lg:mt-0"
        onClose={onClose}
    >
        <div className="flex justify-between items-center mb-4">
            <h3 className="text-xl font-medium tracking-tight">
                <Trans id="Share with friends" />
            </h3>
            <Button
                onClick={onClose}
                size="small"
                iconAfterName="cross"
                variant={VARIANT_BLUE_LINK}
            >
                <Trans id="Close" />
            </Button>
        </div>

        <div className="flex flex-col">
            <button
                className="flex items-center space-x-6 border-gray-100 border-b pb-5"
                type="button"
                onClick={onShareToFacebook}
            >
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M1.01333 0.151867C0.6314 0.3492 0.3082 0.676133 0.1388 1.03667L0 1.33187V16V30.6681L0.137333 30.9603C0.312533 31.3329 0.667067 31.6875 1.03973 31.8627C1.3296 31.9989 1.3942 32 9.2326 32H17.1333V25.8V19.6H15.0333H12.9333V17.2V14.8H15.0277H17.122L17.1517 12.35C17.1783 10.1566 17.1961 9.83893 17.3211 9.3168C17.5928 8.18253 18.0647 7.3074 18.8146 6.54707C19.8678 5.47913 21.1949 4.96113 23.0667 4.8874C23.9697 4.85187 26.6934 4.96327 26.9049 5.04447C26.9845 5.075 27 5.42727 27 7.2022V9.32353L25.25 9.34527C23.5885 9.36593 23.4827 9.37487 23.1588 9.52127C22.7379 9.71153 22.4539 9.9976 22.2534 10.4333C22.1059 10.7539 22.0992 10.8436 22.0782 12.7833L22.0564 14.8H24.4368H26.8172L26.7711 15.2167C26.7051 15.8133 26.2761 19.1253 26.2315 19.3833L26.1939 19.6H24.1303H22.0667V25.8V32H26.3674C30.5709 32 30.6747 31.9969 30.9603 31.8627C31.3329 31.6875 31.6875 31.3329 31.8627 30.9603L32 30.6681V16V1.33187L31.8627 1.03973C31.6875 0.667067 31.3329 0.312533 30.9603 0.137333L30.6681 0L15.9841 0.00186667L1.3 0.00373333L1.01333 0.151867Z" fill="#415698" />
                </svg>
                <span>Facebook</span>
            </button>
            <button
                className="flex items-center space-x-6 pt-5"
                type="button"
                onClick={onShareToTwitter}
            >
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M32 0H0V32H32V0ZM24.8031 12.7108C24.8031 18.2885 20.4834 24.7209 12.5836 24.721C10.1578 24.721 7.90076 24.0223 6 22.8248C6.33619 22.8639 6.67825 22.8833 7.02449 22.8833C9.03704 22.8833 10.8888 22.2088 12.3588 21.0764C10.4789 21.0422 8.89343 19.8218 8.34665 18.1445C8.60832 18.1939 8.87753 18.2206 9.15385 18.2206C9.54572 18.2206 9.92546 18.1688 10.2864 18.0721C8.32111 17.6853 6.84069 15.9784 6.84069 13.9341C6.84069 13.9153 6.84069 13.8978 6.84132 13.8802C7.41993 14.1967 8.08184 14.3868 8.78647 14.4082C7.63345 13.6518 6.87524 12.3589 6.87524 10.8944C6.87524 10.1212 7.08792 9.3966 7.45677 8.77278C9.57483 11.3269 12.74 13.0066 16.3096 13.1831C16.2359 12.8741 16.1982 12.5519 16.1982 12.2207C16.1982 9.89039 18.1216 8 20.4933 8C21.7288 8 22.8446 8.51313 23.6283 9.33364C24.6068 9.14395 25.5258 8.79253 26.3556 8.30903C26.0344 9.29455 25.3537 10.1212 24.4669 10.6438C25.3357 10.5418 26.1638 10.3155 26.9333 9.97927C26.3585 10.8257 25.6296 11.5693 24.791 12.1651C24.7993 12.3462 24.8031 12.5281 24.8031 12.7108Z" fill="#4D9FEC" />
                </svg>
                <span>Twitter</span>
            </button>
        </div>
    </Modal>
)

export default ShareModal
