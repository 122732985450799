import React, { Fragment } from 'react'
import { Trans } from '@lingui/react'
import Icon from './Icon'
import Input from './Input'
import Select from './Select'
import Button, { VARIANT_BLUE_LINK, VARIANT_BLUE_FILLED } from './Button'

const GiftcardProduct = ({
    formState,
    onCreateGiftcardBooking,
    isCreatingBookingGiftcard,
}) => {
    const { giftcards } = formState.values
    return (
        <div className="w-full flex flex-col items-start px-6 py-8">
            <div className="w-full grid grid-cols-4 gap-x-4 gap-y-2 border-b-2 border-gray-100 pb-6 text-sm">
                <p>
                    <Trans id="Quantity" />
                </p>
                <p className="col-span-3">
                    <Trans id="Value of the gift card" />
                </p>
                {giftcards.map((_, index) => (
                    <Fragment key={`giftcard-${index}`}>
                        <div>
                            <Select
                                className="flex-grow-0"
                                {...formState.getNativeInputProps(`giftcards.${index}.amount`)}
                            >
                                {[...Array(10)].map((_, i) => (
                                    <option
                                        value={i + 1}
                                        key={`giftcard-${index}-amount-${i + 1}`}
                                    >
                                        {i + 1}
                                    </option>
                                ))}
                            </Select>
                        </div>
                        <div className="col-span-3">
                            <Input
                                iconBefore={(
                                    <Icon
                                        name="euro"
                                        size="smaller"
                                        className="mt-0.5"
                                    />
                                )}
                                iconAfter={index !== 0 && (
                                    <button
                                        type="button"
                                        onClick={() => formState.onRemoveGiftcard(index)}
                                    >
                                        <Icon
                                            size="smaller"
                                            color="primary"
                                            name="trash-alt"
                                            className="mt-0.5"
                                        />
                                    </button>
                                )}
                                {...formState.getNativeInputProps(`giftcards.${index}.value`)}
                            />
                            <span className="text-xs text-primary mt-1">
                                {formState.getErrorMessages(`giftcards.${index}.value`)[0]}
                            </span>
                        </div>
                    </Fragment>
                ))}
            </div>
            {giftcards.length < 3 && (
                <Button
                    iconBeforeName="plus"
                    className="block pl-0 mt-8"
                    variant={VARIANT_BLUE_LINK}
                    onClick={formState.onAddGiftcard}
                >
                    <Trans id="add another gift card" />
                </Button>
            )}
            <Button
                className="w-full mt-8"
                variant={VARIANT_BLUE_FILLED}
                onClick={onCreateGiftcardBooking}
                isLoading={isCreatingBookingGiftcard}
            >
                <Trans id="Order your gift card(s)" />
            </Button>
        </div>
    )
}

export default GiftcardProduct
