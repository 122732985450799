import React from 'react'
import Page from '../../layout/Page'
import PageMain from '../../layout/PageMain'
import StickyHeader from '../../layout/StickyHeader'
import HeaderWithContent from '../../layout/HeaderWithContent'
import FooterWithContent from '../../layout/FooterWithContent'
import Heading from '../../content/Heading'
import DynamicSection from '../../content/DynamicSection'

const ExcursionPage = ({
    content,
}) => {
    const {
        price,
        title,
        body = [],
        headImage,
        headVideo,
        description,
        headingOptions = [{}],
    } = content

    return (
        <Page data-testid="ExcursionPage">
            <StickyHeader>
                <HeaderWithContent />
            </StickyHeader>
            <PageMain>
                <Heading
                    price={price}
                    title={title}
                    image={headImage}
                    video={headVideo}
                    description={description}
                    {...headingOptions[0]}
                />
                {body && body.map((sectionContent) => (
                    <DynamicSection
                        pageContent={content}
                        content={sectionContent}
                        key={sectionContent._uid}
                    />
                ))}
            </PageMain>
            <FooterWithContent />
        </Page>
    )
}

export default ExcursionPage
