import React from 'react'
import clsx from 'clsx'
import useFlightProps from '../../hooks/useFlightProps'
import FlightOverview from '../../utils/FlightOverview'
import CheckoutCard, { VARIANT_UNPADDED } from '../../utils/CheckoutCard'
import { combineAirRoutes } from '../flight-search/FlightSearchPageResultCard'
import BookDynamicTemplateFlightsModalFlightCardEconomics from './BookDynamicTemplateFlightsModalFlightCardEconomics'

const BookDynamicTemplateFlightsModalFlightCard = ({
    fare,
    className,
    selectedFare,
    onChooseFare,
    numberOfTravelers,
    ...props
}) => {
    const { airRoutes, baggageAllowed } = fare
    const isSelected = selectedFare.id === fare.id
    const airRouteCollections = combineAirRoutes(airRoutes)
    const { getFlightPropsFromAirRoute } = useFlightProps()
    return (
        <CheckoutCard
            className={className}
            variant={VARIANT_UNPADDED}
            {...props}
        >
            <div
                className={
                    clsx(
                        'flex flex-col lg:flex-row mb-4 lg:mb-0 border rounded bg-white',
                        isSelected ? 'bg-gray-50 border-gray-400' : 'bg-white border-gray-100'
                    )
                }
            >
                <div className="flex-grow">
                    {airRouteCollections.map((airRouteCollection, collectionIndex) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={collectionIndex}>
                            {collectionIndex !== 0 && (
                                <div className="h-px border-b border-gray-200 border-dashed w-11/12 mx-auto" />
                            )}
                            {airRouteCollection.map((airRoute, index, array) => {
                                const flightPropsFromAirRoute = getFlightPropsFromAirRoute(airRoute)

                                return (
                                    <FlightOverview
                                        index={index}
                                        amountOfFlights={array.length}
                                        baggageAllowed={baggageAllowed}
                                        key={flightPropsFromAirRoute.id}
                                        {...flightPropsFromAirRoute}
                                    />
                                )
                            })}
                        </div>
                    ))}
                </div>
                <BookDynamicTemplateFlightsModalFlightCardEconomics
                    fare={fare}
                    isSelected={isSelected}
                    selectedFare={selectedFare}
                    onChooseFare={onChooseFare}
                    numberOfTravelers={numberOfTravelers}
                />
            </div>
        </CheckoutCard>
    )
}

export default BookDynamicTemplateFlightsModalFlightCard
