import React from 'react'
import SbEditable from 'storyblok-react'
import RichTextField from '../content/RichTextField'
import Icon from './Icon'

const RichTextIcon = ({
    content,
}) => {
    const {
        icon,
        text,
    } = content
    return (
        <SbEditable content={content}>
            <div className="flex mb-2">
                <Icon
                    name={icon}
                    className="text-blue-bright mr-4"
                />
                <RichTextField content={text} />
            </div>
        </SbEditable>
    )
}

export default RichTextIcon
