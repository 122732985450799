import React from 'react'
import dynamic from 'next/dynamic'
import Page from '../../layout/Page'
import StickyHeader from '../../layout/StickyHeader'
import DynamicSection from '../../content/DynamicSection'
import HeaderWithContent from '../../layout/HeaderWithContent'
import FooterWithContent from '../../layout/FooterWithContent'

const TravelSearchPageResults = dynamic(() => import('./TravelSearchPageResults'), { ssr: false })
const SearchFiltersSidebar = dynamic(() => import('../../utils/SearchFiltersSidebar'), { ssr: false })

const TravelSearchPage = ({
    sortBy,
    travels,
    content,
    hasMore,
    filters,
    isUpdating,
    isFetching,
    onFetchMore,
    sortOptions,
    onChangeSortBy,
    selectedValues,
    isFetchingMore,
    onResetFilters,
    filterFormState,
    totalAmountOfTravels,
}) => (
    <Page data-testid="TravelSearchPage">
        <StickyHeader>
            <HeaderWithContent />
        </StickyHeader>
        <main className="min-h-screen bg-gray-50">
            <div className="w-full flex flex-col lg:flex-row lg:container">
                <SearchFiltersSidebar
                    filters={filters}
                    isFetching={isFetching}
                    formState={filterFormState}
                    onResetFilters={onResetFilters}
                    className="lg:border-r lg:border-gray-100"
                />
                <TravelSearchPageResults
                    sortBy={sortBy}
                    travels={travels}
                    hasMore={hasMore}
                    isFetching={isFetching}
                    isUpdating={isUpdating}
                    sortOptions={sortOptions}
                    onFetchMore={onFetchMore}
                    emptyText={content.emptyText}
                    selectedValues={selectedValues}
                    onChangeSortBy={onChangeSortBy}
                    isFetchingMore={isFetchingMore}
                    onResetFilters={onResetFilters}
                    emptyTitle={content.emptyTitle}
                    totalAmountOfTravels={totalAmountOfTravels}
                />
            </div>
            {content.body && content.body.map((section) => (
                <DynamicSection
                    content={section}
                    key={section._uid}
                />
            ))}
        </main>
        <FooterWithContent />
    </Page>
)

export default TravelSearchPage
