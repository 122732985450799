import React from 'react'
import clsx from 'clsx'
import Input from './Input'
import Select from './Select'
import FormGroup from './FormGroup'
import FormInputErrors from './FormInputErrors'

const JotformFullNameInput = ({
    item,
    formState,
    isSubmitting,
    className,
}) => {
    const {
        getErrorMessages,
        getNativeInputProps,
    } = formState
    const {
        qid,
        prefix,
        middle,
        suffix,
        sublabels,
        compoundHint,
        prefixChoices,
        requiredInputs,
    } = item
    const fields = Object.keys(item.sublabels)
    const formItems = fields.reduce((items, field) => ({
        ...items,
        [field]: {
            id: `${qid}_${field}`,
            name: `${qid}_${field}`,
            label: sublabels[field],
            disabled: isSubmitting,
            isRequired: typeof requiredInputs.find((f) => f === field) !== 'undefined',
        }
    }), {})
    const prefixOptions = [
        '',
        ...prefixChoices
    ]

    return (
        <div
            className={
                clsx(
                    'col-span-2 grid gap-4 md:gap-8',
                    middle ? 'grid-cols-3' : 'grid-cols-2',
                    className
                )
            }
        >
            {prefix && (
                <FormGroup
                    isRequired={formItems.prefix.isRequired}
                    label={formItems.prefix.label}
                    htmlFor={formItems.prefix.id}
                >
                    {prefixChoices.length > 0 ? (
                        <Select
                            id={formItems.prefix.id}
                            placeholder={compoundHint[0]}
                            disabled={item.readOnly || isSubmitting}
                            {...getNativeInputProps(formItems.prefix.name)}
                        >
                            {prefixOptions.map((option) => (
                                <option
                                    key={option}
                                    value={option}
                                >
                                    {option}
                                </option>
                            ))}
                        </Select>
                    ) : (
                        <Input
                            id={formItems.prefix.id}
                            placeholder={compoundHint[0]}
                            disabled={item.readOnly || isSubmitting}
                            {...getNativeInputProps(formItems.prefix.name)}
                        />
                    )}
                    <FormInputErrors errors={getErrorMessages(formItems.prefix.name)} />
                </FormGroup>
            )}
            <FormGroup
                isRequired={formItems.first.isRequired}
                label={formItems.first.label}
                htmlFor={formItems.first.id}
            >
                <Input
                    id={formItems.first.id}
                    placeholder={compoundHint[1]}
                    disabled={item.readOnly || isSubmitting}
                    {...getNativeInputProps(formItems.first.name)}
                />
                <FormInputErrors errors={getErrorMessages(formItems.first.name)} />
            </FormGroup>
            {middle && (
                <FormGroup
                    isRequired={formItems.middle.isRequired}
                    label={formItems.middle.label}
                    htmlFor={formItems.middle.id}
                >
                    <Input
                        id={formItems.middle.id}
                        placeholder={compoundHint[2]}
                        disabled={item.readOnly || isSubmitting}
                        {...getNativeInputProps(formItems.middle.name)}
                    />
                    <FormInputErrors errors={getErrorMessages(formItems.middle.name)} />
                </FormGroup>
            )}
            <FormGroup
                isRequired={formItems.last.isRequired}
                label={formItems.last.label}
                htmlFor={formItems.last.id}
            >
                <Input
                    id={formItems.last.id}
                    placeholder={compoundHint[3]}
                    disabled={item.readOnly || isSubmitting}
                    {...getNativeInputProps(formItems.last.name)}
                />
                <FormInputErrors errors={getErrorMessages(formItems.last.name)} />
            </FormGroup>
            {suffix && (
                <FormGroup
                    isRequired={formItems.suffix.isRequired}
                    label={formItems.suffix.label}
                    htmlFor={formItems.suffix.id}
                >
                    <Input
                        id={formItems.suffix.id}
                        placeholder={compoundHint[4]}
                        disabled={item.readOnly || isSubmitting}
                        {...getNativeInputProps(formItems.suffix.name)}
                    />
                    <FormInputErrors errors={getErrorMessages(formItems.suffix.name)} />
                </FormGroup>
            )}
        </div>
    )
}

export default JotformFullNameInput
