import React from 'react'
import clsx from 'clsx'
import * as constants from '@connections/constants'

const {
    BACKGROUND_BLUE: BLUE,
    BACKGROUND_BLACK: BLACK,
} = constants

const TabsColumnDesktopTabs = ({
    background,
    groups,
    activeTab,
    onChange,
    className,
}) => {
    const hasDarkBackground = background === BLUE || background === BLACK
    return (
        <div
            className={
                clsx(
                    'hidden sm:flex',
                    className,
                )
            }
        >
            <nav className="grid grid-flow-col gap-10 border-b border-gray-100 overflow-visible" aria-label="Tabs">
                {groups.map((group, index) => {
                    const isActive = index === activeTab
                    return (
                        <button
                            key={group._uid}
                            type="button"
                            className={clsx(
                                'uppercase font-medium border-b-2 pb-4 -mb-px',
                                isActive && 'text-primary border-primary',
                                !hasDarkBackground && !isActive && 'text-black border-transparent',
                                hasDarkBackground && !isActive && 'text-white border-transparent',
                            )}
                            aria-current={isActive ? 'page' : undefined}
                            onClick={() => onChange(index)}
                        >
                            {group.title}
                        </button>
                    )
                })}
            </nav>
        </div>
    )
}

export default TabsColumnDesktopTabs
