import React, { forwardRef } from 'react'
import clsx from 'clsx'
import SbEditable from 'storyblok-react'
import { ALIGN_BOTTOM, ALIGN_CENTER } from '@connections/constants'
import { variant, getHrefFromLink } from '../../util/index'
import Image from '../utils/Image'
import TextLink from '../utils/TextLink'
import usePathnameLocale from '../hooks/usePathnameLocale'

const GallerySectionSlide = forwardRef(({
    images,
    background,
    className,
}, ref) => {
    const locale = usePathnameLocale()
    return (
        <div
            className={clsx(
                'inline-flex',
                className,
            )}
            ref={ref}
        >
            {images.map((galleryImage, imageIndex) => {
                const {
                    image,
                    links,
                    text,
                    rotation,
                    position,
                    size,
                    orientation,
                } = galleryImage
                if (!image) {
                    return null
                }
                return (
                    <SbEditable
                        content={galleryImage}
                        key={image.filename}
                    >
                        <div
                            className={clsx(
                                'inline-block mr-20 whitespace-normal',
                                variant(position, {
                                    [ALIGN_BOTTOM]: 'mt-44 self-end',
                                    [ALIGN_CENTER]: 'self-center',
                                })
                            )}
                        >
                            <div className={clsx(
                                'transform',
                                variant(rotation, {
                                    left: 'rotate-1',
                                    right: '-rotate-1',
                                    random: imageIndex % 2 === 0 ? 'rotate-1' : '-rotate-1',
                                })
                            )}
                            >
                                {image?.filename && (
                                    <div className={clsx(
                                        'relative',
                                        variant(size, {
                                            default: 'w-[26rem]',
                                            large: 'w-[33rem]',
                                        }),
                                        variant(orientation, {
                                            portrait: 'h-[28.5rem]',
                                            landscape: 'pt-[24rem]',
                                            square: 'pt-[100%]',
                                        })
                                    )}
                                    >
                                        <Image
                                            fill
                                            maxWidth={750}
                                            alt={image.alt}
                                            draggable={false}
                                            src={image.filename}
                                            className="object-cover"
                                        />
                                    </div>
                                )}
                                {text && (
                                    <span className={clsx(
                                        'font-accent text-2xl mt-2 block',
                                        variant(background, {
                                            white: 'text-black',
                                            'gray-50': 'text-black',
                                            'gray-100': 'text-black',
                                            black: 'text-white',
                                            blue: 'text-white',
                                        }),
                                    )}
                                    >
                                        {text}
                                    </span>
                                )}
                            </div>
                            {links && links.length > 0 && (
                                links.map((link) => (
                                    <TextLink
                                        key={link._uid}
                                        href={getHrefFromLink(link, locale)}
                                        iconBeforeName={link.iconBeforeName}
                                        iconAfterName={link.iconAfterName}
                                        className={clsx(
                                            'mt-2 block w-full',
                                            background === 'blue' ? 'text-white' : 'text-blue-bright',
                                        )}
                                    >
                                        {link.text}
                                    </TextLink>
                                ))
                            )}
                        </div>
                    </SbEditable>
                )
            })}
        </div>
    )
})

export default GallerySectionSlide
