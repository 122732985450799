import React from 'react'
import { format } from 'date-fns'
import { Trans } from '@lingui/react'
import { SbToValidDate } from '@connections/utils'
import Icon from './Icon'
import Image from './Image'
import Modal from './Modal'
import SbButton from './SbButton'
import SbLanguage from './SbLanguage'
import Button, { VARIANT_BLUE_OUTLINED } from './Button'

const ProgramBlockModal = ({
    image,
    slots,
    title,
    button,
    onClose,
    description,
}) => (
    <Modal
        onClose={onClose}
        className="px-6 py-8 w-full min-h-full lg:w-[56rem] lg:mt-24 lg:min-h-0"
    >
        <div className="flex flex-wrap gap-4 md:gap-8">
            <div className="relative w-full h-80 rounded-lg overflow-hidden md:w-80 md:h-[30rem]">
                <div className="sm:hidden">
                    <Image
                        {...image}
                        fill
                        gravity="face"
                        maxWidth={320}
                        maxHeight={320}
                        className="object-cover"
                    />
                </div>
                <div className="hidden sm:block md:hidden">
                    <Image
                        {...image}
                        fill
                        gravity="face"
                        maxWidth={640}
                        maxHeight={320}
                        className="object-cover"
                    />
                </div>
                <div className="hidden md:block">
                    <Image
                        {...image}
                        fill
                        gravity="face"
                        maxWidth={320}
                        maxHeight={480}
                        className="object-cover"
                    />
                </div>
            </div>
            <div className="flex flex-col md:py-0 flex-1">
                <div className="flex justify-between items-center">
                    <h4 className="text-lg md:text-2xl font-medium mb-2">
                        {title}
                    </h4>
                    <button
                        type="button"
                        onClick={onClose}
                        className="hidden md:flex items-center gap-1 text-blue-bright"
                    >
                        <Trans id="Close" />
                        <Icon name="cross" size="smaller" />
                    </button>
                </div>
                {Object.keys(slots).map((slotTitle) => (
                    <div className="mb-2" key={slotTitle}>
                        <span className="text-sm md:text-base text-gray-600 mb-1 block">
                            {slotTitle}
                        </span>
                        <div className="flex flex-wrap mb-2 gap-2 md:table">
                            {slots[slotTitle].map((slot) => {
                                const {
                                    group,
                                    language,
                                    timeEnd,
                                    timeStart,
                                } = slot
                                const start = timeStart && format(SbToValidDate(timeStart), 'HH:mm')
                                const end = timeEnd && format(SbToValidDate(timeEnd), 'HH:mm')

                                return (
                                    <div className="group flex text-xs md:text-sm gap-y-1 gap-x-6 flex-wrap md:table-row md:mb-2" key={timeStart}>
                                        <div className="w-full font-medium sm:table-cell mr-4 sm:w-auto">
                                            <div className="md:mb-1 group-last:mb-0">
                                                {group}
                                            </div>
                                        </div>
                                        <div className="md:table-cell">
                                            {start && end && (
                                                <div className="inline-flex items-center gap-1 align-middle md:ml-4">
                                                    <Icon
                                                        name="clock"
                                                        size="small"
                                                        className="text-blue-bright"
                                                    />
                                                    {start} - {end}
                                                </div>
                                            )}
                                        </div>
                                        <div className="md:table-cell">
                                            <div className="inline-flex items-center gap-1 align-middle md:ml-4">
                                                <Icon
                                                    size="small"
                                                    name="comment-dots"
                                                    className="text-blue-bright"
                                                />
                                                <SbLanguage language={language} />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                ))}
                <p className="text-sm md:text-base mb-14 mt-4">
                    {description}
                </p>
                {button && (
                    <div className="flex flex-start">
                        <SbButton content={button} className="w-full md:auto" />
                    </div>
                )}
                <Button
                    onClick={onClose}
                    className="md:hidden mt-4"
                    variant={VARIANT_BLUE_OUTLINED}
                >
                    <Trans id="Close" />
                </Button>
            </div>
        </div>
    </Modal>
)

export default ProgramBlockModal
