import useAppState from './useAppState'

const useLocalizedCity = (iataCode) => {
    const { airportCodes } = useAppState()
    const airport = airportCodes[iataCode] || null
    if (airport === null) {
        return {
            code: iataCode,
        }
    }
    return {
        name: airport.name,
        code: iataCode,
        nameExtension: airport.nameExtension,
    }
}

export default useLocalizedCity
