import { useEffect } from 'react'
import usePathnameLocale from '../hooks/usePathnameLocale'

export default function ZendeskScript({ loadZendesk }) {
    const simpleLocale = usePathnameLocale()

    useEffect(() => {
        if (loadZendesk) {
            zE('messenger:set', 'locale', simpleLocale)
            zE('messenger', 'show')
        } else {
            zE('messenger', 'hide')
        }
    }, [loadZendesk, simpleLocale])

    return null
}
