import useAppState from './useAppState'

const useLocalizedCodes = ({
    continentCodes = [],
    regionCodes = [],
    countryCodes = [],
    stateCodes = [],
    cityCodes = [],
}) => {
    const {
        destinationContinentCodes,
        destinationRegionCodes,
        destinationCountryCodes,
        destinationStateCodes,
        destinationCityCodes,
    } = useAppState()
    const codes = [
        ...continentCodes.map((code) => destinationContinentCodes[code]),
        ...regionCodes.map((code) => destinationRegionCodes[code]),
        ...countryCodes.map((code) => destinationCountryCodes[code]),
        ...stateCodes.map((code) => destinationStateCodes[code]),
        ...cityCodes.map((code) => destinationCityCodes[code]),
    ]
    return codes.filter((code) => !!code)
}

export default useLocalizedCodes
