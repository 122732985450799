import React from 'react'
import { Trans } from '@lingui/react'
import Button from './Button'
import ModalSlideUp from './ModalSlideUp'
import ModalSlideUpHeader from './ModalSlideUpHeader'
import ModalSlideUpFooter from './ModalSlideUpFooter'
import ModalSlideUpContent from './ModalSlideUpContent'
import GuestsListbox from './GuestsListbox'
import useGuestsState from './useGuestsState'

const GuestsModal = ({
    maxChildAge,
    initialListOfAdults,
    initialListOfChildren,
    initialNumberOfRooms,
    onClose,
    onConfirm,
    ...props
}) => {
    const {
        adults: listOfAdults,
        children: listOfChildren,
        numberOfRooms,
        handleChangeAdults,
        handleChangeChildren,
        handleChangeNumberOfRooms,
        handleChangeChildAge,
    } = useGuestsState({
        initialAdults: initialListOfAdults,
        initialChildren: initialListOfChildren,
        initialNumberOfRooms,
    })
    return (
        <ModalSlideUp
            isFullScreen
            onClose={onClose}
            {...props}
        >
            <ModalSlideUpHeader
                title={<Trans id="Guests" />}
                onClose={onClose}
            />
            <ModalSlideUpContent>
                <GuestsListbox
                    maxChildAge={maxChildAge}
                    listOfAdults={listOfAdults}
                    listOfChildren={listOfChildren}
                    numberOfRooms={numberOfRooms}
                    onChangeChildAge={handleChangeChildAge}
                    onChangeAdults={handleChangeAdults}
                    onChangeChildren={handleChangeChildren}
                    onChangeNumberOfRooms={handleChangeNumberOfRooms}
                    onPressEscape={onClose}
                />
            </ModalSlideUpContent>
            <ModalSlideUpFooter className="flex items-center">
                <Button
                    className="mr-4 w-full"
                    variant="blue-outlined"
                    onClick={onClose}
                >
                    <Trans id="Close" />
                </Button>
                <Button
                    className="w-full"
                    variant="blue-filled"
                    iconAfterName="arrow-right"
                    onClick={() => onConfirm({
                        adults: listOfAdults,
                        children: listOfChildren,
                        numberOfRooms,
                    })}
                    data-testid="GuestsModalConfirmButton"
                >
                    <Trans id="Confirm" />
                </Button>
            </ModalSlideUpFooter>
        </ModalSlideUp>
    )
}

export default GuestsModal
