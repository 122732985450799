import React from 'react'
import { Trans } from '@lingui/react'
import { whereOriginalProductId } from '@connections/utils'
import { BREAK_ENTITY, TOUR_ENTITY } from '@connections/constants'
import Icon from '../../utils/Icon'
import BookTemplateModalTours from '../book-template/BookTemplateModalTours'
import BookTemplateModalActivity from '../book-template/BookTemplateModalActivity'
import BookTemplateModalTransports from '../book-template/BookTemplateModalTransports'
import BookDynamicTemplateChoices from './BookDynamicTemplateChoices'
import BookDynamicTemplateModalHotel from './BookDynamicTemplateModalHotel'
import BookDynamicTemplateModalFlights from './BookDynamicTemplateModalFlights'

const BookDynamicTemplateModalOverview = ({
    type,
    name,
    endDate,
    startDate,
    hasResults,
    selectedFare,
    onChangeFare,
    onChangeTour,
    selectedHotel,
    numberOfRooms,
    selectedTourId,
    numberOfAdults,
    templatePricing,
    onChangeActivity,
    numberOfChildren,
    onChangeHotelRoom,
    selectedActivities,
    selectedHotelRoomId,
    onChangeActivityOptions,
}) => {
    const numberOfTravelers = numberOfAdults + numberOfChildren
    if (!hasResults) {
        return (
            <div className="flex flex-col flex-1 items-center justify-center">
                <Icon
                    color="gray"
                    size="largest"
                    name="calendar-slash"
                />
                <p className="text-gray-400 font-medium mt-4 mb-1 text-center">
                    {(() => {
                        if (type === BREAK_ENTITY) {
                            return <Trans id="Unfortunately this break is no longer available for the selected date range and/or room layout." />
                        }
                        if (type === TOUR_ENTITY) {
                            return <Trans id="Unfortunately this tour is no longer available for the selected date range and/or room layout." />
                        }
                        return null
                    })()}
                </p>
                <p className="text-sm text-gray-400">
                    <Trans id="Please make an other selection." />
                </p>
            </div>
        )
    }
    const {
        tours,
        transports,
        activities,
        alternativeFares,
    } = templatePricing
    alternativeFares.sort((a, b) => a.charge.total - b.charge.total)
    const includedActivities = selectedActivities === null
        ? []
        : activities.filter((activity) => selectedActivities.some(whereOriginalProductId(activity.productId)))
    const optionalActivities = selectedActivities === null
        ? []
        : activities.filter((activity) => !selectedActivities.some(whereOriginalProductId(activity.productId)))
    return (
        <>
            <div className="grid grid-cols-1 md:grid-cols-2 divide-y divide-gray-100 md:divide-y-0 gap-y-8 md:gap-y-0 border-b border-gray-100 pb-6">
                <div className="md:pr-10 md:border-r md:border-gray-100">
                    {selectedFare
                        ? <BookDynamicTemplateModalFlights fare={selectedFare} />
                        : null}
                </div>
                <div className="pt-8 md:pt-0 md:pl-10">
                    {selectedTourId && (
                        <BookTemplateModalTours
                            name={name}
                            tours={tours}
                            onChangeTour={onChangeTour}
                            numberOfRooms={numberOfRooms}
                            selectedTourId={selectedTourId}
                            numberOfAdults={numberOfAdults}
                            numberOfChildren={numberOfChildren}
                        />
                    )}
                    {selectedHotel ? (
                        <BookDynamicTemplateModalHotel
                            hotel={selectedHotel}
                            numberOfRooms={numberOfRooms}
                            numberOfTravelers={numberOfTravelers}
                            selectedHotelRoomId={selectedHotelRoomId}
                        />
                    ) : null}
                </div>
                <div className="md:col-span-2 mt-6">
                    <BookDynamicTemplateChoices
                        selectedFare={selectedFare}
                        onChangeFare={onChangeFare}
                        selectedHotel={selectedHotel}
                        numberOfRooms={numberOfRooms}
                        numberOfAdults={numberOfAdults}
                        templatePricing={templatePricing}
                        numberOfChildren={numberOfChildren}
                        onChangeHotelRoom={onChangeHotelRoom}
                        selectedHotelRoomId={selectedHotelRoomId}
                    />
                </div>
            </div>
            {transports?.length > 0 ? (
                <BookTemplateModalTransports
                    transports={transports}
                    className="border-b border-gray-100 py-6"
                />
            ) : null}
            {includedActivities.map((activity) => (
                <BookTemplateModalActivity
                    landing={endDate}
                    activity={activity}
                    departure={startDate}
                    key={activity.productId}
                    onChangeActivity={onChangeActivity}
                    numberOfTravelers={numberOfTravelers}
                    onChangeActivityOptions={onChangeActivityOptions}
                    selectedOptions={selectedActivities.find(whereOriginalProductId(activity.productId))}
                />
            ))}
            {optionalActivities.length > 0 && (
                <>
                    <h2 className="text-lg font-medium mt-6">
                        <Trans id="Optional activities" />
                    </h2>
                    {optionalActivities.map((activity) => (
                        <BookTemplateModalActivity
                            landing={endDate}
                            activity={activity}
                            departure={startDate}
                            key={activity.productId}
                            onChangeActivity={onChangeActivity}
                            numberOfTravelers={numberOfTravelers}
                            onChangeActivityOptions={onChangeActivityOptions}
                            selectedOptions={selectedActivities.find(whereOriginalProductId(activity.productId))}
                        />
                    ))}
                </>
            )}
        </>
    )
}

export default BookDynamicTemplateModalOverview
