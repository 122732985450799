import React from 'react'
import clsx from 'clsx'
import * as constants from '@connections/constants'
import { variant } from '../../util/index'
import CollapsableItem from './CollapsableItem'

const {
    CONTAINER_EXTRA_SMALL: EXTRA_SMALL,
    CONTAINER_SMALL: SMALL,
    CONTAINER_DEFAULT: DEFAULT,
} = constants

const CollapsableSection = ({
    anchorId,
    background,
    container,
    spacing,
    collapsableItems = [],
    expanded,
    onExpand,
    className,
}) => (
    <section
        id={anchorId || undefined}
        data-testid="CollapsableSection"
        className={clsx(
            'flex flex-col',
            variant(background, {
                white: 'bg-white',
                'gray-50': 'bg-gray-50',
                'gray-100': 'bg-gray-100',
                black: 'bg-black',
                blue: 'bg-blue',
            }),
            variant(spacing, {
                small: 'py-12',
                medium: 'py-24',
                large: 'py-36',
            }),
            className,
        )}
    >
        <div
            className={clsx(
                'w-full',
                variant(container, {
                    [EXTRA_SMALL]: 'container-xs',
                    [SMALL]: 'container-sm',
                    [DEFAULT]: 'container',
                })
            )}
        >
            {collapsableItems.map((collapsableItem, index) => (
                <CollapsableItem
                    key={collapsableItem._uid}
                    content={collapsableItem}
                    isOpen={expanded === index}
                    onExpand={() => onExpand(index)}
                    className="mb-8"
                />
            ))}
        </div>
    </section>
)

export default CollapsableSection
