import React from 'react'
import { Trans } from '@lingui/react'
import Page from '../../layout/Page'
import PageMain from '../../layout/PageMain'
import StickyHeader from '../../layout/StickyHeader'
import HeaderWithContent from '../../layout/HeaderWithContent'
import FooterWithContent from '../../layout/FooterWithContent'
import Heading from '../../content/Heading'
import DynamicSection from '../../content/DynamicSection'
import DestinationPageSubHeader from './DestinationPageSubHeader'

const DestinationPage = ({
    _uid,
    name,
    price,
    title,
    body = [],
    headImage,
    headVideo,
    description,
    videoEndOffset,
    videoStartOffset,
    headingOptions = [{}],
}) => (
    <Page data-testid="DestinationPage">
        <StickyHeader>
            <HeaderWithContent />
            <DestinationPageSubHeader name={name} />
        </StickyHeader>
        <PageMain>
            <Heading
                key={_uid} // DO not remove (video does not refresh otherwise)
                price={price}
                title={title}
                image={headImage}
                video={headVideo}
                videoStartOffset={videoStartOffset}
                videoEndOffset={videoEndOffset}
                description={description}
                pricePrefix={<Trans id="Flights from" />}
                {...headingOptions[0]}
            />
            {body && body.map((content) => (
                <DynamicSection
                    key={content._uid}
                    content={content}
                />
            ))}
        </PageMain>
        <FooterWithContent />
    </Page>
)

export default DestinationPage
