import React from 'react'
import { Trans } from '@lingui/react'
import { BLOG_POST_CARD } from '@connections/constants'
import Page from '../../layout/Page'
import PageMain from '../../layout/PageMain'
import StickyHeader from '../../layout/StickyHeader'
import HeaderWithContent from '../../layout/HeaderWithContent'
import FooterWithContent from '../../layout/FooterWithContent'
import Spinner from '../../utils/Spinner'
import InfiniteScroll from '../../utils/InfiniteScroll'
import DynamicSection from '../../content/DynamicSection'
import CardsSectionWithState from '../../content/CardsSectionWithState'

const mapNodeToCard = (entity) => ({
    ...entity.content,
    blogPost: entity,
    component: BLOG_POST_CARD,
})

const BlogPostSearchPage = ({
    hasMore,
    body = [],
    isFetching,
    onLoadMore,
    onFetchMore,
    searchResults,
    isFetchingMore,
    loadMoreOnPageEnd,
}) => (
    <Page data-testid="BlogPostSearchPage">
        <StickyHeader>
            <HeaderWithContent />
        </StickyHeader>
        <PageMain className="mb-12">
            {(() => {
                if (isFetching) {
                    return (
                        <div className="container min-h-[500px] flex justify-center items-center">
                            <Spinner color="gray" />
                        </div>
                    )
                }
                if (searchResults.length === 0) {
                    return (
                        <div className="container min-h-[500px] flex justify-center items-center">
                            <p className="mb-16">
                                <Trans id="No blog posts found for these search parameters." />
                            </p>
                        </div>
                    )
                }
                return (
                    <InfiniteScroll
                        hasMore={hasMore}
                        loadMoreOnPageEnd={loadMoreOnPageEnd}
                        onLoadMore={onLoadMore}
                        onFetchMore={onFetchMore}
                        isFetchingMore={isFetchingMore}
                    >
                        <CardsSectionWithState
                            className="py-12"
                            content={{
                                columns: 4,
                                cards: searchResults.map(mapNodeToCard),
                            }}
                        />
                    </InfiniteScroll>
                )
            })()}
        </PageMain>
        {body && body.map((content) => (
            <DynamicSection
                key={content._uid}
                content={content}
            />
        ))}
        <FooterWithContent />
    </Page>
)

export default BlogPostSearchPage
