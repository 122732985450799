/* eslint-disable prefer-destructuring */
import React from 'react'
import { Trans } from '@lingui/react'
import { BREAK_ENTITY, TOUR_ENTITY } from '@connections/constants'
import Icon from '../../utils/Icon'
import Modal from '../../utils/Modal'
import BookModalError from '../../utils/BookModalError'
import BookModalBanner from '../../utils/BookModalBanner'
import ModalCloseButton from '../../utils/ModalCloseButton'
import Button, { VARIANT_PRIMARY_FILLED } from '../../utils/Button'
import BookTemplateModalLoading from '../book-dynamic-template/BookTemplateModalLoading'
import BookTemplateModalInputs from './BookTemplateModalInputs'
import BookTemplateModalOverview from './BookTemplateModalOverview'

const BookTemplateModal = ({
    name,
    type,
    price,
    endDate,
    onClose,
    startDate,
    isFetching,
    maxChildAge,
    listOfAdults,
    onChangeTour,
    modalHasError,
    numberOfRooms,
    onChangeDates,
    selectedTourId,
    listOfChildren,
    availableDates,
    hasValidInputs,
    templatePricing,
    absoluteEndDate,
    onChangeActivity,
    selectedHotelIds,
    templateSettings,
    currentTemplateId,
    onChooseHotelRoom,
    isFetchingContent,
    selectedActivities,
    onCloseGuestSelect,
    hasChosenTravelers,
    onChangeActivityOptions,
    onCreateBookingTemplate,
    isCreatingBookingTemplate,
}) => (
    <Modal
        onClose={onClose}
        data-testid="BookTemplateModal"
        className="w-full lg:w-[1000px] min-h-screen lg:min-h-[585px] px-4 py-10 lg:px-10 flex flex-col"
    >
        <ModalCloseButton onClick={onClose} />
        <BookModalBanner
            name={name}
            price={price}
            endDate={endDate}
            className="-mt-5"
            startDate={startDate}
            listOfAdults={listOfAdults}
            numberOfRooms={numberOfRooms}
            listOfChildren={listOfChildren}
            absoluteEndDate={absoluteEndDate}
        />
        {availableDates && !modalHasError && (
            <BookTemplateModalInputs
                endDate={endDate}
                startDate={startDate}
                maxChildAge={maxChildAge}
                listOfAdults={listOfAdults}
                numberOfRooms={numberOfRooms}
                onChangeDates={onChangeDates}
                hasValidInputs={hasValidInputs}
                listOfChildren={listOfChildren}
                availableDates={availableDates}
                absoluteEndDate={absoluteEndDate}
                currentProductId={currentTemplateId}
                onCloseGuestSelect={onCloseGuestSelect}
                hasChosenTravelers={hasChosenTravelers}
            />
        )}
        {isFetching ? (
            <BookTemplateModalLoading className="my-auto" />
        ) : (
            <>
                {(() => {
                    if (modalHasError) {
                        return (
                            <BookModalError
                                name={name}
                                className="my-auto"
                                fallbackFormId={templateSettings.fallbackFormId}
                            />
                        )
                    }
                    if (!hasValidInputs) {
                        return (
                            <div className="flex flex-col flex-1 items-center justify-center">
                                <Icon
                                    color="gray"
                                    size="largest"
                                    name="calendar"
                                />
                                <p className="text-gray-400 font-medium mt-4 mb-1 text-center">
                                    <Trans id="Please select your travel dates and the amount of travellers" />
                                </p>
                            </div>
                        )
                    }
                    return (
                        <BookTemplateModalOverview
                            type={type}
                            name={name}
                            endDate={endDate}
                            startDate={startDate}
                            onChangeTour={onChangeTour}
                            numberOfRooms={numberOfRooms}
                            isFetching={isFetchingContent}
                            selectedTourId={selectedTourId}
                            templatePricing={templatePricing}
                            templateSettings={templateSettings}
                            selectedHotelIds={selectedHotelIds}
                            onChangeActivity={onChangeActivity}
                            numberOfAdults={listOfAdults.length}
                            onChooseHotelRoom={onChooseHotelRoom}
                            selectedActivities={selectedActivities}
                            numberofChildren={listOfChildren.length}
                            onChangeActivityOptions={onChangeActivityOptions}
                        />
                    )
                })()}
                {!isFetchingContent && !modalHasError && templatePricing && (
                    <BookModalBanner
                        name={name}
                        price={price}
                        endDate={endDate}
                        startDate={startDate}
                        className="mt-6 lg:mt-8"
                        listOfAdults={listOfAdults}
                        numberOfRooms={numberOfRooms}
                        listOfChildren={listOfChildren}
                        absoluteEndDate={absoluteEndDate}
                        action={(
                            <Button
                                className="w-full"
                                data-testid="BookTemplateButton"
                                variant={VARIANT_PRIMARY_FILLED}
                                onClick={onCreateBookingTemplate}
                                isLoading={isCreatingBookingTemplate}
                            >
                                {(() => {
                                    switch (type) {
                                        case BREAK_ENTITY:
                                            return <Trans id="Book this break" />
                                        case TOUR_ENTITY:
                                            return <Trans id="Book this holiday" />
                                        default:
                                            return <Trans id="Book now" />
                                    }
                                })()}
                            </Button>
                        )}
                    />
                )}
            </>
        )}
    </Modal>
)

export default BookTemplateModal
