import React from 'react'
import SbEditable from 'storyblok-react'
import { getEntityContentOverrides } from '../../util/entities'
import usePathnameLocale from '../hooks/usePathnameLocale'
import useDestinationPrice from '../hooks/useDestinationPrice'
import DestinationCard from './DestinationCard'

const DestinationCardWithState = ({
    content,
    columnWidth,
    ...props
}) => {
    const {
        buttonText,
        destination,
        hideGradient,
        openInNewTab,
    } = content
    const locale = usePathnameLocale()
    const { price } = useDestinationPrice(destination)
    const {
        href,
        image,
        title,
        description,
    } = getEntityContentOverrides(content, destination, locale)

    if (!href) {
        return null
    }

    return (
        <SbEditable content={content}>
            <DestinationCard
                href={href}
                price={price}
                image={image}
                title={title}
                buttonText={buttonText}
                description={description}
                hideGradient={hideGradient}
                openInNewTab={openInNewTab}
                {...props}
            />
        </SbEditable>
    )
}

export default DestinationCardWithState
