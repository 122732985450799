import React from 'react'
import SbEditable from 'storyblok-react'
import IconFeaturesSection from './IconFeaturesSection'

const IconFeaturesSectionWithState = ({
    content,
}) => (
    <SbEditable content={content}>
        <IconFeaturesSection
            {...content}
        />
    </SbEditable>
)

export default IconFeaturesSectionWithState
