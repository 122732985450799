import useStoryblokQuery from '../../hooks/useStoryblokQuery'
import usePathnameLocale from '../../hooks/usePathnameLocale'

const useBlogPostPageQuery = (isPreview, { page = 1, perPage = 12 } = {}) => {
    const locale = usePathnameLocale()
    const params = {
        page,
        is_startpage: 0,
        language: locale,
        per_page: perPage,
        starts_with: 'inspiratie/',
        version: isPreview ? 'draft' : 'published',
        excluding_slugs: '_test/*,preview/*,*/hidden/*',
    }
    const {
        data,
        error,
        isLoading: isFetching,
    } = useStoryblokQuery('blog-post-search', 'cdn/stories/', params)

    let searchResults = []
    if (isFetching) {
        return {
            isFetching,
            searchResults,
        }
    }

    if (error) {
        return {
            error,
            isFetching,
            searchResults,
        }
    }

    const {
        total,
        data: { stories },
    } = data
    searchResults = stories
    return {
        total,
        perPage,
        isFetching,
        searchResults,
    }
}

export default useBlogPostPageQuery
