import React from 'react'
import Stars from '../../utils/Stars'
import SubHeader from '../../layout/SubHeader'
import ShareTitle from '../../utils/ShareTitle'

const HotelSubHeader = ({
    name,
    stars,
}) => (
    <SubHeader>
        <div className="flex items-center gap-4">
            <ShareTitle>
                {name}
            </ShareTitle>
            <Stars total={stars} />
        </div>
    </SubHeader>
)

export default HotelSubHeader
