import { gql } from 'graphql-request'
import useGraphqlQuery from './useGraphqlQuery'

const QUERY = gql`
    query jotform($id: String!) {
        jotform(id: $id) {
            qid
            hint
            name
            text
            type
            order
            width
            prefix
            middle
            suffix
            shrink
            newLine
            options
            required
            allowTime
            limitDate
            selected
            subLabel
            subHeader
            sublabels
            subfields
            textAlign
            headerType
            validation
            buttonAlign
            compoundHint
            prefixChoices
            requiredInputs
            hidden
        }
    }
`

const useJotformQuery = (id) => {
    const variables = { id }
    const options = { enabled: !!id }
    const {
        data,
        error,
        isLoading: loading,
    } = useGraphqlQuery('jotForm', QUERY, variables, options)
    if (loading || !options.enabled) {
        return {
            loading,
            form: [],
        }
    }
    if (error) {
        return {
            error,
            loading,
            form: [],
        }
    }
    return {
        error,
        loading,
        form: data.jotform,
    }
}

export default useJotformQuery
