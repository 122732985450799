import React from 'react'
import Page from '../../layout/Page'
import PageMain from '../../layout/PageMain'
import StickyHeader from '../../layout/StickyHeader'
import HeaderWithContent from '../../layout/HeaderWithContent'
import FooterWithContent from '../../layout/FooterWithContent'
import DynamicSection from '../../content/DynamicSection'
import ExpertPageHeading from './ExpertPageHeading'

const ExpertPage = ({
    lastname,
    firstname,
    body = [],
    description,
    profileImage,
    headingOptions = [{}],
}) => (
    <Page data-testid="ExpertPage">
        <StickyHeader>
            <HeaderWithContent />
        </StickyHeader>
        <PageMain>
            <ExpertPageHeading
                firstname={firstname}
                lastname={lastname}
                description={description}
                profileImage={profileImage}
                {...headingOptions[0]}
            />

            {body && body.map((content) => (
                <DynamicSection
                    key={content._uid}
                    content={content}
                />
            ))}
        </PageMain>
        <FooterWithContent />
    </Page>
)

export default ExpertPage
