/* eslint-disable prefer-destructuring */
import React from 'react'
import { Trans } from '@lingui/react'
import Modal from '../../utils/Modal'
import ModalCloseButton from '../../utils/ModalCloseButton'
import BookTemplateModalRoomButtonCard from './BookTemplateModalRoomButtonCard'

const BookTemplateModalRooms = ({
    rooms,
    onClose,
    hotelName,
    onChooseRoom,
    selectedRoom,
    numberOfTravelers,
}) => (
    <Modal
        onClose={onClose}
        className="px-4 py-10 w-full min-h-full lg:w-[700px] lg:min-h-[600px] lg:mt-24 lg:px-10"
    >
        <ModalCloseButton onClick={onClose} />
        <h1 className="text-lg font-medium">
            {hotelName}
        </h1>
        <p className="text-sm text-gray-600">
            <Trans id="Choose your room type" />
        </p>
        {rooms.sort((a, b) => a.price - b.price).map(({
            price,
            productId,
            priceLevelName,
        }) => {
            const isSelected = productId === selectedRoom.productId
            const priceDifference = price - selectedRoom.price
            return (
                <BookTemplateModalRoomButtonCard
                    key={productId}
                    roomName={priceLevelName}
                    value={isSelected}
                    priceDifference={priceDifference}
                    numberOfTravelers={numberOfTravelers}
                    onChange={() => onChooseRoom(productId)}
                />
            )
        })}
    </Modal>
)

export default BookTemplateModalRooms
