import React from 'react'
import clsx from 'clsx'
import SbEditable from 'storyblok-react'
import { variant } from '../../util/index'
import {
    sectionBackgroundVariant,
    sectionContainerVariant,
    sectionSpacingVariant,
    sectionTextOnBackgroundVariant,
} from '../../util/variants'
import CustomText from '../utils/CustomText'

const ALIGN_CENTER = 'center'
const ALIGN_LEFT = 'left'
const ALIGN_RIGHT = 'right'

const TitleSection = ({
    content,
    className,
}) => {
    const {
        title,
        description,
        background,
        spacing,
        container,
        align,
    } = content
    return (
        <SbEditable content={content}>
            <section
                className={clsx(
                    sectionBackgroundVariant(background),
                    sectionSpacingVariant({ spacing }),
                    className,
                )}
            >
                <div
                    className={clsx(
                        'flex flex-col',
                        sectionContainerVariant(container),
                        variant(align, {
                            [ALIGN_CENTER]: 'items-center text-center',
                            [ALIGN_LEFT]: 'items-end text-right',
                            [ALIGN_RIGHT]: 'items-start text-left',
                        }),
                    )}
                >
                    {title && (
                        <CustomText
                            as="h3"
                            customText={title}
                            customTextClassName="text-4xl md:text-[40px] font-accent "
                            className={clsx(
                                'text-lg md:text-4xl',
                                sectionTextOnBackgroundVariant(background)
                            )}
                        />
                    )}
                    {description && (
                        <p
                            className={clsx(
                                'text-sm md:text-base mt-8',
                                sectionTextOnBackgroundVariant(background, 'text-gray-600')
                            )}
                        >
                            {description}
                        </p>
                    )}
                </div>
            </section>
        </SbEditable>
    )
}

export default TitleSection
