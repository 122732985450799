import React from 'react'
import clsx from 'clsx'
import DynamicButton from './DynamicButton'

const FlightsListerSectionButtons = ({
    buttons = [],
    className,
}) => {
    if (buttons.length <= 0) {
        return null
    }

    return (
        <div className={clsx('flex flex-col sm:flex-row flex-wrap justify-center mt-20', className)}>
            {buttons.map((button) => (
                <DynamicButton
                    key={button._uid}
                    content={button}
                    className="my-2 sm:mx-2 sm:my-0 w-full sm:w-auto justify-center"
                />
            ))}
        </div>
    )
}

export default FlightsListerSectionButtons
