import { gql } from 'graphql-request'
import useGraphqlQuery from '../../hooks/useGraphqlQuery'

const QUERY = gql`
    query template($id: ID!, $journeyUid: ID!) {
        template(id: $id, journeyUid: $journeyUid) {
            id
            availableDates {
                endDate
                startDate
                productId
            }
        }
    }
`

const useTemplateQuery = (journeyUid, id, isFreeDateChoiceTemplate, availableDates) => {
    const variables = {
        id,
        journeyUid,
    }
    const options = {
        enabled: journeyUid !== null && !isFreeDateChoiceTemplate && !availableDates,
    }
    const {
        data,
        error,
        isLoading: isFetching,
    } = useGraphqlQuery(['template', variables], QUERY, variables, options)
    const allAvailableDates = availableDates || data?.template?.availableDates

    return {
        error,
        isFetching,
        allAvailableDates,
    }
}

export default useTemplateQuery
