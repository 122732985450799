import React from 'react'
import { Trans } from '@lingui/react'
import useFlightProps from '../../hooks/useFlightProps'
import BookModalFlight from '../../utils/BookModalFlight'

const BookDynamicTemplateModalFlights = ({
    fare,
}) => {
    const { airRoutes, baggageAllowed } = fare
    const { getFlightPropsFromAirRoute } = useFlightProps()
    return (
        <>
            <h2 className="text-lg font-medium mb-6">
                <Trans
                    values={{ amount: airRoutes.length }}
                    id="{amount, plural, =1 {Flight} other {Flights}}"
                />
            </h2>
            {airRoutes.map((airRoute, index) => {
                const {
                    origin,
                    departureTime,
                    ...flightProps
                } = getFlightPropsFromAirRoute(airRoute)
                const isLastEntry = index === airRoutes.length - 1
                return (
                    <BookModalFlight
                        {...flightProps}
                        baggageAllowed={baggageAllowed}
                        origin={origin}
                        isLastEntry={isLastEntry}
                        departureTime={departureTime}
                        key={`${origin}-${departureTime}`}
                    />
                )
            })}
        </>
    )
}

export default BookDynamicTemplateModalFlights
