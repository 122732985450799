import React from 'react'
import { Trans } from '@lingui/react'
import Stars from '../../utils/Stars'
import Button, { VARIANT_BLUE_FILLED } from '../../utils/Button'
import RichTextField from '../../content/RichTextField'
import BookBreakButtonWithContent from '../../content/BookBreakButtonWithContent'

const HotelPageDetails = ({
    name,
    type,
    stars,
    address,
    expediaId,
    className,
    breakButton,
    description,
    externalUrl,
    richDescription,
}) => (
    <section className={className}>
        <div className="flex flex-row mb-3">
            {type && (
                <span className="uppercase text-gray-600 text-xs mr-4">{type}</span>
            )}
            <Stars total={stars} />
        </div>
        <h1 className="text-3xl font-medium mb-3">{name}</h1>
        {address && (
            <h3 className="text-gray-400 font-medium mb-3">{address}</h3>
        )}
        <div className="md:max-w-[65%] mb-8 opacity-70">
            {richDescription ? (
                <RichTextField content={richDescription} />
            ) : (
                <p>{description}</p>
            )}
        </div>
        {(() => {
            if (breakButton) {
                return <BookBreakButtonWithContent content={breakButton} />
            }
            let link
            if (expediaId) {
                link = `https://hotels.connections.be/${expediaId}`
            }
            // External url overrides expedia
            if (externalUrl) {
                link = externalUrl.url
            }
            if (link) {
                return (
                    <Button
                        as="a"
                        href={link}
                        target="_blank"
                        variant={VARIANT_BLUE_FILLED}
                    >
                        <Trans id="Book now" />
                    </Button>
                )
            }
            return null
        })()}
    </section>
)

export default HotelPageDetails
