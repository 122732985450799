import React, {
    useRef,
    useMemo,
    useState,
    useEffect,
} from 'react'
import isEqual from 'lodash.isequal'
import SbEditable from 'storyblok-react'
import { stringify } from 'query-string'
import { usePathname } from 'next/navigation'
import { whereType } from '@connections/utils'
import * as constants from '@connections/constants'
import { encodeQueryParams } from 'use-query-params'
import { getCabinClass } from '../../../util/booking/flights'
import { bookingMetaToSelectedMeta } from '../../../util/analytics/flights'
import useLogEvent from '../../hooks/useLogEvent'
import useLocalizedCity from '../../hooks/useLocalizedCity'
import useNotifications from '../../hooks/useNotifications'
import useStoryblokBridge from '../../hooks/useStoryblokBridge'
import useLocaleNavigateTo from '../../hooks/useLocaleNavigateTo'
import QueryParamProvider from '../../providers/QueryParamProvider'
import useSortedFlightResults from '../../hooks/useSortedFlightResults'
import useFilteredFlightResults from '../../hooks/useFilteredFlightResults'
import useFlightSearchExperiment from '../../hooks/useFlightSearchExperiment'
import { getQuickSearchFlightQueryParamsConfig, useQuickSearchFlightQueryParams } from '../../content/useQuickSearchFlightFormState'
import FlightSearchPage from './FlightSearchPage'
import useFlightSearchPageQuery from './useFlightSearchPageQuery'
import FlightSearchStaleResultModal from './FlightSearchStaleResultModal'
import useCreateBookingFlightMutation from './useCreateBookingFlightMutation'
import FlightSearchLoadingModal, { STAGE_CREATING, STAGE_TRANSFERRING } from './FlightSearchLoadingModal'

const {
    BOOKING_ITEM_TYPE_FLIGHT: FLIGHT_ITEM,
} = constants

const combineFares = (fares) => fares.reduce((acc, fare) => {
    if (!fare) {
        return acc
    }
    let existingFareIndex = null
    const existingFare = acc.find((accFare, index) => {
        const hasEqualCharge = isEqual(accFare.charge, fare.charge)
        const hasEqualBaggage = isEqual(accFare.baggageAllowed, fare.baggageAllowed)
        const hasEqualPlatingCarrier = isEqual(accFare.platingCarrier, fare.platingCarrier)
        const isEqualFare = hasEqualCharge && hasEqualBaggage && hasEqualPlatingCarrier
        if (isEqualFare) {
            existingFareIndex = index
        }
        return isEqualFare
    })
    if (existingFare && existingFareIndex !== null) {
        const accClone = [...acc]
        accClone[existingFareIndex] = {
            ...existingFare,
            airRoutes: [
                ...existingFare.airRoutes,
                ...fare.airRoutes,
            ],
        }
        return accClone
    }
    return [...acc, fare]
}, [])

const FlightSearchPageWithState = ({
    data,
    reviewScores,
    resolveRelations,
}) => {
    const mainRef = useRef(null)
    const logEvent = useLogEvent()
    const pathname = usePathname()
    const resultsRef = useRef(null)
    const navigateTo = useLocaleNavigateTo()
    const getLocalizedCity = useLocalizedCity()
    const { dispatchError } = useNotifications()
    const { updatedUi } = useFlightSearchExperiment()
    const [isStaleResult, setIsStaleResult] = useState(false)
    const [params, setParams] = useQuickSearchFlightQueryParams()
    const [qsmIsCollapsed, setQsmIsCollapsed] = useState(updatedUi)
    const [selectedFareTicket, setSelectedFareTicket] = useState(null)
    const { content } = useStoryblokBridge(data.pageStory, resolveRelations)
    const [isCreatingBookingStage, setIsCreatingBookingStage] = useState(null)
    const { mutateAsync: createBookingFlight } = useCreateBookingFlightMutation()
    const search = stringify(encodeQueryParams(getQuickSearchFlightQueryParamsConfig(params.fareType), params))
    const resultsUrl = `${pathname}?${search}`
    const hasSearchQuery = params.to[0] !== null && params.from[0] !== null && params.departure[0] !== null
    const {
        error,
        fares,
        refetch,
        isFetching,
        journeyUid,
        isRefetching,
    } = useFlightSearchPageQuery(params, resultsUrl, hasSearchQuery)
    const selectedFare = fares ? fares.find(({ ticket }) => ticket === selectedFareTicket) : null
    const combinedFares = useMemo(() => (fares ? combineFares(fares) : []), [fares])
    const {
        filters,
        filterParams,
        filteredFares,
        onResetFilters,
        setFilterParams,
        canResetFilters,
    } = useFilteredFlightResults(combinedFares, selectedFareTicket)
    const {
        sortBy,
        sortOptions,
        sortedFares,
        cheapestFares,
        shortestFares,
        onChangeSortBy,
        recommendedRule,
        recommendedFares,
    } = useSortedFlightResults(filteredFares, selectedFare, content)

    const handleChooseFare = (newTicket) => {
        setSelectedFareTicket(newTicket)
        if (newTicket) {
            resultsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
    }

    useEffect(() => {
        if (!isFetching && fares.length > 0 && mainRef?.current) {
            const y = mainRef.current.getBoundingClientRect().top + window.pageYOffset - 150
            window.scrollTo({ top: y })
        }
    }, [isFetching, fares, mainRef])

    const handleCreateBookingFlight = async (fareId) => {
        setIsCreatingBookingStage(STAGE_CREATING)
        try {
            const fare = fares.find(({ id }) => id === fareId)
            const { ticket } = fare
            const input = {
                ticket,
                resultsUrl,
                journeyUid,
                mode: params.mode,
                adults: params.adults,
                infants: params.infants,
                children: params.children,
                cabinClass: getCabinClass(params.fareType),
            }
            const { createBookingFlight: booking } = await createBookingFlight({ input })
            if (booking === null) {
                setIsCreatingBookingStage(null)
                setIsStaleResult(true)
            } else {
                const { uid, items } = booking
                const { brandedFares } = items.find(whereType(FLIGHT_ITEM)).data
                const hasPackages = brandedFares?.length > 0
                const metaData = bookingMetaToSelectedMeta(booking.data.metaData)
                logEvent('SelectedFlight', metaData, journeyUid)
                setIsCreatingBookingStage(STAGE_TRANSFERRING)
                if (hasPackages) {
                    navigateTo({ pathname: `/checkout/${uid}/packages` })
                } else {
                    navigateTo({ pathname: `/checkout/${uid}/travelers` })
                }
            }
        } catch (e) {
            dispatchError(e)
            setIsCreatingBookingStage(null)
        }
    }
    const handleRemoveSelection = () => {
        handleChooseFare(null)
    }

    const fromCode = params.from[0]?.split('-')[1]
    const from = useMemo(() => (getLocalizedCity(fromCode)), [fromCode])
    return (
        <SbEditable content={content}>
            <FlightSearchPage
                from={from}
                error={error}
                sortBy={sortBy}
                filters={filters}
                content={content}
                mainRef={mainRef}
                resultsRef={resultsRef}
                sortOptions={sortOptions}
                sortedFares={sortedFares}
                alerts={data.alertStories}
                reviewScores={reviewScores}
                filterParams={filterParams}
                cheapestFares={cheapestFares}
                qsmIsCollapsed={qsmIsCollapsed}
                shortestFare={shortestFares[0]}
                hasSearchQuery={hasSearchQuery}
                onChangeSortBy={onChangeSortBy}
                onChooseFare={handleChooseFare}
                onResetFilters={onResetFilters}
                recommendedRule={recommendedRule}
                selectedFare={selectedFareTicket}
                setFilterParams={setFilterParams}
                canResetFilters={canResetFilters}
                isFetching={isFetching || isRefetching}
                onRemoveSelection={handleRemoveSelection}
                onOpenQsm={() => setQsmIsCollapsed(false)}
                onCloseQsm={() => setQsmIsCollapsed(true)}
                onCreateBookingFlight={handleCreateBookingFlight}
                recommendedFare={recommendedRule ? recommendedFares[0] : null}
            />
            {isCreatingBookingStage !== null ? (
                <FlightSearchLoadingModal stage={isCreatingBookingStage} />
            ) : null}
            {isStaleResult ? (
                <FlightSearchStaleResultModal
                    onClose={() => setIsStaleResult(false)}
                    onNewSearch={() => {
                        setIsStaleResult(false)
                        setParams({ journeyUid }, 'replace')
                        window.scrollTo({ top: 0, behavior: 'smooth' })
                    }}
                    onRepeatSearch={() => {
                        refetch()
                        setIsStaleResult(false)
                        window.scrollTo({ top: 0, behavior: 'smooth' })
                    }}
                />
            ) : null}
        </SbEditable>
    )
}

export default (props) => (
    <QueryParamProvider>
        <FlightSearchPageWithState {...props} />
    </QueryParamProvider>
)
