import React from 'react'
import * as constants from '@connections/constants'
import JotformText from './JotformText'
import JotformInput from './JotformInput'
import JotformButton from './JotformButton'
import JotformHeading from './JotformHeading'
import JotformRadioInput from './JotformRadioInput'
import JotformAddressInput from './JotformAddressInput'
import JotformDateCalendar from './JotformDateCalendar'
import JotformDropdownInput from './JotformDropdownInput'
import JotformFullNameInput from './JotformFullNameInput'
import JotformTextareaInput from './JotformTextareaInput'
import JotformCheckboxInput from './JotformCheckboxInput'

const {
    JOTFORM_CONTROL_TEXT,
    JOTFORM_CONTROL_HEAD,
    JOTFORM_CONTROL_EMAIL,
    JOTFORM_CONTROL_PHONE,
    JOTFORM_CONTROL_RADIO,
    JOTFORM_CONTROL_BUTTON,
    JOTFORM_CONTROL_NUMBER,
    JOTFORM_CONTROL_TEXTBOX,
    JOTFORM_CONTROL_ADDRESS,
    JOTFORM_CONTROL_DATETIME,
    JOTFORM_CONTROL_DROPDOWN,
    JOTFORM_CONTROL_FULLNAME,
    JOTFORM_CONTROL_TEXTAREA,
    JOTFORM_CONTROL_CHECKBOX,
} = constants

const Components = {
    [JOTFORM_CONTROL_TEXT]: JotformText,
    [JOTFORM_CONTROL_HEAD]: JotformHeading,
    [JOTFORM_CONTROL_PHONE]: JotformInput,
    [JOTFORM_CONTROL_EMAIL]: JotformInput,
    [JOTFORM_CONTROL_NUMBER]: JotformInput,
    [JOTFORM_CONTROL_TEXTBOX]: JotformInput,
    [JOTFORM_CONTROL_BUTTON]: JotformButton,
    [JOTFORM_CONTROL_BUTTON]: JotformButton,
    [JOTFORM_CONTROL_RADIO]: JotformRadioInput,
    [JOTFORM_CONTROL_ADDRESS]: JotformAddressInput,
    [JOTFORM_CONTROL_DATETIME]: JotformDateCalendar,
    [JOTFORM_CONTROL_DROPDOWN]: JotformDropdownInput,
    [JOTFORM_CONTROL_FULLNAME]: JotformFullNameInput,
    [JOTFORM_CONTROL_TEXTAREA]: JotformTextareaInput,
    [JOTFORM_CONTROL_CHECKBOX]: JotformCheckboxInput,
}

const JotformDynamicItem = ({
    formItem,
    ...props
}) => {
    if (formItem.hidden === 'Yes') {
        return null
    }
    const Component = Components[formItem.type]
    if (Component) {
        return (
            <Component item={formItem} {...props} />
        )
    }
    return 'Not available yet'
}

export default JotformDynamicItem
