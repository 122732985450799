import React from 'react'
import JsonLd from '../utils/JsonLd'
import useLocalizedAirline from '../hooks/useLocalizedAirlineDeprecated'
import DestinationFlightsListerCardMobile from './DestinationFlightsListerCardMobile'
import DestinationFlightsListerCardDesktop from './DestinationFlightsListerCardDesktop'

const DestinationFlightsListerCard = ({
    onSelect,
    flightPrice,
}) => {
    const {
        price,
        returnDate,
        airlineCode,
        arrivalCity,
        arrivalCode,
        departureDate,
        departureCode,
        departureCity,
        arrivalAirport,
        departureAirport,
    } = flightPrice
    const airline = useLocalizedAirline(airlineCode)
    const hasImage = airline?.imageSquareUrl
    const hasPrice = typeof price !== 'undefined'
    const isRoundTrip = !!(departureDate && returnDate)
    const props = {
        price,
        airline,
        hasImage,
        hasPrice,
        returnDate,
        arrivalCity,
        arrivalCode,
        isRoundTrip,
        departureCity,
        departureCode,
        departureDate,
        arrivalAirport,
        departureAirport,
    }
    return (
        <>
            <JsonLd
                type="Flight"
                item={{
                    arrivalAirport,
                    departureAirport,
                    provider: airline.name,
                    arrivalTime: returnDate,
                    departureTime: departureDate,
                }}
            />
            <DestinationFlightsListerCardMobile onSelect={onSelect} {...props} />
            <DestinationFlightsListerCardDesktop onSelect={onSelect} {...props} />
        </>
    )
}

export default DestinationFlightsListerCard
