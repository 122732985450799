import React from 'react'
import { Trans } from '@lingui/react'
import { useQueries } from 'react-query'
import Icon from '../utils/Icon'
import DynamicButton from './DynamicButton'
import CustomText from '../utils/CustomText'
import usePathnameLocale from '../hooks/usePathnameLocale'
import useStoryblokClient from '../hooks/useStoryblokClient'

const useMapSectionQuery = (activeContinent = null) => {
    const locale = usePathnameLocale()
    const storyblokClient = useStoryblokClient()
    const skip = activeContinent === null
    const continentCode = activeContinent && activeContinent.continentCode
    const destinationFilter = { continentCode: { in: continentCode } }
    const filter = { continentCodes: { in_array: continentCode } }
    const language = locale
    const activitiesOptions = {
        filter_query: filter,
        starts_with: encodeURI('activiteiten/'),
        excluding_slugs: '_test/*,preview/*,*/hidden/*',
        language,
    }
    const destinationsOptions = {
        filter_query: destinationFilter,
        starts_with: encodeURI('vliegtickets/'),
        excluding_slugs: '_test/*,preview/*,*/hidden/*',
        language,
    }
    const toursOptions = {
        filter_query: filter,
        starts_with: encodeURI('rondreizen/'),
        excluding_slugs: '_test/*,preview/*,*/hidden/*',
        language,
    }
    const breaksOptions = {
        filter_query: filter,
        starts_with: encodeURI('breaks/'),
        excluding_slugs: '_test/*,preview/*,*/hidden/*',
        language,
    }
    const responses = useQueries([{
        enabled: !skip,
        queryKey: ['activiteiten', activitiesOptions],
        queryFn: () => storyblokClient.get('cdn/stories/', activitiesOptions),
    }, {
        enabled: !skip,
        queryKey: ['vliegtickets', destinationsOptions],
        queryFn: () => storyblokClient.get('cdn/stories/', destinationsOptions),
    }, {
        enabled: !skip,
        queryKey: ['rondreizen', toursOptions],
        queryFn: () => storyblokClient.get('cdn/stories/', toursOptions),
    }, {
        enabled: !skip,
        queryKey: ['breaks', breaksOptions],
        queryFn: () => storyblokClient.get('cdn/stories/', breaksOptions),
    }
    ])

    const loading = responses.some(({ isLoading }) => isLoading)
    if (loading) {
        return {
            loading: true,
            activitiesTotal: 0,
            destinationsTotal: 0,
            toursTotal: 0,
            breaksTotal: 0,
        }
    }
    let error
    responses.some((response) => {
        if (response.error) {
            error = response.error
            return true
        }
        return false
    })
    if (error || skip) {
        return {
            error,
            loading: false,
            activitiesTotal: 0,
            destinationsTotal: 0,
            toursTotal: 0,
            breaksTotal: 0,
        }
    }
    return {
        loading: false,
        activitiesTotal: responses[0].data.headers.total,
        destinationsTotal: responses[1].data.headers.total,
        toursTotal: responses[2].data.headers.total,
        breaksTotal: responses[3].data.headers.total,
    }
}

const MapSectionCardGridItem = ({
    count,
    loading,
    children,
}) => (
    <div className="flex items-center">
        <div className="font-accent text-5xl text-blue-bright mr-4">
            {loading ? '-' : count}
        </div>
        <div className="font-medium">
            {children}
        </div>
    </div>
)

const MapSectionTitle = ({
    props,
    children
}) => (
    <CustomText
        as="h3"
        className="text-black text-4xl font-medium mb-4"
        customText={children}
        customTextClassName="font-accent text-5xl"
        {...props}
    />
)

const MapSectionText = (props) => (
    <p className="text-gray-600 mb-6 leading-7" {...props} />
)

const DestinationMapSectionCard = ({
    title,
    description,
    activeContinent,
    bottomText,
}) => {
    const {
        loading,
        toursTotal,
        breaksTotal,
        activitiesTotal,
        destinationsTotal,
    } = useMapSectionQuery(activeContinent)
    return (
        <div className="px-8 py-12 rounded-lg w-[475px] md:shadow-card">
            {(() => {
                if (activeContinent === null) {
                    return (
                        <>
                            <MapSectionTitle>
                                {title}
                            </MapSectionTitle>
                            <MapSectionText>
                                {description}
                            </MapSectionText>
                            {bottomText && (
                                <div>
                                    <span className="font-accent text-2xl">
                                        {bottomText}
                                        <Icon className="hidden align-middle ml-1 lg:inline-block" name="arrow-right" />
                                        <Icon className="inline-block align-middle ml-1 lg:hidden" name="arrow-down" />
                                    </span>
                                </div>
                            )}
                        </>
                    )
                }
                return (
                    <>
                        <MapSectionTitle>
                            {activeContinent.title}
                        </MapSectionTitle>
                        <MapSectionText>
                            {activeContinent.description}
                        </MapSectionText>
                        <div className="grid grid-cols-2 mb-6">
                            <MapSectionCardGridItem
                                loading={loading}
                                count={toursTotal}
                            >
                                <Trans id="Tours" />
                            </MapSectionCardGridItem>
                            <MapSectionCardGridItem
                                loading={loading}
                                count={breaksTotal}
                            >
                                <Trans id="Breaks" />
                            </MapSectionCardGridItem>
                            <MapSectionCardGridItem
                                loading={loading}
                                count={activitiesTotal}
                            >
                                <Trans id="Activities" />
                            </MapSectionCardGridItem>
                            <MapSectionCardGridItem
                                loading={loading}
                                count={destinationsTotal}
                            >
                                <Trans id="Destinations" />
                            </MapSectionCardGridItem>
                        </div>
                        <div>
                            {activeContinent.buttons && activeContinent.buttons.map((button) => (
                                <DynamicButton
                                    key={button._uid}
                                    content={button}
                                />
                            ))}
                        </div>
                    </>
                )
            })()}

        </div>
    )
}

export default DestinationMapSectionCard
