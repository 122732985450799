import React from 'react'
import Head from 'next/head'

const JsonLd = ({
    type,
    item
}) => {
    const finalItem = {
        '@context': 'https://schema.org',
        '@type': type,
        ...item
    }
    return (
        <Head>
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify(finalItem, null, 4),
                }}
            />
        </Head>
    )
}
export default JsonLd
