import React, { Suspense } from 'react'
import NextLink from 'next/link'
import SbEditable from 'storyblok-react'
import { getHrefFromLink } from '../../util/index'
import usePathnameLocale from '../hooks/usePathnameLocale'
import useAddValuesToSearchParams from '../hooks/useAddValuesToSearchParams'
import Button from './Button'
import ConditionalWrapper from './ConditionalWrapper'

const SbButton = ({
    content,
    ...props
}) => {
    const locale = usePathnameLocale()
    const { addValuesToSearchParams } = useAddValuesToSearchParams()

    const {
        size,
        text,
        link,
        width,
        variant,
        openInNewTab,
        iconAfterName,
        iconBeforeName,
        jotformId, // deprecated
    } = content
    const hasForm = !!jotformId
    const isLink = !hasForm
    const href = getHrefFromLink(link, locale)

    return (
        <SbEditable content={content}>
            <ConditionalWrapper
                condition={isLink}
                wrapper={(children) => (
                    <NextLink legacyBehavior href={href}>
                        {children}
                    </NextLink>
                )}
            >
                <Button
                    as={hasForm ? 'button' : 'a'}
                    href={!hasForm ? href : null}
                    target={openInNewTab ? '_blank' : undefined}
                    size={size}
                    variant={variant}
                    width={width}
                    iconAfterName={iconAfterName}
                    iconBeforeName={iconBeforeName}
                    onClick={() => {
                        if (hasForm) {
                            addValuesToSearchParams({ jotformId })
                        }
                    }}
                    {...props}
                >
                    {text}
                </Button>
            </ConditionalWrapper>
        </SbEditable>
    )
}

export default (props) => (
    <Suspense>
        <SbButton {...props} />
    </Suspense>
)
