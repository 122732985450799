import React from 'react'
import SbEditable from 'storyblok-react'
import clsx from 'clsx'
import DynamicButton from './DynamicButton'
import SbLink from '../utils/SbLinkDeprecated'

const ListItem = ({
    content
}) => (
    <SbEditable content={content}>
        <div className={clsx(
            'grid grid-cols-1 gap-4 px-6 py-5  border-b border-gray-100 place-content-center',
            'md:grid-cols-10',
            'last:first:p-0 last:border-0',
        )}
        >
            <div className="md:col-span-4 font-bold flex items-center">
                {content.title}
            </div>
            <div className="md:col-span-4 text-gray-600 flex items-center">
                {content.subtitle}
            </div>
            <div className="md:col-span-2 flex flex-wrap items-center gap-4 md:justify-end">
                {content.actions.map((action) => {
                    switch (action.component) {
                        case 'Link':
                            return (
                                <SbLink
                                    key={action._uid}
                                    content={action}
                                    className="text-blue-bright"
                                />
                            )
                        case 'Button':
                            return (
                                <DynamicButton
                                    key={action._uid}
                                    content={action}
                                />
                            )
                        default:
                            return null
                    }
                })}
            </div>
        </div>
    </SbEditable>
)

export default ListItem
