import React from 'react'
import { Trans } from '@lingui/react'
import { gql } from 'graphql-request'
import useFormatDate from '../../hooks/useFormatDate'
import { formatPriceWithoutSymbol } from '../../../util/prices'
import { getAvailablePriceLevelsFromActivityPriceLevels } from '../../../util/booking'
import Select from '../../utils/Select'
import Button, { VARIANT_BLUE_FILLED, VARIANT_BLUE_OUTLINED } from '../../utils/Button'

const FRAGMENT = gql`
    fragment BookTemplateModalActivity on TemplateActivityService {
        name
        price
        picture
        productId
        endDate
        startDate
        isAlternative
        priceLevels {
            endDate
            productId
            startDate
            priceLevelName
            dateList {
                productId
                availableDate
                price {
                    currency
                    totalPrice
                }
            }
        }
    }
`

const BookTemplateModalActivity = ({
    landing,
    activity,
    departure,
    selectedOptions,
    onChangeActivity,
    numberOfTravelers,
    onChangeActivityOptions,
}) => {
    const formatDate = useFormatDate()
    const {
        name,
        picture,
        productId,
        priceLevels,
        isAlternative,
    } = activity
    const availablePriceLevels = getAvailablePriceLevelsFromActivityPriceLevels(priceLevels, departure, landing)

    if (availablePriceLevels.length === 0) {
        return null
    }

    const getActivityPrice = () => {
        if (selectedOptions) {
            const selectedPriceLevel = availablePriceLevels.find((level) => level.productId === selectedOptions.productId)
            const selectedDatePricelevel = selectedPriceLevel.dateList.find((dl) => dl.productId === selectedOptions.dateProductId)
            return selectedDatePricelevel.price.totalPrice
        }
        const firstPriceLevel = availablePriceLevels[0]
        const firstDate = firstPriceLevel.dateList[0]
        return firstDate.price.totalPrice
    }

    const price = getActivityPrice()
    const pricePerPerson = price / numberOfTravelers
    return (
        <div className="lg:flex border-b border-gray-100 py-6 lg:py-8">
            <div className="relative w-full h-48 lg:w-36 lg:h-36 bg-gray-100 rounded mr-7">
                {picture ? (
                    <img
                        alt={name}
                        src={picture}
                        className="absolute inset-0 object-cover h-full w-full rounded"
                    />
                ) : null}
            </div>
            <div className="py-2 flex-grow">
                <div className="flex items-center justify-between lg:justify-start mb-8">
                    <h2 className="text-lg font-medium">
                        {name}
                    </h2>
                </div>
                <div className="lg:hidden mb-4">
                    {isAlternative ? (
                        <div className="flex items-center">
                            <p className="text-gray-800">
                                <span className="text-xs">&euro;</span>
                                <span className="text-sm font-medium">
                                    {formatPriceWithoutSymbol(pricePerPerson)}
                                </span>
                            </p>
                            <p className="text-xs text-gray-600 ml-1">
                                <Trans id="Per person" />
                            </p>
                            <span className="mx-2">|</span>
                            <p className="text-primary">
                                <span className="text-xs">&euro;</span>
                                <span className="text-sm font-medium">
                                    {formatPriceWithoutSymbol(price)}
                                </span>
                            </p>
                            <p className="text-xs text-gray-600 ml-1">
                                <Trans id="Total price" />
                            </p>
                        </div>
                    ) : (
                        <p className="text-gray-600 font-medium uppercase">
                            <Trans id="Included" />
                        </p>
                    )}
                </div>
                {(() => {
                    if (!selectedOptions) {
                        return null
                    }
                    const priceLevel = availablePriceLevels.find((level) => level.productId === selectedOptions.productId)
                    if (!priceLevel) {
                        return null
                    }
                    const { dateList } = priceLevel
                    dateList.sort((a, b) => {
                        const dateA = new Date(a.availableDate)
                        const dateB = new Date(b.availableDate)
                        return dateA - dateB
                    })
                    return (
                        <div className="lg:flex">
                            <Select
                                value={selectedOptions.productId}
                                onChange={({ target: { value } }) => {
                                    const newPriceLevel = availablePriceLevels.find((level) => level.productId === value)
                                    const [firstDateList] = newPriceLevel.dateList
                                    onChangeActivityOptions({
                                        ...selectedOptions,
                                        productId: value,
                                        date: firstDateList.availableDate,
                                        dateProductId: firstDateList.productId,
                                    })
                                }}
                            >
                                {availablePriceLevels.map((availablePriceLevel) => (
                                    <option
                                        key={availablePriceLevel.productId}
                                        value={availablePriceLevel.productId}
                                    >
                                        {/* The regex targets a specific pattern and converts it. Example: "Ticket (14:00-14:00)" becomes "Ticket 14:00" */}
                                        {availablePriceLevel.priceLevelName.replace(/\((\d{2}:\d{2})-\1\)/, (_, time) => time)}
                                    </option>
                                ))}
                            </Select>
                            <Select
                                className="mt-6 lg:mt-0 lg:ml-5"
                                value={`${selectedOptions.dateProductId}#${selectedOptions.date}`}
                                onChange={({ target: { value } }) => {
                                    const [dateProductId, date] = value.split('#')
                                    onChangeActivityOptions({ ...selectedOptions, dateProductId, date })
                                }}
                            >
                                {dateList.map((availableDate) => {
                                    const value = `${availableDate.productId}#${availableDate.availableDate}`
                                    const date = new Date(availableDate.availableDate)
                                    const hasTimeValue = date.getHours() !== 0 || date.getMinutes() !== 0
                                    return (
                                        <option
                                            key={value}
                                            value={value}
                                        >
                                            {formatDate(new Date(availableDate.availableDate), hasTimeValue ? 'dd/MM/yyyy - HH:mm' : 'dd/MM/yyyy')}
                                        </option>
                                    )
                                })}
                            </Select>
                        </div>
                    )
                })()}
            </div>
            {isAlternative && (
                <Button
                    variant={selectedOptions
                        ? VARIANT_BLUE_OUTLINED
                        : VARIANT_BLUE_FILLED}
                    className="lg:hidden w-full mt-4"
                    onClick={() => onChangeActivity(productId)}
                >
                    {selectedOptions ? <Trans id="Remove" /> : <Trans id="Add" />}
                </Button>
            )}
            <div className="hidden w-48 h-36 lg:flex flex-col justify-center items-center ml-5 border-l border-gray-100 pl-4">
                {!isAlternative ? (
                    <p className="text-sm text-gray-600 uppercase">
                        <Trans id="Included" />
                    </p>
                ) : (
                    <>
                        <div className="w-full flex mb-4">
                            <div className="w-1/2 flex border-r border-gray-100">
                                <div className="flex flex-col items-end shrink-1">
                                    <p className="text-gray-800">
                                        <span className="text-xs">&euro;</span>
                                        <span className="text-sm font-medium">
                                            {formatPriceWithoutSymbol(pricePerPerson)}
                                        </span>
                                    </p>
                                    <p className="text-xs text-gray-600">
                                        <Trans id="Per person" />
                                    </p>
                                </div>
                            </div>
                            <div className="w-1/2 flex flex-col items-end">
                                <p className="text-primary">
                                    <span className="text-xs">&euro;</span>
                                    <span className="text-sm font-medium">
                                        {formatPriceWithoutSymbol(price)}
                                    </span>
                                </p>
                                <p className="text-xs text-gray-600">
                                    <Trans id="Total price" />
                                </p>
                            </div>
                        </div>
                        <Button
                            className="w-full"
                            variant={selectedOptions ? VARIANT_BLUE_OUTLINED : VARIANT_BLUE_FILLED}
                            onClick={() => onChangeActivity(productId)}
                        >
                            {selectedOptions ? <Trans id="Remove" /> : <Trans id="Add" />}
                        </Button>
                    </>
                )}
            </div>
        </div>
    )
}

BookTemplateModalActivity.FRAGMENT = FRAGMENT

export default BookTemplateModalActivity
