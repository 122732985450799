import React, { useState } from 'react'
import { Trans } from '@lingui/react'
import Date from '../../utils/Date'
import Button, { VARIANT_BLUE_FILLED } from '../../utils/Button'
import BookingGuestAmounts from '../../utils/BookingGuestAmounts'
import BookTemplateModalToursModal from './BookTemplateModalToursModal'

const BookTemplateModalTour = ({
    name,
    tour,
    tours,
    onChangeTour,
    numberOfRooms,
    numberOfAdults,
    numberOfChildren,
}) => {
    const [isChoosingTour, setIsChoosingTour] = useState(false)
    const {
        picture,
        endDate,
        startDate,
    } = tour
    return (
        <>
            <div className="relative w-full h-48 bg-gray-100 rounded mb-4">
                {picture && (
                    <img
                        alt={name}
                        src={picture}
                        draggable={false}
                        className="absolute inset-0 object-cover h-full w-full rounded"
                    />
                )}
            </div>
            <p className="text-lg font-medium mb-2">
                {name}
            </p>
            <BookingGuestAmounts
                className="mb-4"
                adults={numberOfAdults}
                numberOfRooms={numberOfRooms}
                numberOfChildren={numberOfChildren}
            />
            <div className="grid grid-cols-2 text-sm">
                <div>
                    <p className="font-bold">
                        <Trans id="Check in:" />
                    </p>
                    <p className="text-gray-800 mt-1">
                        <Date
                            dateString={startDate}
                            format="eee d MMM yyyy"
                        />
                    </p>
                </div>
                <div>
                    <p className="font-bold">
                        <Trans id="Check out:" />
                    </p>
                    <p className="text-gray-800 mt-1">
                        <Date
                            dateString={endDate}
                            format="eee d MMM yyyy"
                        />
                    </p>
                </div>
            </div>
            <div className="w-full grid grid-cols-2 gap-x-2">
                {tours.length > 1 ? (
                    <Button
                        variant={VARIANT_BLUE_FILLED}
                        className="col-span-2 md:col-span-1 mt-4"
                        onClick={() => setIsChoosingTour(true)}
                    >
                        <Trans id="Upgrade your holiday" />
                    </Button>
                ) : null}
            </div>
            {isChoosingTour && (
                <BookTemplateModalToursModal
                    selectedTour={tour}
                    onChooseTour={(tourId) => {
                        onChangeTour(tourId)
                        setIsChoosingTour(false)
                    }}
                    tours={tours}
                    onClose={() => setIsChoosingTour(false)}
                    numberOfTravelers={numberOfAdults + (numberOfChildren || 0)}
                />
            )}
        </>
    )
}

export default BookTemplateModalTour
