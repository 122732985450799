/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import clsx from 'clsx'
import NextLink from 'next/link'
import { variant } from '../../util/index'
import Icon from '../utils/Icon'
import useLocalizedAirline from '../hooks/useLocalizedAirlineDeprecated'
import useFormatFullSlug from '../hooks/useFormatFullSlug'

const AirlinesSection = ({
    background,
    airline,
}) => {
    const { full_slug, content } = airline
    const { name, airlineCode } = content
    const formatFullSlug = useFormatFullSlug()
    const { imageUrl } = useLocalizedAirline(airlineCode)
    return (
        <NextLink legacyBehavior href={formatFullSlug(full_slug)}>
            <a
                className={clsx(
                    'flex flex-col items-center cursor-pointer hover:mix-blend-multiply',
                    variant(background, {
                        white: 'bg-white',
                        'gray-50': 'bg-gray-50',
                        'gray-100': 'bg-gray-100',
                        black: 'bg-black',
                        blue: 'bg-blue',
                    })
                )}
            >
                <div className="relative flex justify-center items-center w-16 h-10 md:w-32 md:h-16 lg:w-64 lg:h-32 mt-4 md:mt-6 lg:mt-10">
                    <img
                        src={imageUrl}
                        alt={name}
                        className="h-[35px] md:h-[50px] object-fit"
                    />
                </div>
                <div className="inline-flex items-center text-blue-bright text-xs md:text-s lg:text-base my-4 md:my-6 lg:my-10">
                    {name}
                    <Icon
                        name="arrow-right"
                        size="smaller"
                        className="ml-2"
                    />
                </div>
            </a>
        </NextLink>
    )
}

export default AirlinesSection
