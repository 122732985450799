import React from 'react'
import ShopCard from './ShopCard'
import TourCard from './TourCard'
import BreakCard from './BreakCard'
import HotelCard from './HotelCard'
import ExpertCard from './ExpertCard'
import ActivityCard from './ActivityCard'
import BlogPostCard from './BlogPostCard'
import DestinationCard from './DestinationCardWithState'
import AirlinePricesCard from './AirlinePricesCardWithState'
import Placeholder from './Placeholder'
import Card from './Card'
import PageCard from './PageCard'
import IconFeaturesCard from './IconFeaturesCard'

const Components = {
    DestinationCard,
    HotelCard,
    BreakCard,
    TourCard,
    ActivityCard,
    BlogPostCard,
    ExpertCard,
    ShopCard,
    Card,
    AirlinePricesCard,
    IconFeaturesCard,
    WhyConnectionsReasonCard: IconFeaturesCard, //Backwards compability
    PageCard,
}

const DynamicCard = ({
    content,
    ...props
}) => {
    const Component = Components[content.component]
    if (Component) {
        return (
            <Component content={content} {...props} />
        )
    }
    return <Placeholder componentName={content.component} />
}

export default DynamicCard
