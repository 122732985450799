import clsx from 'clsx'
import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import * as constants from '@connections/constants'
import Icon from './Icon'
import { variant } from '../../util/index'

const {
    BACKGROUND_BLUE: BLUE,
    BACKGROUND_BLACK: BLACK,
} = constants

const BulletItem = ({
    icon,
    label,
    background,
    children,
    isOpen,
    onToggleOpen,
}) => (
    <li
        className={clsx(
            'border border-gray-100 rounded-md p-4 grid grid-cols-5 gap-2',
            'md:p-0 md:border-0',
        )}
    >
        <div className={clsx(
            'col-span-5 flex font-bold items-center',
            'md:col-span-2 md:items-start'
        )}
        >
            <div className="mr-2">
                <Icon
                    name={icon}
                    size="small"
                    className={clsx(
                        variant(background, {
                            [BLUE]: 'text-white',
                            [BLACK]: 'text-blue-bright',
                        }, 'text-blue')
                    )}
                />
            </div>
            {label}
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button
                onClick={onToggleOpen}
                type="button"
                className="ml-auto h-8 w-8 flex items-center justify-center md:hidden"
            >
                <div
                    className={clsx(
                        'border-l-[3.5px] border-r-[3.5px] w-0 h-0 border-transparent',
                        isOpen && 'border-b-4 border-t-0',
                        !isOpen && 'border-t-4 border-b-0',
                        (background === BLUE || background === BLACK) && isOpen ? 'border-b-white' : 'border-b-black',
                        (background === BLUE || background === BLACK) && !isOpen ? 'border-t-white' : 'border-t-black',
                    )}
                />
            </button>
        </div>
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    className={clsx(
                        'col-span-5 flex origin-top ml-9',
                        'md:hidden',
                    )}
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                >
                    {/* Padding needs to be inside container, otherwise animation is yanky */}
                    <div className="pt-3">
                        {children}
                    </div>
                </motion.div>
            )}
        </AnimatePresence>

        <div className="hidden col-span-3 md:flex">
            {children}
        </div>
    </li>
)

export default BulletItem
