import React, { useState, useRef } from 'react'
import clsx from 'clsx'
import { Trans } from '@lingui/react'
import DateRangeButton from '../../utils/DateRangeButton'
import DateRangeCalendar from '../../utils/DateRangeCalendar'
import Button, { VARIANT_BLUE_FILLED } from '../../utils/Button'

const BookDynamicTemplateDesktopDateRangeCalendar = ({
    hasError,
    onChange,
    className,
    returnDate,
    departureDate,
    labelTextToday,
    rangeStartDate,
    labelTextLastSelected,
    dayIsDisabledCondition,
    labelTextFirstSelected,
    isOpen: isOpenFromProps = false,
}) => {
    const containerRef = useRef()
    const [isOpen, setIsOpen] = useState(isOpenFromProps)
    const [[startDate, endDate], setDates] = useState([departureDate, returnDate])
    const handleConfirm = () => {
        if (startDate && endDate) {
            onChange(startDate, endDate)
        }
        setIsOpen(false)
    }
    return (
        <div
            className={clsx('relative hidden lg:block', className)}
            data-testid="BookDynamicTemplateDesktopDateRangeCalendarContainer"
        >
            <DateRangeButton
                hasError={hasError}
                returnDate={returnDate}
                departureDate={departureDate}
                onClickReturnDate={() => setIsOpen(true)}
                onClickDepartureDate={() => setIsOpen(true)}
            />
            {isOpen && (
                <div
                    ref={containerRef}
                    data-testid="BookDynamicTemplateDesktopDateRangeCalendar"
                    className="hidden lg:block absolute z-10 -top-2 -left-2 px-2 pt-2 rounded-md w-[600px] shadow-2xl bg-white"
                >
                    <DateRangeCalendar
                        returnDate={returnDate}
                        inputClassName="w-[444px]"
                        departureDate={departureDate}
                        clickOutsideRef={containerRef}
                        labelTextToday={labelTextToday}
                        rangeStartDate={rangeStartDate}
                        onPressEscape={() => setIsOpen(false)}
                        onClickOutside={() => setIsOpen(false)}
                        labelTextLastSelected={labelTextLastSelected}
                        labelTextFirstSelected={labelTextFirstSelected}
                        dayIsDisabledCondition={dayIsDisabledCondition}
                        onChange={(start, end) => {
                            setDates([start, end])
                        }}
                    />
                    <div className="flex justify-end px-1 pb-4">
                        <Button
                            className="w-[250px]"
                            onClick={handleConfirm}
                            iconAfterName="arrow-right"
                            variant={VARIANT_BLUE_FILLED}
                            data-testid="BookDynamicTemplateDesktopDateRangeCalendarConfirm"
                        >
                            <Trans id="Confirm" />
                        </Button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default BookDynamicTemplateDesktopDateRangeCalendar
