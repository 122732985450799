import { useState } from 'react'
import { useInfiniteQuery } from 'react-query'
import usePathnameLocale from '../../hooks/usePathnameLocale'
import useStoryblokClient from '../../hooks/useStoryblokClient'

const useSearchPageQuery = (
    cardType,
    filters = { query: '' },
    { perPage = 4, page = 1, pageLimit = 3 } = {}
) => {
    const locale = usePathnameLocale()
    const storyblokClient = useStoryblokClient()
    const [currentPage, setCurrentPage] = useState(page)
    const skip = filters.query === ''
    const options = {
        language: locale,
        per_page: perPage,
        content_type: cardType,
        search_term: filters.query,
        excluding_slugs: '_test/*,preview/*,*/hidden/*',
    }
    const {
        data,
        error,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
        isLoading: isFetching,
    } = useInfiniteQuery(
        [`search-${cardType}`, options],
        async ({ pageParam = 1 }) => storyblokClient.get('cdn/stories/', { ...options, page: pageParam }),
        {
            enabled: !skip,
            getNextPageParam: ({ total }) => (
                currentPage * perPage >= total ? undefined : currentPage + 1)
            ,
        }
    )

    let searchResults = []
    if (isFetching) {
        return {
            isFetching,
            searchResults,
        }
    }

    if (error || skip) {
        return {
            error,
            isFetching,
            searchResults,
        }
    }

    const handleFetchNextPage = async () => {
        setCurrentPage(currentPage + 1)
        await fetchNextPage()
    }

    const {
        pages,
    } = data
    searchResults = pages.reduce((prev, resultPage) => {
        const { data: { stories } } = resultPage
        return [...prev, ...stories]
    }, [])

    return {
        perPage,
        isFetching,
        searchResults,
        hasMore: hasNextPage,
        loadMoreOnPageEnd: false,
        isFetchingMore: isFetchingNextPage,
        handleLoadMore: handleFetchNextPage,
        total: pages[pages.length - 1].total,
    }
}

export default useSearchPageQuery
