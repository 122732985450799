import React from 'react'
import clsx from 'clsx'
import { Trans } from '@lingui/react'
import SbEditable from 'storyblok-react'
import * as constants from '@connections/constants'
import Image from '../utils/Image'
import DynamicButton from './DynamicButton'
import CustomText from '../utils/CustomText'
import Button, { VARIANT_BLUE_FILLED } from '../utils/Button'
import { variant } from '../../util/index'
import { getEntityContentOverrides } from '../../util/entities'
import Video from '../utils/Video'
import usePathnameLocale from '../hooks/usePathnameLocale'

const {
    CONTAINER_EXTRA_SMALL: EXTRA_SMALL,
    CONTAINER_SMALL: SMALL,
    CONTAINER_DEFAULT: DEFAULT,
} = constants

const HighlightSection = ({
    content
}) => {
    const {
        tag,
        entity,
        buttons = [],
        background,
        container,
        spacing,
        anchorId,
    } = content
    const locale = usePathnameLocale()
    const {
        href,
        title,
        image,
        video,
        description,
    } = getEntityContentOverrides(content, entity, locale)
    const hasImage = !!image?.filename
    const hasVideo = !!video?.filename
    return (
        <SbEditable content={content}>
            <section
                id={anchorId || undefined}
                data-testid="HighlightSection"
                className={clsx(
                    '-z-1',
                    variant(spacing, {
                        small: 'my-12',
                        medium: 'my-24',
                        large: 'my-36',
                    }),
                    variant(background, {
                        white: 'bg-white',
                        'gray-50': 'bg-gray-50',
                        'gray-100': 'bg-gray-100',
                        black: 'bg-black',
                        blue: 'bg-blue',
                    })
                )}
            >
                <div className="relative w-full h-[60vh] z-0">
                    {(() => {
                        if (hasVideo) {
                            return (
                                <Video
                                    loop
                                    muted
                                    autoPlay
                                    playsInline
                                    src={video.filename}
                                    className="absolute h-full w-full inset-0 object-cover"
                                />
                            )
                        }
                        if (hasImage) {
                            return (
                                <Image
                                    fill
                                    alt={image.alt}
                                    src={image.filename}
                                    className="object-cover"
                                />
                            )
                        }
                        return null
                    })()}
                </div>
                <div
                    className={clsx(
                        'flex justify-center -mt-16',
                        variant(container, {
                            [EXTRA_SMALL]: 'container-xs',
                            [SMALL]: 'container-sm',
                            [DEFAULT]: 'container',
                        })
                    )}
                >
                    <div className="w-full flex flex-col items-center bg-white rounded-t-lg py-10 px-6 md:px-20 lg:px-28 z-1">
                        {tag && (
                            <p className="text-md font-medium uppercase text-gray-600">
                                {tag}
                            </p>
                        )}
                        {title && (
                            <CustomText
                                as="h3"
                                customText={title}
                                className="text-4xl font-medium my-4 text-center"
                                customTextClassName="font-accent text-[40px]"
                            />
                        )}
                        {description && (
                            <p className="text-center mb-8 leading-7">
                                {description}
                            </p>
                        )}
                        {buttons.length > 0 && (
                            <div className="grid grid-flow-col gap-4">
                                {buttons.map((button) => (
                                    <DynamicButton
                                        key={button._uid}
                                        content={button}
                                    />
                                ))}
                            </div>
                        )}
                        {(buttons.length === 0 && href) && (
                            <Button
                                as="a"
                                href={href}
                                iconAfterName="arrow-right"
                                variant={VARIANT_BLUE_FILLED}
                            >
                                <Trans id="Discover" />
                            </Button>
                        )}
                    </div>
                </div>
            </section>
        </SbEditable>
    )
}

export default HighlightSection
