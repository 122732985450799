import React from 'react'
import { Trans } from '@lingui/react'
import useFormState, { createFormValidation } from 'use-form-state'
import { useQueryParams, StringParam, withDefault } from 'use-query-params'
import { isRequired, isGreaterOrEqualThan, isLessOrEqualThan } from '@connections/utils'
import { getDateFromQueryParam } from './useQuickSearchFlightFormState'

export const getUpdatedRoomTravelersList = (list, room, newAmount, newTraveler) => {
    if (list.length === 0) {
        return [newTraveler]
    }
    const roomTravelers = list.filter(({ roomIndex }) => roomIndex === room)
    let hasChanged = false
    return list.reduce((prev, traveler) => {
        if (roomTravelers.length > newAmount && traveler.roomIndex === room && !hasChanged) {
            hasChanged = true
            return prev
        }
        if (roomTravelers.length < newAmount && !hasChanged) {
            hasChanged = true
            return [...prev, traveler, newTraveler]
        }
        return [...prev, traveler]
    }, [])
}

export const addRoomToTravelersList = (list, roomIndex) => {
    const newList = [...list]
    newList.push({ roomIndex })
    return newList
}

export const removeRoomFromTravelersList = (list, roomIndex, numberOfRooms) => {
    const newList = list.filter(({ roomIndex: index }) => index !== roomIndex)
    if (roomIndex + 1 < numberOfRooms) {
        return newList.map((traveler) => {
            if (traveler.roomIndex > roomIndex) {
                return {
                    ...traveler,
                    roomIndex: traveler.roomIndex - 1,
                }
            }
            return traveler
        })
    }
    return newList
}

const validation = createFormValidation([{
    path: 'destination',
    validate: isRequired,
    message: <Trans id="The destination is required" />,
}, {
    path: 'checkinDate',
    validate: isRequired,
    message: <Trans id="The check-in date is required" />,
}, {
    path: 'checkoutDate',
    validate: isRequired,
    message: <Trans id="The check-out date is required" />,
}, {
    path: 'numberOfAdults',
    validate: () => (
        isGreaterOrEqualThan(1)
        && isLessOrEqualThan(9)
    ),
    message: <Trans id="The number of adults must be between 1 & 9" />,
}, {
    path: 'numberOfRooms',
    validate: () => (
        isGreaterOrEqualThan(1)
        && isLessOrEqualThan(3)
    ),
    message: <Trans id="The number of rooms must be between 1 & 3" />,
}])

const useQuickSearchHotelFormState = () => {
    const [query] = useQueryParams({
        checkin: withDefault(StringParam, null),
        checkout: withDefault(StringParam, null),
    })
    const checkinDate = getDateFromQueryParam(query.checkin)
    const checkoutDate = getDateFromQueryParam(query.checkout)
    const formState = useFormState({
        destination: null,
        checkinDate,
        checkoutDate,
        adults: [{ roomIndex: 0 }],
        children: [],
        numberOfRooms: 1,
    }, { validation })

    return {
        ...formState,
        handleChangeAdults: (index, number) => {
            if (number <= 9 && number >= 1) {
                const { adults } = formState.values
                const newTraveler = { roomIndex: index }
                formState.handleChange('adults', getUpdatedRoomTravelersList(adults, index, number, newTraveler))
            }
        },
        handleChangeChildren: (index, number) => {
            if (number <= 9 && number >= 0) {
                const { children } = formState.values
                const newTraveler = { roomIndex: index, age: 10 }
                formState.handleChange('children', getUpdatedRoomTravelersList(children, index, number, newTraveler))
            }
        },
        handleChangeNumberOfRooms: (number, roomIndex) => {
            if (number <= 3 && number >= 1) {
                const {
                    adults,
                    children,
                    numberOfRooms,
                } = formState.values
                formState.handleChange('numberOfRooms', number)
                let newAdults = adults
                let newChildren = children
                if (number > numberOfRooms) {
                    newAdults = addRoomToTravelersList(adults, roomIndex)
                } else {
                    newAdults = removeRoomFromTravelersList(adults, roomIndex, numberOfRooms)
                    newChildren = removeRoomFromTravelersList(children, roomIndex, numberOfRooms)
                }
                formState.handleChange('adults', newAdults)
                formState.handleChange('children', newChildren)
            }
        },
        handleChangeChildAge: (roomIndex, index, age) => {
            const { children } = formState.values
            let childIndex = 0
            const newChildren = children.map((child) => {
                if (child.roomIndex === roomIndex) {
                    if (index === childIndex) {
                        childIndex += 1
                        return { roomIndex, age }
                    }
                    childIndex += 1
                }
                return child
            })
            formState.handleChange('children', newChildren)
        }
    }
}

export default useQuickSearchHotelFormState
