import React from 'react'
import clsx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'
import useCarousel from '@dashdot/react-hooks-slider'
import { sectionContainerVariant } from '../../util/variants'
import DynamicCard from './DynamicCard'
import CarouselButton, { NEXT, PREVIOUS } from '../utils/CarouselButton'

const getWidth = (columnWidth, columnCount) => {
    const ratio = `${columnWidth}/${columnCount}`

    switch (ratio) {
        case '1/2':
        case '2/4':
            return 'md:w-1/2'
        case '1/3':
            return 'md:w-1/3'
        case '2/3':
            return 'md:w-2/3'
        case '1/4':
            return 'md:w-1/2 lg:w-1/4'
        case '3/4':
            return 'md:w-3/4'
        case '3/2': // Should be 150% but is not useful
        case '2/2':
        case '3/3':
        default:
            return ''
    }
}

const CardsSectionCarousel = ({
    container,
    cards,
    columnCount,
}) => {
    const {
        position,
        hasPreviousSlide,
        hasNextSlide,
        handleNextSlide,
        handlePreviousSlide,
        slideRefs,
        containerRef,
        isDisabled,
        swipingProps,
        isSwiping,
    } = useCarousel()

    return (
        <div
            className={clsx(
                'relative',
                sectionContainerVariant(container),
            )}
        >
            {!isDisabled && (
                <>
                    <AnimatePresence>
                        {hasPreviousSlide && (
                            <CarouselButton
                                onClick={handlePreviousSlide}
                                className={clsx(
                                    'absolute top-[50%] left-0 transform -translate-x-1/4 -translate-y-1/2 z-1',
                                    'md:top-1/2 md:translate-x-0'
                                )}
                                direction={PREVIOUS}
                            />
                        )}
                    </AnimatePresence>
                    <AnimatePresence>
                        {hasNextSlide && (
                            <CarouselButton
                                onClick={handleNextSlide}
                                className={clsx(
                                    'absolute top-[50%] right-0 transform translate-x-1/4 -translate-y-1/2 z-1',
                                    'md:top-1/2 md:translate-x-0'
                                )}
                                direction={NEXT}
                            />
                        )}
                    </AnimatePresence>
                </>
            )}
            <motion.div
                ref={containerRef}
                className={clsx(
                    'whitespace-nowrap flex flex-nowrap -mr-4 lg:-mr-6 touch-pan-y',
                    !isDisabled && 'cursor-move select-none',
                )}
                animate={{ x: position !== null && `${position}%` }}
                transition={{ duration: 0.4, ease: 'easeOut' }}
                {...swipingProps}
            >
                {cards.map((card, index) => {
                    const columnWidth = parseInt(card.columnWidth, 10) || 1
                    return (
                        <div
                            key={card._uid}
                            ref={(ref) => { slideRefs.current[index] = ref }}
                            className={clsx(
                                'bg-transparent w-full pr-4 lg:pr-6 flex-grow-0 flex-shrink-0 whitespace-normal',
                                isSwiping && 'pointer-events-none',
                                getWidth(columnWidth, columnCount),
                            )}
                        >
                            <DynamicCard
                                content={card}
                                columnWidth={columnWidth}
                            />
                        </div>
                    )
                })}
            </motion.div>
        </div>
    )
}

export default CardsSectionCarousel
