import { getPersonPriceFromCharge } from '../../util/prices'
import { calculateAverageFareTravelTime, getCabinClass, getFareLuggageStatus } from '../../util/booking/flights'
import { useQuickSearchFlightQueryParams } from '../content/useQuickSearchFlightFormState'
import useAirportIataLocation from './useAirportIataLocation'

export default function useSortingRules(pageContent, cheapestFare) {
    const getAirportIataLocation = useAirportIataLocation()
    const [{ mode, infants, fareType }] = useQuickSearchFlightQueryParams()
    const cabinClass = getCabinClass(fareType)
    const {
        recommendedSortingRules: rules,
        defaultRecommendedSortingRule: defaultRule,
    } = pageContent

    const isMatchingRule = (fare, rule) => {
        const [{ origin }] = fare.airRoutes[0].segments
        const { destination } = fare.airRoutes[0].segments[fare.airRoutes[0].segments.length - 1]
        const {
            country: toCountry,
            destination: toCity,
            continent: toContinent,
        } = getAirportIataLocation(destination.code)
        const {
            country: fromCountry,
            destination: fromCity,
            continent: fromContinent,
        } = getAirportIataLocation(origin.code)

        const {
            airlines,
            toCities,
            fromCities,
            toCountries,
            toContinents,
            fromCountries,
            fromContinents,
        } = rule
        const matchesInboundCity = toCity && toCities?.includes(toCity.value)
        const matchesInboundCountry = toCountry && toCountries?.includes(toCountry.value)
        const matchesInboundContinent = toContinent && toContinents?.includes(toContinent.value)
        const matchesInboundRule = matchesInboundCity || matchesInboundCountry || matchesInboundContinent

        const matchesOutboundCity = fromCity && fromCities?.includes(fromCity.value)
        const matchesOutboundCountry = fromCountry && fromCountries?.includes(fromCountry.value)
        const matchesOutboundContinent = fromContinent && fromContinents?.includes(fromContinent.value)
        const matchesOutboundRule = matchesOutboundCity || matchesOutboundCountry || matchesOutboundContinent

        return matchesInboundRule && matchesOutboundRule && airlines.includes(fare.platingCarrier)
    }

    const getRuleParameterEvaluations = (fare, rule) => {
        const {
            maxAmountOfStops,
            maxFlightDuration,
            allowedLuggageStatuses,
            maxPriceDifferenceFromCheapest,
        } = rule

        const luggageStatus = getFareLuggageStatus(fare, mode, infants, cabinClass)
        const hasValidLuggageStatus = !allowedLuggageStatuses || allowedLuggageStatuses.length === 0 || allowedLuggageStatuses.includes(luggageStatus)

        const amountOfStops = fare.airRoutes.reduce((totalStops, { segments }) => totalStops + segments.length - 1, 0)
        const hasValidStops = !maxAmountOfStops || amountOfStops <= parseInt(maxAmountOfStops, 10)

        const averageFlightDurationMs = calculateAverageFareTravelTime(fare.airRoutes)
        const averageFlightDurationMinutes = averageFlightDurationMs / 1000 / 60
        const hasValidFlightDuration = !maxFlightDuration || averageFlightDurationMinutes <= parseInt(maxFlightDuration, 10)

        const totalPrice = getPersonPriceFromCharge(fare.charge)
        const cheapestPrice = getPersonPriceFromCharge(cheapestFare.charge)
        const hasValidPrice = !maxPriceDifferenceFromCheapest || totalPrice <= cheapestPrice * (1 + (parseInt(maxPriceDifferenceFromCheapest, 10) / 100))

        return {
            hasValidStops,
            hasValidPrice,
            hasValidLuggageStatus,
            hasValidFlightDuration,
        }
    }

    const getSortingRule = (fares) => {
        if (rules?.length <= 0) {
            return null
        }
        const matchingRule = rules.find((rule) => fares.some((fare) => isMatchingRule(fare, rule)))
        return matchingRule || { ...defaultRule[0], isDefault: true }
    }

    return {
        getSortingRule,
        getRuleParameterEvaluations,
    }
}
