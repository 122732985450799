import React from 'react'
import clsx from 'clsx'
import Link from 'next/link'
import { Trans } from '@lingui/react'
import useFormatFullSlug from '../hooks/useFormatFullSlug'
import useLocalizedAirline from '../hooks/useLocalizedAirlineDeprecated'
import Date from '../utils/Date'
import Price from '../utils/Price'

const Container = ({
    className,
    children,
}) => (
    <div
        className={clsx(
            'border bg-white border-gray-100 p-8',
            className,
        )}
    >
        {children}
    </div>
)

const AirlinePricesCard = ({
    airline,
    destinations,
}) => {
    const formatFullSlug = useFormatFullSlug()
    const { imageUrl } = useLocalizedAirline(airline.content.airlineCode)
    return (
        <Container>
            <div className="relative mb-8 flex justify-center">
                {imageUrl && (
                    <img
                        src={imageUrl}
                        alt={airline.name}
                        className="h-[75px] object-fit"
                    />
                )}
            </div>
            <ul className="divide-y divide-gray-100">
                {destinations.map((destination) => {
                    const {
                        name,
                        full_slug,
                        flightPrice = {}
                    } = destination
                    const { price, departureDate, returnDate } = flightPrice
                    const hasPrice = typeof price !== 'undefined'
                    return (
                        <li key={destination.uuid}>
                            <Link href={formatFullSlug(full_slug)} className="py-4 grid grid-cols-2">
                                <div>
                                    <div className="text-sm mb-1">
                                        {name}
                                    </div>
                                    <div className="text-xs text-gray-600">
                                        {hasPrice ? (
                                            <>
                                                <Date dateString={departureDate} format="dd MMM" />
                                                {' - '}
                                                <Date dateString={returnDate} format="dd MMM" />
                                            </>
                                        ) : (
                                            <Trans id="No date found" />
                                        )}
                                    </div>
                                </div>
                                <div className="text-sm text-right">
                                    {hasPrice ? (
                                        <Price price={price} />
                                    ) : (
                                        <Trans id="No pricing found" />
                                    )}
                                </div>
                            </Link>
                        </li>
                    )
                })}
            </ul>
        </Container>
    )
}

AirlinePricesCard.Container = Container

export default AirlinePricesCard
