import { gql } from 'graphql-request'
import useGraphqlMutation from './useGraphqlMutation'

const mutation = gql`
    mutation submitJotform(
        $id: String!
        $input: Json!
        $type: String
        $title: String
        $token: String!
    ) {
        submitJotform(
            id: $id
            type: $type
            title: $title
            input: $input
            token: $token
        ) {
            submissionId
        }
    }
`

const useSubmitJotformMutation = (options) => useGraphqlMutation(mutation, options)

export default useSubmitJotformMutation
