import React from 'react'
import SbEditable from 'storyblok-react'
import JotformSectionWithProvider from './JotformSectionWithProvider'

const JotformSectionWithContent = ({
    content,
}) => {
    const {
        id,
        type,
        background,
        container,
        spacing,
    } = content
    return (
        <SbEditable content={content}>
            <JotformSectionWithProvider
                jotformId={id}
                jotformType={type}
                background={background}
                container={container}
                spacing={spacing}
            />
        </SbEditable>
    )
}

export default JotformSectionWithContent
