import React from 'react'
import Page from '../../layout/Page'
import PageMain from '../../layout/PageMain'
import StickyHeader from '../../layout/StickyHeader'
import HeaderWithContent from '../../layout/HeaderWithContent'
import FooterWithContent from '../../layout/FooterWithContent'
import Heading from '../../content/Heading'
import DynamicSection from '../../content/DynamicSection'
import TourPageSubHeader from './TourPageSubHeader'

const TourPage = ({
    content,
}) => {
    const {
        name,
        title,
        price,
        headImage,
        headVideo,
        body = [],
        description,
        headingOptions = [{}],
    } = content
    const [firstSection] = body
    const hasHeadingSection = firstSection.component === 'HeadingSection'

    return (
        <Page data-testid="TourPage">
            <StickyHeader>
                <HeaderWithContent />
                <TourPageSubHeader name={name} />
            </StickyHeader>
            <PageMain>
                {hasHeadingSection ? null : (
                    <Heading
                        price={price}
                        title={title}
                        image={headImage}
                        video={headVideo}
                        description={description}
                        {...headingOptions[0]}
                    />
                )}
                {body && body.map((sectionContent) => (
                    <DynamicSection
                        pageContent={content}
                        content={sectionContent}
                        key={sectionContent._uid}
                    />
                ))}
            </PageMain>
            <FooterWithContent />
        </Page>
    )
}

export default TourPage
