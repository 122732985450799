import React from 'react'
import SbEditable from 'storyblok-react'
import TextLink from './TextLink'
import { getHrefFromLink } from '../../util/index'
import usePathnameLocale from '../hooks/usePathnameLocale'

const SbLink = ({
    content,
    ...props
}) => {
    const {
        text,
        link,
        iconBeforeName,
        iconAfterName,
        openInNewTab,
    } = content
    const locale = usePathnameLocale()
    const href = getHrefFromLink(link, locale)
    return (
        <SbEditable content={content}>
            <TextLink
                href={href}
                iconBeforeName={iconBeforeName}
                iconAfterName={iconAfterName}
                openInNewTab={openInNewTab}
                {...props}
            >
                {text}
            </TextLink>
        </SbEditable>
    )
}

export default SbLink
