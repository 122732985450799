import React from 'react'
import clsx from 'clsx'
import FlightsListerGroupsTab from './FlightsListerGroupsTab'

const FlightsListerGroups = ({
    groups,
    onChange,
    activeTab,
    className,
    background,
}) => (
    <div className={clsx('border-b border-gray-100', className)}>
        <nav className="grid grid-flow-col gap-x-2 overflow-scroll no-scrollbar" aria-label="Tabs">
            {groups.map((group, index) => {
                const isActive = index === activeTab

                return (
                    <FlightsListerGroupsTab
                        key={group._uid}
                        isActive={isActive}
                        background={background}
                        onChange={() => onChange(index)}
                        {...group}
                    />
                )
            })}
        </nav>
    </div>
)

export default FlightsListerGroups
