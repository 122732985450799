import React from 'react'
import Page from '../../layout/Page'
import PageMain from '../../layout/PageMain'
import StickyHeader from '../../layout/StickyHeader'
import HeaderWithContent from '../../layout/HeaderWithContent'
import FooterWithContent from '../../layout/FooterWithContent'
import DynamicSection from '../../content/DynamicSection'

const DefaultPage = ({
    body = [],
    hideHeader = false,
    hideFooter = false,
}) => (
    <Page data-testid="DefaultPage">
        {!hideHeader && (
            <StickyHeader>
                <HeaderWithContent />
            </StickyHeader>
        )}
        <PageMain className="min-h-screen">
            {body && body.map((content) => (
                <DynamicSection
                    key={content._uid || content.uid}
                    content={content}
                />
            ))}
        </PageMain>
        {!hideFooter && (
            <FooterWithContent />
        )}
    </Page>
)

export default DefaultPage
