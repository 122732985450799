import clsx from 'clsx'
import React from 'react'

const Table = ({
    className,
    isHorizontalScrollable,
    isVerticalScrollable,
    children,
    ...props
}) => (
    <div
        className={clsx(
            'border border-gray-100 rounded-md',
            isHorizontalScrollable && 'overflow-x-scroll',
            isVerticalScrollable && 'overflow-y-scroll max-h-[600px]',
            className
        )}
        {...props}
    >
        <table
            className={clsx(
                'w-full',
                isHorizontalScrollable && 'min-w-[800px]',
            )}
        >
            {children}
        </table>
    </div>
)

Table.Head = (props) => (
    <thead {...props} />
)

Table.HeadRow = (props) => (
    <tr className="divide-x divide-blue-bright" {...props} />
)

Table.Body = (props) => (
    <tbody
        className="bg-white divide-y divide-gray-100"
        {...props}
    />
)

Table.Row = (props) => (
    <tr className="even:bg-gray-50" {...props} />
)

Table.Cell = ({
    className,
    children,
    ...props
}) => (
    <td
        className={(clsx(
            'px-3 py-5 lg:px-8 lg:py-5 text-sm whitespace-no-wrap',
            className
        ))}
        {...props}
    >
        {children}
    </td>
)

Table.HeadCell = ({
    className,
    children,
    ...props
}) => (
    <th
        className={(clsx(
            'px-3 py-4 lg:px-8 lg:py-4 bg-blue text-left text-xs leading-4 font-semibold text-white uppercase tracking-wider',
            className,
        ))}
        {...props}
    >
        {children}
    </th>
)

export default Table
