import React from 'react'

const SearchPageResultsTitle = ({
    children,
}) => (
    <h3 className="text-2xl font-medium border-l-4 border-blue pl-5 mb-6">
        {children}
    </h3>
)

export default SearchPageResultsTitle
