import useStoryblokQuery from '../../hooks/useStoryblokQuery'
import useStoryblokInfiniteQuery from '../../hooks/useStoryblokInfiniteQuery'

const getFilter = (array, queryParamArray) => {
    if (queryParamArray?.length > 0 && array?.length > 0) {
        const formulaUuids = array
            .filter(({ content }) => queryParamArray.includes(content.name))
            .map(({ uuid }) => uuid)
        if (formulaUuids.length > 0) {
            return {
                any_in_array: formulaUuids.join(',')
            }
        }
    }
    return null
}

const useTravelSearchPageQuery = ({
    sortBy,
    months,
    formulas,
    themes,
    vacations,
    countryCode,
    continentCodes,
}, settings) => {
    const perPage = 25
    const filter = {
        __or: [
            { component: { in: 'Tour' } },
            { component: { in: 'Break' } },
        ],
    }
    if (continentCodes?.length > 0) {
        filter.continentCodes = {
            any_in_array: continentCodes.join(',').toUpperCase(),
        }
    }
    if (countryCode) {
        filter.countryCodes = {
            any_in_array: countryCode,
        }
    }
    if (months?.length > 0) {
        filter.months = {
            any_in_array: months.join(','),
        }
    }
    if (vacations?.length > 0) {
        const { timePeriods } = settings.find(({ component }) => component === 'TimeSearchSettings')
        const timePeriodUuids = timePeriods
            .filter(({ content }) => vacations.includes(content.name))
            .map(({ uuid }) => uuid)
        if (timePeriodUuids.length > 0) {
            filter.timePeriods = {
                any_in_array: timePeriodUuids.join(',')
            }
        }
    }
    const {
        data: formulaData,
        error: formulaError,
        isLoading: isLoadingFormulas,
    } = useStoryblokQuery('travel-formulas', 'cdn/stories/', { content_type: 'TravelType' })
    const travelFormulas = formulaData?.data?.stories || []
    const formulasFilter = getFilter(travelFormulas, formulas)
    if (formulasFilter) {
        filter.travelFormulas = formulasFilter
    }
    const {
        data: themesData,
        error: themesError,
        isLoading: isLoadingThemes,
    } = useStoryblokQuery('travel-themes', 'cdn/stories/', { content_type: 'TravelTheme' })
    const travelThemes = themesData?.data?.stories || []
    const themesFilter = getFilter(travelThemes, themes)
    if (themesFilter) {
        filter.travelThemes = themesFilter
    }
    const params = {
        per_page: perPage,
        filter_query: filter,
        sort_by: `content.${sortBy}`,
        excluding_slugs: '_test/*,preview/*,*/hidden/*',
        resolve_relations: ['Tour.services', 'Break.services'],
    }
    const options = {
        keepPreviousData: true,
    }
    const {
        isFetching,
        hasNextPage,
        fetchNextPage,
        data: travelData,
        isFetchingNextPage,
        error: travelError,
        isLoading: isLoadingTravels,
    } = useStoryblokInfiniteQuery(['travel-search', filter], 'cdn/stories/', params, options)
    const isFetchingAll = isLoadingTravels || isLoadingFormulas || isLoadingThemes
    const total = travelData?.pages[0]?.total || 0

    return {
        perPage,
        travelThemes,
        travelFormulas,
        hasMore: hasNextPage,
        isUpdating: isFetching,
        isFetching: isFetchingAll,
        onFetchMore: fetchNextPage,
        isFetchingMore: isFetchingNextPage,
        error: travelError || formulaError || themesError,
        total: isFetchingAll && total === 0 ? null : total,
        travels: travelData?.pages?.reduce((acc, page) => [...acc, ...page.data.stories], []) || [],
    }
}

export default useTravelSearchPageQuery
