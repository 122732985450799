import React from 'react'
import clsx from 'clsx'
import * as constants from '@connections/constants'
import { variant as createVariant } from '../../util/index'
import CustomText from './CustomText'

const {
    BACKGROUND_BLUE: BLUE,
    BACKGROUND_BLACK: BLACK,
} = constants

const SIZE_SMALL = 'small'
const SIZE_LARGE = 'large'

const Quote = ({
    size,
    quote,
    caption,
    variant,
    className,
    background,
}) => (
    <figure className={className}>
        <CustomText
            customText={quote}
            className={clsx(
                'font-medium tracking-tight  block',
                createVariant(size, {
                    [SIZE_SMALL]: 'text-3xl',
                    [SIZE_LARGE]: 'text-4xl md:text-5xl',
                }),
                createVariant(variant, {
                    blue: 'text-blue',
                }),
                createVariant(background, {
                    [BLUE]: 'text-white',
                    [BLACK]: 'text-white',
                })
            )}
            customTextClassName={clsx(
                'font-accent inline-block',
                createVariant(size, {
                    [SIZE_SMALL]: 'text-[2rem] leading-[32px]',
                    [SIZE_LARGE]: 'text-[2.5rem] md:text-7xl',
                }),
            )}
        />
        {caption && (
            <figcaption className={clsx(
                'text-xs tracking-wider',
                createVariant(size, {
                    [SIZE_SMALL]: 'text-3xl mt-4',
                    [SIZE_LARGE]: 'font-bold  mt-6',
                }),
                createVariant(background, {
                    [BLUE]: 'text-white',
                    [BLACK]: 'text-white',
                })
            )}
            >
                {caption}
            </figcaption>
        )}
    </figure>
)

export default Quote
