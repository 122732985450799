import React from 'react'
import clsx from 'clsx'
import { AnimatePresence } from 'framer-motion'
import { variant } from '../../util'
import CarouselButton, { NEXT, PREVIOUS } from '../utils/CarouselButton'

const CarouselControls = ({
    isDisabled,
    onNextSlide,
    hasNextSlide,
    size = 'medium',
    onPreviousSlide,
    hasPreviousSlide,
}) => {
    return (
        <AnimatePresence>
            {!isDisabled && (
                <div className="absolute inset-0 pointer-events-none">
                    <div
                        className={clsx(
                            'w-full h-full flex items-center mx-auto lg:max-w-[1440px]',
                            variant(size, {
                                small: 'px-2 lg:px-3',
                                medium: 'px-4 lg:px-6',
                            })
                        )}
                    >
                        {hasPreviousSlide && (
                            <CarouselButton
                                size={size}
                                direction={PREVIOUS}
                                onClick={onPreviousSlide}
                                className="pointer-events-auto"
                            />
                        )}
                        {hasNextSlide && (
                            <CarouselButton
                                size={size}
                                direction={NEXT}
                                onClick={onNextSlide}
                                className="ml-auto pointer-events-auto"
                            />
                        )}
                    </div>
                </div>
            )}
        </AnimatePresence>
    )
}

export default CarouselControls
