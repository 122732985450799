import React from 'react'
import FlightsListerGroups from './FlightsListerGroups'
import FlightsListerSectionButtons from './FlightsListerSectionButtons'
import FlightsListerSectionHeading from './FlightsListerSectionHeading'
import FlightsListerSectionContainer from './FlightsListerSectionContainer'
import DestinationFlightsListerGroup from './DestinationFlightsListerGroup'

const DestinationFlightsListerGroupSection = ({
    error,
    title,
    groups,
    loading,
    buttons,
    spacing,
    onSelect,
    anchorId,
    activeTab,
    container,
    background,
    activeGroup,
    description,
    onChangeTab,
}) => (
    <FlightsListerSectionContainer
        spacing={spacing}
        container={container}
        background={background}
        id={anchorId || undefined}
        data-testid="DestinationFlightsListerGroupSection"
    >
        <FlightsListerSectionHeading
            title={title}
            background={background}
            description={description}
        />
        <FlightsListerGroups
            groups={groups}
            activeTab={activeTab}
            onChange={onChangeTab}
            background={background}
            className="mb-6 sm:mb-10"
        />
        {activeGroup && (
            <DestinationFlightsListerGroup
                error={error}
                loading={loading}
                onSelect={onSelect}
                flightPrices={activeGroup.flightPrices}
            />
        )}
        <FlightsListerSectionButtons buttons={buttons} />
    </FlightsListerSectionContainer>
)

export default DestinationFlightsListerGroupSection
