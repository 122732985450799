import React from 'react'
import { format } from 'date-fns'
import { Trans } from '@lingui/react'
import * as constants from '@connections/constants'
import useFormState, { createFormValidation } from 'use-form-state'
import { whereName, isValidEmail, isRequired } from '@connections/utils'
import { SUBLABELS } from '../utils/JotformAddressInput'

const {
    JOTFORM_CONTROL_BUTTON,
    JOTFORM_CONTROL_ADDRESS,
    JOTFORM_CONTROL_CHECKBOX,
    JOTFORM_CONTROL_DATETIME,
    JOTFORM_CONTROL_FULLNAME,
} = constants

const mapJotformToForm = (form) => (
    form.reduce((formItems, formItem) => {
        if (formItem.type === JOTFORM_CONTROL_FULLNAME) {
            const { requiredInputs, sublabels } = formItem
            const subfields = Object.keys(sublabels)
            const nameFormItems = subfields.map((field) => {
                const name = `${formItem.qid}_${field}`
                return {
                    ...formItem,
                    required: typeof requiredInputs.find((f) => f === field) !== 'undefined',
                    qid: name,
                    name,
                }
            })
            return [
                ...formItems,
                ...nameFormItems
            ]
        }
        if (formItem.type === JOTFORM_CONTROL_ADDRESS) {
            const { requiredInputs, subfields } = formItem
            const addressFormItems = subfields.map((field) => {
                const name = `${formItem.qid}_${SUBLABELS[field]}`
                return {
                    ...formItem,
                    required: typeof requiredInputs.find((f) => f === field) !== 'undefined',
                    qid: name,
                    name,
                }
            })
            return [
                ...formItems,
                ...addressFormItems
            ]
        }
        if (formItem.type === JOTFORM_CONTROL_BUTTON) {
            return formItems
        }
        return [
            ...formItems,
            formItem
        ]
    }, [])
)

const createValidation = (formItems) => {
    const validation = formItems.reduce((validations, formItem) => {
        const formItemValidations = []
        if (formItem.required) {
            formItemValidations.push({
                path: formItem.name,
                validate: isRequired,
                message: <Trans id="This field is required" />,
            })
        }
        if (formItem.validation !== 'NONE') {
            let validate = () => { }
            if (formItem.validation === 'EMAIL') {
                validate = (value) => value === '' || isValidEmail(value)
            }
            formItemValidations.push({
                path: formItem.name,
                validate,
                message: <Trans id="This field has an invalid format." />,
            })
        }
        return [
            ...validations,
            ...formItemValidations
        ]
    }, [])
    return createFormValidation(validation)
}

const createInitialValues = (formItems) => (
    formItems.reduce((items, {
        type,
        selected,
        name,
        allowTime
    }) => {
        let initialValue = ''
        if (selected !== null) {
            if (type === JOTFORM_CONTROL_CHECKBOX) {
                initialValue = selected.split('|').map((value) => value.substring(0, value.length - 2)).join('|')
            } else {
                initialValue = selected
            }
        }
        if (type === JOTFORM_CONTROL_DATETIME) {
            if (allowTime) {
                return {
                    ...items,
                    [name]: null,
                    [`${name}_time`]: '12:00',
                }
            }
            return {
                ...items,
                [name]: null,
            }
        }
        return {
            ...items,
            [name]: initialValue
        }
    }, {})
)

const valuesToInput = (values, formItems, formTitle) => (
    Object
        .keys(values)
        .reduce((input, key) => {
            const value = values[key]
            if (key.includes('_time')) {
                return input
            }
            const {
                qid,
                type,
                allowTime,
                name,
                hidden
            } = formItems.find(whereName(key))
            if (name === 'title' && hidden === 'Yes') {
                return {
                    ...input,
                    [`submission[${qid}]`]: formTitle || 'Unknown'
                }
            }
            if (type === JOTFORM_CONTROL_DATETIME && value !== null) {
                if (allowTime) {
                    const timeValue = values[`${key}_time`]
                    const [hours, minutes] = timeValue.split(':')
                    return {
                        ...input,
                        [`submission[${qid}_day]`]: format(value, 'd'),
                        [`submission[${qid}_month]`]: format(value, 'M'),
                        [`submission[${qid}_year]`]: format(value, 'yyyy'),
                        [`submission[${qid}_timeInput]`]: timeValue,
                        [`submission[${qid}_hour]`]: hours,
                        [`submission[${qid}_min]`]: minutes,
                    }
                }
                return {
                    ...input,
                    [`submission[${qid}_day]`]: format(value, 'd'),
                    [`submission[${qid}_month]`]: format(value, 'M'),
                    [`submission[${qid}_year]`]: format(value, 'yyyy'),
                }
            }
            return {
                ...input,
                [`submission[${qid}]`]: value
            }
        }, {})
)

const useJotformFormState = (form, formTitle) => {
    const formItems = mapJotformToForm(form)
    const validation = createValidation(formItems)
    const initialValues = createInitialValues(formItems)
    return useFormState(
        initialValues,
        {
            validation,
            valuesToInput: (values) => valuesToInput(values, formItems, formTitle),
        }
    )
}

export default useJotformFormState
