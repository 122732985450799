import React from 'react'
import clsx from 'clsx'
import * as constants from '@connections/constants'
import { variant } from '../../util/index'

const {
    COLUMN_LEFT,
    COLUMN_RIGHT,
    ALIGN_CENTER: CENTER,
    ALIGN_LEFT: LEFT,
    ALIGN_RIGHT: RIGHT,
    ALIGN_TOP: TOP,
    ALIGN_BOTTOM: BOTTOM,
    COLUMN_WIDTH_FULL: FULL,
    COLUMN_WIDTH_LARGE: LARGE,
    COLUMN_WIDTH_MEDIUM: MEDIUM,
    COLUMN_WIDTH_SMALL: SMALL,
    COLUMN_SPACING_NONE,
    COLUMN_SPACING_LARGE,
    COLUMN_SPACING_MEDIUM,
    COLUMN_SPACING_SMALL,
} = constants

const Column = ({
    align,
    children,
    className,
    alignVertical,
}) => (
    <div
        className={clsx(
            'flex w-full lg:flex-1',
            variant(align, {
                [CENTER]: 'justify-center',
                [LEFT]: 'justify-start',
                [RIGHT]: 'justify-end',
            }),
            variant(alignVertical, {
                [CENTER]: 'my-auto',
                [TOP]: 'mb-auto',
                [BOTTOM]: 'mt-auto',
            }),
            className,
        )}
    >
        {children}
    </div>
)

const ColumnContainer = ({
    position,
    width = FULL,
    spacing = COLUMN_SPACING_NONE,
    className,
    ...props
}) => (
    <div
        className={clsx(
            '',
            variant(spacing, {
                [COLUMN_SPACING_NONE]: 'py-8 lg:py-0',
                [COLUMN_SPACING_SMALL]: 'py-8 lg:py-8',
                [COLUMN_SPACING_MEDIUM]: 'py-16 lg:py-16',
                [COLUMN_SPACING_LARGE]: 'py-24 lg:py-24',
            }),
            variant(width, {
                [FULL]: 'w-full',
                [LARGE]: 'w-full lg:max-w-[85%]',
                [MEDIUM]: 'w-full lg:max-w-[70%]',
                [SMALL]: 'w-full lg:max-w-[50%]',
            }),
            variant(position, {
                [COLUMN_LEFT]: 'md:pr-4 lg:pr-6 xl:pr-8',
                [COLUMN_RIGHT]: 'md:pl-4 lg:pl-6 xl:pl-8',
            }),
            className,
        )}
        {...props}
    />
)

Column.Container = ColumnContainer

export default Column
