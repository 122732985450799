import React from 'react'
import SbEditable from 'storyblok-react'
import useStoryblokBridge from '../../hooks/useStoryblokBridge'
import ExperiencePage from './ExperiencePage'

const ExperiencePageWithState = ({
    data,
    resolveRelations,
}) => {
    const { content } = useStoryblokBridge(data.pageStory, resolveRelations)
    return (
        <SbEditable content={content}>
            <ExperiencePage {...content} />
        </SbEditable>
    )
}

export default ExperiencePageWithState
