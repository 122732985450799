import React from 'react'
import SbEditable from 'storyblok-react'
import useStoryblokBridge from '../../hooks/useStoryblokBridge'
import HotelPage from './HotelPage'

const HotelPageWithState = ({
    data,
    resolveRelations,
}) => {
    const { content } = useStoryblokBridge(data.pageStory, resolveRelations)

    return (
        <SbEditable content={content}>
            <HotelPage {...content} />
        </SbEditable>
    )
}

export default HotelPageWithState
