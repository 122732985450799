import React from 'react'
import clsx from 'clsx'
import SbEditable from 'storyblok-react'
import * as constants from '@connections/constants'
import { variant } from '../../util/index'
import Column from '../utils/Column'
import ContentVideo from './ContentVideo'

const { IMAGE_STRETCH: STRETCH } = constants

const VideoColumn = ({
    content,
    position,
}) => {
    const {
        size,
        video,
        autoPlay,
        muted,
        showControls,
        loop,
    } = content

    return (
        <SbEditable content={content}>
            <Column
                position="none"
                className={clsx(
                    variant(size, {
                        [STRETCH]: 'self-stretch',
                    })
                )}
            >
                <ContentVideo
                    size={size}
                    video={video}
                    maxWidth={1080}
                    position={position}
                    autoPlay={autoPlay}
                    muted={muted}
                    controls={showControls}
                    loop={loop}
                />
            </Column>
        </SbEditable>
    )
}

export default VideoColumn
