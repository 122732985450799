import { lineString } from '@turf/helpers'
import bezierSpline from '@turf/bezier-spline'
import midpoint from '@turf/midpoint'
import distance from '@turf/distance'
import lineSliceAlong from '@turf/line-slice-along'

const KM_RATIO = 1000

export const getPointsFromDayItems = (dayItems) => (
    dayItems.reduce((acc, item) => [
        ...acc,
        ...(item.points || []),
    ], []))

export const getBounds = (dayItems) => {
    const points = getPointsFromDayItems(dayItems)
    const bounds = points.reduce(([
        southWest,
        northEast,
    ], marker) => {
        const { latitude, longitude } = marker
        return [
            [
                Math.min(southWest[0], longitude),
                Math.min(southWest[1], latitude)
            ],
            [
                Math.max(northEast[0], longitude),
                Math.max(northEast[1], latitude)
            ]
        ]
    }, [[180, 90], [-180, -90]])

    return bounds
}

export const getMeterPerPixel = (zoom, lat) => (
    // eslint-disable-next-line no-restricted-properties
    (156543.03392 * Math.cos(lat * (Math.PI / 180))) / Math.pow(2, zoom)
)

export const getCurvedLine = (from, to, zoom, mapRef) => {
    if (!zoom || !mapRef) {
        return null
    }
    const distanceBetween = distance(from, to) * KM_RATIO
    const distanceIsBigEnough = distanceBetween > 16 * KM_RATIO
    if (!distanceIsBigEnough) {
        return null
    }

    const midpointFromTo = midpoint(from, to).geometry.coordinates
    const offset = from.geometry.coordinates[1] <= to.geometry.coordinates[1]
        ? -(distanceBetween / KM_RATIO) / 1800
        : distanceBetween / KM_RATIO / 1800
    const midpointOffset = [
        midpointFromTo[0] + offset,
        midpointFromTo[1] + offset,
    ]
    const line = lineString([
        from.geometry.coordinates,
        midpointOffset,
        to.geometry.coordinates,
    ])
    const curved = bezierSpline(line)

    // Calculate slice offset depending on zoom
    const { lat } = mapRef.getCenter()
    const mPerPixel = getMeterPerPixel(zoom, lat)
    const sliceOffset = Math.min(mPerPixel * 15, distanceBetween / 3) / KM_RATIO
    const sliced = lineSliceAlong(
        curved,
        sliceOffset,
        (distanceBetween / KM_RATIO) - sliceOffset,
    )
    return sliced
}

export const coordinatesAreTheSame = (from, to) => {
    const distanceBetween = distance(from, to)
    return distanceBetween === 0
}
