import React, { forwardRef } from 'react'
import clsx from 'clsx'
import SbEditable from 'storyblok-react'
import Image from '../utils/Image'
import RichTextField from './RichTextField'
import Stamp from './Stamp'

const TestimonialsSectionSlide = forwardRef(({
    testimonials,
    className,
}, ref) => (
    <div
        className={clsx(
            'inline-flex items-start',
            className,
        )}
        ref={ref}
    >
        {testimonials.map((testimonial, index) => {
            const {
                _uid,
                image,
                text,
                name,
                experience,
            } = testimonial
            if (!image) {
                return null
            }
            // Get random value which is the same everytime (for SSR)
            const randomInt = _uid.replace(/\D/g, '')[0]
            const stampValue = Math.ceil((randomInt / 10) * 3)
            return (
                <SbEditable
                    content={testimonial}
                    key={_uid}
                >
                    <div
                        className={clsx(
                            'bg-white rounded-2xl shadow-md text-black p-2 relative inline-block mr-10 w-72 shrink-0',
                            // eslint-disable-next-line no-bitwise
                            (index & 1) && 'mt-12'
                        )}
                    >
                        {image.filename && (
                            <div className="flex">
                                <Image
                                    width={270}
                                    height={148}
                                    alt={image.alt}
                                    draggable={false}
                                    src={image.filename}
                                    className="object-cover rounded-t-2xl"
                                />
                            </div>
                        )}
                        <div className="whitespace-pre-wrap px-5 py-4">
                            {text && (
                                <RichTextField
                                    content={text}
                                    className="mb-2 prose-p:mb-4 last:prose-p:mb-0 prose-p:text-base"
                                />
                            )}
                            <div className="flex justify-between items-center w-full">
                                <div>
                                    {name && (
                                        <h4 className="font-medium text-lg">
                                            {name}
                                        </h4>
                                    )}
                                    {experience && (
                                        <span className="text-gray-500">
                                            {experience}
                                        </span>
                                    )}
                                </div>
                                <Stamp value={stampValue} />
                            </div>
                        </div>
                    </div>
                </SbEditable>
            )
        })}
    </div>
))

export default TestimonialsSectionSlide
