import React, { useState } from 'react'
import BulletItem from './BulletItem'

const BulletItemWithState = ({
    icon,
    label,
    background,
    children,
}) => {
    const [isOpen, setIsOpen] = useState(false)

    const handleToggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <BulletItem
            icon={icon}
            label={label}
            isOpen={isOpen}
            onToggleOpen={handleToggle}
            background={background}
        >
            {children}
        </BulletItem>
    )
}

export default BulletItemWithState
