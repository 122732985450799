import React from 'react'
import clsx from 'clsx'
import dynamic from 'next/dynamic'
import SbEditable from 'storyblok-react'
import { variant } from '../../util/index'

const MapSectionMap = dynamic(
    () => import('./MapSectionMap'),
    { ssr: false }
)

const SIZE_SMALL = 'small'
const SIZE_MEDIUM = 'medium'
const SIZE_LARGE = 'large'

const MapSection = ({
    content,
    className,
}) => {
    const {
        size = SIZE_MEDIUM,
        zoom,
        centerLat,
        centerLong,
        points = [],
    } = content
    return (
        <SbEditable content={content}>
            <section
                className={clsx(
                    variant(size, {
                        [SIZE_SMALL]: 'h-[50vh]',
                        [SIZE_MEDIUM]: 'h-[75vh]',
                        [SIZE_LARGE]: 'h-screen',
                    }),
                    className,
                )}
            >
                <MapSectionMap
                    zoom={zoom}
                    centerLat={centerLat}
                    centerLong={centerLong}
                    points={points}
                />
            </section>
        </SbEditable>
    )
}

export default MapSection
