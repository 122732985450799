import useStoryblokQuery from '../../hooks/useStoryblokQuery'

const useExperiencesSearchPageQuery = (
    filters = {},
    { perPage = 12, page = 1 } = {}
) => {
    const filter = {
        isExperience: {
            is: 'true',
        },
    }
    if (filters.type) {
        filter.experienceType = { in: filters.type }
    }
    if (filters.continentCode) {
        filter.continentCodes = { in_array: filters.continentCode }
    }
    if (filters.countryCode) {
        filter.countryCodes = { in_array: filters.countryCode }
    }
    if (filters.iataCode) {
        filter.iataCodes = { in_array: filters.iataCode }
    }
    const params = {
        page,
        per_page: perPage,
        filter_query: filter,
        excluding_slugs: '_test/*,preview/*,*/hidden/*',
        by_slugs: 'rondreizen/*,activiteiten/*,breaks/*',
    }
    const {
        data,
        error,
        isLoading: isFetching,
    } = useStoryblokQuery('experiences-search', 'cdn/stories/', params)

    let searchResults = []
    if (isFetching) {
        return {
            isFetching,
            searchResults,
        }
    }

    if (error) {
        return {
            error,
            isFetching,
            searchResults,
        }
    }

    const {
        total,
        data: {
            stories,
        },
    } = data
    searchResults = stories

    return {
        total,
        perPage,
        isFetching,
        searchResults,
    }
}

export default useExperiencesSearchPageQuery
