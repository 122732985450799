import React from 'react'
import clsx from 'clsx'

const TabsColumnMobileTabs = ({
    groups,
    activeGroup,
    onChange,
    className,
}) => (
    <div
        className={clsx(
            'sm:hidden',
            className,
        )}
    >
        <label htmlFor="groups" className="sr-only">
            Select a group
        </label>
        <span className="relative inline-flex items-center w-full">
            <select
                id="groups"
                name="groups"
                className="block w-full p-4 pr-10 bg-white border border-gray-100 focus:outline-none text-sm rounded-md appearance-none"
                defaultValue={activeGroup}
                onChange={(e) => onChange(parseInt(e.target.value, 10))}
            >
                {groups.map((group, index) => (
                    <option
                        key={group._uid}
                        value={index}
                    >
                        {group.title}
                    </option>
                ))}
            </select>
            <svg className="absolute right-4 text-gray-800" width="7" height="4" viewBox="0 0 7 4">
                <path d="M0 0L3.5 4L7 0H0Z" />
            </svg>
        </span>
    </div>
)

export default TabsColumnMobileTabs
