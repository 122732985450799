import React from 'react'
import clsx from 'clsx'
import { Trans } from '@lingui/react'
import { formatPriceWithoutSymbol } from '../../../util/prices'
import Icon from '../../utils/Icon'
import Radio from '../../utils/Radio'

const AVAILABLE = 'Available'

const BookTemplateModalRoomButtonCard = ({
    value,
    roomName,
    onChange,
    priceDifference,
    numberOfTravelers,
    boardBaseDisplayName = null,
    availabilityStatus = AVAILABLE,
}) => {
    const isNegative = priceDifference < 0
    const priceDiff = Math.abs(priceDifference)
    const isAvailable = availabilityStatus === AVAILABLE
    const priceDiffPerPerson = priceDiff / numberOfTravelers
    return (
        <button
            type="button"
            onClick={onChange}
            disabled={!isAvailable}
            className={clsx(
                'w-full flex justify-between items-center py-5 px-6 border border-gray-100 rounded mt-6',
                value ? 'bg-gray-50' : '',
                !isAvailable ? 'opacity-50' : '',
            )}
        >
            <div className="flex items-center">
                <Radio
                    readOnly
                    value={value}
                    disabled={!isAvailable}
                    className="mr-4 lg:mr-8"
                />
                <div>
                    <div className="flex mb-px">
                        <Icon
                            name="bed"
                            size="small"
                            color="blue"
                            className="hidden lg:block"
                        />
                        <p className="text-sm font-medium text-left lg:ml-3">
                            {roomName}
                        </p>
                    </div>
                    <p className="text-xs text-gray-600 text-left">
                        {boardBaseDisplayName !== null ? boardBaseDisplayName : null}
                        {isAvailable && boardBaseDisplayName !== null ? ' - ' : null}
                        {isAvailable ? <Trans id="Available" /> : null}
                    </p>
                </div>
            </div>
            {isAvailable ? (
                <div className="lg:flex">
                    <div className="flex flex-col items-end lg:pr-4 lg:border-r lg:border-gray-100">
                        <p className="text-gray-800">
                            <span className="mr-1">
                                {isNegative ? '-' : '+'}
                            </span>
                            <span>&euro;</span>
                            <span className="text-xl font-medium">
                                {formatPriceWithoutSymbol(priceDiffPerPerson)}
                            </span>
                        </p>
                        <p className="text-xs text-gray-600">
                            <Trans id="Per person" />
                        </p>
                    </div>
                    <div className="w-28 flex flex-col items-end">
                        <p className="text-primary">
                            <span className="mr-1">
                                {isNegative ? '-' : '+'}
                            </span>
                            <span>&euro;</span>
                            <span className="text-xl font-medium">
                                {formatPriceWithoutSymbol(priceDiff)}
                            </span>
                        </p>
                        <p className="text-xs text-gray-600">
                            <Trans id="Total price" />
                        </p>
                    </div>
                </div>
            ) : (
                <p className="text-sm uppercase">
                    <Trans id="Sold out" />
                </p>
            )}
        </button>
    )
}

export default BookTemplateModalRoomButtonCard
