/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useEffect, Fragment } from 'react'
import clsx from 'clsx'
import { Trans, useLingui } from '@lingui/react'
import { useOnClickOutside } from '@wappla/react-hooks'
import { MAX_BOOKING_CHILD_AGE } from '@connections/constants'
import usePressEscape from '../hooks/usePressEscape'
import Select from './Select'
import GuestsSelectButton from './GuestsSelectButton'
import PassengersCountInput from './PassengersCountInput'

const GuestsListbox = ({
    buttons,
    className,
    maxChildAge,
    listOfAdults,
    numberOfRooms,
    listOfChildren,
    onChangeAdults,
    clickOutsideRef,
    onChangeChildren,
    onChangeChildAge,
    onChangeNumberOfRooms,
    onPressEscape = () => { },
    onClickOutside = () => { },
}) => {
    const inputRef = useRef()
    const { i18n } = useLingui()
    const containerRef = useRef()
    useEffect(() => inputRef.current.focus(), [])
    useOnClickOutside(clickOutsideRef || containerRef, onClickOutside)
    usePressEscape(onPressEscape)
    // MaxChildAge can be an empty string
    const maxChildAgeToUse = maxChildAge || MAX_BOOKING_CHILD_AGE
    return (
        <div
            ref={containerRef}
            className={clsx(
                'bg-white',
                className,
            )}
        >
            <GuestsSelectButton
                ref={inputRef}
                className="mb-2"
                numberOfAdults={listOfAdults.length}
                numberOfChildren={listOfChildren.length}
                numberOfRooms={numberOfRooms}
            />
            <div className="px-2 pb-2 pt-2">
                {[...Array(numberOfRooms)].map((_, i) => {
                    const roomAdults = listOfAdults.filter(({ roomIndex }) => roomIndex === i)
                    const roomChildren = listOfChildren.filter(({ roomIndex }) => roomIndex === i)
                    return (
                        <Fragment key={`guest-listbox-room-${i}`}>
                            <div className="flex justify-between items-center">
                                <p className="text-sm font-bold ">
                                    <Trans id="Room" />
                                    {` ${i + 1}`}
                                </p>
                                {i !== 0 && (
                                    <button
                                        type="button"
                                        className="text-sm text-blue-bright underline"
                                        onClick={() => onChangeNumberOfRooms(numberOfRooms - 1, i)}
                                    >
                                        <Trans id="remove" />
                                    </button>
                                )}
                            </div>
                            <PassengersCountInput
                                title={<Trans id="Adult(s)" />}
                                subTitle={<Trans id="+ {maxChildAge} years old" values={{ maxChildAge: maxChildAgeToUse }} />}
                                value={roomAdults.length}
                                onChange={(number) => onChangeAdults(i, number)}
                                min={1}
                                testId="AdultsPassengersCountInput"
                            />
                            <PassengersCountInput
                                title={<Trans id="Child(ren)" />}
                                subTitle={<Trans id="Until {maxChildAge} years" values={{ maxChildAge: maxChildAgeToUse }} />}
                                value={roomChildren.length}
                                onChange={(number) => onChangeChildren(i, number)}
                                testId="ChildrenPassengersCountInput"
                            />
                            {roomChildren.length > 0 && roomChildren.map(({ age }, childIndex) => (
                                <div className="mb-2">
                                    <label className="text-sm">
                                        <Trans id="Child" /> {childIndex + 1}
                                    </label>
                                    <div className="text-xs text-gray-400 mt-[2px]">
                                        <Select
                                            value={age}
                                            onChange={(e) => onChangeChildAge(i, childIndex, e.target.value)}
                                        >
                                            {[...Array(maxChildAgeToUse + 1)].map((_, index) => (
                                                <option value={index} key={index}>
                                                    {(() => {
                                                        if (index === 0) {
                                                            return i18n._(/*i18n*/'Under 1')
                                                        }
                                                        if (index === 1) {
                                                            return `${index} ${i18n._(/*i18n*/'Year')}`
                                                        }
                                                        return `${index} ${i18n._(/*i18n*/'Years')}`
                                                    })()}
                                                </option>
                                            ))}
                                        </Select>
                                    </div>
                                </div>
                            ))}
                            {/* Max amount of rooms is set to 3 in the formState */}
                            {i + 1 === numberOfRooms && i !== 2 && (
                                <button
                                    type="button"
                                    className="text-sm text-blue-bright underline"
                                    onClick={() => onChangeNumberOfRooms(numberOfRooms + 1, numberOfRooms)}
                                >
                                    + <Trans id="Add another room" />
                                </button>
                            )}
                        </Fragment>
                    )
                })}
            </div>
            {buttons}
        </div>
    )
}

export default GuestsListbox
