import React from 'react'
import clsx from 'clsx'
import SbEditable from 'storyblok-react'
import {
    sectionBackgroundVariant,
    sectionContainerVariant,
    sectionSpacingVariant,
} from '../../util/variants'

const IframeSection = ({
    content,
    className,
}) => {
    const {
        url,
        canScroll,
        title,
        background,
        spacing,
        container,
        height,
        anchorId,
    } = content
    return (
        <SbEditable content={content}>
            <section
                id={anchorId || undefined}
                className={clsx(
                    sectionBackgroundVariant(background),
                    sectionSpacingVariant({ spacing }),
                    className,
                )}
            >
                <div
                    style={{
                        height: `${height}px`
                    }}
                    className={clsx(
                        sectionContainerVariant(container),
                    )}
                >
                    <iframe
                        className="w-full h-full"
                        src={url}
                        scrolling={canScroll ? 'yes' : 'no'}
                        title={title}
                    />
                </div>
            </section>
        </SbEditable>
    )
}

export default IframeSection
