import React from 'react'
import { Trans } from '@lingui/react'
import SbEditable from 'storyblok-react'
import { splitCodes } from '../../util/index'
import Spinner from '../utils/Spinner'
import useCheapestDestinationsPrices from '../hooks/useCheapestDestinationsPrices'
import AirlinePricesCard from './AirlinePricesCard'

const AirlinePricesCardWithState = ({
    content,
}) => {
    const {
        airline,
        departureCodes,
        destinations
    } = content
    const {
        error,
        loading,
        cheapestDestinations,
    } = useCheapestDestinationsPrices({
        airline,
        destinations,
        departureCodes: splitCodes(departureCodes)
    })
    if (!airline.content) {
        return (
            <AirlinePricesCard.Container className="flex justify-center items-center">
                <p><Trans id="Please select an airline." /></p>
            </AirlinePricesCard.Container>
        )
    }
    if (loading) {
        return (
            <AirlinePricesCard.Container>
                <Spinner color="gray" />
            </AirlinePricesCard.Container>
        )
    }
    if (error) {
        return (
            <AirlinePricesCard.Container className="flex justify-center items-center">
                <p><Trans id="Something went wrong fetching this airlines prices." /></p>
            </AirlinePricesCard.Container>
        )
    }
    return (
        <SbEditable content={content}>
            <AirlinePricesCard
                airline={airline}
                destinations={cheapestDestinations}
            />
        </SbEditable>
    )
}

export default AirlinePricesCardWithState
