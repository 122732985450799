import React from 'react'
import clsx from 'clsx'
import { Trans } from '@lingui/react'
import Spinner from '../utils/Spinner'
import FlightsListerDestinationCard from './FlightsListerDestinationCard'

const FlightsListerDestinationCardGroup = ({
    error,
    loading,
    destinations,
    className,
}) => {
    if (loading) {
        return (
            <div className={clsx('flex justify-center items-center', className)}>
                <Spinner color="gray" />
            </div>
        )
    }

    if (destinations.length === 0 || error) {
        return (
            <div className={clsx('flex justify-center items-center', className)}>
                <p className="text-sm md:text-base text-black opacity-70 md:text-center">
                    <Trans id="Unfortunately, there are no flights available for this group." />
                </p>
            </div>
        )
    }

    return (
        <div className={clsx('grid grid-cols-2 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-4 gap-3 md:gap-6', className)}>
            {destinations.map((destination) => {
                if (!destination) {
                    return null
                }
                return (
                    <FlightsListerDestinationCard
                        key={destination.id}
                        destination={destination}
                    />
                )
            })}
        </div>
    )
}

export default FlightsListerDestinationCardGroup
