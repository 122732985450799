import React from 'react'
import CardsSection from '../../content/CardsSection'
import InfiniteScroll from '../../utils/InfiniteScroll'

const SearchPageRow = ({
    title,
    state,
    component,
    entity,
}) => {
    const {
        total,
        searchResults,
        hasMore,
        handleFetchMore,
        isFetchingMore,
        loadMoreOnPageEnd,
        handleLoadMore,
    } = state

    if (total === 0) {
        return null
    }

    return (
        <InfiniteScroll
            hasMore={hasMore}
            onFetchMore={handleFetchMore}
            isFetchingMore={isFetchingMore}
            loadMoreOnPageEnd={loadMoreOnPageEnd}
            onLoadMore={handleLoadMore}
        >
            <CardsSection
                title={title}
                columns={total >= 4 ? 4 : total}
                cards={searchResults.map((result) => ({
                    _uid: result.uuid,
                    component,
                    [entity]: result,
                }))}
                className="mb-8"
            />
        </InfiniteScroll>
    )
}

export default SearchPageRow
