import React, { useState } from 'react'
import DayItemTabGroup from './DayItemTabGroup'

const DayItemTabGroupWithState = ({
    content,
}) => {
    const [activeTabGroupId, setActiveTabGroupId] = useState(0)

    const handleTabGroupClick = (id) => {
        setActiveTabGroupId(id)
    }

    return (
        <DayItemTabGroup
            content={content}
            activeTabGroupId={activeTabGroupId}
            onTabGroupClick={handleTabGroupClick}
        />
    )
}

export default DayItemTabGroupWithState
