import React from 'react'
import SbEditable from 'storyblok-react'
import CarouselSection from './CarouselSection'

const CarouselSectionWithState = ({
    content,
}) => (
    <SbEditable content={content}>
        <CarouselSection
            {...content}
        />
    </SbEditable>
)

export default CarouselSectionWithState
