import React, { useState } from 'react'
import SbEditable from 'storyblok-react'
import { whereUuid } from '@connections/utils'
import ProgramBlockModal from '../utils/ProgramBlockModal'
import ProgramBlocksSection from './ProgramBlocksSection'

const ProgramBlocksSectionWithState = ({
    content,
}) => {
    const [selectedBlockUuid, setSelectedBlockUuid] = useState(null)
    const [pagesVisible, setPagesVisible] = useState(1)
    const { groups, blocksPerPage } = content
    const blocksObject = groups.reduce((blocksAcc, group) => {
        const newAcc = { ...blocksAcc }
        group.slots.forEach((slot) => {
            const {
                timeEnd,
                timeStart,
                language,
                title = '',
                programBlock,
            } = slot
            const { uuid } = slot.programBlock
            const existingBlock = newAcc[uuid] || {}
            const slots = { ...existingBlock.slots }
            slots[title] = [
                ...(slots[title] || []),
                {
                    group: group.title,
                    title,
                    timeEnd,
                    timeStart,
                    language,
                }
            ]
            newAcc[uuid] = {
                ...programBlock,
                ...newAcc[uuid],
                slots,
            }
        })
        return newAcc
    }, {})

    const blocksVisible = pagesVisible * parseInt(blocksPerPage, 10)
    const blockArr = Object.values(blocksObject)
    const blocks = blockArr.slice(0, blocksVisible)
    const hasMorePages = blocks.length < blockArr.length

    const handleCloseModal = () => {
        setSelectedBlockUuid(null)
    }

    const handleReadMore = (uuid) => {
        setSelectedBlockUuid(uuid)
    }

    const handleShowMoreSpeakers = () => {
        setPagesVisible(pagesVisible + 1)
    }

    const selectedBlock = blocks.find((whereUuid(selectedBlockUuid)))

    return (
        <SbEditable content={content}>
            <ProgramBlocksSection
                {...content}
                blocks={blocks}
                onReadMore={handleReadMore}
                hasMorePages={hasMorePages}
                onShowMoreSpeakers={handleShowMoreSpeakers}
            />
            {selectedBlock && (
                <ProgramBlockModal
                    onClose={handleCloseModal}
                    {...selectedBlock}
                />
            )}
        </SbEditable>
    )
}

export default ProgramBlocksSectionWithState
