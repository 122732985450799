import React from 'react'
import AnnouncementBarSliderWithState from './AnnouncementBarSliderWithState'

export default function PageMain({
    children,
    ...props
}) {
    return (
        <main {...props}>
            <AnnouncementBarSliderWithState className="lg:hidden" />
            {children}
        </main>
    )
}
