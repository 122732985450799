import { useEffect, useState } from 'react'

// Third-grade students = 150 words per minute (wpm)
// Eighth grade students = 250 wpm
// Average college student = 450 wpm
// Average "high-level exec" = 575 wpm
// Average college professor = 675 wpm
// Speed readers = 1,500 wpm
// World speed reading champion = 4,700 wpm
// Average adult = 300 wpm

const useTimeToRead = ({
    wpm = 300,
    id = '',
}) => {
    const [timeToRead, setTimeToRead] = useState(null)
    useEffect(() => {
        const text = document.getElementById(id).innerText
        const words = text.trim().split(/\s+/).length
        setTimeToRead(Math.round(words / wpm))
    }, [])
    return timeToRead
}

export default useTimeToRead
