import clsx from 'clsx'
import React from 'react'
import dynamic from 'next/dynamic'
import { Trans } from '@lingui/react'
import { motion, AnimatePresence } from 'framer-motion'
import { BACKGROUND_BLACK } from '@connections/constants'
import { variant } from '../../util'
import { sectionBackgroundVariant, sectionContainerVariant, sectionSpacingVariant } from '../../util/variants'
import Image from '../utils/Image'
import CustomText from '../utils/CustomText'
import Button, { VARIANT_BLUE_OUTLINED } from '../utils/Button'

const ProgramSlotsBackground = dynamic(
    () => import('../utils/ProgramSlotsBackground'),
    { ssr: false }
)

const ProgramBlocksSection = ({
    title,
    blocks,
    spacing,
    anchorId,
    container,
    onReadMore,
    expandText,
    background,
    description,
    hasMorePages,
    onShowMoreSpeakers,
}) => (
    <section
        id={anchorId || undefined}
        data-testid="ProgramBlocksSection"
        className={sectionSpacingVariant({ spacing })}
    >
        <div className="text-center mb-8 md:mb-16">
            <CustomText
                as="h3"
                customText={title}
                className="text-4xl font-medium mb-4"
                customTextClassName="font-accent text-5xl"
            />
            <p className="">
                {description}
            </p>
        </div>

        <div className="relative">
            <div className={sectionContainerVariant(container)}>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {blocks.map((block) => {
                        const { uuid, image } = block

                        if (!image || !block.title || !block.button) {
                            return null
                        }

                        return (
                            <div key={uuid} className="bg-white rounded-lg shadow-md overflow-hidden will-change-transform flex flex-col">
                                <div className="curve-parent">
                                    <div className="relative h-40 curve-child">
                                        {image?.src && (
                                            <Image
                                                {...image}
                                                fill
                                                gravity="face"
                                                maxWidth={880}
                                                maxHeight={320}
                                                className="object-cover z-1"
                                            />
                                        )}
                                        <div className="absolute inset-0 skeleton" />
                                    </div>
                                </div>
                                <div className="px-6 py-8 text-center flex flex-col justify-between flex-1">
                                    <div className="mb-8">
                                        <h4 className="text-lg font-medium">{block.title}</h4>
                                    </div>
                                    <div>
                                        <p className="line-clamp-4 mb-8">
                                            {block.description}
                                        </p>
                                        <div className="px-2 w-full">
                                            <Button
                                                className="w-full"
                                                variant={VARIANT_BLUE_OUTLINED}
                                                onClick={() => onReadMore(uuid)}
                                            >
                                                <Trans id="Read more" />
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div
                className={clsx(
                    'absolute -z-1 w-full top-16 bottom-16 overflow-hidden',
                    sectionBackgroundVariant(background),
                )}
            >
                <ProgramSlotsBackground
                    className={clsx(
                        'opacity-5',
                        variant(background, {
                            [BACKGROUND_BLACK]: 'text-white',
                        }, 'text-black')
                    )}
                />
            </div>
        </div>
        <AnimatePresence>
            {hasMorePages && (
                <motion.div
                    className="flex justify-center"
                    exit={{ opacity: 0, height: 0 }}
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                >
                    <div className="pt-20">
                        <Button
                            onClick={onShowMoreSpeakers}
                            variant={VARIANT_BLUE_OUTLINED}
                        >
                            {expandText}
                        </Button>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    </section>
)

export default ProgramBlocksSection
