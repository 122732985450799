import { useState, useEffect } from 'react'
import { getAvailablePriceLevelsFromActivityPriceLevels } from '../../../util/booking'

const useTemplateActivities = (endDate, startDate, activities) => {
    const [selectedActivities, setSelectedActivities] = useState([])

    useEffect(() => {
        if (activities) {
            const defaultActivities = activities
                .filter(({ isAlternative }) => !isAlternative)
                .reduce((acc, { productId, priceLevels }) => {
                    const availablePriceLevels = getAvailablePriceLevelsFromActivityPriceLevels(priceLevels, startDate, endDate)
                    if (availablePriceLevels.length === 0) {
                        return acc
                    }
                    const [firstPriceLevel] = availablePriceLevels
                    const [firstAvailableDate] = firstPriceLevel.dateList
                    return [...acc, {
                        originalProductId: productId,
                        productId: firstPriceLevel.productId,
                        date: firstAvailableDate.availableDate,
                        dateProductId: firstAvailableDate.productId,
                    }]
                }, [])
            setSelectedActivities(defaultActivities)
        } else {
            setSelectedActivities([])
        }
    }, [activities])

    const handleChangeActivityOptions = ({
        date, productId, dateProductId, originalProductId
    }) => {
        const updatedSelectedActivities = selectedActivities.map((activity) => {
            if (activity.originalProductId === originalProductId) {
                return {
                    date,
                    productId,
                    dateProductId,
                    originalProductId,
                }
            }
            return activity
        })
        setSelectedActivities(updatedSelectedActivities)
    }
    const handleChangeActivity = (activityProductId) => {
        const existingActivity = selectedActivities.find(({ originalProductId }) => originalProductId === activityProductId)
        if (existingActivity) {
            setSelectedActivities(selectedActivities.filter(({ originalProductId }) => originalProductId !== activityProductId))
        } else {
            const activity = activities.find(({ productId }) => productId === activityProductId)
            const availablePriceLevels = getAvailablePriceLevelsFromActivityPriceLevels(activity.priceLevels, startDate, endDate)
            const [firstPriceLevel] = availablePriceLevels
            const [firstAvailableDate] = firstPriceLevel.dateList
            setSelectedActivities([
                ...selectedActivities,
                {
                    originalProductId: activityProductId,
                    productId: firstPriceLevel.productId,
                    date: firstAvailableDate.availableDate,
                    dateProductId: firstAvailableDate.productId,
                },
            ])
        }
    }

    return {
        selectedActivities,
        handleChangeActivity,
        handleChangeActivityOptions,
    }
}

export default useTemplateActivities
