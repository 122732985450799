import React from 'react'
import Page from '../../layout/Page'
import PageMain from '../../layout/PageMain'
import StickyHeader from '../../layout/StickyHeader'
import HeaderWithContent from '../../layout/HeaderWithContent'
import FooterWithContent from '../../layout/FooterWithContent'
import DynamicSection from '../../content/DynamicSection'
import CarouselSection from '../../content/CarouselSection'
import HotelPageDetails from './HotelPageDetails'
import HotelPageSubHeader from './HotelPageSubHeader'

const HotelPage = ({
    name,
    type,
    stars,
    images,
    address,
    body = [],
    expediaId,
    description,
    externalUrl,
    richDescription,
    breakButton = [],
}) => (
    <Page data-testid="HotelPage">
        <StickyHeader>
            <HeaderWithContent />
            <HotelPageSubHeader
                name={name}
                stars={stars}
            />
        </StickyHeader>
        <PageMain>
            <CarouselSection
                images={images}
                className="mb-16"
            />
            <HotelPageDetails
                name={name}
                type={type}
                stars={stars}
                address={address}
                expediaId={expediaId}
                externalUrl={externalUrl}
                description={description}
                className="container mb-16"
                breakButton={breakButton[0]}
                richDescription={richDescription}
            />
            {body && body.map((content) => (
                <DynamicSection
                    content={content}
                    key={content._uid}
                />
            ))}
        </PageMain>
        <FooterWithContent />
    </Page>
)

export default HotelPage
