import React from 'react'
import clsx from 'clsx'
import SbEditable from 'storyblok-react'
import * as constants from '@connections/constants'
import { variant } from '../../util/index'
import Column from '../utils/Column'
import Icon from '../utils/Icon'

const {
    COLUMN_WIDTH_FULL: FULL,
    BACKGROUND_BLUE: BLUE,
    BACKGROUND_BLACK: BLACK,
} = constants

const BulletsColumn = ({
    content,
    background,
    position,
    className,
}) => {
    const {
        title,
        width = FULL,
        columns = 1,
        align,
        spacing,
        bullets
    } = content
    return (
        <SbEditable content={content}>
            <Column
                align={align}
                position={position}
                className={className}
            >
                <Column.Container
                    width={width}
                    position={position}
                    spacing={spacing}
                >
                    {title && (
                        <h3
                            className={clsx(
                                'font-accent text-2xl transform -rotate-1 mb-6',
                                variant(background, {
                                    [BLUE]: 'text-white',
                                    [BLACK]: 'text-blue-bright',
                                }, 'text-blue')
                            )}
                        >
                            {title}
                        </h3>
                    )}
                    {bullets.length > 0 && (
                        <ul
                            className={clsx(
                                'grid gap-y-3 gap-x-4',
                                variant(columns, {
                                    1: 'grid-cols-1 md:grid-cols-1',
                                    2: 'grid-cols-1 md:grid-cols-2',
                                    3: 'grid-cols-1 md:grid-cols-3',
                                    4: 'grid-cols-1 md:grid-cols-4',
                                })
                            )}
                        >
                            {bullets.map((item) => (
                                <SbEditable key={item._uid} content={item}>
                                    <div className="flex items-center">
                                        <Icon
                                            name={item.icon}
                                            size="small"
                                            className={clsx(
                                                'mr-4 flex-shrink-0',
                                                variant(background, {
                                                    [BLUE]: 'text-white',
                                                    [BLACK]: 'text-blue-bright',
                                                }, 'text-blue')
                                            )}
                                        />
                                        <span className="truncate">
                                            {item.text}
                                        </span>
                                    </div>
                                </SbEditable>
                            ))}
                        </ul>
                    )}
                </Column.Container>
            </Column>
        </SbEditable>
    )
}

export default BulletsColumn
