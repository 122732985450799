import React from 'react'
import clsx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'
import SbEditable from 'storyblok-react'
import * as constants from '@connections/constants'
import CarouselButton, { NEXT, PREVIOUS } from '../utils/CarouselButton'
import { variant } from '../../util/index'
import CustomText from '../utils/CustomText'
import FeaturedCarouselSlide from './FeaturedCarouselSlide'
import SbButton from '../utils/SbButton'

const {
    CONTAINER_EXTRA_SMALL: EXTRA_SMALL,
    CONTAINER_SMALL: SMALL,
    CONTAINER_MEDIUM: MEDIUM,
    CONTAINER_DEFAULT: DEFAULT,
} = constants

const FeaturedCarouselSection = ({
    activeSlide,
    position,
    hasPreviousSlide,
    hasNextSlide,
    handleSlideSelect,
    handleNextSlide,
    handlePreviousSlide,
    slideRefs,
    containerRef,
    isDisabled,
    swipingProps,
    activeSlideContent,
    currentContentRef,
    title,
    subtitle,
    slides = [],
    background,
    size,
    spacing,
    anchorId,
    horizontalSpacing,
    verticalSpacing,
    hasSkew,
    hasDots,
    hasArrows,
    className,
}) => (
    <section
        id={anchorId || undefined}
        data-testid="FeaturedCarouselSection"
        className={clsx(
            variant(spacing, {
                small: 'py-12',
                medium: 'py-24',
                large: 'py-36',
            }),
            variant(background, {
                white: 'bg-white',
                'gray-50': 'bg-gray-50',
                'gray-100': 'bg-gray-100',
                black: 'bg-black',
                blue: 'bg-blue',
            }),
            className
        )}
    >
        {(title || subtitle) && (
            <div className="text-center mb-10">
                {title && (
                    <CustomText
                        as="h3"
                        className="text-black text-3xl md:text-5xl font-medium mb-4"
                        customText={title}
                        customTextClassName="font-accent text-4xl md:text-6xl"
                    />
                )}
                {subtitle && (
                    <h3 className="text-xl md:text-2xl">
                        {subtitle}
                    </h3>
                )}
            </div>
        )}
        <div className="overflow-hidden flex justify-center relative">
            <div
                className={clsx(
                    'relative w-full perspective-full',
                    variant(size, {
                        [EXTRA_SMALL]: 'max-w-xs pt-[15rem]',
                        [SMALL]: 'max-w-md pt-[20rem]',
                        [MEDIUM]: 'max-w-lg pt-[25rem]',
                        [DEFAULT]: 'max-w-xs pt-[15rem]',
                    }),
                )}
            >
                <motion.div
                    ref={containerRef}
                    /**
                     * We don't check if position is avaiable on animate.
                     * This triggers a warning in the console because we are animating to `null%`
                     * This is intended because if we don't do this the animation triggers
                     * on page load which gives janky results if the initial slide number is not 0
                     */
                    initial={{ opacity: 0 }}
                    animate={{ x: `${position}%`, opacity: 1 }}
                    transition={{ duration: 0.4, ease: 'easeOut' }}
                    className={clsx(
                        'whitespace-nowrap flex flex-nowrap absolute inset-0 touch-pan-y preserve-3d',
                        !isDisabled && 'cursor-move select-none',
                    )}
                    {...swipingProps}
                >
                    {slides.map((content, index) => (
                        <SbEditable
                            content={content}
                            key={content?.image?.filename || index}
                        >
                            <FeaturedCarouselSlide
                                index={index}
                                hasSkew={hasSkew}
                                slideRefs={slideRefs}
                                activeSlide={activeSlide}
                                verticalSpacing={verticalSpacing}
                                horizontalSpacing={horizontalSpacing}
                                onClick={() => handleSlideSelect(index)}
                                size={size}
                                {...content}
                            />
                        </SbEditable>
                    ))}
                </motion.div>
            </div>
            {hasArrows && (
                <AnimatePresence>
                    {!isDisabled && (
                        <div className="absolute inset-0 pointer-events-none">
                            <div className="container w-full h-full flex items-center">
                                {hasPreviousSlide && (
                                    <CarouselButton
                                        onClick={handlePreviousSlide}
                                        direction={PREVIOUS}
                                        className="pointer-events-auto"
                                    />
                                )}
                                {hasNextSlide && (
                                    <CarouselButton
                                        onClick={handleNextSlide}
                                        direction={NEXT}
                                        className="ml-auto pointer-events-auto"
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </AnimatePresence>
            )}
        </div>
        {hasDots && (
            <div className="flex mt-4 justify-center space-x-2">
                {slides.map(({ image }, index) => (
                    <button
                        key={image?.filename || index}
                        type="button"
                        onClick={() => handleSlideSelect(index)}
                        className="h-10 flex items-center"
                    >
                        <div
                            className={clsx(
                                'h-[2px] w-4 transition-colors',
                                activeSlide === index ? 'bg-blue-bright' : 'bg-gray-200'
                            )}
                        />
                    </button>
                ))}
            </div>
        )}
        <motion.div
            key={activeSlide}
            ref={currentContentRef}
            initial={{ Y: 100, height: currentContentRef?.current?.clientHeight || 'auto' }}
            animate={{ y: 0, height: 'auto' }}
            exit={{ y: 100, height: currentContentRef?.current?.clientHeight || 'auto' }}
            className="container-xs text-center overflow-hidden"
        >
            <AnimatePresence>
                <motion.div
                    key={activeSlide}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                >
                    {activeSlideContent?.tag && (
                        <p className="text-md font-medium uppercase text-gray-600 mt-2">
                            {activeSlideContent.tag}
                        </p>
                    )}
                    {activeSlideContent?.title && (
                        <CustomText
                            as="h3"
                            className="font-medium text-3xl mt-2"
                            customText={activeSlideContent.title}
                            customTextClassName="font-accent text-4xl"
                        />
                    )}
                    {activeSlideContent?.subtitle && (
                        <h3 className="font-medium leading-none mt-1">
                            {activeSlideContent.subtitle}
                        </h3>
                    )}
                    {activeSlideContent?.description && (
                        <p className="mt-3">
                            {activeSlideContent.description}
                        </p>
                    )}
                </motion.div>
            </AnimatePresence>
            {activeSlideContent?.buttons.length > 0 && (
                <div className="mt-6 flex gap-2 w-full items-center justify-center">
                    {activeSlideContent.buttons.map((button) => (
                        <SbButton
                            content={button}
                            key={button.text}
                        />
                    ))}
                </div>
            )}
        </motion.div>
    </section>
)

export default FeaturedCarouselSection
