import React, { useState } from 'react'
import clsx from 'clsx'
import DateButton from './DateButton'
import DateCalendarMobileModal from './DateCalendarMobileModal'

const JotformDateCalendarMobile = ({
    title,
    name,
    value,
    format,
    hasError,
    onChange,
    dayIsDisabledCondition,
    className,
}) => {
    const [isOpen, setIsOpen] = useState(false)
    return (
        <div
            className={clsx(
                'block lg:hidden',
                className
            )}
        >
            {isOpen && (
                <DateCalendarMobileModal
                    title={title}
                    initialDate={value}
                    dayIsDisabledCondition={dayIsDisabledCondition}
                    onClose={() => setIsOpen(false)}
                    onConfirm={(date) => {
                        onChange(date)
                        setIsOpen(false)
                    }}
                />
            )}
            <DateButton
                id={name}
                date={value}
                onClick={() => setIsOpen(true)}
                format={format}
                inputClassName="text-left"
                className={clsx([
                    'text-left rounded-l whitespace-nowrap bg-white min-h-[48px]',
                    hasError && 'border-primary',
                ])}
            />
        </div>
    )
}

export default JotformDateCalendarMobile
