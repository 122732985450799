import { splitCodes } from '../../util/index'
import useCheapestFlightPrice from './useCheapestFlightPrice'

const useCheapestDestinationsPrices = ({
    airline,
    destinations = [],
    departureCodes = [],
}) => {
    const hasAirline = !!airline && typeof airline.content !== 'undefined'
    const arrivalCodes = destinations
        .filter((destination) => !!destination.content)
        .flatMap((destination) => {
            const { iataCode, airportCodes } = destination.content
            if (airportCodes) {
                return splitCodes(airportCodes)
            }
            return [iataCode]
        })
    const variables = {
        arrivalCodes,
        departureCodes,
    }
    if (hasAirline) {
        variables.airlineCodes = [airline.content.airlineCode]
    }
    const { error, loading, cheapestFlightPrice } = useCheapestFlightPrice(variables)
    const cheapestDestinations = destinations
        .filter((destination) => !!destination.content)
        .map((destination) => {
            const flightPrice = cheapestFlightPrice.find(({ arrivalCode }) => (
                arrivalCode === destination.content.iataCode
                || (
                    destination.content.airportCodes
                    && destination.content.airportCodes.includes(arrivalCode)
                )
            ))
            return {
                ...destination,
                flightPrice,
            }
        })
    return {
        error,
        loading,
        cheapestDestinations
    }
}

export default useCheapestDestinationsPrices
