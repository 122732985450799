import { gql } from 'graphql-request'
import { DESTINATION_ENTITY } from '@connections/constants'
import { splitCodes } from '../../util/index'
import useGraphqlQuery from './useGraphqlQuery'

const QUERY = gql`
    query cheapestFlightPrices(
        $arrivalCodes: [String]
    ) {
        cheapestFlightPrices(
            arrivalCodes: $arrivalCodes
        ) {
            price
        }
    }
`

const useDestinationPrice = (destination = null) => {
    let skip = true
    const variables = {}
    if (destination !== null) {
        const { content } = destination
        skip = typeof content === 'undefined'
            || content.component !== DESTINATION_ENTITY
            || !content.iataCode
        if (!skip) {
            if (content.airportCodes) {
                variables.arrivalCodes = splitCodes(content.airportCodes)
            } else {
                variables.arrivalCodes = [content.iataCode]
            }
        }
    }
    const options = { enabled: !skip }
    const {
        data,
        error,
        isLoading: loading,
    } = useGraphqlQuery('destinationPrice', QUERY, variables, options)
    let price = null
    if (typeof data !== 'undefined') {
        const [pricing] = data.cheapestFlightPrices
        price = (typeof pricing !== 'undefined' && pricing.price) || null
    }
    return {
        price,
        error,
        loading,
    }
}

export default useDestinationPrice
