import React, { useState } from 'react'
import clsx from 'clsx'
import SbEditable from 'storyblok-react'
import * as constants from '@connections/constants'
import { variant } from '../../util/index'
import Icon from '../utils/Icon'
import Image from '../utils/Image'
import Quote from '../utils/Quote'
import Dialog from '../utils/Dialog'
import useDialog from '../hooks/useDialog'

const {
    CONTAINER_EXTRA_SMALL: EXTRA_SMALL,
    CONTAINER_SMALL: SMALL,
    CONTAINER_DEFAULT: DEFAULT,
} = constants

const Title = ({
    children,
    className,
}) => (
    <h3
        className={clsx(
            'text-primary font-accent text-2xl',
            className,
        )}
    >
        {children}
    </h3>
)

const Video = ({
    content,
    onClick,
}) => {
    const { placeholder } = content
    return (
        <button
            type="button"
            onClick={onClick}
            className="rounded-full flex items-center justify-center w-[100px] h-[100px] relative"
            style={{ backgroundImage: 'url("data:image/svg+xml,%3csvg width=\'100%25\' height=\'100%25\' xmlns=\'http://www.w3.org/2000/svg\'%3e%3crect width=\'100%25\' height=\'100%25\' fill=\'none\' rx=\'100\' ry=\'100\' stroke=\'%2392AEC7FF\' stroke-width=\'1\' stroke-dasharray=\'4.21\' stroke-dashoffset=\'0\' stroke-linecap=\'square\'/%3e%3c/svg%3e")' }}
        >
            <div className="block relative h-[85px] w-[85px] bg-gray-100 rounded-full">
                {placeholder && placeholder.filename && (
                    <Image
                        width={85}
                        height={85}
                        layout="fixed"
                        alt={placeholder.alt}
                        className="rounded-full"
                        src={placeholder.filename}
                    />
                )}
            </div>
            <Icon
                name="play"
                color="white"
                className="absolute left-1/2 top-1/2 z-[1] transform -translate-x-1/2 -translate-y-1/2"
            />
        </button>
    )
}

const QuoteSection = ({
    content,
}) => {
    const { isOpen, openDialog, closeDialog } = useDialog()
    const [, setActiveVideo] = useState(0)
    const {
        spacing,
        anchorId,
        container,
        background,
        quote: [quote],
        videos: [video1, video2, video3],
    } = content
    const hasVideos = typeof video1 !== 'undefined'

    const openVideo = (videoId) => {
        setActiveVideo(videoId)
        openDialog()
    }

    if (typeof quote === 'undefined') {
        return null
    }

    return (
        <SbEditable content={content}>
            <section
                id={anchorId || undefined}
                data-testid="QuoteSection"
                className={clsx(
                    variant(background, {
                        blue: 'bg-blue',
                        white: 'bg-white',
                        black: 'bg-black',
                        'gray-50': 'bg-gray-50',
                        'gray-100': 'bg-gray-100',
                    }),
                    variant(spacing, {
                        small: 'py-12',
                        medium: 'py-24',
                        large: 'py-36',
                    }),
                )}
            >
                <div
                    className={clsx(
                        'relative',
                        hasVideos && 'pt-32 pb-32 md:py-24',
                        variant(container, {
                            [EXTRA_SMALL]: 'container-xs',
                            [SMALL]: 'container-sm',
                            [DEFAULT]: 'container',
                        })
                    )}
                >
                    {typeof video1 !== 'undefined' && (
                        <SbEditable content={video1}>
                            <div
                                className={clsx(
                                    'md:top-0',
                                    'absolute -top-6 right-4 flex flex-col items-end',
                                )}
                            >
                                <Title className="ml-8 mb-3 transform rotate-2">
                                    {video1.title}
                                </Title>
                                <Video
                                    content={video1}
                                    onClick={() => openVideo(0)}
                                />
                            </div>
                        </SbEditable>
                    )}
                    {typeof video2 !== 'undefined' && (
                        <SbEditable content={video2}>
                            <div
                                className={clsx(
                                    'md:top-40 md:left-4',
                                    'absolute -top-8 left-8',
                                )}
                            >
                                <Video
                                    content={video2}
                                    onClick={() => openVideo(1)}
                                />
                                <Title className="mt-3 transform -rotate-2">
                                    {video2.title}
                                </Title>
                            </div>
                        </SbEditable>
                    )}
                    {typeof video3 !== 'undefined' && (
                        <SbEditable content={video3}>
                            <div
                                className={clsx(
                                    'md:bottom-0',
                                    'absolute -bottom-7 right-20',
                                )}
                            >
                                <Video
                                    content={video3}
                                    onClick={() => openVideo(2)}
                                />
                                <Title className="mt-3 -ml-2 transform -rotate-2">
                                    {video3.title}
                                </Title>
                            </div>
                        </SbEditable>
                    )}
                    <div className={clsx(
                        'text-center px-4',
                        hasVideos && 'md:px-36 lg:px-4'
                    )}
                    >
                        <Quote
                            size={quote.size}
                            quote={quote.text}
                            caption={quote.caption}
                            variant={quote.variant}
                            background={background}
                        />
                    </div>
                </div>
                <Dialog
                    isOpen={isOpen}
                    onClose={closeDialog}
                >
                    {/* @TODO: video carousel */}
                    <div />
                </Dialog>
            </section>
        </SbEditable>
    )
}

export default QuoteSection
