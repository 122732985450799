/* eslint-disable max-len */
import React from 'react'
import { Trans } from '@lingui/react'
import * as constants from '@connections/constants'
import Page from '../../layout/Page'
import PageMain from '../../layout/PageMain'
import StickyHeader from '../../layout/StickyHeader'
import HeaderWithContent from '../../layout/HeaderWithContent'
import FooterWithContent from '../../layout/FooterWithContent'
import DynamicSection from '../../content/DynamicSection'
import JotformSectionWithState from '../../content/JotformSectionWithState'
import Spinner from '../../utils/Spinner'
import useFormatFullSlug from '../../hooks/useFormatFullSlug'
import SearchPageRow from './SearchPageRow'
import SearchPageTitle from './SearchPageTitle'
import SearchPageResults from './SearchPageResults'
import SearchPageResultsItem from './SearchPageResultsItem'
import SearchPageResultsTitle from './SearchPageResultsTitle'

const {
    TOUR_CARD,
    SHOP_CARD,
    HOTEL_CARD,
    BREAK_CARD,
    EXPERT_CARD,
    ACTIVITY_CARD,
    BLOG_POST_CARD,
    DESTINATION_CARD,
} = constants

const SearchPage = ({
    tours,
    pages,
    query,
    shops,
    hotels,
    breaks,
    experts,
    blogPosts,
    body = [],
    activities,
    destinations,
    hasNoResults,
    isFetchingAll,
    contactJotformId,
    nothingWasSearched,
}) => {
    const formatFullSlug = useFormatFullSlug()

    return (
        <Page data-testid="SearchPage">
            <StickyHeader>
                <HeaderWithContent />
            </StickyHeader>
            <PageMain>
                {(() => {
                    if (isFetchingAll) {
                        return (
                            <SearchPageTitle
                                title={<Trans id="Searching..." />}
                            />
                        )
                    }
                    if (nothingWasSearched) {
                        return (
                            <SearchPageTitle
                                title={<Trans id="No search query" />}
                                description={<Trans id="Use the search button in the top right to search." />}
                            />
                        )
                    }
                    if (hasNoResults) {
                        return (
                            <SearchPageTitle
                                title={(
                                    <Trans
                                        id="Sorry we don’t have any results for <0>{query}</0>"
                                        values={{ query }}
                                        components={[
                                            <span className="text-blue-bright" />
                                        ]}
                                    />
                                )}
                                description={<Trans id="Please contact us and let us know how can we help." />}
                            />
                        )
                    }
                    return (
                        <SearchPageTitle
                            title={(
                                <Trans
                                    id="Results for <0>{query}</0>"
                                    components={[
                                        <span className="text-blue-bright" />
                                    ]}
                                />
                            )}
                        />
                    )
                })()}
                {(() => {
                    if (isFetchingAll) {
                        return (
                            <div className="flex justify-center items-center h-[400px]">
                                <Spinner color="gray" size="larger" />
                            </div>
                        )
                    }
                    if ((hasNoResults || nothingWasSearched) && contactJotformId) {
                        return (
                            <div className="container-xs lg:border lg:border-gray-100 md:p-12 lg:rounded-lg lg:shadow-lg mb-20">
                                <JotformSectionWithState
                                    jotformId={contactJotformId}
                                />
                            </div>
                        )
                    }
                    return (
                        <div className="mt-10 mb-20 space-y-16">
                            <SearchPageRow
                                title={<Trans id="Destinations" />}
                                state={destinations}
                                component={DESTINATION_CARD}
                                entity="destination"
                            />
                            <SearchPageRow
                                title={<Trans id="Hotels" />}
                                state={hotels}
                                component={HOTEL_CARD}
                                entity="hotel"
                            />
                            <SearchPageRow
                                title={<Trans id="Breaks" />}
                                state={breaks}
                                component={BREAK_CARD}
                                entity="break"
                            />
                            <SearchPageRow
                                title={<Trans id="Tours" />}
                                state={tours}
                                component={TOUR_CARD}
                                entity="tour"
                            />
                            <SearchPageRow
                                title={<Trans id="Activities" />}
                                state={activities}
                                component={ACTIVITY_CARD}
                                entity="activity"
                            />
                            <SearchPageRow
                                title={<Trans id="Blog Posts" />}
                                state={blogPosts}
                                component={BLOG_POST_CARD}
                                entity="blogPost"
                            />
                            <SearchPageRow
                                title={<Trans id="Shops" />}
                                state={shops}
                                component={SHOP_CARD}
                                entity="shop"
                            />
                            <SearchPageRow
                                title={<Trans id="Travel Designers" />}
                                state={experts}
                                component={EXPERT_CARD}
                                entity="expert"
                            />
                            <SearchPageResults query={pages}>
                                <SearchPageResultsTitle>
                                    <Trans id="Pages" />
                                </SearchPageResultsTitle>
                                <div className="space-y-4 mb-8">
                                    {pages.searchResults.map(({ content, full_slug }) => {
                                        const href = formatFullSlug(full_slug)
                                        return (
                                            <SearchPageResultsItem
                                                key={content._uid}
                                                href={href}
                                                title={content.seo.title || href}
                                                description={content.seo.description}
                                            />
                                        )
                                    })}
                                </div>
                            </SearchPageResults>
                        </div>
                    )
                })()}
                {body && body.map((content) => (
                    <DynamicSection
                        key={content._uid}
                        content={content}
                    />
                ))}
            </PageMain>
            <FooterWithContent />
        </Page>
    )
}

export default SearchPage
