import React from 'react'
import GallerySection from './GallerySection'
import useAutomaticScroller from '../hooks/useAutomaticScroller'

const GallerySectionWithState = ({
    content,
}) => {
    const { direction, galleryImages, } = content
    const {
        pageCount,
        containerRef,
        galleryRef,
        x,
        onPanEnd,
    } = useAutomaticScroller({
        direction,
        slides: galleryImages,
    })

    return (
        <GallerySection
            key={content.galleryImages.length}
            content={content}
            containerRef={containerRef}
            galleryRef={galleryRef}
            x={x}
            onPanEnd={onPanEnd}
            pageCount={pageCount}
        />
    )
}

export default GallerySectionWithState
