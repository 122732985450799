import React from 'react'
import clsx from 'clsx'
import useCarousel from '@dashdot/react-hooks-slider'
import {
    sectionSpacingVariant,
    sectionContainerVariant,
    sectionBackgroundVariant,
    sectionTextOnBackgroundVariant,
} from '../../util/variants'
import useTrustpilotQuery from '../hooks/useTrustpilotQuery'
import ReviewsSection from './ReviewsSection'

const ReviewsSectionWithState = ({
    content,
}: {
    content: any
}) => {
    const {
        spacing,
        container,
        background,
        bridgeAttributes,
    } = content
    const {
        position,
        slideRefs,
        isSwiping,
        isDisabled,
        activeSlide,
        containerRef,
        swipingProps,
        hasNextSlide,
        handleNextSlide,
        hasPreviousSlide,
        handlePreviousSlide,
    } = useCarousel()
    const { isLoading, error, data } = useTrustpilotQuery()
    const currentSlideHeight = slideRefs.current[activeSlide]?.children[0].clientHeight

    if (error) {
        return null
    }

    return (
        <section
            className={clsx(
                sectionSpacingVariant({ spacing }),
                sectionBackgroundVariant(background),
                sectionTextOnBackgroundVariant(background, 'text-black'),
            )}
            {...bridgeAttributes}
        >
            <div className={sectionContainerVariant(container)}>
                <ReviewsSection
                    data={data}
                    content={content}
                    position={position}
                    isLoading={isLoading}
                    slideRefs={slideRefs}
                    isSwiping={isSwiping}
                    isDisabled={isDisabled}
                    containerRef={containerRef}
                    hasNextSlide={hasNextSlide}
                    swipingProps={swipingProps}
                    handleNextSlide={handleNextSlide}
                    hasPreviousSlide={hasPreviousSlide}
                    currentSlideHeight={currentSlideHeight}
                    handlePreviousSlide={handlePreviousSlide}
                />
            </div>
        </section>
    )
}

export default ReviewsSectionWithState
