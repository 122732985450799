import React from 'react'
import { Trans } from '@lingui/react'
import Spinner from './Spinner'
import ScrollSentinel from './ScrollSentinel'
import Button, { VARIANT_BLUE_OUTLINED } from './Button'

const InfiniteScroll = ({
    isFetchingMore,
    onLoadMore,
    onFetchMore,
    hasMore,
    loadMoreOnPageEnd = true,
    children,
}) => (
    <div className="relative">
        {children}
        {(loadMoreOnPageEnd && isFetchingMore) && (
            <div className="flex justify-center items-center h-10">
                <Spinner color="gray" />
            </div>
        )}
        {(loadMoreOnPageEnd && !isFetchingMore && hasMore) && (
            <ScrollSentinel
                onIntersect={onFetchMore}
            />
        )}
        {!loadMoreOnPageEnd && hasMore && (
            <div className="flex justify-center items-center">
                <Button
                    onClick={onLoadMore}
                    variant={VARIANT_BLUE_OUTLINED}
                >
                    <Trans id="Load more" />
                </Button>
            </div>
        )}
    </div>
)
export default InfiniteScroll
