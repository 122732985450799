import React from 'react'
import dynamic from 'next/dynamic'
import Page from '../../layout/Page'
import StickyHeader from '../../layout/StickyHeader'
import HeaderWithContent from '../../layout/HeaderWithContent'
import FooterWithContent from '../../layout/FooterWithContent'
import QuickSearchSectionWithState from '../../content/QuickSearchSectionWithState'

const FlightSearchPageClientOnly = dynamic(() => import('./FlightSearchPageDynamic'), { ssr: false })

const FlightSearchPage = ({
    from,
    error,
    alerts,
    sortBy,
    content,
    filters,
    mainRef,
    onOpenQsm,
    onCloseQsm,
    resultsRef,
    isFetching,
    sortOptions,
    sortedFares,
    selectedFare,
    onChooseFare,
    reviewScores,
    filterParams,
    shortestFare,
    cheapestFares,
    onChangeSortBy,
    onResetFilters,
    hasSearchQuery,
    qsmIsCollapsed,
    canResetFilters,
    setFilterParams,
    recommendedFare,
    recommendedRule,
    onRemoveSelection,
    onCreateBookingFlight,
}) => (
    <Page className="bg-gray-50">
        <StickyHeader>
            <HeaderWithContent />
        </StickyHeader>
        <QuickSearchSectionWithState
            isStandalone
            openTab="flight"
            spacing="smaller"
            tabs={['flight']}
            onOpen={onOpenQsm}
            container="default"
            background="gray-50"
            onClose={onCloseQsm}
            isPageSearching={isFetching}
            isCollapsed={qsmIsCollapsed}
            anchorId="flight-search-page-qsm"
        />
        <FlightSearchPageClientOnly
            from={from}
            error={error}
            sortBy={sortBy}
            alerts={alerts}
            filters={filters}
            content={content}
            mainRef={mainRef}
            resultsRef={resultsRef}
            isFetching={isFetching}
            sortOptions={sortOptions}
            sortedFares={sortedFares}
            filterParams={filterParams}
            reviewScores={reviewScores}
            onChooseFare={onChooseFare}
            selectedFare={selectedFare}
            shortestFare={shortestFare}
            cheapestFares={cheapestFares}
            qsmIsCollapsed={qsmIsCollapsed}
            hasSearchQuery={hasSearchQuery}
            onChangeSortBy={onChangeSortBy}
            onResetFilters={onResetFilters}
            recommendedRule={recommendedRule}
            setFilterParams={setFilterParams}
            canResetFilters={canResetFilters}
            recommendedFare={recommendedFare}
            onRemoveSelection={onRemoveSelection}
            onCreateBookingFlight={onCreateBookingFlight}
        />
        <FooterWithContent />
    </Page>
)

export default FlightSearchPage
