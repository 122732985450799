import React from 'react'
import SbEditable from 'storyblok-react'
import { motion, AnimatePresence } from 'framer-motion'
import clsx from 'clsx'
import Icon from '../utils/Icon'
import DayInfo from './DayInfo'
import RichTextField from './RichTextField'

const DayItem = ({
    content,
    isOpen,
    onToggle,
}) => {
    const {
        title,
        name,
        description,
    } = content
    return (
        <SbEditable content={content}>
            <div className={clsx(
                'w-full py-5 border-b border-gray-100 transition-colors',
                isOpen && 'bg-gray-50',
            )}
            >
                <header
                    className="flex justify-between items-center focus:outline-none px-4"
                    onClick={onToggle}
                    onKeyPress={onToggle}
                    role="button"
                    tabIndex="0"
                >
                    <div>
                        <h4 className="uppercase font-medium text-xs text-gray-600 mb-1">
                            {title}
                        </h4>
                        <h3 className={clsx(
                            'font-medium text-lg transition-colors',
                            isOpen && 'text-blue',
                        )}
                        >
                            {name}
                        </h3>
                    </div>
                    <motion.div
                        onClick={onToggle}
                        type="button"
                        className="text-blue appearance-none"
                        animate={isOpen ? 'isOpen' : 'isClosed'}
                        variants={{
                            isOpen: { rotate: 180 },
                            isClosed: { rotate: 0 },
                        }}
                    >
                        <Icon name="chevron-down" />
                    </motion.div>
                </header>

                <AnimatePresence>
                    {isOpen && (
                        <motion.div
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: 'auto' }}
                            exit={{ opacity: 0, height: 0 }}
                        >
                            {description && (
                                <RichTextField
                                    content={description}
                                    className="pt-4 px-4 empty:hidden"
                                />
                            )}
                            <div className="md:hidden mt-8">
                                <DayInfo content={content} />
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </SbEditable>
    )
}

export default DayItem
