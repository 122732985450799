import React from 'react'
import SbEditable from 'storyblok-react'
import Icon from '../utils/Icon'

const IconFeaturesCard = ({
    content
}) => {
    const {
        icon,
        title,
        description,
    } = content
    return (
        <SbEditable content={content}>
            <div className="bg-white shadow-card flex flex-col items-center pl-5 pr-3 py-6 md:flex-row md:py-4 rounded-md md:items-start">
                <div className="w-10 h-10 bg-blue-bright rounded-full flex justify-center items-center md:mr-5">
                    <Icon
                        name={icon}
                        color="white"
                        size="small"
                    />
                </div>
                <div className="text-center flex flex-col flex-1 mt-2 md:text-left md:mt-0">
                    <p className="text-xs tracking-wider font-medium text-blue-bright uppercase h-2/5 flex justify-center items-center md:justify-start md:font-bold mb-1">
                        {title}
                    </p>
                    <p className="min-h-3/5 pt-2 text-xs md:text-sm md:p-0">
                        {description}
                    </p>
                </div>
            </div>
        </SbEditable>
    )
}

export default IconFeaturesCard
