import React from 'react'
import { Trans } from '@lingui/react'
import { SHOP_CARD } from '@connections/constants'
import Page from '../../layout/Page'
import PageMain from '../../layout/PageMain'
import StickyHeader from '../../layout/StickyHeader'
import HeaderWithContent from '../../layout/HeaderWithContent'
import FooterWithContent from '../../layout/FooterWithContent'
import Spinner from '../../utils/Spinner'
import InfiniteScroll from '../../utils/InfiniteScroll'
import DynamicSection from '../../content/DynamicSection'
import CardsSectionWithState from '../../content/CardsSectionWithState'
import QuickSearchSectionWithState from '../../content/QuickSearchSectionWithState'

const mapNodeToCard = (entity) => ({
    ...entity.content,
    _uid: entity.uuid,
    shop: entity,
    component: SHOP_CARD,
})

const ShopSearchPage = ({
    content,
    hasMore,
    isFetching,
    onLoadMore,
    onFetchMore,
    searchResults,
    isFetchingMore,
    loadMoreOnPageEnd,
}) => (
    <Page data-testid="ShopSearchPage">
        <StickyHeader>
            <HeaderWithContent />
        </StickyHeader>
        <PageMain className="min-h-screen">
            <QuickSearchSectionWithState
                isStandalone
                openTab="shop"
                tabs={['shop']}
                spacing="medium"
                background="gray-100"
                anchorId="shop-search-page-qsm"
            />
            <section className="mb-12">
                {(() => {
                    if (isFetching) {
                        return (
                            <div className="container min-h-[500px] flex justify-center items-center">
                                <Spinner color="gray" />
                            </div>
                        )
                    }
                    if (searchResults.length === 0) {
                        return (
                            <div className="container min-h-[500px] flex justify-center items-center">
                                <p className="mb-16">
                                    <Trans id="No shops found for these search parameters." />
                                </p>
                            </div>
                        )
                    }
                    // TODO rework this without cards section
                    return (
                        <InfiniteScroll
                            hasMore={hasMore}
                            onLoadMore={onLoadMore}
                            onFetchMore={onFetchMore}
                            isFetchingMore={isFetchingMore}
                            loadMoreOnPageEnd={loadMoreOnPageEnd}
                        >
                            <CardsSectionWithState
                                className="py-12"
                                content={{
                                    columns: 4,
                                    cards: searchResults.map(mapNodeToCard),
                                }}
                            />
                        </InfiniteScroll>
                    )
                })()}
            </section>
            {content.body && content.body.map((section) => (
                <DynamicSection
                    content={section}
                    key={section._uid}
                />
            ))}
        </PageMain>
        <FooterWithContent />
    </Page>
)

export default ShopSearchPage
