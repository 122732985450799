import { useEffect, useState } from 'react'
import addStoryblokBridgeScript from '../../storyblok/addStoryblokBridgeScript'
import getStoryblokClient from '../../storyblok/getStoryblokClient'
import usePageProps from './usePageProps'
import transformData from '../../storyblok/transformers'
import usePathnameLocale from './usePathnameLocale'

const DEFAULT_STORY = {
    content: {
        _uid: '',
        _editable: undefined,
        component: 'Unkown',
    }
}

export default function useStoryblokBridge(initialStory = DEFAULT_STORY, resolveRelations = '') {
    const locale = usePathnameLocale()
    const [story, setStory] = useState(initialStory)
    const { isPreview } = usePageProps()
    const initEventListeners = () => {
        const { StoryblokBridge, location } = window
        if (typeof StoryblokBridge !== 'undefined') {
            const storyblokBridge = new StoryblokBridge({
                resolveRelations
            })
            storyblokBridge.on(['change', 'published'], () => (
                location.reload(true)
            ))
            storyblokBridge.on('input', (event) => {
                if (event.story._uid === story._uid) {
                    const transformedData = transformData(event, {
                        language: locale
                    })
                    setStory(transformedData.story)
                }
            })
            const storyblokClient = getStoryblokClient(isPreview)
            storyblokBridge.on('enterEditmode', async (event) => {
                try {
                    const response = await storyblokClient
                        .get(`cdn/stories/${event.storyId}`, {
                            version: 'draft',
                            language: locale,
                            resolve_links: 'url',
                            resolve_relations: resolveRelations,
                        })
                    if (response.data.story && response.data.story.content) {
                        const transformedData = transformData(response.data, {
                            language: locale,
                            isPreview: true,
                        })
                        setStory(transformedData.story)
                    }
                } catch (e) {
                    // do nothing
                }
            })
        }
    }

    useEffect(() => {
        if (isPreview) {
            addStoryblokBridgeScript(initEventListeners)
        }
    }, [isPreview])

    useEffect(() => {
        setStory(initialStory)
    }, [initialStory])

    return story
}
