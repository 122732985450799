import React, { useState } from 'react'
import SbEditable from 'storyblok-react'
import useStoryblokBridge from '../../hooks/useStoryblokBridge'
import QueryParamProvider from '../../providers/QueryParamProvider'
import { useExpertsSearchQueryParams } from '../../content/useQuickSearchExpertsFormState'
import ExpertSearchPage from './ExpertSearchPage'
import useExpertSearchPageQuery from './useExpertSearchPageQuery'

const ExpertSearchPageWithState = ({
    data,
    resolveRelations,
}) => {
    const [query] = useExpertsSearchQueryParams()
    const { content } = useStoryblokBridge(data.pageStory, resolveRelations)
    const [loadMoreOnPageEnd, setLoadMoreOnPageEnd] = useState(false)
    const {
        hasMore,
        isFetching,
        isFetchingMore,
        searchResults,
        handleFetchMore,
    } = useExpertSearchPageQuery(query)

    return (
        <SbEditable content={content}>
            <ExpertSearchPage
                content={content}
                hasMore={hasMore}
                isFetching={isFetching}
                searchResults={searchResults}
                onFetchMore={handleFetchMore}
                isFetchingMore={isFetchingMore}
                loadMoreOnPageEnd={loadMoreOnPageEnd}
                onLoadMore={() => setLoadMoreOnPageEnd(true)}
            />
        </SbEditable>
    )
}

export default (props) => (
    <QueryParamProvider>
        <ExpertSearchPageWithState {...props} />
    </QueryParamProvider>
)
