import React from 'react'

const SearchPageTitle = ({
    title,
    description
}) => (
    <div className="container my-10 lg:my-14">
        <h1 className="text-2xl font-medium">
            {title}
        </h1>
        {description && (
            <p className="mt-1">
                {description}
            </p>
        )}
    </div>
)

export default SearchPageTitle
