import { gql } from 'graphql-request'
import useGraphqlMutation from '../../hooks/useGraphqlMutation'

const MUTATION = gql`
    mutation createBookingFlight ($input: CreateBookingFlightInput!) {
        createBookingFlight(input: $input) {
            id
            uid
            data
            items {
                id
                type
                data
            }
        }
    }
`

const useCreateBookingFlightMutation = (options) => useGraphqlMutation(MUTATION, options)

export default useCreateBookingFlightMutation
