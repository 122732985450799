import React, { useState } from 'react'
import clsx from 'clsx'
import DateButton from './DateButton'
import DateCalendar from './DateCalendar'

const JotformDateCalendarDesktop = ({
    name,
    format,
    value,
    hasError,
    onChange,
    dayIsDisabledCondition,
    className,
}) => {
    const [isOpen, setIsOpen] = useState(false)
    return (
        <div
            className={clsx(
                'hidden lg:block',
                className
            )}
        >
            <DateButton
                id={name}
                date={value}
                onClick={() => setIsOpen(true)}
                format={format}
                className={clsx([
                    'text-left rounded-l whitespace-nowrap bg-white min-h-[48px]',
                    hasError && 'border-primary',
                ])}
            />
            {isOpen && (
                <DateCalendar
                    date={value}
                    format={format}
                    className="hidden lg:block absolute z-10 top-[26px] px-2 pt-2 rounded-md w-[400px] shadow-2xl"
                    inputClassName="text-left w-[170px]"
                    dayIsDisabledCondition={dayIsDisabledCondition}
                    onClickOutside={() => setIsOpen(false)}
                    onPressEscape={() => setIsOpen(false)}
                    onChange={onChange}
                />
            )}
        </div>
    )
}

export default JotformDateCalendarDesktop
