import { gql } from 'graphql-request'
import useGraphqlMutation from '../hooks/useGraphqlMutation'

const MUTATION = gql`
    mutation closeJourney($uid: ID!) {
        closeJourney(uid: $uid)
    }
`

const useCloseJourneyMutation = (options) => useGraphqlMutation(MUTATION, options)

export default useCloseJourneyMutation
