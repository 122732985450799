import React from 'react'
import Image from '../utils/Image'
import DayItemTabGroupWithState from './DayItemTabGroupWithState'

const DayInfo = ({
    content,
}) => {
    const { image, dayItemTabGroup } = content
    return (
        <>
            {image?.src && (
                <div className="px-4">
                    <div className="w-full pt-[53%] relative bg-gray-100">
                        <Image
                            {...image}
                            fill
                            maxWidth={750}
                            className="object-cover"
                        />
                    </div>
                </div>
            )}
            <DayItemTabGroupWithState
                content={dayItemTabGroup}
            />
        </>
    )
}

export default DayInfo
