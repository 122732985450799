import React from 'react'
import clsx from 'clsx'
import { sectionContainerVariant } from '../../util/variants'
import CardSectionCard from './CardSectionCard'

const CardSectionCards = ({
    container,
    cards,
    columnCount,
}) => (
    <div
        className={clsx(
            sectionContainerVariant(container),
        )}
    >
        <div
            className={clsx(
                'grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-6',
                columnCount === 3 && 'lg:grid-cols-3',
                columnCount === 4 && 'lg:grid-cols-4'
            )}
        >
            {cards.map((card) => {
                const columnWidth = parseInt(card.columnWidth, 10) || 1
                return (
                    <CardSectionCard
                        key={card._uid}
                        content={card}
                        component={card.component}
                        columnWidth={columnWidth}
                        className={clsx(
                            'bg-transparent col-span-1',
                            columnWidth === 1 && 'md:col-span-1',
                            columnWidth === 2 && 'md:col-span-2',
                            columnWidth === 3 && 'md:col-span-3',
                        )}
                    />
                )
            })}
        </div>
    </div>
)

export default CardSectionCards
