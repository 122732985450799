import React from 'react'
import clsx from 'clsx'
import SbEditable from 'storyblok-react'
import * as constants from '@connections/constants'
import { variant } from '../../util'
import ContentVideo from './ContentVideo'

const {
    CONTAINER_SMALL: SMALL,
    CONTAINER_DEFAULT: DEFAULT,
    CONTAINER_EXTRA_SMALL: EXTRA_SMALL,
} = constants

const VideoSection = ({
    content,
}) => {
    const {
        size,
        video,
        spacing,
        anchorId,
        container,
        autoPlay,
        muted,
        showControls,
        loop,
    } = content
    return (
        <SbEditable content={content}>
            <section
                id={anchorId || undefined}
                className={clsx(
                    variant(spacing, {
                        small: 'py-12',
                        medium: 'py-24',
                        large: 'py-36',
                    }),
                    variant(container, {
                        [EXTRA_SMALL]: 'container-xs',
                        [SMALL]: 'container-sm',
                        [DEFAULT]: 'container',
                    })
                )}
            >
                <ContentVideo
                    size={size}
                    video={video}
                    autoPlay={autoPlay}
                    muted={muted}
                    controls={showControls}
                    loop={loop}
                />
            </section>
        </SbEditable>
    )
}

export default VideoSection
