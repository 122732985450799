import React from 'react'
import { Trans } from '@lingui/react'
import Date from '../utils/Date'
import Icon from '../utils/Icon'
import Price from '../utils/Price'
import Tag, { SIZE_LARGE, VARIANT_BLUE_BORDERLESS } from '../utils/Tag'

const DestinationFlightsListerCardMobile = ({
    price,
    airline,
    hasPrice,
    onSelect,
    returnDate,
    arrivalCity,
    arrivalCode,
    isRoundTrip,
    departureDate,
    departureCity,
    departureCode,
    arrivalAirport,
    departureAirport,
}) => (
    <button
        type="button"
        onClick={onSelect}
        className="lg:hidden bg-white rounded-lg shadow-sm text-left"
    >
        <div className="flex justify-between items-center p-4">
            <div>
                <div className="text-lg text-blue font-bold truncate">
                    {departureCity}
                </div>
                <div className="text-sm text-gray-600 truncate mt-1">
                    {departureAirport} <span className="uppercase">[{departureCode}]</span>
                </div>
            </div>
            <Icon
                size="small"
                className="text-blue"
                name={isRoundTrip ? 'change-directions' : 'arrow-from-right'}
            />
            <div>
                <div className="text-lg text-blue font-bold truncate">
                    {arrivalCity}
                </div>
                <div className="text-sm text-gray-600 truncate mt-1">
                    {arrivalAirport} <span className="uppercase">[{arrivalCode}]</span>
                </div>
            </div>
        </div>
        <div className="flex justify-between border-t border-gray-100 p-4">
            <div className="relative w-8 h-8">
                <img
                    alt={airline.name}
                    src={airline.imageSquareUrl}
                    className="absolute inset-0 object-cover h-full w-full"
                />
            </div>
            <div className="flex items-center gap-x-4">
                <div className="text-sm text-gray-600 truncate">
                    {(() => {
                        if (hasPrice) {
                            if (isRoundTrip) {
                                return (
                                    <>
                                        <Date dateString={departureDate} format="d/M" />
                                        {' - '}
                                        <Date dateString={returnDate} format="d/M" />
                                    </>
                                )
                            }
                            return <Date dateString={departureDate} format="d/M" />
                        }
                        return <Trans id="No date found" />
                    })()}
                </div>
                <Tag
                    size={SIZE_LARGE}
                    className="!lowercase !text-base"
                    variant={VARIANT_BLUE_BORDERLESS}
                >
                    {hasPrice ? (
                        <Price price={price} />
                    ) : (
                        <Trans id="No pricing found" />
                    )}
                </Tag>
            </div>
        </div>
    </button>
)

export default DestinationFlightsListerCardMobile
