import React from 'react'
import SbEditable from 'storyblok-react'
import clsx from 'clsx'
import * as constants from '@connections/constants'
import { variant } from '../../util/index'
import ListItem from './ListItem'

const {
    CONTAINER_EXTRA_SMALL: EXTRA_SMALL,
    CONTAINER_SMALL: SMALL,
    CONTAINER_DEFAULT: DEFAULT,
} = constants

const ListSection = ({
    content,
}) => {
    const {
        spacing,
        background,
        container,
        anchorId,
        list = [],
        title,
    } = content
    return (
        <SbEditable content={content}>
            <section
                id={anchorId || undefined}
                data-testid="ListSection"
                className={clsx(
                    variant(spacing, {
                        small: 'py-12',
                        medium: 'py-24',
                        large: 'py-36',
                    }),
                    variant(background, {
                        white: 'bg-white',
                        'gray-50': 'bg-gray-50',
                        'gray-100': 'bg-gray-100',
                        black: 'bg-black',
                        blue: 'bg-blue',
                    }),
                )}
            >
                <div className={variant(container, {
                    [EXTRA_SMALL]: 'container-xs',
                    [SMALL]: 'container-sm',
                    [DEFAULT]: 'container',
                })}
                >
                    <h3 className="text-xl font-medium mb-10 md:text-3xl md:text-center">
                        {title}
                    </h3>
                    <div className="rounded-lg shadow-card px-8 py-6 bg-white">
                        {list.map((listItem) => (
                            <ListItem
                                key={listItem._uid}
                                content={listItem}
                            />
                        ))}
                    </div>
                </div>
            </section>
        </SbEditable>
    )
}

export default ListSection
