import React from 'react'
import FormGroup from './FormGroup'
import CheckboxInput from './CheckboxInput'
import FormInputErrors from './FormInputErrors'

const JotformCheckboxInput = ({
    item,
    className,
    formState,
    isSubmitting,
}) => {
    const {
        getValue,
        handleChange,
        getErrorMessages,
    } = formState
    const itemValue = getValue(item.name)

    return (
        <div className={className}>
            <FormGroup
                label={item.text}
                htmlFor={item.qid}
                isRequired={item.required}
                description={item.subLabel}
            >
                <div className="flex flex-col gap-2">
                    {item.options.map((option) => {
                        const isChecked = itemValue.includes(option)

                        return (
                            <CheckboxInput
                                key={option}
                                value={isChecked}
                                disabled={isSubmitting}
                                className="text-sm mr-8"
                                id={`${option}-checkbox`}
                                onChange={() => {
                                    let values = itemValue.split('|')
                                    if (isChecked) {
                                        values = values.filter((value) => value !== option)
                                    } else {
                                        values.push(option)
                                    }
                                    handleChange(item.name, values.join('|'))
                                }}
                            >
                                {option}
                            </CheckboxInput>
                        )
                    })}
                </div>
                <FormInputErrors errors={getErrorMessages(item.name)} />
            </FormGroup>
        </div>
    )
}

export default JotformCheckboxInput
