import React from 'react'
import clsx from 'clsx'
import { format } from 'date-fns'
import { Trans } from '@lingui/react'
import Icon from './Icon'
import FlightOverviewHeading from './FlightOverviewHeading'
import FlightOverviewTimingMobile from './FlightOverviewTimingMobile'

const BookModalFlight = ({
    stops,
    origin,
    carrier,
    className,
    originCity,
    destination,
    arrivalTime,
    isLastEntry,
    airRouteType,
    departureTime,
    destinationCity,
    baggageAllowed,
    landsDifferentDay,
}) => (
    <div className={className}>
        <FlightOverviewHeading
            airRouteType={airRouteType}
            departureTime={departureTime}
        />
        <div className="flex">
            <FlightOverviewTimingMobile
                stops={stops}
                arrivalTime={arrivalTime}
                departureTime={departureTime}
            />
            <div className="flex-1">
                <p className="text-sm text-gray-800 mb-3">
                    {originCity.name}
                    {' '}
                    [{origin}]
                </p>
                <div className="flex justify-between mb-3">
                    <div className="flex items-center ">
                        <div className="relative w-5 h-5 mr-4 bg-gray-100">
                            {carrier?.imageSquareUrl && (
                                <img
                                    src={carrier.imageSquareUrl}
                                    alt={carrier.name}
                                    className="absolute inset-0 object-cover h-full w-full"
                                />
                            )}
                        </div>
                        <p className="text-xs text-gray-600 font-medium">
                            {carrier.name}
                        </p>
                    </div>
                    {baggageAllowed !== null ? (
                        <div className="flex items-center text-xs text-gray-600">
                            <Icon
                                name={baggageAllowed ? 'bag' : 'bag-slash'}
                                size="smaller"
                                className="mr-1"
                            />
                            <p className="text-xs">
                                {baggageAllowed
                                    ? <Trans id="Luggage included" />
                                    : <Trans id="Luggage excluded" />}
                            </p>
                        </div>
                    ) : null}
                </div>
                <div className="flex items-center">
                    <p className="text-sm text-gray-800">
                        {destinationCity.name}
                        {' '}
                        [{destination}]
                    </p>
                    {landsDifferentDay && (
                        <p className="text-xs text-primary font-medium text-right uppercase ml-2">
                            {format(new Date(arrivalTime), 'd MMM')}
                        </p>
                    )}
                </div>
            </div>
        </div>
        <div className={clsx(
            'border-t border-dashed w-4/5 mx-auto my-6 lg:my-12',
            isLastEntry && 'md:hidden'
        )}
        />
    </div>
)

export default BookModalFlight
