import React from 'react'
import clsx from 'clsx'
import { Trans } from '@lingui/react'
import { CONTAINER_NONE } from '@connections/constants'
import JotformSectionWithProvider from '../content/JotformSectionWithProvider'

const BookModalError = ({
    name,
    className,
    fallbackFormId,
}) => (
    <div className={clsx('items-center justify-center h-full w-full', className)}>
        {fallbackFormId ? (
            <JotformSectionWithProvider
                formTitle={name}
                showFormTitle={false}
                container={CONTAINER_NONE}
                jotformId={fallbackFormId}
            />
        ) : (
            <>
                <p className="text-gray-400 font-medium mb-1 text-center">
                    <Trans id="Something went wrong!" />
                </p>
                <p className="text-sm text-gray-400 text-center">
                    <Trans id="Please try again later." />
                </p>
            </>
        )}
    </div>
)

export default BookModalError
