import React, { useState } from 'react'
import SbEditable from 'storyblok-react'
import { whereUuid } from '@connections/utils'
import ProgramSection from './ProgramSection'

const ProgramSectionWithState = ({
    content,
}) => {
    const [selectedSlot, setSelectedSlot] = useState(null)
    const [selectedGroupUuid, setSelectedGroupUuid] = useState(content.groups[0]?.uuid || null)

    const handleHideSlot = () => {
        setSelectedSlot(null)
    }

    const selectedGroup = content.groups.find((whereUuid(selectedGroupUuid)))
    return (
        <SbEditable content={content}>
            <ProgramSection
                {...content}
                selectedSlot={selectedSlot}
                onHideSlot={handleHideSlot}
                onShowSlot={setSelectedSlot}
                selectedGroup={selectedGroup}
                onGroupChange={setSelectedGroupUuid}
                selectedGroupUuid={selectedGroupUuid}
            />
        </SbEditable>
    )
}

export default ProgramSectionWithState
