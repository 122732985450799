import React from 'react'
import clsx from 'clsx'
import { Trans } from '@lingui/react'
import { BREAK_ENTITY, TOUR_ENTITY } from '@connections/constants'
import Icon from '../../utils/Icon'
import Modal from '../../utils/Modal'
import BookModalError from '../../utils/BookModalError'
import BookModalBanner from '../../utils/BookModalBanner'
import ModalCloseButton from '../../utils/ModalCloseButton'
import Button, { VARIANT_PRIMARY_FILLED } from '../../utils/Button'
import BookModalInputs from '../book-template/BookTemplateModalInputs'
import BookTemplateModalLoading from './BookTemplateModalLoading'
import BookDynamicTemplateModalOverview from './BookDynamicTemplateModalOverview'

const BookDynamicTemplateModal = ({
    name,
    type,
    price,
    onClose,
    endDate,
    startDate,
    templateId,
    hasResults,
    maxChildAge,
    onChangeFare,
    listOfAdults,
    selectedFare,
    selectedHotel,
    numberOfRooms,
    modalHasError,
    onChangeDates,
    listOfChildren,
    hasValidInputs,
    availableDates,
    templatePricing,
    absoluteEndDate,
    templateSettings,
    onChangeActivity,
    onChangeHotelRoom,
    hasChosenTravelers,
    onCloseGuestSelect,
    selectedActivities,
    isFetchingTemplate,
    selectedHotelRoomId,
    lastPossibleReturnDate,
    onChangeActivityOptions,
    onCreateBookingTemplate,
    isFreeDateChoiceTemplate,
    isFetchingTemplatePricing,
    isCreatingBookingTemplate,
    firstPossibleDepartureDate,
}) => (
    <Modal
        onClose={onClose}
        data-testid="BookDynamicTemplateModal"
        className="w-full lg:w-[1000px] min-h-screen lg:min-h-[585px] px-4 py-10 lg:px-10 flex flex-col"
    >
        <ModalCloseButton onClick={onClose} />
        <BookModalBanner
            name={name}
            price={price}
            className="-mt-5"
            endDate={endDate}
            startDate={startDate}
            listOfAdults={listOfAdults}
            numberOfRooms={numberOfRooms}
            listOfChildren={listOfChildren}
            absoluteEndDate={absoluteEndDate}
        />
        {isFetchingTemplate ? (
            <BookTemplateModalLoading className="my-auto" />
        ) : (
            <>
                {!modalHasError && (
                    <BookModalInputs
                        endDate={endDate}
                        startDate={startDate}
                        maxChildAge={maxChildAge}
                        listOfAdults={listOfAdults}
                        numberOfRooms={numberOfRooms}
                        onChangeDates={onChangeDates}
                        currentProductId={templateId}
                        hasValidInputs={hasValidInputs}
                        listOfChildren={listOfChildren}
                        availableDates={availableDates}
                        absoluteEndDate={absoluteEndDate}
                        onCloseGuestSelect={onCloseGuestSelect}
                        hasChosenTravelers={hasChosenTravelers}
                        lastPossibleReturnDate={lastPossibleReturnDate}
                        isFreeDateChoiceTemplate={isFreeDateChoiceTemplate}
                        firstPossibleDepartureDate={firstPossibleDepartureDate}
                    />
                )}
                {(() => {
                    if (isFetchingTemplatePricing) {
                        return (
                            <BookTemplateModalLoading
                                className="my-auto"
                                templateSettings={templateSettings}
                                data-testid="BookDynamicTemplateModalLoading"
                            />
                        )
                    }
                    if (modalHasError) {
                        return (
                            <BookModalError
                                name={name}
                                className="my-auto"
                                data-testid="BookDynamicTemplateModalError"
                                fallbackFormId={templateSettings.fallbackFormId}
                            />
                        )
                    }
                    if (!hasValidInputs) {
                        return (
                            <div className="flex flex-col flex-1 items-center justify-center">
                                <Icon
                                    color="gray"
                                    size="largest"
                                    name="calendar"
                                />
                                <p className="text-gray-400 font-medium mt-4 mb-1 text-center">
                                    <Trans id="Please select your travel dates and the amount of travellers" />
                                </p>
                            </div>
                        )
                    }
                    return (
                        <div
                            data-testid="BookDynamicTemplateModalOverview"
                            className={clsx(hasResults ? 'mt-6 lg:mt-8' : 'my-auto')}
                        >
                            <BookDynamicTemplateModalOverview
                                type={type}
                                name={name}
                                endDate={endDate}
                                startDate={startDate}
                                hasResults={hasResults}
                                selectedFare={selectedFare}
                                onChangeFare={onChangeFare}
                                selectedHotel={selectedHotel}
                                numberOfRooms={numberOfRooms}
                                templatePricing={templatePricing}
                                onChangeActivity={onChangeActivity}
                                numberOfAdults={listOfAdults.length}
                                onChangeHotelRoom={onChangeHotelRoom}
                                selectedActivities={selectedActivities}
                                numberOfChildren={listOfChildren.length}
                                selectedHotelRoomId={selectedHotelRoomId}
                                onChangeActivityOptions={onChangeActivityOptions}
                            />
                            {price ? (
                                <BookModalBanner
                                    name={name}
                                    price={price}
                                    endDate={endDate}
                                    startDate={startDate}
                                    className="mt-6 lg:mt-8"
                                    listOfAdults={listOfAdults}
                                    numberOfRooms={numberOfRooms}
                                    listOfChildren={listOfChildren}
                                    absoluteEndDate={absoluteEndDate}
                                    action={(
                                        <Button
                                            className="w-full"
                                            data-testid="BookTemplateButton"
                                            variant={VARIANT_PRIMARY_FILLED}
                                            onClick={onCreateBookingTemplate}
                                            isLoading={isCreatingBookingTemplate}
                                        >
                                            <Trans id={(() => {
                                                switch (type) {
                                                    case BREAK_ENTITY:
                                                        return 'Book this break'
                                                    case TOUR_ENTITY:
                                                        return 'Book this tour'
                                                    default:
                                                        return 'Book'
                                                }
                                            })()}
                                            />
                                        </Button>
                                    )}
                                />
                            ) : null}
                        </div>
                    )
                })()}
            </>
        )}
    </Modal>
)

export default BookDynamicTemplateModal
