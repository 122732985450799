import { gql } from 'graphql-request'
import useGraphqlQuery from './useGraphqlQuery'

const QUERY = gql`
    query cheapestFlightPrices(
        $departureCodes: [String]
        $arrivalCodes: [String]
        $airlineCodes: [String]
    ) {
        cheapestFlightPrices(
            departureCodes: $departureCodes
            arrivalCodes: $arrivalCodes
            airlineCodes: $airlineCodes
        ) {
            id
            price
            provider
            directFlight
            returnDate
            airlineCode
            arrivalCity
            arrivalCode
            arrivalAirport
            arrivalIsMetropolitan
            departureCode
            departureDate
            departureCity
            departureAirport
            departureIsMetropolitan
        }
    }
`

const useCheapestFlightPrices = (variables, options) => {
    const {
        data,
        error,
        isLoading: loading,
    } = useGraphqlQuery(['cheapestFlightPrices', variables], QUERY, variables, options)
    if (loading) {
        return {
            loading,
            cheapestFlightPrices: [],
        }
    }
    if (error !== null) {
        return {
            error,
            loading,
            cheapestFlightPrices: [],
        }
    }
    return {
        error,
        loading,
        cheapestFlightPrices: data?.cheapestFlightPrices || [],
    }
}

export default useCheapestFlightPrices
