import React from 'react'
import { Trans } from '@lingui/react'
import { formatPriceWithoutSymbol } from '../../../util/prices'
import Icon from '../../utils/Icon'
import Button, { VARIANT_BLUE_FILLED } from '../../utils/Button'

const BookDynamicTemplateModalFlightsCardEconomics = ({
    fare,
    isSelected,
    selectedFare,
    numberOfTravelers,
    onChooseFare,
}) => {
    const { baggageAllowed } = fare
    const priceDifference = fare.charge.total - selectedFare.charge.total
    const isNegative = priceDifference < 0
    const priceDiff = Math.abs(priceDifference)
    const priceDifferencePerPerson = priceDiff / numberOfTravelers
    return (
        <>
            {/* Desktop*/}
            <div
                data-testid="BookDynamicTemplateFlightsModalFlightCardEconomicsDesktop"
                className="flex-col justify-between py-5 px-4 border-l border-gray-100 hidden lg:flex xl:min-w-[10.7rem]"
            >
                <div className="text-center mb-3">
                    <p className="text-2xl text-primary">
                        <span className="mr-1">
                            {isNegative ? '-' : '+'}
                        </span>
                        <span>&euro;</span>
                        <span className="font-medium">
                            {formatPriceWithoutSymbol(priceDiff)}
                        </span>
                    </p>
                    <p className="text-xs text-gray-600 mb-2">
                        <Trans id="Total price" />
                    </p>
                    <p className="text-base text-gray-800">
                        <span className="mr-1">
                            {isNegative ? '-' : '+'}
                        </span>
                        <span>&euro;</span>
                        <span className="text-base font-medium">
                            {formatPriceWithoutSymbol(priceDifferencePerPerson)}
                        </span>
                    </p>
                    <p className="text-xs text-gray-600">
                        <Trans id="Per person" />
                    </p>
                </div>
                <div className="flex flex-col items-center text-center">
                    <Icon
                        name={baggageAllowed ? 'bag' : 'bag-slash'}
                        size="smaller"
                        color="blue-bright"
                    />
                    <p className="text-xs text-blue-bright">
                        {baggageAllowed
                            ? <Trans id="Luggage included" />
                            : <Trans id="Luggage excluded" />}
                    </p>
                    {isSelected ? (
                        <p className="text-xs italic text-gray-400 mt-3">
                            <Trans id="Currently selected" />
                        </p>
                    ) : null}
                    <Button
                        className="mt-3"
                        variant={VARIANT_BLUE_FILLED}
                        onClick={onChooseFare}
                        data-testid="BookDynamicTemplateFlightsModalFlightCardEconomicsDesktopSelect"
                    >
                        <Trans id="Select" />
                    </Button>
                </div>
            </div>
            {/* Mobile */}
            <div
                data-testid="BookDynamicTemplateFlightsModalFlightCardEconomicsMobile"
                className="lg:hidden"
            >
                <div className="flex border-b border-t border-gray-100 p-4 text-gray-600 text-xs">
                    <Icon
                        name="ticket"
                        size="smaller"
                        className="mr-1"
                    />
                    <Icon
                        name={baggageAllowed ? 'bag' : 'bag-slash'}
                        size="smaller"
                        className="mr-1"
                    />
                    <div className="flex justify-between w-full">
                        <p>
                            {baggageAllowed
                                ? <Trans id="Luggage included" />
                                : <Trans id="Luggage excluded" />}
                        </p>
                        {isSelected ? (
                            <p className="text-xs italic text-gray-400">
                                <Trans id="Currently selected" />
                            </p>
                        ) : null}
                    </div>
                </div>
                <div className="flex justify-between p-4 gap-2">
                    <div>
                        <p className="text-primary text-xl mb-1">
                            <span className="mr-1">
                                {isNegative ? '-' : '+'}
                            </span>
                            <span>&euro;</span>
                            <span className="font-medium mr-1">
                                {formatPriceWithoutSymbol(priceDiff)}
                            </span>
                            <span className="text-xs text-gray-600">
                                <Trans id="Total price" />
                            </span>
                        </p>
                        <p className="text-base">
                            <span className="mr-1">
                                {isNegative ? '-' : '+'}
                            </span>
                            <span className="text-base">&euro;</span>
                            <span className="font-medium">
                                {formatPriceWithoutSymbol(priceDifferencePerPerson)}
                            </span>
                            <span className="text-xs text-gray-600">
                                <span className="mx-1">/</span>
                                <Trans id="person" />
                            </span>
                        </p>
                    </div>
                    <Button
                        variant={VARIANT_BLUE_FILLED}
                        onClick={onChooseFare}
                        data-testid="BookDynamicTemplateFlightsModalFlightCardEconomicsMobileSelect"
                    >
                        <Trans id="Select" />
                    </Button>
                </div>
            </div>
        </>
    )
}

export default BookDynamicTemplateModalFlightsCardEconomics
