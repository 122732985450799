import React from 'react'
import { Trans } from '@lingui/react'
import GuestsSelect from '../../utils/GuestsSelect'
import useGuestsState from '../../utils/useGuestsState'
import Button, { SIZE_SMALLER, VARIANT_BLUE_FILLED } from '../../utils/Button'

const BookTemplateGuestsSelect = ({
    isOpen,
    onClose,
    maxChildAge,
    listOfAdults,
    numberOfRooms,
    listOfChildren,
}) => {
    const {
        handleChangeAdults,
        handleChangeChildren,
        handleChangeChildAge,
        handleChangeGuestState,
        handleChangeNumberOfRooms,
        ...guestState
    } = useGuestsState({
        maxAmountOfGuests: 4,
        initialAdults: listOfAdults,
        initialChildren: listOfChildren,
        initialNumberOfRooms: numberOfRooms,
    })

    const handleClose = (state) => {
        const prevState = {
            numberOfRooms,
            adults: listOfAdults,
            children: listOfChildren,
        }
        handleChangeGuestState(state || prevState)
        onClose(state || prevState)
    }

    return (
        <GuestsSelect
            isOpen={isOpen}
            onClose={handleClose}
            maxChildAge={maxChildAge}
            listOfAdults={guestState.adults}
            onChangeGuestState={handleClose}
            onChangeAdults={handleChangeAdults}
            listOfChildren={guestState.children}
            onChangeChildren={handleChangeChildren}
            onChangeChildAge={handleChangeChildAge}
            numberOfRooms={guestState.numberOfRooms}
            onChangeNumberOfRooms={handleChangeNumberOfRooms}
            desktopButtons={(closeSelect) => (
                <div className="grid grid-cols-2 gap-x-4">
                    <Button
                        size={SIZE_SMALLER}
                        className="col-span-1"
                        variant="blue-outlined"
                        onClick={(e) => {
                            e.preventDefault()
                            closeSelect()
                            handleClose()
                        }}
                    >
                        <Trans id="Close" />
                    </Button>
                    <Button
                        size={SIZE_SMALLER}
                        className="col-span-1"
                        iconAfterName="arrow-right"
                        variant={VARIANT_BLUE_FILLED}
                        data-testid="BookDynamicTemplateDesktopGuestsSelectConfirm"
                        onClick={(e) => {
                            e.preventDefault()
                            closeSelect()
                            onClose(guestState)
                        }}
                    >
                        <Trans id="Confirm" />
                    </Button>
                </div>
            )}
        />
    )
}

export default BookTemplateGuestsSelect
