/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react'
import clsx from 'clsx'
import {
    isEqual,
    isToday,
    isFirstDayOfMonth,
    isLastDayOfMonth,
    isSameMonth,
    isAfter,
    isBefore,
    parse,
    isValid,
} from 'date-fns'
import FormGroup from './FormGroup'
import JotformDateCalendarMobile from './JotformDateCalendarMobile'
import JotformDateCalendarDesktop from './JotformDateCalendarDesktop'
import TimeSelect from './TimeSelect'

const DATE_FORMAT = 'dd/MM/yyyy'
const TIME_FORMAT = 'HH:mm'
const TIME_OPTIONS = [
    '07:00', '07:30',
    '08:00', '08:30',
    '09:00', '09:30',
    '10:00', '10:30',
    '11:00', '11:30',
    '12:00', '12:30',
    '13:00', '13:30',
    '14:00', '14:30',
    '15:00', '15:30',
    '16:00', '16:30',
    '17:00', '17:30',
    '18:00', '18:30',
    '19:00', '19:30',
    '20:00', '20:30',
    '21:00', '21:30',
    '22:00',
]

/*
custom: "false"
days:
    friday: "true"
    monday: "true"
    saturday: "false"
    sunday: "false"
    thursday: "true"
    tuesday: "true"
    wednesday: "true"
end: ""
future: "true"
past: "false"
ranges: "false"
start: "today+1"
*/

const getDayNumber = (day) => {
    if (day === 'sunday') return 0
    if (day === 'monday') return 1
    if (day === 'tuesday') return 2
    if (day === 'wednesday') return 3
    if (day === 'thursday') return 4
    if (day === 'friday') return 5
    if (day === 'saturday') return 6
    return 0
}

const JotformDateCalendar = ({
    item,
    formState,
    className,
}) => {
    const {
        future,
        past,
        days,
        start,
        end
    } = item.limitDate
    const dayLimits = Object.entries(days).map(([key, value]) => ({
        dayNumber: getDayNumber(key),
        isDisabled: value === 'false',
    }))
    const startDate = parse(start, 'yyyy-MM-dd', new Date())
    const endDate = parse(end, 'yyyy-MM-dd', new Date())
    const startIsValid = isValid(startDate)
    const endIsValid = isValid(endDate)
    const dayIsDisabledCondition = (day) => {
        const conditions = []
        if (future === 'false') {
            conditions.push(isAfter(day, new Date()))
        }
        if (past === 'false') {
            conditions.push(isBefore(day, new Date()))
        }
        if (startIsValid) {
            conditions.push(isBefore(day, startDate))
        }
        if (endIsValid) {
            conditions.push(isAfter(day, endDate))
        }
        dayLimits.forEach(({ dayNumber, isDisabled }) => {
            if (dayNumber === day.getDay()) {
                conditions.push(isDisabled)
            }
        })
        return conditions.some((condition) => condition)
    }
    const {
        hasError,
        getValue,
        handleChange
    } = formState
    const itemTimeName = `${item.name}_time`
    const dateValue = getValue(item.name)
    const timeValueValue = getValue(itemTimeName)
    return (
        <div className={clsx('relative', className)}>
            <FormGroup
                isRequired={item.required}
                label={item.text}
                htmlFor={item.name}
                description={item.subLabel}
            >
                <div className="flex">
                    <JotformDateCalendarDesktop
                        name={item.name}
                        format={DATE_FORMAT}
                        value={dateValue}
                        hasError={hasError(item.name)}
                        onChange={(date) => handleChange(item.name, date)}
                        className="flex-1"
                        dayIsDisabledCondition={dayIsDisabledCondition}
                    />
                    <JotformDateCalendarMobile
                        title={item.text}
                        name={item.name}
                        value={dateValue}
                        format={DATE_FORMAT}
                        hasError={hasError(item.name)}
                        onChange={(date) => handleChange(item.name, date)}
                        className="flex-1"
                        dayIsDisabledCondition={dayIsDisabledCondition}
                    />
                    {item.allowTime && (
                        <TimeSelect
                            value={timeValueValue}
                            onChange={(e) => {
                                handleChange(itemTimeName, e.target.value)
                            }}
                            className="ml-4 w-[100px] rounded-md border-gray-100"
                        >
                            {TIME_OPTIONS.map((time) => (
                                <option key={time} value={time}>{time}</option>
                            ))}
                        </TimeSelect>
                    )}
                </div>
            </FormGroup>
        </div>
    )
}

export default JotformDateCalendar
