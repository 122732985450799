import React from 'react'
import clsx from 'clsx'
import SbEditable from 'storyblok-react'
import * as constants from '@connections/constants'
import ContentImage from './ContentImage'
import { variant } from '../../util'

const {
    CONTAINER_EXTRA_SMALL: EXTRA_SMALL,
    CONTAINER_SMALL: SMALL,
    CONTAINER_DEFAULT: DEFAULT,
} = constants

const ImageSection = ({
    content,
}) => {
    const {
        image,
        size,
        spacing,
        container,
        anchorId,
    } = content
    return (
        <SbEditable content={content}>
            <section
                id={anchorId || undefined}
                className={clsx(
                    variant(spacing, {
                        small: 'py-12',
                        medium: 'py-24',
                        large: 'py-36',
                    }),
                    variant(container, {
                        [EXTRA_SMALL]: 'container-xs',
                        [SMALL]: 'container-sm',
                        [DEFAULT]: 'container',
                    })
                )}
            >
                <ContentImage
                    image={image}
                    size={size}
                />
            </section>
        </SbEditable>
    )
}

export default ImageSection
