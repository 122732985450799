import React from 'react'
import clsx from 'clsx'
import SbEditable from 'storyblok-react'
import * as constants from '@connections/constants'
import { variant } from '../../util/index'
import Column from '../utils/Column'
import ContentImage from './ContentImage'

const { IMAGE_STRETCH: STRETCH } = constants

const ImageColumn = ({
    content,
    position,
}) => {
    const {
        image,
        size,
    } = content

    return (
        <SbEditable content={content}>
            <Column
                position="none"
                className={clsx(
                    variant(size, {
                        [STRETCH]: 'self-stretch',
                    })
                )}
            >
                <ContentImage
                    maxWidth={1080}
                    image={image}
                    position={position}
                    size={size}
                />
            </Column>
        </SbEditable>
    )
}

export default ImageColumn
