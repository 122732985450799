import React from 'react'
import clsx from 'clsx'
import * as constants from '@connections/constants'
import { getHrefFromLink, variant } from '../../util'
import {
    sectionBackgroundVariant,
    sectionContainerVariant,
    sectionSpacingVariant,
    sectionTextOnBackgroundVariant,
} from '../../util/variants'
import CustomText from '../utils/CustomText'
import Button, { VARIANT_BLUE_FILLED } from '../utils/Button'
import usePathnameLocale from '../hooks/usePathnameLocale'
import IconFeaturesCard from './IconFeaturesCard'

const {
    CONTAINER_EXTRA_SMALL: EXTRA_SMALL,
    CONTAINER_SMALL: SMALL,
    CONTAINER_MEDIUM: MEDIUM,
    CONTAINER_DEFAULT: DEFAULT,
} = constants

const IconFeaturesSection = ({
    title,
    link,
    description,
    buttonText,
    background,
    container,
    spacing,
    cards = [],
    anchorId,
    className,
}) => {
    const locale = usePathnameLocale()
    return (
        <section
            id={anchorId || undefined}
            data-testid="IconFeaturesSection"
            className={clsx(
                'relative',
                sectionBackgroundVariant(background),
                sectionSpacingVariant({ spacing }),
                className,
            )}
        >
            <div
                className={clsx(
                    'md:grid md:grid-cols-2 gap-8',
                    sectionContainerVariant(container)
                )}
            >
                <div
                    className={clsx(
                        'md:order-2 md:flex md:flex-col w-full lg:w-3/4 md:self-center',
                        variant(container, {
                            [EXTRA_SMALL]: ' md:justify-self-end',
                            [SMALL]: 'md:justify-self-end',
                            [MEDIUM]: 'md:justify-self-end',
                            [DEFAULT]: ' md:justify-self-center',
                        })
                    )}
                >
                    {title && (
                        <CustomText
                            as="h3"
                            customText={title}
                            customTextClassName="font-accent"
                            className={clsx(
                                'text-lg font-medium text-center mb-4 md:text-3xl md:text-left',
                                sectionTextOnBackgroundVariant(background)
                            )}
                        />
                    )}
                    {description && (
                        <p
                            className={clsx(
                                'text-sm text-center mb-6 md:text-base md:text-left',
                                sectionTextOnBackgroundVariant(background)
                            )}
                        >
                            {description}
                        </p>
                    )}
                    {link && (
                        <Button
                            href={getHrefFromLink(link, locale)}
                            as="a"
                            variant={VARIANT_BLUE_FILLED}
                            className="w-full justify-center md:w-max"
                        >
                            {buttonText}
                        </Button>
                    )}
                </div>
                <div className="grid grid-cols-2 gap-4 mt-6 md:mt-0 md:grid-cols-1">
                    {cards.map((card) => (
                        <IconFeaturesCard
                            key={card._uid}
                            content={card}
                        />
                    ))}
                </div>
            </div>
        </section>
    )
}

export default IconFeaturesSection
