import React from 'react'
import SbEditable from 'storyblok-react'
import * as constants from '@connections/constants'
import { getEntityContentOverrides, getEntityContentPrice } from '../../util/entities'
import usePathnameLocale from '../hooks/usePathnameLocale'
import useDestinationPrice from '../hooks/useDestinationPrice'
import HeadingMobile from './HeadingMobile'

const {
    DESTINATION_ENTITY: DESTINATION
} = constants

const HeadingMobileWithState = ({
    content,
    hasArc,
    className,
    size,
    hasNoQsm,
    isSlideShow,
}) => {
    const {
        buttons = [],
        subTitle,
        entity,
        gradient,
        gradientMobile,
        background,
        centerMobile,
        fullHeightMobile,
        contentImage,
        descriptionColor,
        subTitleColor,
        titleColor,
        contentImageMaxHeight,
        contentImageMaxWidth,
    } = content
    const locale = usePathnameLocale()
    const {
        image,
        video,
        videoStartOffset,
        videoEndOffset,
        title,
        description,
    } = getEntityContentOverrides(content, entity, locale)
    const isDestination = entity && entity.content && entity.content.component === DESTINATION
    let price = getEntityContentPrice(entity)
    const destinationPrice = useDestinationPrice(entity)
    if (isDestination) {
        price = destinationPrice.price
    }
    return (
        <SbEditable content={content}>
            <HeadingMobile
                buttons={buttons}
                subTitle={subTitle}
                image={image}
                video={video}
                videoStartOffset={videoStartOffset}
                videoEndOffset={videoEndOffset}
                contentImage={contentImage}
                title={title}
                description={description}
                price={price}
                hasArc={hasArc}
                gradient={gradient}
                gradientMobile={gradientMobile}
                className={className}
                size={size}
                hasNoQsm={hasNoQsm}
                isSlideShow={isSlideShow}
                background={background}
                centerMobile={centerMobile}
                fullHeightMobile={fullHeightMobile}
                descriptionColor={descriptionColor}
                subTitleColor={subTitleColor}
                titleColor={titleColor}
                contentImageMaxHeight={contentImageMaxHeight}
                contentImageMaxWidth={contentImageMaxWidth}
            />
        </SbEditable>
    )
}

export default HeadingMobileWithState
