import React from 'react'
import Button from '../utils/SbButton'
import ShareButton from '../utils/ShareButtonWithContent'
import Placeholder from './Placeholder'
import FormButton from './FormButtonWithContent'
import BookTourButton from './BookTourButtonWithContent'
import BookBreakButton from './BookBreakButtonWithContent'

const Components = {
    Button,
    FormButton,
    ShareButton,
    BookTourButton,
    BookBreakButton,
}

const DynamicButton = ({
    content,
    ...props
}) => {
    const Component = Components[content.component]
    if (Component) {
        return (
            <Component content={content} {...props} />
        )
    }
    return <Placeholder componentName={content.component} />
}

export default DynamicButton
