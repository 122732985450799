import React from 'react'
import clsx from 'clsx'
import { variant } from '../../util'

const FlightsListerGroupsTab = ({
    title,
    onChange,
    isActive,
    background,
    count = null,
}) => (
    <button
        type="button"
        onClick={onChange}
        aria-current={isActive ? 'page' : undefined}
        className={clsx(
            'text-sm font-semibold border-b-2 pb-3 px-1 whitespace-nowrap',
            isActive ? 'text-primary border-primary' : 'text-gray-400 border-transparent hover:text-primary',
            variant(background, {
                blue: 'text-white',
                white: 'text-black',
                black: 'text-white',
                'gray-50': 'text-black',
                'gray-100': 'text-black',
            }),
        )}
    >
        {title}
        {' '}
        <span className={clsx('inline-block text-xs text-gray-400 ml-0.5')}>
            ({count})
        </span>
    </button>
)

export default FlightsListerGroupsTab
