import React from 'react'
import useScreenSize from '../../hooks/useScreenSize'
import BookDynamicTemplateMobileDateRangeCalendar from './BookDynamicTemplateMobileDateRangeCalendar'
import BookDynamicTemplateDesktopDateRangeCalendar from './BookDynamicTemplateDesktopDateRangeCalendar'

const BookDynamicTemplateDateRangeCalendar = ({
    isLoading,
    errors = [],
    ...props
}) => {
    const hasError = errors.length > 0
    const { isSm, isMd } = useScreenSize()

    return (
        <div data-testid="BookDynamicTemplateDateRangeCalendar">
            <BookDynamicTemplateMobileDateRangeCalendar
                {...props}
                hasError={hasError}
                isOpen={isSm || isMd}
            />
            <BookDynamicTemplateDesktopDateRangeCalendar
                {...props}
                hasError={hasError}
                isOpen={!(isSm || isMd)}
            />
        </div>
    )
}

export default BookDynamicTemplateDateRangeCalendar
