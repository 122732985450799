import React from 'react'
import clsx from 'clsx'
import SbEditable from 'storyblok-react'
import * as constants from '@connections/constants'
import { variant } from '../../util/index'
import Column from '../utils/Column'
import CustomText from '../utils/CustomText'
import BulletList from './BulletList'
import DynamicButton from './DynamicButton'
import RichTextFieldWithComponents from './RichTextFieldWithComponents'

const {
    COLUMN_WIDTH_FULL: FULL,
    BACKGROUND_BLUE: BLUE,
    BACKGROUND_BLACK: BLACK,
} = constants

const ContentColumn = ({
    content,
    position,
    className,
    background,
}) => {
    const {
        text,
        title,
        align,
        spacing,
        subtitle,
        width = FULL,
        buttons = [],
        alignVertical,
        bulletList = [],
    } = content
    return (
        <SbEditable content={content}>
            <Column
                align={align}
                className={className}
                alignVertical={alignVertical}
            >
                <Column.Container
                    width={width}
                    spacing={spacing}
                    position={position}
                >
                    {subtitle && (
                        <h4
                            className={clsx(
                                'font-accent text-2xl transform -rotate-1 mb-5 last:mb-0',
                                variant(background, {
                                    [BLUE]: 'text-white',
                                    [BLACK]: 'text-blue-bright',
                                }, 'text-blue')
                            )}
                        >
                            {subtitle}
                        </h4>
                    )}
                    {title && (
                        <CustomText
                            as="h3"
                            customText={title}
                            customTextClassName="font-accent text-[32px]"
                            className={clsx(
                                'font-medium text-xl mb-6 last:mb-0',
                                variant(background, {
                                    [BLUE]: 'text-white',
                                    [BLACK]: 'text-white',
                                })
                            )}
                        />
                    )}
                    {text && (
                        <RichTextFieldWithComponents
                            className="mb-8 last:mb-0"
                            content={text}
                            background={background}
                        />
                    )}
                    {bulletList.length > 0 && (
                        <BulletList
                            list={bulletList}
                            background={background}
                        />
                    )}
                    {buttons.length > 0 && (
                        <div className="flex flex-wrap gap-4">
                            {buttons.map((button) => (
                                <DynamicButton
                                    key={button._uid}
                                    content={button}
                                />
                            ))}
                        </div>
                    )}
                </Column.Container>
            </Column>
        </SbEditable>
    )
}

export default ContentColumn
