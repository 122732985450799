import useStoryblokQuery from './useStoryblokQuery'

export default function useTemplateSettings(templateSettings) {
    const templateHasSettings = templateSettings?.length > 0
    const templateHasFallbackFormId = templateHasSettings && !!templateSettings[0].fallbackFormId
    const { data } = useStoryblokQuery('template-settings', 'cdn/stories/algemeen/template-instellingen')
    const globalSettings = data?.data?.story?.content

    if (templateHasSettings) {
        const templateSettingsToUse = templateSettings[0]
        if (templateHasFallbackFormId) {
            return templateSettingsToUse
        }
        return { ...templateSettingsToUse, fallbackFormId: globalSettings?.fallbackFormId }
    }
    return globalSettings
}
