import React from 'react'
import SbEditable from 'storyblok-react'
import IntroSection from './IntroSection'

const IntroSectionWithState = ({
    content,
    className,
}) => (
    <SbEditable content={content}>
        <IntroSection
            className={className}
            {...content}
        />
    </SbEditable>
)

export default IntroSectionWithState
