import React from 'react'
import SubHeader from '../../layout/SubHeader'
import ShareButtonWithState from '../../utils/ShareButtonWithState'
import ShareTitle from '../../utils/ShareTitle'

const DestinationPageSubHeader = ({
    name,
}) => (
    <SubHeader>
        <ShareTitle>
            {name}
        </ShareTitle>
        <div>
            <ShareButtonWithState />
        </div>
    </SubHeader>
)

export default DestinationPageSubHeader
