import React from 'react'
import { Trans, useLingui } from '@lingui/react'
import Modal from '../../utils/Modal'
import Select, { VARIANT_INLINE } from '../../utils/Select'
import ModalCloseButton from '../../utils/ModalCloseButton'
import BookDynamicTemplateFlightsModalFlightCard from './BookDynamicTemplateFlightsModalFlightCard'

export const BAGGAGE_ALL = 'BAGGAGE_ALL'
export const BAGGAGE_INCLUDED = 'BAGGAGE_INCLUDED'
export const BAGGAGE_EXCLUDED = 'BAGGAGE_EXCLUDED'

const BookDynamicTemplateFlightsModal = ({
    fares,
    onClose,
    selectedFare,
    onChooseFare,
    filterBaggage,
    showFilterBagge,
    numberOfTravelers,
    onChangeFilterBaggage
}) => {
    const { i18n } = useLingui()
    return (
        <Modal
            onClose={onClose}
            data-testid="BookDynamicTemplateFlightsModal"
            className="px-4 py-10 w-full self-center lg:w-[1000px] min-h-screen lg:min-h-0 lg:px-10"
        >
            <ModalCloseButton onClick={onClose} />
            <h1 className="text-lg font-medium mb-4">
                <Trans id="Change flight" />
            </h1>
            <div className="flex flex-col lg:flex-row lg:justify-between mb-4">
                <span className="text-base mb-2 lg:mb-0">
                    <Trans
                        values={{ count: fares.length }}
                        components={[
                            <span className="font-bold" />,
                            <span />
                        ]}
                        id="We found <0>{count} {count, plural, =1 {flight} other {flights}}</0> <1>for you</1>"
                    />
                </span>
                {showFilterBagge ? (
                    <div className="flex items-center">
                        <label htmlFor="filterBaggage">
                            <span className="mr-2">
                                <Trans id="Filter on" />:
                            </span>
                            <Select
                                id="filterBaggage"
                                value={filterBaggage}
                                variant={VARIANT_INLINE}
                                className="focus:outline-none focus:ring-0"
                                onChange={(e) => onChangeFilterBaggage(e.target.value)}
                            >
                                <option value={BAGGAGE_ALL}>
                                    {i18n._(/*i18n*/'All options')}
                                </option>
                                <option value={BAGGAGE_INCLUDED}>
                                    {i18n._(/*i18n*/'Luggage included')}
                                </option>
                                <option value={BAGGAGE_EXCLUDED}>
                                    {i18n._(/*i18n*/'Luggage excluded')}
                                </option>
                            </Select>
                        </label>
                    </div>
                ) : null}
            </div>
            {fares.map((fare, index) => (
                <BookDynamicTemplateFlightsModalFlightCard
                    fare={fare}
                    key={fare.id}
                    className="mb-4 lg:mb-6"
                    selectedFare={selectedFare}
                    numberOfTravelers={numberOfTravelers}
                    onChooseFare={() => onChooseFare(fare.id)}
                    data-testid={`BookDynamicTemplateFlightsModalFlightCard-${index}`}
                />
            ))}
        </Modal>
    )
}

export default BookDynamicTemplateFlightsModal
