import React, { useState } from 'react'
import SbEditable from 'storyblok-react'
import Column from '../utils/Column'
import RichTextFieldWithComponents from './RichTextFieldWithComponents'
import TabsColumnMobileTabs from './TabsColumnMobileTabs'
import TabsColumnDesktopTabs from './TabsColumnDesktopTabs'

const TabsColumn = ({
    content,
    position,
    background,
    className
}) => {
    const [activeTab, setActiveTab] = useState(0)
    const {
        groups,
        width,
        align,
        spacing,
    } = content
    const activeGroup = groups[activeTab]
    return (
        <SbEditable content={content}>
            <Column
                align={align}
                className={className}
            >
                <Column.Container
                    width={width}
                    position={position}
                    spacing={spacing}
                >
                    <TabsColumnMobileTabs
                        background={background}
                        groups={groups}
                        activeGroup={activeGroup}
                        onChange={(index) => setActiveTab(index)}
                        className="mb-6"
                    />
                    <TabsColumnDesktopTabs
                        background={background}
                        groups={groups}
                        activeTab={activeTab}
                        onChange={(index) => setActiveTab(index)}
                        className="mb-10"
                    />
                    {typeof activeGroup !== 'undefined' && (
                        <RichTextFieldWithComponents
                            content={activeGroup.body}
                            background={background}
                            className="md:min-h-[350px]"
                        />
                    )}
                </Column.Container>
            </Column>
        </SbEditable>
    )
}

export default TabsColumn
