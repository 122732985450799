import React from 'react'
import clsx from 'clsx'
import { Trans } from '@lingui/react'
import * as constants from '@connections/constants'
import {
    sectionBackgroundVariant,
    sectionSpacingVariant,
    sectionContainerVariant,
} from '../../util/variants'
import Icon from '../utils/Icon'
import Spinner from '../utils/Spinner'
import JotformHeading from '../utils/JotformHeading'
import JotformDynamicItem from '../utils/JotformDynamicItem'

const {
    BACKGROUND_WHITE,
    CONTAINER_DEFAULT,
} = constants

const JotformSection = ({
    form,
    spacing,
    anchorId,
    onSubmit,
    isLoading,
    className,
    formState,
    formTitle,
    isSubmitted,
    errorMessage,
    isSubmitting,
    formSubTitle,
    showFormTitle,
    container = CONTAINER_DEFAULT,
    background = BACKGROUND_WHITE,
}) => (
    <section
        id={anchorId || undefined}
        data-testid="JotformSection"
        className={clsx(
            sectionSpacingVariant({ spacing }),
            sectionBackgroundVariant(background),
            className
        )}
    >
        <div
            className={clsx(
                sectionContainerVariant(container)
            )}
        >
            {(() => {
                if (errorMessage !== null) {
                    return (
                        <div
                            className={clsx(
                                'flex flex-col justify-center items-center min-h-[250px] ',
                                className,
                            )}
                        >
                            <Icon className="text-red-500 mb-8" name="warning" size="largest" />
                            <h3 className="text-3xl text-center">
                                <Trans id="Something went wrong." />
                            </h3>
                            <br />
                            <div className="text-gray-600 text-center break-words w-full">
                                {errorMessage}
                            </div>
                        </div>
                    )
                }
                if (isLoading) {
                    return (
                        <div
                            className={clsx(
                                'flex justify-center items-center min-h-[250px]',
                                className,
                            )}
                        >
                            <Spinner color="gray" />
                        </div>
                    )
                }
                if (isSubmitted) {
                    return (
                        <div
                            className={clsx(
                                'flex flex-col justify-center items-center min-h-[250px]',
                                className,
                            )}
                        >
                            <Icon className="text-green mb-8" name="check-circle" size="largest" />
                            <h3 className="text-3xl text-center">
                                <Trans id="Your request has successfully been submitted!" />
                            </h3>
                        </div>
                    )
                }
                return (
                    <form
                        onSubmit={(e) => {
                            e.preventDefault()
                            onSubmit()
                        }}
                        className="grid grid-cols-2 gap-4 md:gap-6"
                    >
                        {(formTitle || formSubTitle) && (
                            <JotformHeading
                                className="col-span-2"
                                item={{
                                    textAlign: 'CENTER',
                                    headerType: 'LARGE',
                                    subHeader: formSubTitle,
                                    text: showFormTitle && formTitle,
                                }}
                            />
                        )}
                        {form.map((formItem) => (
                            <JotformDynamicItem
                                key={formItem.qid}
                                formItem={formItem}
                                formState={formState}
                                className={clsx(
                                    formItem.shrink ? 'col-span-2 md:col-span-1' : 'col-span-2'
                                )}
                                isSubmitting={isSubmitting}
                            />
                        ))}
                    </form>
                )
            })()}
        </div>
    </section>
)

export default JotformSection
