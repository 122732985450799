import React, { useState, useMemo } from 'react'
import BookDynamicTemplateFlightsModal, {
    BAGGAGE_ALL,
    BAGGAGE_INCLUDED,
    BAGGAGE_EXCLUDED,
} from './BookDynamicTemplateFlightsModal'

const BookDynamicTemplateFlightsModalWithState = ({
    onClose,
    fares = [],
    selectedFare,
    onChooseFare,
    numberOfTravelers,
}) => {
    const [filterBaggage, setFilterBaggage] = useState(BAGGAGE_ALL)

    // We only show the filter if there are differences on the value of baggageAllowed
    const showFilterBagge = fares.some((fare) => fare.baggageAllowed !== fares[0].baggageAllowed)

    const filteredFares = useMemo(() => {
        if (showFilterBagge && filterBaggage !== BAGGAGE_ALL) {
            return fares.filter(({ baggageAllowed }) => {
                const hasSelectedIncluded = baggageAllowed && filterBaggage === BAGGAGE_INCLUDED
                const hasSelectedExcluded = !baggageAllowed && filterBaggage === BAGGAGE_EXCLUDED
                return hasSelectedIncluded || hasSelectedExcluded
            })
        }
        return fares
    }, [fares, filterBaggage])

    return (
        <BookDynamicTemplateFlightsModal
            onClose={onClose}
            fares={filteredFares}
            selectedFare={selectedFare}
            onChooseFare={onChooseFare}
            filterBaggage={filterBaggage}
            showFilterBagge={showFilterBagge}
            numberOfTravelers={numberOfTravelers}
            onChangeFilterBaggage={setFilterBaggage}
        />
    )
}

export default BookDynamicTemplateFlightsModalWithState
