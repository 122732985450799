import React from 'react'
import clsx from 'clsx'
import { Trans } from '@lingui/react'
import { gql } from 'graphql-request'
import { whereId } from '@connections/utils'
import { formatPriceWithoutSymbol } from '../../../util/prices'
import Date from '../../utils/Date'
import TextValue from '../../utils/TextValue'
import Button, { VARIANT_BLUE_FILLED } from '../../utils/Button'
import BookingGuestAmounts from '../../utils/BookingGuestAmounts'
import Stars, { convertCategoryToNumber } from '../../utils/Stars'
import CheckoutCard, { VARIANT_UNPADDED } from '../../utils/CheckoutCard'

const FRAGMENT = gql`
    fragment BookDynamicTemplateHotelsModalHotelCard on Hotel {
        id
        name
        picture
        category
        address {
            city
            street
            zipCode
            houseNumber
        }
        morePricesNeeded
        priceLevels {
            id
            adults
            endDate
            children
            roomName
            startDate
            availabilityStatus
            boardBaseDisplayName
            priceInfo {
                totalPrice
            }
        }
    }
`

const BookDynamicTemplateHotelsModalHotelCard = ({
    hotel,
    className,
    numberOfRooms,
    selectedHotel,
    numberOfTravelers,
    onChangeHotelRoom,
    selectedHotelProductId,
}) => {
    const {
        name,
        address,
        picture,
        category,
        priceLevels,
    } = hotel
    if (priceLevels.length === 0) {
        return null
    }
    const selectedPriceLevel = selectedHotel.priceLevels.find(whereId(selectedHotelProductId))
    const [cheapestPriceLevel] = priceLevels.sort((a, b) => a.priceInfo.totalPrice - b.priceInfo.totalPrice)
    const amountOfStars = convertCategoryToNumber(category)
    const {
        adults,
        children,
        startDate,
        endDate,
        roomName,
        boardBaseDisplayName,
    } = cheapestPriceLevel
    const priceDifference = cheapestPriceLevel.priceInfo.totalPrice - selectedPriceLevel.priceInfo.totalPrice
    const isNegative = priceDifference < 0
    const priceDiff = Math.abs(priceDifference)
    const priceDiffPerPerson = priceDiff / numberOfTravelers
    return (
        <CheckoutCard
            className={
                clsx(
                    'flex flex-col bg-white border border-gray-100 lg:flex-row',
                    className
                )
            }
            variant={VARIANT_UNPADDED}
        >
            <div className="relative w-full lg:w-64 h-32 lg:h-56 bg-gray-100 flex-shrink-0 rounded rounded-b-none lg:rounded-l lg:rounded-r-none">
                {picture ? (
                    <img
                        alt={name}
                        src={picture}
                        className="absolute inset-0 object-cover h-full w-full rounded rounded-b-none lg:rounded-l lg:rounded-r-none"
                    />
                ) : null}
            </div>
            <div className="flex-1 px-4 lg:px-8 py-3 lg:py-6">
                <div className="flex">
                    <p className="text-lg font-medium">
                        {name}
                    </p>
                    <Stars
                        className="pt-1 ml-2"
                        total={amountOfStars}
                    />
                </div>
                {address?.city ? (
                    <p className="text-xs text-gray-400 mt-2">
                        <span className="font-medium">
                            {address?.city?.toUpperCase()}
                        </span>
                        {address?.street && `, ${address.street}`}
                        {address?.houseNumber && `, ${address.houseNumber}`}
                    </p>
                ) : null}
                <p className="text-sm text-gray-800 mt-4 mb-2">
                    {roomName}
                    {boardBaseDisplayName && ` - ${boardBaseDisplayName}`}
                </p>
                <BookingGuestAmounts
                    adults={adults}
                    className="mb-4"
                    numberOfChildren={children}
                    numberOfRooms={numberOfRooms}
                />
                <div className="flex">
                    <TextValue
                        label={<><Trans id="Check in" />:</>}
                    >
                        <Date
                            dateString={startDate}
                            format="eee d MMM yyyy"
                        />
                    </TextValue>
                    <TextValue
                        label={<><Trans id="Check out" />:</>}
                        className="ml-4"
                    >
                        <Date
                            dateString={endDate}
                            format="eee d MMM yyyy"
                        />
                    </TextValue>
                </div>
            </div>
            <div className="flex justify-between items-center px-4 py-3 lg:flex-col lg:px-8 lg:py-6 lg:w-48 lg:border-l lg:border-gray-100">
                <div>
                    <div className="flex items-center mb-1 lg:flex-col lg:mb-2">
                        <p className="text-xl text-primary mr-1 lg:text-2xl lg:mr-0">
                            <span className="mr-1">
                                {isNegative ? '-' : '+'}
                            </span>
                            <span>&euro;</span>
                            <span className="font-medium">
                                {formatPriceWithoutSymbol(priceDiff)}
                            </span>
                        </p>
                        <p className="text-xs text-gray-600">
                            <Trans id="Total price" />
                        </p>
                    </div>
                    <div className="flex items-center lg:flex-col">
                        <p className="text-base mr-1 lg:text-gray-800 lg:mr-0">
                            <span className="mr-1">
                                {isNegative ? '-' : '+'}
                            </span>
                            <span>&euro;</span>
                            <span className="font-medium">
                                {formatPriceWithoutSymbol(priceDiffPerPerson)}
                            </span>
                        </p>
                        <p className="text-xs text-gray-600">
                            <Trans id="Per person" />
                        </p>
                    </div>
                </div>
                <Button
                    className="lg:w-full"
                    variant={VARIANT_BLUE_FILLED}
                    onClick={() => onChangeHotelRoom(cheapestPriceLevel.id)}
                >
                    <Trans id="Select" />
                </Button>
            </div>
        </CheckoutCard>
    )
}

BookDynamicTemplateHotelsModalHotelCard.FRAGMENT = FRAGMENT

export default BookDynamicTemplateHotelsModalHotelCard
