import React from 'react'
import TestimonialsSection from './TestimonialsSection'
import useAutomaticScroller from '../hooks/useAutomaticScroller'

const TestimonialsSectionWithState = ({
    content,
}) => {
    const { direction, testimonials, } = content
    const {
        pageCount,
        containerRef,
        galleryRef,
        x,
    } = useAutomaticScroller({
        direction,
        slides: testimonials,
    })

    return (
        <TestimonialsSection
            key={content.testimonials.length}
            content={content}
            containerRef={containerRef}
            galleryRef={galleryRef}
            x={x}
            pageCount={pageCount}
        />
    )
}

export default TestimonialsSectionWithState
