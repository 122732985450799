import { gql } from 'graphql-request'
import useGraphqlMutation from '../../hooks/useGraphqlMutation'

const MUTATION = gql`
    mutation createBookingTemplate ($input: CreateBookingTemplateInput!) {
        createBookingTemplate(input: $input) {
            booking {
                id
                uid
            }
        }
    }
`

const useCreateBookingTemplateMutation = (options) => useGraphqlMutation(MUTATION, options)

export default useCreateBookingTemplateMutation
