import React from 'react'
import Placeholder from './Placeholder'
import MapSection from './MapSection'
import ListSection from './ListSection'
import ImageSection from './ImageSection'
import VideoSection from './VideoSection'
import QuoteSection from './QuoteSection'
import TableSection from './TableSection'
import TitleSection from './TitleSection'
import IframeSection from './IframeSection'
import BannerSection from './BannerSection'
import ColumnsSection from './ColumnsSection'
import FeaturesSection from './FeaturesSection'
import AirlinesSection from './AirlinesSection'
import RichTextSection from './RichTextSection'
import HighlightSection from './HighlightSection'
import CardsSection from './CardsSectionWithState'
import IntroSection from './IntroSectionWithState'
import ReviewsSection from './ReviewsSectionWithState'
import ProgramSection from './ProgramSectionWithState'
import GallerySection from './GallerySectionWithState'
import HeadingSection from './HeadingSectionWithState'
import CarouselSection from './CarouselSectionWithState'
import JotformSection from './JotformSectionWithContent'
import DayByDaySection from './DayByDaySectionWithState'
import DestinationMapSection from './DestinationMapSection'
import OurExpertsSection from './OurExpertsSectionWithState'
import DayByDayMapSection from './DayByDayMapSectionWithState'
import CollapsableSection from './CollapsableSectionWithState'
import TestimonialsSection from './TestimonialsSectionWithState'
import QuickSearchSection from './QuickSearchSectionWithContent'
import IconFeaturesSection from './IconFeaturesSectionWithState'
import ProgramBlocksSection from './ProgramBlocksSectionWithState'
import FlightsListerSection from './FlightsListerSectionWithState'
import FeaturedCarouselSection from './FeaturedCarouselSectionWithState'
import FlightsListerGroupSection from './FlightsListerGroupSectionWithState'
import DestinationFlightsListerGroupSection from './DestinationFlightsListerGroupSectionWithState'

const Components = {
    MapSection,
    ListSection,
    ImageSection,
    VideoSection,
    CardsSection,
    QuoteSection,
    IntroSection,
    TitleSection,
    TableSection,
    IframeSection,
    BannerSection,
    ColumnsSection,
    GallerySection,
    HeadingSection,
    JotformSection,
    ProgramSection,
    ReviewsSection,
    CarouselSection,
    DayByDaySection,
    AirlinesSection,
    FeaturesSection,
    RichTextSection,
    HighlightSection,
    OurExpertsSection,
    DayByDayMapSection,
    CollapsableSection,
    QuickSearchSection,
    IconFeaturesSection,
    TestimonialsSection,
    FlightsListerSection,
    ProgramBlocksSection,
    DestinationMapSection,
    FeaturedCarouselSection,
    FlightsListerGroupSection,
    DestinationFlightsListerGroupSection,
    WhyConnectionsSection: IconFeaturesSection, //Backwards compability
}

const DynamicSection = ({
    content,
    ...props
}) => {
    const Component = Components[content.component]
    if (Component) {
        return (
            <Component content={content} {...props} />
        )
    }
    return <Placeholder componentName={content.component} />
}

export default DynamicSection
