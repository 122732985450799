import clsx from 'clsx'
import React from 'react'
import useScrollPosition from '../hooks/useScrollPosition'

const SubHeader = ({
    children,
}) => {
    const position = useScrollPosition()
    return (
        <div
            className={clsx(
                'w-full bg-white py-2 shadow-md transition-all relative -z-1',
                position < 200 ? 'opacity-0 pointer-events-none' : 'opacity-1'
            )}
        >
            <div className="container flex items-center justify-between gap-4">
                {children}
            </div>
        </div>
    )
}

export default SubHeader
