import React from 'react'
import Slide from '../content/Slide'
import Icon from '../utils/Icon'
import Stars from '../utils/Stars'
import Spinner from '../utils/Spinner'
import CustomText from '../utils/CustomText'
import SliderContainer from '../utils/CarouselContainer'
import TrustPilotLogo from './TrustPilotLogo'
import TrustPilotStars from './TrustPilotStars'

export type TrustpilotBusinessType = {
    stars: number
    trustScore: number
    numberOfReviews: number
}

export type TrustpilotReviewType = {
    name: string
    text: string
    stars: number
    title: string
    createdAt: string
}

type ReviewSectionProps = {
    position: number
    swipingProps: any
    isLoading: boolean
    isSwiping: boolean
    isDisabled: boolean
    hasNextSlide: boolean
    hasPreviousSlide: boolean
    content: { title: string }
    currentSlideHeight: number
    slideRefs: [HTMLDivElement]
    handleNextSlide: () => void
    containerRef: HTMLDivElement
    handlePreviousSlide: () => void
    data: {
        trustpilotReviews: [TrustpilotReviewType]
        trustpilotBusiness: TrustpilotBusinessType
    }
}

const ReviewsSection = ({
    data,
    content,
    position,
    isLoading,
    slideRefs,
    isSwiping,
    isDisabled,
    containerRef,
    swipingProps,
    hasNextSlide,
    handleNextSlide,
    hasPreviousSlide,
    currentSlideHeight,
    handlePreviousSlide,
}: ReviewSectionProps) => {
    if (isLoading || !data) {
        return (
            <div className="flex justify-center w-full">
                <Spinner size="huge" />
            </div>
        )
    }

    const {
        trustpilotReviews: reviews,
        trustpilotBusiness: business,
    } = data
    const { title } = content

    return (
        <div>
            <div className="flex flex-wrap-reverse gap-8 justify-between items-center text-center md:text-left mb-16 px-8 md:px-16 lg:px-32">
                {title && (
                    <CustomText
                        as="h3"
                        customText={title}
                        className="text-lg md:text-4xl max-w-2xl md:flex-1"
                        customTextClassName="text-4xl md:text-[40px] font-accent"
                    />
                )}
                <div className="text-sm mx-auto w-52">
                    <TrustPilotLogo className="mb-6" />
                    <TrustPilotStars stars={business.stars} className="w-full h-10 mb-2" />
                    <span className="w-full text-center block">
                        Trustscore {business.trustScore} | {business.numberOfReviews} reviews
                    </span>
                </div>
            </div>
            <div className="relative px-16 lg:px-32">
                {hasPreviousSlide ? (
                    <button
                        onClick={handlePreviousSlide}
                        className="absolute top-1/2 left-0 transform -translate-y-1/2"
                    >
                        <Icon
                            size="largest"
                            name="arrow-left"
                        />
                    </button>
                ) : null}
                {hasNextSlide ? (
                    <button
                        onClick={handleNextSlide}
                        className="absolute top-1/2 right-0 transform -translate-y-1/2"
                    >
                        <Icon
                            size="largest"
                            name="arrow-right"
                        />
                    </button>
                ) : null}
                <div
                    style={{ height: currentSlideHeight }}
                    className="relative overflow-hidden transition-height min-h-[10rem]"
                >
                    <SliderContainer
                        position={position}
                        isDisabled={isDisabled}
                        containerRef={containerRef}
                        swipingProps={swipingProps}
                    >
                        {reviews.map((review, index) => (
                            <Slide
                                key={index}
                                isSwiping={isSwiping}
                                ref={(ref) => { slideRefs.current[index] = ref }}
                            >
                                <div className="flex flex-col md:flex-row gap-8 md:gap-16 lg:gap-32">
                                    <div>
                                        <span className="text-sm block">
                                            {review.createdAt}
                                        </span>
                                        <span className="font-medium mb-2 block text-lg">
                                            {review.name}
                                        </span>
                                        <Stars
                                            total={review.stars}
                                            className="w-32"
                                        />
                                    </div>
                                    <div className="flex flex-col gap-1">
                                        <span className="text-xl font-bold mb-2">{review.title}</span>
                                        <span>{review.text}</span>
                                    </div>
                                </div>
                            </Slide>
                        ))}
                    </SliderContainer>
                </div>
            </div>
        </div>
    )
}

export default ReviewsSection
