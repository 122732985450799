import { useEffect } from 'react'
import { gql } from 'graphql-request'
import useAppState from './useAppState'
import useGraphqlQuery from './useGraphqlQuery'

const QUERY = gql`
    query reviews {
        trustpilotBusiness {
            stars
            trustScore
            numberOfReviews
        }
        trustpilotReviews {
            createdAt
            stars
            title
            text
            name
        }
    }
`

const useTrustpilotQuery = () => {
    const { locale } = useAppState()
    const {
        data,
        error,
        isLoading,
        refetch,
    } = useGraphqlQuery('reviews', QUERY)

    useEffect(() => {
        refetch()
    }, [locale])
    return {
        error,
        isLoading,
        data,
    }
}

export default useTrustpilotQuery
