import React from 'react'
import { Trans } from '@lingui/react'
import NextLink from 'next/link'
import clsx from 'clsx'
import useFormatFullSlug from '../hooks/useFormatFullSlug'
import useLocalizedAirline from '../hooks/useLocalizedAirlineDeprecated'
import Date from '../utils/Date'
import Price from '../utils/Price'
import JsonLd from '../utils/JsonLd'

const FlightsListerAirlineCard = ({
    destination,
}) => {
    const {
        content,
        flightPrice = {},
        full_slug: fullSlug,
    } = destination
    const { name } = content
    const {
        price,
        airlineCode,
        returnDate,
        departureDate,
    } = flightPrice
    const airline = useLocalizedAirline(airlineCode)
    const formatFullSlug = useFormatFullSlug()
    const hasPrice = typeof price !== 'undefined'
    const hasImage = airline.imageSquareUrl !== null

    return (
        <NextLink
            href={formatFullSlug(fullSlug)}
            className="flex border border-gray-100 bg-white px-4 py-3 cursor-pointer"
        >
            <JsonLd
                type="Flight"
                item={{
                    departureTime: departureDate,
                    arrivalTime: returnDate,
                    provider: airline.name,
                }}
            />
            <div
                className={clsx(
                    'relative w-12 h-12 mr-4',
                    !hasImage && 'bg-gray-100',
                )}
            >
                {hasImage && (
                    <img
                        src={airline.imageSquareUrl}
                        alt={airline.name}
                        className="absolute inset-0 object-cover h-full w-full"
                    />
                )}
            </div>
            <div className="flex flex-1 justify-between items-center">
                <div className="flex flex-col items-start">
                    <span className="text-base text-black">{name}</span>
                    <span className="text-xs text-gray-600">{airline.name}</span>
                </div>
                <div className="flex flex-col items-end">
                    <span className="text-base text-black">
                        {hasPrice ? (
                            <Price price={price} />
                        ) : (
                            <Trans id="No pricing found" />
                        )}
                    </span>
                    <span className="text-xs text-gray-600">
                        {hasPrice ? (
                            <>
                                <Date dateString={departureDate} format="dd MMM" />
                                {' - '}
                                <Date dateString={returnDate} format="dd MMM" />
                            </>
                        ) : (
                            <Trans id="No date found" />
                        )}
                    </span>
                </div>
            </div>
        </NextLink>
    )
}

export default FlightsListerAirlineCard
