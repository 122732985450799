import { useReducer } from 'react'
import {
    addRoomToTravelersList,
    getUpdatedRoomTravelersList,
    removeRoomFromTravelersList,
} from '../content/useQuickSearchHotelFormState'

const SET_STATE = 'SET_STATE'
const CHANGE_ADULTS = 'CHANGE_ADULTS'
const CHANGE_CHILDREN = 'CHANGE_CHILDREN'
const CHANGE_NUMBER_ROOM = 'CHANGE_NUMBER_ROOM'
const CHANGE_CHILD_AGE = 'CHANGE_CHILD_AGE'

const reducer = (state, action) => {
    switch (action.type) {
        case CHANGE_ADULTS: {
            const { roomIndex, number } = action
            const guest = { roomIndex }
            const adults = getUpdatedRoomTravelersList(state.adults, roomIndex, number, guest)
            const numberOfTravelers = adults.length + state.children.length
            return ({
                ...state,
                adults,
                numberOfTravelers,
            })
        }
        case CHANGE_CHILDREN: {
            const { roomIndex, number } = action
            const child = { roomIndex, age: 10 }
            const children = getUpdatedRoomTravelersList(state.children, roomIndex, number, child)
            const numberOfTravelers = children.length + state.adults.length
            return ({
                ...state,
                children,
                numberOfTravelers,
            })
        }
        case CHANGE_NUMBER_ROOM: {
            const {
                adults,
                children,
                numberOfRooms,
            } = state
            const {
                number,
                roomIndex
            } = action
            let newAdults = adults
            let newChildren = children
            if (number > numberOfRooms) {
                newAdults = addRoomToTravelersList(adults, roomIndex)
            } else {
                newAdults = removeRoomFromTravelersList(adults, roomIndex, numberOfRooms)
                newChildren = removeRoomFromTravelersList(children, roomIndex, numberOfRooms)
            }
            const numberOfTravelers = newChildren.length + newAdults.length
            return ({
                ...state,
                numberOfRooms: action.number,
                numberOfTravelers,
                adults: newAdults,
                children: newChildren,
            })
        }
        case CHANGE_CHILD_AGE: {
            const {
                age,
                index,
                roomIndex
            } = action
            const { children } = state
            let childIndex = 0
            const newChildren = children.map((child) => {
                if (child.roomIndex === roomIndex) {
                    if (index === childIndex) {
                        childIndex += 1
                        return { roomIndex, age: parseInt(age, 10) }
                    }
                    childIndex += 1
                }
                return child
            })
            const numberOfTravelers = newChildren.length + state.adults.length
            return ({
                ...state,
                numberOfTravelers,
                children: newChildren,
            })
        }
        case SET_STATE: {
            const numberOfTravelers = action.state.children.length + action.state.adults.length
            return { numberOfTravelers, ...action.state }
        }
        default:
            throw new Error()
    }
}

const useGuestsState = ({
    initialAdults = [{
        roomIndex: 0,
    }, {
        roomIndex: 0,
    }],
    initialChildren = [],
    initialNumberOfRooms = 1,
    maxAmountOfGuests = null,
} = {}) => {
    const [state, dispatch] = useReducer(reducer, {
        adults: initialAdults,
        children: initialChildren,
        numberOfRooms: initialNumberOfRooms,
        numberOfTravelers: initialAdults.length + initialChildren.length,
    })
    const {
        adults, children, numberOfRooms, numberOfTravelers
    } = state
    const hasReachedLimit = numberOfTravelers === maxAmountOfGuests
    return {
        adults,
        children,
        numberOfRooms,
        numberOfTravelers,
        handleChangeAdults: (roomIndex, number) => {
            const isBlockedByLimit = number > adults.length && hasReachedLimit
            if (!isBlockedByLimit && number <= 9 && number >= 1) {
                dispatch({
                    type: CHANGE_ADULTS,
                    roomIndex,
                    number,
                })
            }
        },
        handleChangeChildren: (roomIndex, number) => {
            const isBlockedByLimit = number > children.length && hasReachedLimit
            if (!isBlockedByLimit && number <= 9 && number >= 0) {
                dispatch({
                    type: CHANGE_CHILDREN,
                    roomIndex,
                    number,
                })
            }
        },
        handleChangeNumberOfRooms: (number, roomIndex) => {
            if (number <= 3 && number >= 1) {
                dispatch({
                    type: CHANGE_NUMBER_ROOM,
                    roomIndex,
                    number,
                })
            }
        },
        handleChangeChildAge: (roomIndex, index, age) => {
            dispatch({
                type: CHANGE_CHILD_AGE,
                roomIndex,
                index,
                age
            })
        },
        handleChangeGuestState: (newState) => {
            dispatch({
                type: SET_STATE,
                state: newState
            })
        }
    }
}

export default useGuestsState
