import React from 'react'
import clsx from 'clsx'

const Textarea = ({
    hasError,
    disabled,
    className,
    isLoading,
    ...props
}) => (
    <div className="relative">
        {isLoading ? <div className="absolute skeleton h-full w-full" /> : null}
        <textarea
            className={clsx([
                'border border-gray-100 py-3 px-4 w-full leading-[22px] rounded text-sm placeholder-gray-400 text-black resize-none min-h-[8rem]',
                disabled && 'bg-gray-50',
                hasError && 'border-primary hover:border-primary-dark',
                className,
            ])}
            disabled={disabled}
            {...props}
        />
    </div>
)

export default Textarea
