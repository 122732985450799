import React from 'react'
import clsx from 'clsx'
import * as constants from '@connections/constants'
import DynamicButton from './DynamicButton'
import CustomText from '../utils/CustomText'
import WorldMapWithZoom from '../utils/WorldMapWithZoom'
import { variant } from '../../util/index'
import {
    sectionBackgroundVariant,
    sectionSpacingVariant,
    sectionTextOnBackgroundVariant,
} from '../../util/variants'

const {
    CONTAINER_EXTRA_SMALL: EXTRA_SMALL,
    CONTAINER_SMALL: SMALL,
    CONTAINER_DEFAULT: DEFAULT,
    BACKGROUND_BLUE
} = constants

const SIZE_SMALL = 'small'
const SIZE_LARGE = 'large'

const OurExpertsSection = ({
    description,
    title,
    experts = [],
    buttons = [],
    subTitle,
    background = BACKGROUND_BLUE,
    container,
    spacing,
    continentCode,
    anchorId,
    size = SIZE_SMALL,
    isFetching,
}) => (
    <section
        id={anchorId || undefined}
        data-testid="OurExpertsSection"
        className={clsx(
            'overflow-hidden',
            sectionSpacingVariant({ spacing }),
            sectionBackgroundVariant(background)
        )}
    >
        <div
            className={clsx(
                'flex items-center relative',
                variant(container, {
                    [EXTRA_SMALL]: 'container-xs',
                    [SMALL]: 'container-sm',
                    [DEFAULT]: 'container',
                }),
            )}
        >
            <div className={clsx(
                'flex flex-col max-w-[24rem] py-20 z-1 w-full flex-shrink-0 text-center mx-auto',
                'md:text-left md:mx-0',
                sectionTextOnBackgroundVariant(background),
            )}
            >
                {title && (
                    <CustomText
                        as="h3"
                        className={clsx(
                            'font-medium mb-4 text-xl',
                            variant(size, {
                                [SIZE_SMALL]: 'md:text-3xl',
                                [SIZE_LARGE]: 'md:text-5xl',
                            })
                        )}
                        customText={title}
                        customTextClassName={clsx(
                            'font-accent text-3xl',
                            variant(size, {
                                [SIZE_SMALL]: 'md:text-[2.5rem]',
                                [SIZE_LARGE]: 'md:text-7xl',
                            })
                        )}
                    />
                )}
                {subTitle && (
                    <h3 className="text-xl font-medium mb-4">
                        {subTitle}
                    </h3>
                )}
                {description && (
                    <CustomText
                        as="p"
                        className="text-sm md:text-base mb-8"
                        customText={description}
                    />
                )}
                <div className="flex flex-wrap gap-4">
                    {buttons.map((button) => (
                        <DynamicButton
                            className="w-full md:w-auto justify-center mb-4"
                            key={button._uid}
                            content={button}
                        />
                    ))}
                </div>
            </div>
            <div
                className={clsx(
                    'absolute top-0 left-[-25%] h-full flex items-center w-[150%]',
                    'md:-ml-8 md:-mr-44 md:flex-1 md:w-full md:left-auto ',
                    variant(size, {
                        [SIZE_SMALL]: 'md:transform md:scale-75 md:right-[-10%] lg:right-[-15%]',
                        [SIZE_LARGE]: 'md:relative md:py-24 md:right-0 lg:right-[-10%]',
                    })
                )}
            >
                <WorldMapWithZoom
                    continentCode={continentCode}
                    background={background}
                    experts={experts}
                    isFetching={isFetching}
                />
            </div>
        </div>
    </section>
)

export default OurExpertsSection
