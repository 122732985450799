import React from 'react'
import Page from './Page'
import HeaderWithContent from './HeaderWithContent'
import FooterWithContent from './FooterWithContent'
import StickyHeader from './StickyHeader'

const PageUnknown = () => (
    <Page data-testid="PageUnknown" noIndex>
        <StickyHeader>
            <HeaderWithContent />
        </StickyHeader>
        <main className="h-screen flex flex-col items-center justify-center">
            <h1 className="heading text-2xl text-black mb-2">Unknown page</h1>
            <p className="paragraph text-gray-600">Check if the page is added in AppRouter.</p>
        </main>
        <FooterWithContent />
    </Page>
)

export default PageUnknown
