import { gql } from 'graphql-request'
import * as Sentry from '@sentry/nextjs'
import { useQueryParam } from 'use-query-params'
import { useAsyncEffect } from '@wappla/react-hooks'
import { useFlightSearchMetaData } from '../../../util/analytics/flights'
import { toSegments, getCabinClass } from '../../../util/booking/flights'
import useLogEvent from '../../hooks/useLogEvent'
import useGraphqlQuery from '../../hooks/useGraphqlQuery'
import useCreateJourneyMutation from '../../utils/useCreateJourneyMutation'

const QUERY = gql`
    query searchFares($journeyUid: ID, $input: SearchFaresInput!) {
        searchFares(journeyUid: $journeyUid, input: $input) {
            journeyUid
            responseTime
            fares {
                id
                ticket
                provider
                isLowCost
                baggageAllowed
                platingCarrier
                charge {
                    total
                    adult {
                        sum
                        total
                        numberOfPersons
                        tax {
                            total
                        }
                    }
                    child {
                        sum
                        total
                        numberOfPersons
                        tax {
                            total
                        }
                    }
                    infant {
                        sum
                        total
                        numberOfPersons
                        tax {
                            total
                        }
                    }
                }
                airRoutes {
                    id
                    fareId
                    travelTime
                    airRouteType
                    segments {
                        id
                        carrier
                        equipment
                        flightTime
                        cabinClass
                        travelOrder
                        arrivalTime
                        departureTime
                        classOfService
                        availableSeats
                        operatingCarrier
                        operatingFlightNumber
                        origin {
                            code
                            type
                        }
                        destination {
                            code
                            type
                        }
                    }
                }
                priceBreakdown {
                    ageTypes {
                        code
                    }
                    fareInformation {
                        brandId
                        fareBasis
                        privateFare
                        negotiatedFare
                    }
                }
            }
        }
    }
`

const useFlightSearchPageQuery = (params, resultsUrl, hasSearchQuery) => {
    const logEvent = useLogEvent()
    const [journeyUid, setJourneyUid] = useQueryParam('journeyUid')
    const { flightSearchQueryToSearchStartMetaData, flightSearchQueryToSearchCompleteMetaData } = useFlightSearchMetaData()

    const {
        mode,
        adults,
        infants,
        children,
        fareType,
        airlineIataCodes,
    } = params
    const options = {
        enabled: hasSearchQuery && !!journeyUid,
        onSuccess: (data) => {
            // In case the journeyUid is not valid we set it again here
            const hasJourneyUid = !!journeyUid && journeyUid !== 'journeyUid'
            if (!hasJourneyUid) {
                setJourneyUid(data?.searchFares?.journeyUid)
            }

            const responseTime = data?.searchFares?.responseTime
            const numberOfFlights = data?.searchFares?.fares?.length
            const validJourneyUid = hasJourneyUid ? journeyUid : data?.searchFares?.journeyUid
            const resultCountPerProvider = data?.searchFares?.fares.reduce((acc, { provider }) => {
                const accClone = { ...acc }
                const key = `search-count-${provider}`
                const providerCount = acc[key]
                accClone[key] = providerCount + 1 || 1

                return accClone
            }, {})
            const metaData = flightSearchQueryToSearchCompleteMetaData(window.location.href, responseTime, numberOfFlights, resultCountPerProvider)
            logEvent('SearchComplete', metaData, validJourneyUid)
        },
        onError: ({ response }) => {
            const errorMessage = response?.errors?.[0]?.message
            const metaData = flightSearchQueryToSearchStartMetaData(window.location.href)
            logEvent('SearchError', { ...metaData, 'search-error': errorMessage }, journeyUid)
        },
    }
    const segments = toSegments(params)
    const input = {
        mode,
        adults,
        infants,
        segments,
        children,
        seniors: 0,
        resultsUrl,
        airlineIataCodes,
        cabinClass: getCabinClass(fareType),
    }
    const variables = {
        input,
        journeyUid,
    }
    const {
        data,
        error,
        refetch,
        isRefetching,
        isLoading: isFetching,
    } = useGraphqlQuery(['flights', variables], QUERY, variables, options)

    const { mutateAsync: createJourney } = useCreateJourneyMutation()
    useAsyncEffect(async () => {
        if (!journeyUid) {
            try {
                const response = await createJourney()
                setJourneyUid(response?.createJourney?.journey?.uid)
            } catch (e) {
                /**
                 * If we fail to create a journey we set the journeyUid to a random string
                 * which will trigger the flight search
                 * We can still fetch try to fetch results since this will return a new journeyUid
                 * If that also fails we will show an error
                 * */
                setJourneyUid('journeyUid')
                Sentry.captureException(e)
            }
        }
    }, [journeyUid])

    return {
        error,
        refetch,
        isRefetching,
        isFetching: isFetching || !journeyUid,
        fares: (!error && !isFetching && data?.searchFares?.fares) || [],
        journeyUid: !error && !isFetching && data?.searchFares?.journeyUid,
    }
}

export default useFlightSearchPageQuery
