import React from 'react'
import clsx from 'clsx'
import Select from './Select'
import FormGroup from './FormGroup'
import FormInputErrors from './FormInputErrors'

const JotformDropdownInput = ({
    item,
    formState,
    className,
}) => {
    const {
        getErrorMessages,
        getNativeInputProps,
    } = formState
    return (
        <div
            className={clsx(
                className
            )}
        >
            <FormGroup
                isRequired={item.required}
                label={item.text}
                htmlFor={item.name}
                description={item.subLabel}
            >
                <Select
                    {...getNativeInputProps(item.name)}
                >
                    {item.options.map((option) => (
                        <option
                            key={option}
                            value={option}
                        >
                            {option}
                        </option>
                    ))}
                </Select>
                <FormInputErrors errors={getErrorMessages(item.name)} />
            </FormGroup>
        </div>
    )
}

export default JotformDropdownInput
