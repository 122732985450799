import React, { useState } from 'react'
import { useRouter } from 'next-nprogress-bar'
import useNotifications from '../hooks/useNotifications'
import usePathnameLocale from '../hooks/usePathnameLocale'
import GiftcardProduct from './GiftcardProduct'
import useGiftcardProductFormState from './useGiftcardProductFormState'
import useCreateBookingGiftcardMutation from './useCreateBookingGiftcardMutation'

const GiftcardProductWithState = () => {
    const router = useRouter()
    const fullLocale = usePathnameLocale(true)
    const { dispatchError } = useNotifications()
    const formState = useGiftcardProductFormState()
    const { mutateAsync: createBookingGiftcard } = useCreateBookingGiftcardMutation()
    const [isCreatingBookingGiftcard, setIsCreatingBookingGiftcard] = useState(false)

    const handleCreateGiftcardBooking = async () => {
        if (formState.validate()) {
            setIsCreatingBookingGiftcard(true)
            try {
                const input = formState.valuesToInput()
                const response = await createBookingGiftcard({ input })
                const { uid } = response.createBookingGiftcard.booking
                router.push(`/${fullLocale}/checkout/${uid}/giftcards`)
            } catch (error) {
                setIsCreatingBookingGiftcard(false)
                dispatchError(error.message)
            }
        }
    }

    return (
        <GiftcardProduct
            formState={formState}
            onCreateGiftcardBooking={handleCreateGiftcardBooking}
            isCreatingBookingGiftcard={isCreatingBookingGiftcard}
        />
    )
}

export default GiftcardProductWithState
