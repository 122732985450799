import React from 'react'
import { getHrefFromLink } from '../../util/index'
import TextLink from '../utils/TextLink'
import TagList from '../utils/TagList'
import usePathnameLocale from '../hooks/usePathnameLocale'

const BulletItemContent = ({
    link,
    text,
    tags,
    tagVariant,
}) => {
    const locale = usePathnameLocale()
    const hasLink = link.story || link.url
    if (hasLink) {
        return (
            <TextLink
                href={getHrefFromLink(link, locale)}
                className="relative underline"
            >
                {text || link.url || link.story.name}
            </TextLink>
        )
    }

    if (tags.length > 0) {
        return (
            <TagList
                tags={tags.map((tag) => tag.text)}
                collapseFrom={8}
                variant={tagVariant}
            />
        )
    }

    return text
}

export default BulletItemContent
