import React from 'react'
import { Trans } from '@lingui/react'
import { BREAK_ENTITY } from '@connections/constants'
import BookTemplateModalLoading from '../book-dynamic-template/BookTemplateModalLoading'
import Icon from '../../utils/Icon'
import BookTemplateModalTours from './BookTemplateModalTours'
import BookTemplateModalHotels from './BookTemplateModalHotels'
import BookTemplateModalFlights from './BookTemplateModalFlights'
import BookTemplateModalActivity from './BookTemplateModalActivity'
import BookTemplateModalTransports from './BookTemplateModalTransports'

const BookTemplateModalOverview = ({
    type,
    name,
    endDate,
    startDate,
    isFetching,
    onChangeTour,
    numberOfRooms,
    numberOfAdults,
    selectedTourId,
    templatePricing,
    templateSettings,
    numberOfChildren,
    selectedHotelIds,
    onChangeActivity,
    onChooseHotelRoom,
    selectedActivities,
    onChangeActivityOptions,
}) => {
    if (isFetching) {
        return (
            <BookTemplateModalLoading
                className="my-auto"
                templateSettings={templateSettings}
                data-testid="BookDynamicTemplateModalLoading"
            />
        )
    }
    if (!templatePricing) {
        return (
            <div className="flex flex-col flex-1 items-center justify-center">
                <Icon
                    color="gray"
                    size="largest"
                    name="calendar-slash"
                />
                <p className="text-gray-400 font-medium mt-4 mb-1 text-center">
                    {type === BREAK_ENTITY
                        ? <Trans id="Unfortunately this break is no longer available for the selected date range and/or room layout." />
                        : <Trans id="Unfortunately this tour is no longer available for the selected date range and/or room layout." />}
                </p>
                <p className="text-sm text-gray-400">
                    <Trans id="Please make an other selection." />
                </p>
            </div>
        )
    }
    const {
        tours,
        hotels,
        flights,
        transports,
        activities,
    } = templatePricing
    const includedActivities = activities.filter((activity) => selectedActivities.find(({ originalProductId }) => originalProductId === activity.productId))
    const optionalActivities = activities.filter((activity) => !selectedActivities.find(({ originalProductId }) => originalProductId === activity.productId))
    return (
        <>
            <div className="grid grid-cols-1 lg:grid-cols-2 divide-y lg:divide-y-0 gap-y-8 lg:gap-y-0 lg:divide-x divide-gray-100 pb-6 border-b border-gray-100 mt-6 lg:mt-8">
                <BookTemplateModalFlights
                    flights={flights}
                    className="lg:pr-10"
                />
                <div className="pt-8 lg:pt-0 lg:pl-10">
                    {selectedTourId && (
                        <>
                            <BookTemplateModalTours
                                name={name}
                                tours={tours}
                                onChangeTour={onChangeTour}
                                numberOfRooms={numberOfRooms}
                                selectedTourId={selectedTourId}
                                numberOfAdults={numberOfAdults}
                                numberOfChildren={numberOfChildren}
                            />
                            {selectedHotelIds?.length > 0 && <div className="border-b border-gray-100 my-8" />}
                        </>
                    )}
                    {selectedHotelIds?.length > 0 && (
                        <BookTemplateModalHotels
                            hotels={hotels}
                            numberOfRooms={numberOfRooms}
                            numberOfAdults={numberOfAdults}
                            selectedHotelIds={selectedHotelIds}
                            numberOfChildren={numberOfChildren}
                            onChooseHotelRoom={onChooseHotelRoom}
                        />
                    )}
                </div>
            </div>
            {transports?.length > 0 ? (
                <BookTemplateModalTransports
                    transports={transports}
                    className="border-b border-gray-100 py-6"
                />
            ) : null}
            {includedActivities.map((activity) => (
                <BookTemplateModalActivity
                    landing={endDate}
                    activity={activity}
                    departure={startDate}
                    key={activity.productId}
                    onChangeActivity={onChangeActivity}
                    onChangeActivityOptions={onChangeActivityOptions}
                    numberOfTravelers={numberOfAdults + (numberOfChildren || 0)}
                    selectedOptions={selectedActivities.find(({ originalProductId }) => originalProductId === activity.productId)}
                />
            ))}
            {optionalActivities.length > 0 && (
                <>
                    <h2 className="text-lg font-medium mt-6">
                        <Trans id="Optional activities" />
                    </h2>
                    {optionalActivities.map((activity) => (
                        <BookTemplateModalActivity
                            landing={endDate}
                            activity={activity}
                            departure={startDate}
                            key={activity.productId}
                            onChangeActivity={onChangeActivity}
                            onChangeActivityOptions={onChangeActivityOptions}
                            numberOfTravelers={numberOfAdults + (numberOfChildren || 0)}
                            selectedOptions={selectedActivities.find(({ originalProductId }) => originalProductId === activity.productId)}
                        />
                    ))}
                </>
            )}
        </>
    )
}

export default BookTemplateModalOverview
