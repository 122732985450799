import { gql } from 'graphql-request'
import useGraphqlMutation from '../../hooks/useGraphqlMutation'

const MUTATION = gql`
    mutation createBookingDynamicTemplate (
        $id: ID!
        $input: CreateBookingDynamicTemplateInput!
    ) {
        createBookingDynamicTemplate(id: $id, input: $input) {
            booking {
                id
                uid
            }
        }
    }
`

const useCreateBookingDynamicTemplateMutation = (options) => useGraphqlMutation(MUTATION, options)

export default useCreateBookingDynamicTemplateMutation
