import React from 'react'
import Modal from './Modal'
import ModalCloseButton from './ModalCloseButton'
import JotformSectionWithProvider from '../content/JotformSectionWithProvider'

const JotformModal = ({
    onClose,
    formId,
    formType,
    formTitle,
    formSubTitle
}) => (
    <Modal
        className="py-16 px-4 lg:px-10 w-full lg:w-[900px]"
        onClose={onClose}
    >
        <ModalCloseButton onClick={onClose} />
        <JotformSectionWithProvider
            jotformId={formId}
            jotformType={formType}
            formTitle={formTitle}
            formSubTitle={formSubTitle}
        />
    </Modal>
)

export default JotformModal
