import React, { useState } from 'react'
import SbEditable from 'storyblok-react'
import DayByDaySection from './DayByDaySection'

const DayByDaySectionWithState = ({
    content,
    pageContent,
}) => {
    const dayItems = content.dayItems.length > 0
        ? content.dayItems
        : pageContent.dayItems
    const firstDayUid = dayItems.length > 0 ? dayItems[0]._uid : null
    const [lastActiveDayUid, setLastActiveDayUid] = useState(firstDayUid)
    const [activeDayUid, setActiveDayUid] = useState(firstDayUid)
    const lastActiveDay = dayItems.find(({ _uid }) => _uid === lastActiveDayUid) || null

    const handleToggle = (uid) => {
        if (activeDayUid === uid) {
            setActiveDayUid(null)
        } else {
            setActiveDayUid(uid)
            setLastActiveDayUid(uid)
        }
    }

    return (
        <SbEditable content={content}>
            <DayByDaySection
                onToggle={handleToggle}
                activeDayUid={activeDayUid}
                lastActiveDay={lastActiveDay}
                {...content}
                dayItems={dayItems}
            />
        </SbEditable>
    )
}

export default DayByDaySectionWithState
