import React, { useState } from 'react'
import clsx from 'clsx'
import { motion, AnimatePresence } from 'framer-motion'
import Tag from './Tag'

const AnimatedTag = motion(Tag)

const TagList = ({
    tags,
    variant,
    className,
    collapseFrom,
}) => {
    const [isCollapsed, setIsCollapsed] = useState(true)
    const needsToBeCollapsed = typeof collapseFrom !== 'undefined' && tags.length > collapseFrom

    const handleExpand = () => {
        setIsCollapsed(false)
    }

    const firstTags = tags.slice(0, collapseFrom)
    const remainingTags = tags.slice(collapseFrom, tags.length)

    return (
        <div className={clsx(
            'flex flex-wrap',
            className,
        )}
        >
            <AnimatePresence>
                {firstTags.map((tag) => (
                    <Tag key={tag} variant={variant} text={tag} className="mr-2 mb-2" />
                ))}
                {remainingTags.map((tag) => {
                    if (needsToBeCollapsed && isCollapsed) {
                        return null
                    }

                    return (
                        <AnimatedTag
                            key={tag}
                            variant={variant}
                            className="origin-left mr-2 mb-2"
                            initial={{ opacity: 0, scaleX: 0.8 }}
                            animate={{ opacity: 1, scaleX: 1 }}
                            exit={{ opacity: 0, scaleX: 0.8 }}
                        >
                            {tag}
                        </AnimatedTag>
                    )
                })}
                {needsToBeCollapsed && isCollapsed && (
                    <Tag
                        variant={variant}
                        className="cursor-pointer mr-2 mb-2"
                        onClick={handleExpand}
                    >
                        ...
                    </Tag>
                )}
            </AnimatePresence>
        </div>
    )
}

export default TagList
