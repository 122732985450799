import React from 'react'
import { Trans } from '@lingui/react'
import * as constants from '@connections/constants'
import Page from '../../layout/Page'
import PageMain from '../../layout/PageMain'
import StickyHeader from '../../layout/StickyHeader'
import HeaderWithContent from '../../layout/HeaderWithContent'
import FooterWithContent from '../../layout/FooterWithContent'
import Spinner from '../../utils/Spinner'
import InfiniteScroll from '../../utils/InfiniteScroll'
import DynamicSection from '../../content/DynamicSection'
import CardsSectionWithState from '../../content/CardsSectionWithState'
import QuickSearchSectionWithState from '../../content/QuickSearchSectionWithState'

const {
    TOUR_ENTITY: TOUR,
    BREAK_ENTITY: BREAK,
    ACTIVITY_ENTITY: ACTIVITY,
    TOUR_CARD,
    BREAK_CARD,
    ACTIVITY_CARD,
} = constants

const mapNodeToCard = (entity) => {
    switch (entity.content.component) {
        case ACTIVITY:
            return {
                ...entity.content,
                activity: entity,
                component: ACTIVITY_CARD,
            }
        case BREAK:
            return {
                ...entity.content,
                break: entity,
                component: BREAK_CARD,
            }
        case TOUR:
            return {
                ...entity.content,
                tour: entity,
                component: TOUR_CARD,
            }
        default:
            return null
    }
}

const ExperiencesSearchPage = ({
    content,
    hasMore,
    isFetching,
    onLoadMore,
    onFetchMore,
    searchResults,
    isFetchingMore,
    loadMoreOnPageEnd,
}) => (
    <Page data-testid="ExperiencesSearchPage">
        <StickyHeader>
            <HeaderWithContent />
        </StickyHeader>
        <QuickSearchSectionWithState
            isStandalone
            spacing="medium"
            openTab="experience"
            background="gray-100"
            tabs={['experience']}
            anchorId="experiences-search-page-qsm"
        />
        <PageMain className="min-h-screen">
            <section className="mb-12">
                {(() => {
                    if (isFetching) {
                        return (
                            <div className="container min-h-[500px] flex justify-center items-center">
                                <Spinner color="gray" />
                            </div>
                        )
                    }
                    if (searchResults.length === 0) {
                        return (
                            <div className="container min-h-[500px] flex justify-center items-center">
                                <p className="mb-16">
                                    <Trans id="No experiences found for these search parameters." />
                                </p>
                            </div>
                        )
                    }

                    return (
                        <InfiniteScroll
                            hasMore={hasMore}
                            onLoadMore={onLoadMore}
                            onFetchMore={onFetchMore}
                            isFetchingMore={isFetchingMore}
                            loadMoreOnPageEnd={loadMoreOnPageEnd}
                        >
                            <CardsSectionWithState
                                className="py-12"
                                content={{
                                    columns: 4,
                                    cards: searchResults.map(mapNodeToCard),
                                }}
                            />
                        </InfiniteScroll>
                    )
                })()}
            </section>
            {content.body && content.body.map((section) => (
                <DynamicSection
                    content={section}
                    key={section._uid}
                />
            ))}
        </PageMain>
        <FooterWithContent />
    </Page>
)

export default ExperiencesSearchPage
