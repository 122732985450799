import React from 'react'
import { Trans } from '@lingui/react'
import { CONTAINER_SMALL, SPACING_SMALL } from '@connections/constants'
import Page from '../../layout/Page'
import PageMain from '../../layout/PageMain'
import StickyHeader from '../../layout/StickyHeader'
import HeaderWithContent from '../../layout/HeaderWithContent'
import FooterWithContent from '../../layout/FooterWithContent'
import IntroSection from '../../content/IntroSection'
import DynamicSection from '../../content/DynamicSection'
import JsonLd from '../../utils/JsonLd'
import LocaleLink from '../../utils/LocaleLink'

const JobPage = ({
    title,
    body = [],
    headImage,
    description,
    introIsVertical,
}) => (
    <Page data-testid="JobPage">
        <JsonLd
            type="JobPosting"
            item={{
                title,
                name: title,
                description,
            }}
        />
        <StickyHeader>
            <HeaderWithContent />
        </StickyHeader>
        <PageMain>
            <IntroSection
                title={title}
                description={description}
                headImage={headImage}
                background="gray-100"
                spacing={SPACING_SMALL}
                container={CONTAINER_SMALL}
                isVertical={introIsVertical}
                buttons={[
                    <LocaleLink
                        href="/jobs"
                        iconBeforeName="arrow-left"
                        className="text-blue-bright"
                    >
                        <Trans id="Return to overview" />
                    </LocaleLink>
                ]}
            />
            {body && body.map((content) => (
                <DynamicSection
                    key={content._uid}
                    content={content}
                />
            ))}
        </PageMain>
        <FooterWithContent />
    </Page>
)

export default JobPage
