import { gql } from 'graphql-request'
import useGraphqlQuery from '../../hooks/useGraphqlQuery'

const QUERY = gql`
    query templates($ids: [ID]!, $journeyUid: ID!) {
        templates(ids: $ids, journeyUid: $journeyUid) {
            id
            availableDates {
                endDate
                startDate
                productId
            }
        }
    }
`

const useTemplatesQuery = (journeyUid, ids, availableDates) => {
    const variables = {
        ids,
        journeyUid,
    }
    const options = {
        enabled: journeyUid !== null && !availableDates,
    }
    const {
        data,
        error,
        isLoading: isFetching,
    } = useGraphqlQuery(['templates', variables], QUERY, variables, options)

    const allAvailableDates = availableDates || data?.templates?.reduce((allDates, template) => [
        ...allDates,
        ...template.availableDates,
    ], [])
    allAvailableDates?.sort((a, b) => new Date(a.startDate) - new Date(b.startDate))

    return {
        error,
        isFetching,
        allAvailableDates,
    }
}

export default useTemplatesQuery
