import React, { useRef } from 'react'
import SbEditable from 'storyblok-react'
import useCarousel from '@dashdot/react-hooks-slider'
import FeaturedCarouselSection from './FeaturedCarouselSection'

const FeaturedCarouselSectionWithState = ({
    content,
}) => {
    const currentContentRef = useRef()
    const {
        initialSlideIndex,
        slides = [],
        autoplaySpeed,
    } = content
    const {
        activeSlide,
        position,
        hasPreviousSlide,
        hasNextSlide,
        handleSlideSelect,
        handleNextSlide,
        handlePreviousSlide,
        slideRefs,
        containerRef,
        isDisabled,
        swipingProps,
    } = useCarousel(parseInt(initialSlideIndex || 0, 10), parseInt(autoplaySpeed || 0, 10))
    const activeSlideContent = slides[activeSlide]

    return (
        <SbEditable content={content}>
            <FeaturedCarouselSection
                activeSlide={activeSlide}
                position={position}
                hasPreviousSlide={hasPreviousSlide}
                hasNextSlide={hasNextSlide}
                handleSlideSelect={handleSlideSelect}
                handleNextSlide={handleNextSlide}
                handlePreviousSlide={handlePreviousSlide}
                slideRefs={slideRefs}
                containerRef={containerRef}
                isDisabled={isDisabled}
                swipingProps={swipingProps}
                activeSlideContent={activeSlideContent}
                currentContentRef={currentContentRef}
                {...content}
            />
        </SbEditable>
    )
}

export default FeaturedCarouselSectionWithState
