import React from 'react'
import clsx from 'clsx'
import SbEditable from 'storyblok-react'
import Image from '../utils/Image'

const DayItemTabGroupItem = ({
    content,
}) => {
    const {
        image,
        title,
        description,
    } = content
    return (
        <SbEditable content={content}>
            <div className={clsx(
                'flex py-5 border-b border-gray-100 px-4',
                'first:pt-6',
                'last:border-0 last:pb-0 md:last:pb-6',
                'md:px-8 md:py-4 md:border-0',
            )}
            >
                {image?.filename && (
                    <div className="relative w-28 h-28 flex-shrink-0 mr-4">
                        <Image
                            fill
                            width={160}
                            height={160}
                            src={image.filename}
                            className="object-cover"
                        />
                    </div>
                )}
                <div>
                    <h5 className="text-sm uppercase text-gray-600 font-medium mb-2">
                        {title}
                    </h5>
                    <p className="leading-7">
                        {description}
                    </p>
                </div>
            </div>
        </SbEditable>
    )
}

export default DayItemTabGroupItem
