import React from 'react'
import Lottie from 'lottie-react'
import { Trans } from '@lingui/react'
import Modal from '../../utils/Modal'
import airplaneLoadingAnimation from '../../../animations/airplane-loading.json'

export const STAGE_CREATING = 'creating'
export const STAGE_TRANSFERRING = 'transferring'

const stageContents = {
    [STAGE_CREATING]: (
        <>
            <Trans id="One moment please" />
            <span className="dots" />
        </>
    ),
    [STAGE_TRANSFERRING]: <Trans id="Get ready for take off!" />,
}

const FlightSearchLoadingModal = ({
    stage,
}) => (
    <Modal
        onClose={() => {}}
        className="px-4 py-8 w-full self-center lg:w-[500px] min-h-screen lg:min-h-0 lg:px-8"
    >
        <div className="flex flex-col items-center justify-center w-full">
            <Lottie
                loop
                className="w-80"
                animationData={airplaneLoadingAnimation}
            />
            <span className="uppercase font-medium mt-4">
                {stageContents[stage]}
            </span>
        </div>
    </Modal>
)

export default FlightSearchLoadingModal
