import { useRouter } from 'next-nprogress-bar'
import { usePathname, useSearchParams } from 'next/navigation'

export default function useSearchParamValues() {
    const router = useRouter()
    const pathname = usePathname()
    const searchParams = useSearchParams()

    return {
        searchParams,
        addValuesToSearchParams: (values) => {
            const params = new URLSearchParams(searchParams.toString())
            Object.keys(values).forEach((key) => {
                const value = values[key]
                params.set(key, value)
            })

            router.push(`${pathname}?${params.toString()}`)
        }
    }
}
