import React, { Fragment } from 'react'
import { Dialog as HuiDialog, Transition } from '@headlessui/react'

const Dialog = ({
    isOpen,
    onClose,
    children,
}) => (
    <Transition appear show={isOpen} as={Fragment}>
        <HuiDialog
            as="div"
            className="fixed inset-0 z-10 overflow-y-auto bg-gray-900 bg-opacity-30"
            onClose={onClose}
        >
            <div className="min-h-screen px-4 text-center flex items-center justify-center">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <HuiDialog.Overlay className="fixed inset-0 cursor-pointer" />
                </Transition.Child>

                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    {children}
                </Transition.Child>
            </div>
        </HuiDialog>
    </Transition>
)

export default Dialog
