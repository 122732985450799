import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { useLingui } from '@lingui/react'
import useAppState from '../../hooks/useAppState'
import { MONTHS } from '../../hooks/useTravelSearchSettingsQuery'
import useTravelSearchFilterFormState from './useTravelSearchFilterFormState'

const getFilters = (
    i18n,
    settings,
    travelFormulas,
    travelThemes,
    destinationCountryCodes,
    destinationContinentCodes,
) => {
    const timeSettings = settings.find(({ component }) => component === 'TimeSearchSettings')
    const destinationSettings = settings.find(({ component }) => component === 'DestinationSearchSettings')

    // Compose filters
    const filters = []
    // Destination filters
    const { countryCodes, continentCodes } = destinationSettings
    if (continentCodes?.length > 0 || countryCodes?.length > 0) {
        let destinationFilter = {
            filters: [],
            key: 'destinationFilter',
            label: i18n._(/*i18n*/'Destinations'),
        }
        // Continent filter
        if (continentCodes?.length > 0) {
            destinationFilter = {
                ...destinationFilter,
                filters: [{
                    type: 'checkbox',
                    value: 'continentCodes',
                    label: i18n._(/*i18n*/'Regions'),
                    amountToShow: continentCodes.length,
                    options: continentCodes.map((code) => ({
                        value: code,
                        label: destinationContinentCodes[code],
                    }))
                }]
            }
        }
        // Country filter
        if (countryCodes?.length > 0) {
            destinationFilter = {
                ...destinationFilter,
                filters: [
                    ...destinationFilter.filters, {
                        type: 'select',
                        value: 'countryCode',
                        label: i18n._(/*i18n*/'Or choose a country'),
                        defaultOption: <option value="">{i18n._(/*i18n*/'All countries')}</option>,
                        options: countryCodes.map((code) => ({
                            value: code,
                            label: destinationCountryCodes[code],
                        })).sort((a, b) => a.label.localeCompare(b.label))
                    }]
            }
        }
        filters.push(destinationFilter)
    }
    // Period filters
    let periodFilter = ({
        key: 'dateFilter',
        label: i18n._(/*i18n*/'Dates'),
        // Month filter
        filters: [{
            value: 'months',
            options: MONTHS,
            type: 'checkbox',
            amountToShow: MONTHS.length,
            label: i18n._(/*i18n*/'Months'),
            className: 'grid grid-rows-6 grid-flow-col',
        }]
    })
    // Vacation filter
    if (timeSettings?.timePeriods?.length > 0) {
        periodFilter = {
            ...periodFilter,
            filters: [
                ...periodFilter.filters, {
                    type: 'checkbox',
                    value: 'vacations',
                    label: i18n._(/*i18n*/'Vacation periods'),
                    options: timeSettings.timePeriods.map(({ content }) => ({
                        value: content.name,
                        label: content.name,
                    }))
                }
            ],
        }
    }
    filters.push(periodFilter)
    // Service filters
    if (travelFormulas?.length > 0) {
        filters.push({
            key: 'formulaFilter',
            label: i18n._(/*i18n*/'Experience'),
            filters: [{
                amountToShow: 20,
                type: 'checkbox',
                value: 'formulas',
                options: travelFormulas.map(({ content }) => ({
                    value: content.name,
                    label: content.name,
                })),
            }],
        })
    }
    // Theme filters
    if (travelThemes?.length > 0) {
        filters.push({
            key: 'themesFilter',
            label: i18n._(/*i18n*/'Travel themes'),
            filters: [{
                amountToShow: 20,
                type: 'checkbox',
                value: 'themes',
                options: travelThemes.map(({ content }) => ({
                    value: content.name,
                    label: content.name,
                })),
            }],
        })
    }

    return filters
}

const useTravelSearchPageFilters = (
    query,
    setQuery,
    settings,
    travelFormulas,
    travelThemes,
) => {
    const { i18n } = useLingui()
    const [filters, setFilters] = useState([])
    const formState = useTravelSearchFilterFormState(query)
    const { destinationCountryCodes, destinationContinentCodes } = useAppState()

    useEffect(() => {
        if (!_.isEmpty(destinationCountryCodes) && !_.isEmpty(destinationContinentCodes)) {
            setFilters(getFilters(
                i18n,
                settings,
                travelFormulas,
                travelThemes,
                destinationCountryCodes,
                destinationContinentCodes,
            ))
        }
    }, [
        destinationCountryCodes,
        destinationContinentCodes,
        travelFormulas.length,
        travelThemes.length,
    ])
    useEffect(() => {
        setQuery({ ...formState.values })
    }, [formState.values])

    return ({
        filters,
        formState,
        selectedValues: filters.reduce((acc, filterCategory) => {
            const selectedValues = filterCategory.filters
                .reduce((optionsAcc, { value, type, options }) => {
                    const selectedFilterValue = formState.values[value]
                    const selectedOptions = options.filter((option) => (type === 'checkbox'
                        ? selectedFilterValue.includes(option.value)
                        : option.value === selectedFilterValue))
                        .map((option) => ({
                            ...option,
                            onChange: formState.onChange[value],
                        }))
                    return [...optionsAcc, ...selectedOptions]
                }, [])
            return [...acc, ...selectedValues]
        }, []),

    })
}

export default useTravelSearchPageFilters
