import React from 'react'
import Input from './Input'
import FormGroup from './FormGroup'
import FormInputErrors from './FormInputErrors'

const JotformAddressFormInput = ({
    formItem,
    formState,
    placeholder,
    className
}) => (
    <div className={className}>
        <FormGroup
            label={formItem.label}
            htmlFor={formItem.id}
            isRequired={formItem.isRequired}
        >
            <Input
                id={formItem.id}
                disabled={formItem.disabled}
                placeholder={placeholder}
                {...formState.getNativeInputProps(formItem.name)}
            />
            <FormInputErrors errors={formState.getErrorMessages(formItem.name)} />
        </FormGroup>
    </div>
)

export default JotformAddressFormInput
