import React from 'react'
import clsx from 'clsx'
import Lottie from 'lottie-react'
import loadingAnimation from '../../../animations/loading.json'
import CmsLoadingCarousel from '../../utils/CmsLoadingCarousel'

const BookTemplateModalLoading = ({
    className,
    templateSettings,
}) => {
    const hasImages = templateSettings?.loadingItems.some(({ image }) => !!image?.filename)
    return (
        <div className={clsx(
            'flex flex-col items-center justify-center w-full',
            className
        )}>
            {hasImages ? null : (
                <Lottie
                    loop
                    className="w-80"
                    animationData={loadingAnimation}
                />
            )}
            {templateSettings ? (
                <CmsLoadingCarousel
                    loadingContents={templateSettings.loadingItems}
                    loadingItemDelay={parseInt(templateSettings.loadingItemDelay, 10)}
                />
            ) : null}
        </div>
    )
}

export default BookTemplateModalLoading
