import React, { useState } from 'react'
import { Trans } from '@lingui/react'
import Date from '../../utils/Date'
import Stars from '../../utils/Stars'
import BookingGuestAmounts from '../../utils/BookingGuestAmounts'
import Button, { VARIANT_BLUE_FILLED, VARIANT_BLUE_OUTLINED } from '../../utils/Button'
import BookTemplateModalRooms from './BookTemplateModalRooms'

const BookTemplateModalHotel = ({
    hotel,
    hotels,
    alternativeHotels = [],
    numberOfRooms,
    numberOfAdults,
    numberOfChildren,
    onChooseHotelRoom,
    onExploreMoreHotels,
}) => {
    const [isChoosingRoom, setIsChoosingRoom] = useState(false)
    const {
        name,
        picture,
        endDate,
        category,
        startDate,
        productId,
        priceLevelName,
    } = hotel
    const alternativeRooms = hotels.filter((room) => room.productId !== productId
        && room.name === name
        && room.endDate === endDate
        && room.startDate === startDate)
    return (
        <>
            <div className="relative w-full h-48 bg-gray-100 rounded">
                {picture && (
                    <img
                        alt={name}
                        src={picture}
                        draggable={false}
                        className="absolute inset-0 object-cover h-full w-full rounded"
                    />
                )}
            </div>
            <div className="flex items-start justify-between mt-4">
                <p className="text-lg font-medium">
                    {name}
                </p>
                <Stars
                    total={category}
                    className="pt-1"
                />
            </div>
            <p className="text-sm text-gray-800 mt-1">
                {priceLevelName}
            </p>
            <BookingGuestAmounts
                className="mt-2"
                adults={numberOfAdults}
                numberOfRooms={numberOfRooms}
                numberOfChildren={numberOfChildren}
            />
            <div className="grid grid-cols-2 mt-3 text-sm">
                <div>
                    <p className="font-bold">
                        <Trans id="Check in:" />
                    </p>
                    <p className="text-gray-800 mt-1">
                        <Date
                            dateString={startDate}
                            format="eee d MMM yyyy"
                        />
                    </p>
                </div>
                <div>
                    <p className="font-bold">
                        <Trans id="Check out:" />
                    </p>
                    <p className="text-gray-800 mt-1">
                        <Date
                            dateString={endDate}
                            format="eee d MMM yyyy"
                        />
                    </p>
                </div>
            </div>
            <div className="w-full grid grid-cols-2 gap-x-2">
                {alternativeRooms.length > 0 ? (
                    <Button
                        className="col-span-2 md:col-span-1 mt-4"
                        variant={VARIANT_BLUE_FILLED}
                        onClick={() => setIsChoosingRoom(true)}
                    >
                        <Trans id="Change room" />
                    </Button>
                ) : null}
                {alternativeHotels.length > 0 ? (
                    <Button
                        className="col-span-2 md:col-span-1 mt-4"
                        variant={VARIANT_BLUE_OUTLINED}
                        onClick={onExploreMoreHotels}
                    >
                        <Trans id="Change hotel" />
                    </Button>
                ) : null}
            </div>
            {isChoosingRoom && (
                <BookTemplateModalRooms
                    hotelName={name}
                    selectedRoom={hotel}
                    onChooseRoom={(room) => {
                        onChooseHotelRoom(room)
                        setIsChoosingRoom(false)
                    }}
                    rooms={[hotel, ...alternativeRooms]}
                    onClose={() => setIsChoosingRoom(false)}
                    numberOfTravelers={numberOfAdults + (numberOfChildren || 0)}
                />
            )}
        </>
    )
}

export default BookTemplateModalHotel
