import React from 'react'
import { Trans } from '@lingui/react'
import { whereId } from '@connections/utils'
import useCarousel from '@dashdot/react-hooks-slider'
import Date from '../../utils/Date'
import Slide from '../../content/Slide'
import SliderContainer from '../../utils/CarouselContainer'
import CarouselControls from '../../content/CarouselControls'
import BookingGuestAmounts from '../../utils/BookingGuestAmounts'
import Stars, { convertCategoryToNumber } from '../../utils/Stars'

const BookDynamicTemplateModalHotel = ({
    hotel,
    numberOfRooms,
    numberOfAdults,
    numberOfChildren,
    selectedHotelRoomId,
}) => {
    const currentPriceLevel = hotel.priceLevels.find(whereId(selectedHotelRoomId))
    const stars = convertCategoryToNumber(hotel.category)
    const { endDate, roomName, startDate } = currentPriceLevel
    const additionalPictures = hotel.additionalPictures || []
    const images = [hotel.picture, ...additionalPictures]
    const {
        position,
        slideRefs,
        isSwiping,
        isDisabled,
        hasNextSlide,
        containerRef,
        swipingProps,
        handleNextSlide,
        hasPreviousSlide,
        handlePreviousSlide,
    } = useCarousel(images.length >= 2 ? 2 : 0)

    return (
        <>
            <h2 className="text-lg font-medium mb-6">
                <Trans id="Hotel" />
            </h2>
            <div className="relative w-full h-48 bg-gray-100 rounded overflow-hidden">
                <SliderContainer
                    position={position}
                    isDisabled={isDisabled}
                    className="w-full h-full"
                    containerRef={containerRef}
                    swipingProps={swipingProps}
                >
                    {images.map((image, index) => (
                        <Slide
                            key={image}
                            isSwiping={isSwiping}
                            className="relative w-full h-full"
                            ref={(ref) => { slideRefs.current[index] = ref }}
                        >
                            <img
                                src={image}
                                alt={hotel.name}
                                draggable={false}
                                className="absolute inset-0 object-cover h-full w-full rounded"
                            />
                        </Slide>
                    ))}
                </SliderContainer>
                <CarouselControls
                    size="small"
                    isDisabled={isDisabled}
                    hasNextSlide={hasNextSlide}
                    onNextSlide={handleNextSlide}
                    hasPreviousSlide={hasPreviousSlide}
                    onPreviousSlide={handlePreviousSlide}
                />
            </div>
            <div className="flex items-start justify-between mt-4">
                <p className="text-lg font-medium">
                    {hotel.name}
                </p>
                <Stars
                    total={stars}
                    className="pt-1"
                />
            </div>
            <p className="text-sm text-gray-800 mt-1">
                {roomName}
            </p>
            <BookingGuestAmounts
                className="mt-2"
                adults={numberOfAdults}
                numberOfRooms={numberOfRooms}
                numberOfChildren={numberOfChildren}
            />
            <div className="grid grid-cols-2 mt-3 text-sm">
                <div>
                    <p className="font-bold">
                        <Trans id="Check in:" />
                    </p>
                    <p className="text-gray-800 mt-1">
                        <Date
                            dateString={startDate}
                            format="eee d MMM yyyy"
                        />
                    </p>
                </div>
                <div>
                    <p className="font-bold">
                        <Trans id="Check out:" />
                    </p>
                    <p className="text-gray-800 mt-1">
                        <Date
                            dateString={endDate}
                            format="eee d MMM yyyy"
                        />
                    </p>
                </div>
            </div>
        </>
    )
}

export default BookDynamicTemplateModalHotel
