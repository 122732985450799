/* eslint-disable prefer-destructuring */
import React from 'react'
import { Trans } from '@lingui/react'
import Modal from '../../utils/Modal'
import ModalCloseButton from '../../utils/ModalCloseButton'
import BookTemplateModalTourButtonCard from './BookTemplateModalTourButtonCard'

const BookTemplateModalToursModal = ({
    tours,
    onClose,
    onChooseTour,
    selectedTour,
    numberOfTravelers,
}) => (
    <Modal
        onClose={onClose}
        className="px-4 py-10 w-full min-h-full lg:w-[700px] lg:min-h-[600px] lg:mt-24 lg:px-10"
    >
        <ModalCloseButton onClick={onClose} />
        <h1 className="text-lg font-medium">
            <Trans id="Choose your experience" />
        </h1>
        {tours.sort((a, b) => a.price - b.price).map(({
            name,
            price,
            productId,
        }) => {
            const priceDifference = price - selectedTour.price
            const isSelected = productId === selectedTour.productId
            return (
                <BookTemplateModalTourButtonCard
                    name={name}
                    key={productId}
                    value={isSelected}
                    priceDifference={priceDifference}
                    numberOfTravelers={numberOfTravelers}
                    onChange={() => onChooseTour(productId)}
                />
            )
        })}
    </Modal>
)

export default BookTemplateModalToursModal
