import React from 'react'
import Page from '../../layout/Page'
import PageMain from '../../layout/PageMain'
import StickyHeader from '../../layout/StickyHeader'
import HeaderWithContent from '../../layout/HeaderWithContent'
import FooterWithContent from '../../layout/FooterWithContent'
import Heading from '../../content/Heading'
import DynamicSection from '../../content/DynamicSection'
import ActivityPageSubHeader from './ActivityPageSubHeader'

const ActivityPage = ({
    name,
    title,
    price,
    headImage,
    headVideo,
    body = [],
    description,
    headingOptions = [{}],
}) => (
    <Page data-testid="ActivityPage">
        <StickyHeader>
            <HeaderWithContent />
            <ActivityPageSubHeader name={name} />
        </StickyHeader>
        <PageMain>
            <Heading
                price={price}
                title={title}
                image={headImage}
                video={headVideo}
                description={description}
                {...headingOptions[0]}
            />
            {body && body.map((content) => (
                <DynamicSection
                    key={content._uid}
                    content={content}
                />
            ))}
        </PageMain>
        <FooterWithContent />
    </Page>
)

export default ActivityPage
