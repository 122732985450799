import React, { useState } from 'react'
import clsx from 'clsx'
import SbEditable from 'storyblok-react'
import { useAsyncEffect } from '@wappla/react-hooks'
import * as constants from '@connections/constants'
import { sectionSpacingVariant, sectionContainerVariant, sectionBackgroundVariant } from '../../util/variants'
import usePathnameLocale from '../hooks/usePathnameLocale'

const {
    LANGUAGE_EN: EN,
    LANGUAGE_FR_BE: FR_BE,
    DEFAULT_LANGUAGE_WEB: DEFAULT,
} = constants

const getBannerLocale = (locale) => {
    switch (locale) {
        // We don't have banners in English. Connections decided to use the French versions in this case.
        case EN:
        case FR_BE:
            return FR_BE
        default:
            return DEFAULT
    }
}

const BannerSection = ({
    content,
}) => {
    const locale = usePathnameLocale()
    const [hasBannerFile, setHasBannerFile] = useState(false)
    const {
        banner,
        spacing,
        container,
        spacingTop,
        background,
        spacingBottom,
    } = content
    const parsedLocale = getBannerLocale(locale)

    useAsyncEffect(async () => {
        const bannerFile = await fetch(`/banners/${banner}_${parsedLocale}/index.html`)
        setHasBannerFile(bannerFile.status === 200)
    }, [])

    return (
        <SbEditable content={content}>
            <section
                className={clsx(
                    sectionBackgroundVariant(background),
                    sectionSpacingVariant({ spacing, spacingTop, spacingBottom }),
                )}
            >
                <div className={clsx(sectionContainerVariant(container))}>
                    {hasBannerFile && (
                        <iframe
                            title="banner"
                            scrolling="no"
                            src={`/banners/${banner}_${parsedLocale}/index.html`}
                            className="w-full h-[200px] md:h-[250px] lg:h-[300px]"
                        />
                    )}
                </div>
            </section>
        </SbEditable>
    )
}

export default BannerSection
