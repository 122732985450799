import React from 'react'
import SbEditable from 'storyblok-react'
import BookTourButtonWithState from '../utils/BookTourButtonWithState'

const BookTourButtonWithContent = ({
    content,
}) => {
    const {
        tour: cmsTour = null,
        button: [button = null],
    } = content

    if (button === null) {
        return null
    }

    const {
        size,
        text,
        width,
        variant,
        iconAfterName,
        iconBeforeName,
    } = button

    return (
        <SbEditable content={content}>
            <BookTourButtonWithState
                size={size}
                text={text}
                width={width}
                variant={variant}
                cmsTour={cmsTour}
                iconAfterName={iconAfterName}
                iconBeforeName={iconBeforeName}
            />
        </SbEditable>
    )
}

export default BookTourButtonWithContent
