import React from 'react'
import clsx from 'clsx'
import * as constants from '@connections/constants'
import { variant } from '../../util/index'
import Video from '../utils/Video'

const {
    COLUMN_LEFT,
    COLUMN_RIGHT,
    IMAGE_SQUARE: SQUARE,
    IMAGE_STRETCH: STRETCH,
    IMAGE_PORTRAIT: PORTRAIT,
    IMAGE_LANDSCAPE: LANDSCAPE,
} = constants

const ContentVideo = ({
    video,
    position,
    size = SQUARE,
    autoPlay = true,
    muted = true,
    controls,
    loop = true,
}) => (
    <div
        className={clsx(
            'relative w-full',
            variant(size, {
                [SQUARE]: 'pt-[100%]',
                [PORTRAIT]: 'pt-[120%]',
                [LANDSCAPE]: 'pt-[50%]',
                [STRETCH]: 'pt-[50%] md:pt-0 md:h-full',
            }),
        )}
    >
        {video !== '' && video.filename !== '' && (
            <div
                className={clsx(
                    'absolute inset-0',
                    variant(position, {
                        [COLUMN_LEFT]: '-right-4 -left-4 lg:right-0 lg:-left-6 xl:left-0',
                        [COLUMN_RIGHT]: '-left-4 -right-4 lg:left-0 lg:-right-6 xl:right-0',
                    })
                )}
            >
                <Video
                    autoPlay={autoPlay}
                    muted={muted}
                    controls={controls}
                    loop={loop}
                    playsInline
                    src={video.filename}
                    className="absolute h-full w-full inset-0 object-cover"
                />
            </div>
        )}
    </div>
)

export default ContentVideo
