import React, { Fragment } from 'react'
import clsx from 'clsx'
import bearing from '@turf/bearing'
import { point } from '@turf/helpers'
import { motion } from 'framer-motion'
import SbEditable from 'storyblok-react'
import { Layer, Marker, Source } from 'react-map-gl'
import { getCurvedLine } from '../../util/map'
import Icon from '../utils/Icon'

const DayByDayMapSectionObjects = ({
    zoom,
    mapRef,
    markers,
    lastKey,
    selectedKey,
    onClickMarker,
    onMouseEnterMarker,
    onMouseLeaveMarker,
}) => {
    if (mapRef.current) {
        return markers.map((marker, index) => {
            const {
                key,
                latitude,
                longitude,
                isLayover,
            } = marker
            const id = key + latitude + longitude
            const isFirstMarker = index === 0
            const isLastMarker = index === markers.length - 1 || key === lastKey
            const isFirstOrLastMarker = isFirstMarker || isLastMarker
            // First we create from and to points
            const nextMarker = isLastMarker
                ? markers[0]
                : markers[index + 1]
            const from = point([longitude, latitude])
            const to = point([nextMarker.longitude, nextMarker.latitude])
            // From those points we create a curved line
            const line = getCurvedLine(from, to, zoom, mapRef.current)
            let lineStart
            let lineEnd
            if (line) {
                const { coordinates } = line.geometry;
                ([lineStart] = coordinates)
                lineEnd = coordinates[coordinates.length - 1]
            }
            const lineBearing = bearing(from, to)
            // We calculate which markers return on the same coordinates
            const markersWithSamePoints = markers.filter((markerCompare) => (
                marker.longitude === markerCompare.longitude
                                && marker.latitude === markerCompare.latitude
            ))
            const hasMorePoints = markersWithSamePoints.length > 1
            let offset = [0, 0]
            // We add an offset if markers have the same coordinates
            if (hasMorePoints) {
                const markerIndex = markersWithSamePoints.findIndex((markerCompare) => marker.key === markerCompare.key)
                const count = markersWithSamePoints.length - 1
                offset = [(markerIndex * 24) - (count * 12), 0]
            }
            return (
                <Fragment key={id}>
                    <Marker
                        anchor="center"
                        offset={offset}
                        latitude={latitude}
                        longitude={longitude}
                    >
                        <motion.div
                            exit={{ opacity: 0 }}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                        >
                            <SbEditable content={marker}>
                                <button
                                    type="button"
                                    onClick={(e) => onClickMarker(e, marker)}
                                    onMouseEnter={() => onMouseEnterMarker(marker)}
                                    onMouseLeave={() => onMouseLeaveMarker(marker)}
                                    className={clsx(
                                        'cursor-pointer transition-opacity',
                                        (selectedKey === key || selectedKey === null)
                                            ? 'opacity-100'
                                            : 'opacity-50'
                                    )}
                                >
                                    {isLayover ? (
                                        <div className="rounded-full bg-primary w-4 h-4" />
                                    ) : (
                                        <div className="relative">
                                            <span
                                                className={clsx(
                                                    'absolute left-0 text-white font-bold w-full text-center text-xs',
                                                    hasMorePoints ? 'top-[0.2rem]' : 'top-[0.4rem]'
                                                )}
                                            >
                                                {key}
                                            </span>
                                            <Icon
                                                name="location-point-filled"
                                                size={hasMorePoints ? 'default' : 'large'}
                                                color={isFirstOrLastMarker ? 'blue' : 'primary'}
                                            />
                                        </div>
                                    )}
                                </button>
                            </SbEditable>
                        </motion.div>
                    </Marker>
                    {/* Lines with arrows and dots */}
                    {line !== null && !isLastMarker && (
                        <>
                            <Source
                                type="geojson"
                                id={`start${id}`}
                                data={{
                                    type: 'FeatureCollection',
                                    features: [point(lineStart)],
                                }}
                            >
                                <Layer
                                    id={`point${id}`}
                                    type="circle"
                                    paint={{
                                        'circle-radius': 4,
                                        'circle-color': '#EC0B2D',
                                    }}
                                />
                            </Source>
                            <Source
                                type="geojson"
                                id={`polylineLayer${id}`}
                                data={{
                                    features: [line],
                                    type: 'FeatureCollection',
                                }}
                            >
                                <Layer
                                    type="line"
                                    id={`line${id}`}
                                    layout={{
                                        'line-cap': 'round',
                                        'line-join': 'round',
                                    }}
                                    paint={{
                                        'line-width': 2,
                                        'line-color': '#EC0B2D',
                                    }}
                                />
                            </Source>
                        </>
                    )}
                    {lineEnd && !isLastMarker && (
                        <Marker
                            latitude={lineEnd[1]}
                            longitude={lineEnd[0]}
                            rotation={lineBearing}
                            rotationAlignment="map"
                        >
                            <motion.div
                                exit={{ opacity: 0 }}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                            >
                                <Icon
                                    name="chevron-up"
                                    className="text-primary"
                                />
                            </motion.div>
                        </Marker>
                    )}
                </Fragment>
            )
        })
    }
    return null
}

export default DayByDayMapSectionObjects
