import React from 'react'
import { Trans } from '@lingui/react'
import Heading from '../../content/Heading'

const ExpertPageHeading = ({
    firstname,
    lastname,
    description,
    profileImage,
    ...props
}) => (
    <Heading
        subTitle={<Trans id="Travel Designer" />}
        description={description}
        title={`${firstname || ''} ${lastname || ''}`}
        image={profileImage}
        {...props}
    />
)

export default ExpertPageHeading
