import React from 'react'
import FlightsListerGroups from './FlightsListerGroups'
import FlightsListerSectionButtons from './FlightsListerSectionButtons'
import FlightsListerSectionHeading from './FlightsListerSectionHeading'
import FlightsListerAirlineCardGroup from './FlightsListerAirlineCardGroup'
import FlightsListerSectionContainer from './FlightsListerSectionContainer'
import FlightsListerDestinationCardGroup from './FlightsListerDestinationCardGroup'

export const AIRLINE = 'airline'
export const DESTINATION = 'destination'

const FlightsListerGroupSection = ({
    error,
    title,
    groups,
    loading,
    spacing,
    variant,
    buttons,
    anchorId,
    activeTab,
    container,
    background,
    activeGroup,
    description,
    onChangeTab,
}) => (
    <FlightsListerSectionContainer
        spacing={spacing}
        container={container}
        background={background}
        id={anchorId || undefined}
        data-testid="FlightsListerGroupSection"
    >
        <FlightsListerSectionHeading
            title={title}
            background={background}
            description={description}
        />
        <FlightsListerGroups
            groups={groups}
            activeTab={activeTab}
            onChange={onChangeTab}
            background={background}
            className="mb-6 sm:mb-10"
        />
        {(() => {
            if (activeGroup) {
                if (variant === AIRLINE) {
                    return (
                        <FlightsListerAirlineCardGroup
                            error={error}
                            className="mb-8"
                            loading={loading}
                            destinations={activeGroup.destinations}
                        />
                    )
                }
                if (variant === DESTINATION) {
                    return (
                        <FlightsListerDestinationCardGroup
                            error={error}
                            className="mb-8"
                            loading={loading}
                            destinations={activeGroup.destinations}
                        />
                    )
                }
            }
            return null
        })()}
        <FlightsListerSectionButtons buttons={buttons} />
    </FlightsListerSectionContainer>
)

export default FlightsListerGroupSection
