import React from 'react'
import clsx from 'clsx'
import SbEditable from 'storyblok-react'
import { motion } from 'framer-motion'
import { Trans } from '@lingui/react'
import { sectionBackgroundVariant, sectionSpacingVariant, sectionTextOnBackgroundVariant } from '../../util/variants'
import TestimonialsSectionSlide from './TestimonialsSectionSlide'
import CustomText from '../utils/CustomText'
import Image from '../utils/Image'

const TestimonialsSection = ({
    content,
    containerRef,
    galleryRef,
    x,
    onPanEnd,
    isAnimating,
    pageCount,
}) => {
    const {
        title,
        spacing,
        background,
        backgroundImage,
        testimonials,
        anchorId,
    } = content

    return (
        <SbEditable content={content}>
            <section
                id={anchorId || undefined}
                data-testid="TestimonialsSection"
                className={clsx(
                    'px-12 overflow-hidden relative z-1',
                    sectionSpacingVariant({ spacing }),
                    sectionBackgroundVariant(background),
                    sectionTextOnBackgroundVariant(background, 'text-black')
                )}
            >
                <div className="md:max-w-lg mx-auto text-center mb-8 md:mb-16">
                    <CustomText
                        as="h3"
                        customText={title}
                        className="text-4xl font-medium mb-4"
                        customTextClassName="font-accent text-[2.3rem]"
                    />
                </div>
                <motion.div
                    className={clsx(
                        'whitespace-nowrap cursor-move',
                        isAnimating && 'transition-transform',
                    )}
                    drag="x"
                    style={{ x }}
                    onPan={onPanEnd}
                    dragMomentum={false}
                    ref={containerRef}
                >
                    <div className="inline-block absolute right-full">
                        {pageCount > 0 && (
                            new Array(pageCount).fill('').map((item, index) => (
                                <TestimonialsSectionSlide
                                    key={index}
                                    testimonials={testimonials}
                                />
                            ))
                        )}
                    </div>
                    <TestimonialsSectionSlide
                        testimonials={testimonials}
                        ref={galleryRef}
                    />
                    <div className="inline-block">
                        {new Array(pageCount).fill('').map((item, index) => (
                            <TestimonialsSectionSlide
                                key={index}
                                testimonials={testimonials}
                            />
                        ))}
                    </div>
                </motion.div>
                {backgroundImage && (
                    <div className="absolute inset-0 -z-1">
                        <div className="absolute inset-0 opacity-20 bg-black" />
                        <Image
                            fill
                            src={backgroundImage}
                            className="opacity-10 object-cover"
                            alt={<Trans id="Testimonials background" />}
                        />
                    </div>
                )}
            </section>
        </SbEditable>
    )
}

export default TestimonialsSection
