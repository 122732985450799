import React from 'react'
import clsx from 'clsx'
import SbEditable from 'storyblok-react'
import { motion } from 'framer-motion'
import { variant } from '../../util/index'
import GallerySectionSlide from './GallerySectionSlide'

const GallerySection = ({
    content,
    containerRef,
    galleryRef,
    x,
    onPanEnd,
    isAnimating,
    pageCount,
}) => {
    const {
        spacing,
        background,
        galleryImages,
        anchorId,
    } = content

    return (
        <SbEditable content={content}>
            <section
                id={anchorId || undefined}
                data-testid="GallerySection"
                className={clsx(
                    'px-12 overflow-hidden',
                    variant(spacing, {
                        small: 'py-12',
                        medium: 'py-24',
                        large: 'py-36',
                    }),
                    variant(background, {
                        white: 'bg-white',
                        'gray-50': 'bg-gray-50',
                        'gray-100': 'bg-gray-100',
                        black: 'bg-black',
                        blue: 'bg-blue',
                    }),
                )}
            >
                <motion.div
                    className={clsx(
                        'whitespace-nowrap cursor-move',
                        isAnimating && 'transition-transform',
                    )}
                    drag="x"
                    style={{ x }}
                    onPan={onPanEnd}
                    dragMomentum={false}
                    ref={containerRef}
                >
                    <div className="inline-block absolute right-full">
                        {pageCount > 0 && (
                            new Array(pageCount).fill('').map((item, index) => (
                                <GallerySectionSlide
                                    key={index}
                                    images={galleryImages}
                                    background={background}
                                />
                            ))
                        )}
                    </div>
                    <GallerySectionSlide
                        images={galleryImages}
                        background={background}
                        ref={galleryRef}
                    />
                    <div className="inline-block">
                        {new Array(pageCount).fill('').map((item, index) => (
                            <GallerySectionSlide
                                key={index}
                                images={galleryImages}
                                background={background}
                            />
                        ))}
                    </div>
                </motion.div>
            </section>
        </SbEditable>
    )
}

export default GallerySection
