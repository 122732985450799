import { Trans } from '@lingui/react'
import React from 'react'

const SbLanguage = ({ language }) => {
    switch (language) {
        case 'ENGLISH':
            return <Trans id="English" />
        case 'FRENCH':
            return <Trans id="French" />
        case 'DUTCH':
            return <Trans id="Dutch" />
        default:
            return null
    }
}

export default SbLanguage
