import React, { useState } from 'react'
import { Trans } from '@lingui/react'
import Button, { VARIANT_BLUE_OUTLINED, VARIANT_BLUE_FILLED } from '../../utils/Button'
import BookDynamicTemplateRoomsModal from './BookDynamicTemplateRoomsModal'
import BookDynamicTemplateHotelsModal from './BookDynamicTemplateHotelsModal'
import BookDynamicTemplateFlightsModalWithState from './BookDynamicTemplateFlightsModalWithState'

const BookDynamicTemplateChoices = ({
    selectedFare,
    onChangeFare,
    selectedHotel,
    numberOfRooms,
    numberOfAdults,
    templatePricing,
    numberOfChildren,
    onChangeHotelRoom,
    selectedHotelRoomId,
}) => {
    const [isChoosingRoom, setIsChoosingRoom] = useState(false)
    const [exploreAlternativeFares, setExploreAlternativeFares] = useState(false)
    const [exploreAlternativeHotels, setExploreAlternativeHotels] = useState(false)

    const handleChangeFare = (fareId) => {
        onChangeFare(fareId)
        setExploreAlternativeFares(false)
    }
    const handleChangeHotelRoom = (roomId) => {
        onChangeHotelRoom(roomId)
        setIsChoosingRoom(false)
        setExploreAlternativeHotels(false)
    }

    const numberOfTravelers = numberOfAdults + numberOfChildren
    const { alternativeFares, alternativeHotels } = templatePricing
    alternativeFares.sort((a, b) => a.charge.total - b.charge.total)
    const isAvailable = ({ id, availabilityStatus }) => id !== selectedHotelRoomId && availabilityStatus === 'Available'
    const hasOtherAvailableRooms = selectedHotel.priceLevels.some(isAvailable)
    const hasOtherAvailableHotels = alternativeHotels.some((alternativeHotel) => alternativeHotel.name !== selectedHotel.name && alternativeHotel.priceLevels.some(isAvailable))
    return (
        <>
            <div className="w-full flex flex-col md:flex-row md:justify-between">
                <div className="md:w-1/2 grid grid-cols-2 gap-x-4 md:pl-6">
                    {alternativeFares.length > 0 ? (
                        <Button
                            variant={VARIANT_BLUE_OUTLINED}
                            onClick={() => setExploreAlternativeFares(true)}
                            className="col-span-2 md:col-span-1 mt-6 md:mt-0"
                            data-testid="BookDynamicTemplateModalFlightsChangeFlightButton"
                        >
                            <Trans id="Change flight" />
                        </Button>
                    ) : null}
                </div>
                <div className="md:w-1/2 grid grid-cols-2 gap-x-4 md:pl-10">
                    {hasOtherAvailableRooms ? (
                        <Button
                            variant={VARIANT_BLUE_FILLED}
                            onClick={() => setIsChoosingRoom(true)}
                            className="col-span-2 md:col-span-1 mt-6 md:mt-0"
                        >
                            <Trans id="Change room" />
                        </Button>
                    ) : null}
                    {hasOtherAvailableHotels ? (
                        <Button
                            variant={VARIANT_BLUE_OUTLINED}
                            onClick={() => setExploreAlternativeHotels(true)}
                            className="col-span-2 md:col-span-1 mt-6 md:mt-0"
                            data-testid="BookDynamicTemplateModalHotelsChangeHotelButton"
                        >
                            <Trans id="Change hotel" />
                        </Button>
                    ) : null}
                </div>
            </div>
            {exploreAlternativeFares ? (
                <BookDynamicTemplateFlightsModalWithState
                    fares={alternativeFares}
                    selectedFare={selectedFare}
                    onChooseFare={handleChangeFare}
                    numberOfTravelers={numberOfTravelers}
                    onClose={() => setExploreAlternativeFares(false)}
                />
            ) : null}
            {isChoosingRoom ? (
                <BookDynamicTemplateRoomsModal
                    hotelName={selectedHotel.name}
                    rooms={selectedHotel.priceLevels}
                    numberOfTravelers={numberOfTravelers}
                    onClose={() => setIsChoosingRoom(false)}
                    onChangeHotelRoom={handleChangeHotelRoom}
                    selectedHotelProductId={selectedHotelRoomId}
                />
            ) : null}
            {exploreAlternativeHotels ? (
                <BookDynamicTemplateHotelsModal
                    hotels={alternativeHotels}
                    numberOfRooms={numberOfRooms}
                    selectedHotel={selectedHotel}
                    numberOfTravelers={numberOfTravelers}
                    onChangeHotelRoom={handleChangeHotelRoom}
                    selectedHotelProductId={selectedHotelRoomId}
                    onClose={() => setExploreAlternativeHotels(false)}
                />
            ) : null}
        </>
    )
}

export default BookDynamicTemplateChoices
