import React from 'react'
import clsx from 'clsx'
import { Trans } from '@lingui/react'
import Spinner from '../utils/Spinner'
import DestinationFlightsListerCard from './DestinationFlightsListerCard'

const DestinationFlightsListerGroup = ({
    error,
    loading,
    onSelect,
    className,
    flightPrices,
}) => {
    if (loading) {
        return (
            <div className={clsx('flex justify-center items-center', className)}>
                <Spinner color="gray" />
            </div>
        )
    }

    if (flightPrices.length === 0 || error) {
        return (
            <div className={clsx('flex justify-center items-center', className)}>
                <p className="text-sm md:text-base text-black opacity-70 md:text-center">
                    <Trans id="Unfortunately, there are no flights available for this group." />
                </p>
            </div>
        )
    }

    return (
        <div className={clsx('grid grid-cols-1 md:grid-cols-2 gap-4 xl:gap-8', className)}>
            {flightPrices.map((flightPrice) => (
                <DestinationFlightsListerCard
                    key={flightPrice.id}
                    flightPrice={flightPrice}
                    onSelect={() => onSelect(flightPrice)}
                />
            ))}
        </div>
    )
}

export default DestinationFlightsListerGroup
