import { useInfiniteQuery } from 'react-query'
import { getSimpleLocale } from '@connections/utils'
import usePathnameLocale from './usePathnameLocale'
import useStoryblokClient from './useStoryblokClient'

const useStoryblokInfiniteQuery = (key, url, params = {}, options = {}, method = 'get') => {
    const locale = usePathnameLocale()
    const finalParams = {
        language: getSimpleLocale(locale),
        ...params,
    }
    const storyblokClient = useStoryblokClient()
    return useInfiniteQuery(
        [key, finalParams],
        async ({ pageParam = 1 }) => {
            const data = await storyblokClient[method](url, { ...finalParams, page: pageParam })
            return { ...data, page: pageParam }
        },
        {
            ...options,
            getNextPageParam: (lastPage) => {
                const { page, perPage, total } = lastPage
                if (page * perPage >= total) {
                    return undefined
                }
                return lastPage.page + 1
            }
        },
    )
}

export default useStoryblokInfiniteQuery
