import React, { useState } from 'react'
import { BREAK_ENTITY } from '@connections/constants'
import useTemplateSettings from '../hooks/useTemplateSettings'
import BookTemplateModalWithState from '../pages/book-template/BookTemplateModalWithState'
import BookDynamicTemplateModalWithState from '../pages/book-dynamic-template/BookDynamicTemplateModalWithState'
import Button from './Button'

const BookBreakButtonWithState = ({
    text,
    size,
    width,
    variant,
    cmsBreak,
    iconAfterName,
    iconBeforeName,
}) => {
    const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false)
    const [isDynamicTemplateModalOpen, setIsDynamicTemplateModalOpen] = useState(false)
    const {
        content: {
            templateSettings,
            isDynamicTemplate,
            travelBoosterTemplateId,
        },
    } = cmsBreak
    const templateSettingsToUse = useTemplateSettings(templateSettings)

    const handleOpenModal = () => {
        if (travelBoosterTemplateId) {
            if (isDynamicTemplate) {
                setIsDynamicTemplateModalOpen(true)
            } else {
                setIsTemplateModalOpen(true)
            }
        }
    }

    let testId = 'BookBreakButtonWithState'
    if (travelBoosterTemplateId) {
        testId = isDynamicTemplate ? 'BookDynamicTemplateBreakButtonWithState' : 'BookTemplateBreakButtonWithState'
    }

    return (
        <>
            <Button
                size={size}
                width={width}
                variant={variant}
                data-testid={testId}
                onClick={handleOpenModal}
                iconAfterName={iconAfterName}
                iconBeforeName={iconBeforeName}
            >
                {text}
            </Button>
            {isTemplateModalOpen && (
                <BookTemplateModalWithState
                    content={cmsBreak}
                    type={BREAK_ENTITY}
                    templateSettings={templateSettingsToUse}
                    onClose={() => setIsTemplateModalOpen(false)}
                />
            )}
            {isDynamicTemplateModalOpen && (
                <BookDynamicTemplateModalWithState
                    content={cmsBreak}
                    type={BREAK_ENTITY}
                    templateSettings={templateSettingsToUse}
                    onClose={() => setIsDynamicTemplateModalOpen(false)}
                />
            )}
        </>
    )
}

export default BookBreakButtonWithState
