import React from 'react'
import { Trans } from '@lingui/react'
import useFormState, { createFormValidation } from 'use-form-state'
import { isRequired, isGreaterThan } from '@connections/utils'

const INITIAL_VALUES = {
    giftcards: [{ value: '', amount: 1 }],
}

const validation = createFormValidation([{
    path: 'giftcards.*.value',
    validate: isRequired,
    message: <Trans id="Enter a value" />,
}, {
    path: 'giftcards.*.value',
    validate: parseFloat,
    message: <Trans id="Enter a valid number" />,
}, {
    path: 'giftcards.*.value',
    validate: (value) => isGreaterThan(parseFloat(value))(0),
    message: <Trans id="Enter a value higher than 0" />,
}])

const valuesToInput = ({ giftcards }) => ({
    giftcards: giftcards.map(({ value, amount }) => ({
        value: parseFloat(value),
        amount: parseInt(amount, 10),
    }))
})

const useGiftcardProductFormState = () => {
    const formState = useFormState(INITIAL_VALUES, { validation, valuesToInput })
    return {
        ...formState,
        onAddGiftcard: () => {
            const { giftcards } = formState.values
            formState.handleChange('giftcards', [...giftcards, { value: '', amount: 1 }])
        },
        onRemoveGiftcard: (index) => {
            const { giftcards } = formState.values
            formState.handleChange('giftcards', giftcards.filter((_, i) => i !== index))
        },
    }
}

export default useGiftcardProductFormState
