import React from 'react'
import Icon from '../../utils/Icon'

const SearchPageResultsItem = ({
    href,
    title,
    description,
}) => (
    <a href={href} className="flex justify-between md:items-center bg-white border border-gray-100 hover:border-gray-200 rounded-lg pl-6 pr-3 py-6 md:pl-8 md:pr-8 md:py-8">
        <div className="w-16 h-16 bg-gray-100 rounded-full hidden md:flex justify-center items-center mr-5 flex-shrink-0 ">
            <Icon
                name="globe"
                color="blue-bright"
                size="large"
            />
        </div>
        <div className="flex-1">
            {title && (
                <h3 className="text-xl font-medium mb-1">{title}</h3>
            )}
            {description && (
                <p className="text-gray-600">{description}</p>
            )}
        </div>
        <div className="w-8 h-8 flex justify-center items-center flex-shrink-0 ">
            <Icon
                name="chevron-right"
                color="gray-light"
                size="large"
            />
        </div>
    </a>
)

export default SearchPageResultsItem
