import React from 'react'
import { Trans } from '@lingui/react'
import Date from '../../utils/Date'
import Icon from '../../utils/Icon'
import { getRouteLocationIcon } from '../../utils/TransportOverview'

const BookTemplateModalTransports = ({ transports, className }) => (
    <div className={className}>
        <h2 className="text-lg font-medium mb-4">
            <Trans
                values={{ amount: transports.length }}
                id="{amount, plural, =1 {Transfer} other {Transfers}}"
            />
        </h2>
        <div className="grid sm:grid-cols-2 gap-y-4">
            {transports.map(({
                startDate,
                toPlace,
                toPlaceType,
                fromPlace,
                fromPlaceType
            }) => (
                <div
                    key={`${startDate}/${fromPlace}/${toPlace}`}
                    className="col-span-2 sm:col-span-1"
                >
                    <Date
                        dateString={startDate}
                        format="eee d MMM yyyy"
                        className="text-sm font-medium uppercase"
                    />
                    <div className="mt-4 flex items-center">
                        <div className="flex items-center">
                            <Icon
                                size="small"
                                color="blue-bright"
                                name={getRouteLocationIcon(fromPlaceType, 'departure')}
                            />
                            <p className="text-sm text-gray-800 ml-2">
                                {fromPlaceType === 'Hotel'
                                    ? (
                                        <Trans
                                            id="Hotel in {city}"
                                            values={{ city: fromPlace }}
                                        />
                                    )
                                    : fromPlace}
                            </p>
                        </div>
                        <Icon
                            size="small"
                            name="arrow-right"
                            className="mx-2"
                        />
                        <div className="flex items-center">
                            <Icon
                                size="small"
                                color="blue-bright"
                                name={getRouteLocationIcon(toPlaceType, 'arrival')}
                            />
                            <p className="text-sm text-gray-800 ml-2">
                                {toPlaceType === 'Hotel'
                                    ? (
                                        <Trans
                                            id="Hotel in {city}"
                                            values={{ city: toPlace }}
                                        />
                                    )
                                    : toPlace}
                            </p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    </div>
)

export default BookTemplateModalTransports
