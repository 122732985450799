import React from 'react'
import clsx from 'clsx'
import { Trans } from '@lingui/react'
import { formatPriceWithoutSymbol } from '../../util/prices'
import Date from './Date'
import BookingGuestAmounts from './BookingGuestAmounts'

const BookModalBanner = ({
    name,
    price,
    action,
    endDate,
    className,
    startDate,
    listOfAdults,
    numberOfRooms,
    listOfChildren,
    absoluteEndDate,
}) => {
    const numberOfAdults = listOfAdults.length
    const numberOfChildren = listOfChildren.length
    return (
        <>
            <div
                className={clsx(
                    'lg:flex justify-between items-start',
                    className,
                )}
            >
                <div>
                    <h2 className="text-xl font-medium">
                        {name}
                    </h2>
                    {price ? (
                        <div className="lg:flex text-xs font-medium text-gray-400 mt-2 lg:mt-0">
                            <BookingGuestAmounts
                                adults={listOfAdults.length}
                                numberOfRooms={numberOfRooms}
                                numberOfChildren={listOfChildren.length}
                            />
                            <p className="lg:ml-8">
                                <Date
                                    format="dd MMM yyyy"
                                    className="uppercase"
                                    dateString={startDate}
                                />
                                {' - '}
                                <Date
                                    format="dd MMM yyyy"
                                    className="uppercase"
                                    dateString={absoluteEndDate || endDate}
                                />
                            </p>
                        </div>
                    ) : null}
                </div>
                {price ? (
                    <div className="flex mt-4 lg:mt-0">
                        <div className="flex flex-col lg:items-end border-r border-gray-100 pr-4 mr-4">
                            <p>
                                &euro;
                                <span className="text-xl font-medium">
                                    {formatPriceWithoutSymbol(price / (numberOfAdults + numberOfChildren))}
                                </span>
                            </p>
                            <p className="text-xs text-gray-600">
                                <Trans id="Per person" />
                            </p>
                        </div>
                        <div className="flex flex-col lg:items-end mr-6">
                            <p className="text-primary">
                                &euro;
                                <span className="text-xl font-medium">
                                    {formatPriceWithoutSymbol(price)}
                                </span>
                            </p>
                            <p className="text-xs text-gray-600">
                                <Trans id="Total price" />
                            </p>
                        </div>
                        {action && (
                            <div className="hidden lg:block ml-6">
                                {action}
                            </div>
                        )}
                    </div>
                ) : null}
            </div>
            {action ? (
                <div className="mt-6 lg:hidden">
                    {action}
                </div>
            ) : null}
        </>
    )
}

export default BookModalBanner
