import React from 'react'
import clsx from 'clsx'
import { variant } from '../../util/index'

const FlightsListerSectionHeading = ({
    title,
    className,
    background,
    description,
}) => (
    <div
        className={clsx(
            'mb-6',
            variant(background, {
                blue: 'text-white',
                black: 'text-white',
                white: 'text-black',
                'gray-50': 'text-black',
                'gray-100': 'text-black',
            }),
            className,
        )}
    >
        <h3 className="text-xl font-medium">
            {title.replace(/<[^>]*>?/gm, '').replace('{', '').replace('}', '')}
        </h3>
        {description && (
            <p className="text-gray-600 mt-0.5">
                {description}
            </p>
        )}
    </div>
)

export default FlightsListerSectionHeading
