import useStoryblokQuery from '../../hooks/useStoryblokQuery'
import * as constants from '@connections/constants'

const {
    EXPERT_ENTITY,
} = constants

const useExpertSearchPageQuery = (
    filters = {},
    { perPage = 4, page = 1 } = {},
) => {
    const filter = {}
    if (filters.continentCode) {
        filter.continentCodes = { in_array: filters.continentCode }
    }
    if (filters.countryCode) {
        filter.countryCodes = { in_array: filters.countryCode }
    }
    if (filters.iataCode) {
        filter.iataCodes = { in_array: filters.iataCode }
    }
    const params = {
        page,
        per_page: perPage,
        filter_query: filter,
        content_type: EXPERT_ENTITY,
        excluding_slugs: '_test/*,preview/*,*/hidden/*',
    }
    const {
        data,
        error,
        isLoading: isFetching,
    } = useStoryblokQuery('expert-search', 'cdn/stories/', params)

    let searchResults = []
    if (isFetching) {
        return {
            isFetching,
            searchResults,
        }
    }

    if (error) {
        return {
            error,
            isFetching,
            searchResults,
        }
    }

    const {
        total,
        data: {
            stories,
        },
    } = data
    searchResults = stories

    return {
        total,
        perPage,
        isFetching,
        searchResults,
    }
}

export default useExpertSearchPageQuery
