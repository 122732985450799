import React from 'react'
import InfiniteScroll from '../../utils/InfiniteScroll'

const SearchPageResults = ({
    query,
    children,
}) => {
    const {
        total,
        hasMore,
        handleFetchMore,
        isFetchingMore,
        loadMoreOnPageEnd,
        handleLoadMore,
    } = query
    if (total === 0) {
        return null
    }
    return (
        <InfiniteScroll
            hasMore={hasMore}
            onFetchMore={handleFetchMore}
            isFetchingMore={isFetchingMore}
            loadMoreOnPageEnd={loadMoreOnPageEnd}
            onLoadMore={handleLoadMore}
        >
            <section className="container">
                {children}
            </section>
        </InfiniteScroll>
    )
}

export default SearchPageResults
