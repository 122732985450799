import React from 'react'
import clsx from 'clsx'
import { motion, AnimatePresence } from 'framer-motion'
import * as constants from '@connections/constants'
import CarouselDots from '../utils/CarouselDots'
import HeadingMobile from './HeadingMobileWithState'
import { variant } from '../../util'

const {
    SIZE_SMALL: SMALL,
    SIZE_MEDIUM: MEDIUM,
    SIZE_DEFAULT: DEFAULT,
    SIZE_LARGE: LARGE,
    SIZE_FULL: FULL,
} = constants

const HeadingSectionMobile = ({
    content,
    activeHeading,
    onChangeHeading,
}) => {
    const {
        size,
        hasArc,
        hasNoQsm,
        headings,
    } = content
    const heading = headings[activeHeading]
    const isSlideShow = headings.length > 1

    // Avoid crash when adding a new empty heading in the cms
    if (typeof heading === 'undefined') {
        return null
    }

    return (
        <section className="relative md:hidden">
            <div
                className={clsx(
                    'relative w-full flex flex-col',
                    variant(size, {
                        [SMALL]: 'h-[33vh] max-h-[15rem]',
                        [MEDIUM]: 'h-[50vh] max-h-[30rem]',
                        [LARGE]: 'min-h-[75vh]',
                        [FULL]: 'min-h-[100vh]',
                        [DEFAULT]: hasNoQsm ? 'min-h-[50vh]' : 'min-h-[75vh]',
                    })
                )}
            >
                <AnimatePresence initial={false}>
                    <motion.div
                        key={activeHeading}
                        className="w-full flex-1 flex h-full"
                        initial={{ opacity: 0, position: 'absolute' }}
                        animate={{ opacity: 1, position: 'relative' }}
                        exit={{ opacity: 0, position: 'absolute' }}
                    >
                        <HeadingMobile
                            key={heading._uid}
                            content={heading}
                            hasArc={hasArc}
                            hasNoQsm={hasNoQsm}
                            isSlideShow={isSlideShow}
                            size={size}
                        />
                    </motion.div>
                </AnimatePresence>
            </div>
            {isSlideShow && (
                <div className="absolute top-0 left-1/2 transform -translate-x-1/2 z-1">
                    <CarouselDots
                        count={headings.length}
                        activeIndex={activeHeading}
                        onChangeIndex={onChangeHeading}
                        dotClassName="transform -rotate-90"
                    />
                </div>
            )}
        </section>
    )
}

export default HeadingSectionMobile
