import React from 'react'
import { ReCaptchaProvider } from 'next-recaptcha-v3'
import JotformSectionWithState from './JotformSectionWithState'

const JotformSectionWithProvider = ({
    ...props
}) => (
    <ReCaptchaProvider
        reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
    >
        <JotformSectionWithState {...props} />
    </ReCaptchaProvider>
)

export default JotformSectionWithProvider
