import clsx from 'clsx'
import React from 'react'
import {
    ALIGN_TOP_LEFT, SIZE_DEFAULT, SIZE_LARGE, SIZE_SMALL
} from '@connections/constants'
import { variant } from '../../util'
import HeadingDesktop from './HeadingDesktop'
import HeadingMobile from './HeadingMobile'

const Heading = ({
    size = SIZE_DEFAULT,
    position = ALIGN_TOP_LEFT,
    hasNoQsm = true,
    subTitle,
    description,
    ...props
}) => (
    <>
        <section className={clsx(
            'hidden md:block relative w-full',
            variant(size, {
                [SIZE_SMALL]: 'h-[50vh]',
                [SIZE_DEFAULT]: 'h-[75vh]',
                [SIZE_LARGE]: 'h-[85vh]',
            })
        )}
        >
            <HeadingDesktop
                size={size}
                position={position}
                subTitle={subTitle}
                description={description}
                {...props}
            />
        </section>
        <section className="flex flex-col md:hidden relative w-full">
            <HeadingMobile
                size={size}
                position={position}
                subTitle={subTitle}
                description={description}
                hasNoQsm={hasNoQsm}
                {...props}
            />
        </section>
    </>
)

export default Heading
