import React, { useRef } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import SbEditable from 'storyblok-react'
import DayItemTabGroupItem from './DayItemTabGroupItem'
import DayItemTabGroupTab from './DayItemTabGroupTab'
import RichTextFieldWithComponents from './RichTextFieldWithComponents'

const DayItemTabGroup = ({
    content,
    activeTabGroupId = 0,
    onTabGroupClick,
}) => {
    const activeTabGroup = content[activeTabGroupId] || {}
    const { dayItemTabGroupItems = [], description } = activeTabGroup
    const contentRef = useRef()
    const contentHeight = (contentRef.current && contentRef.current.clientHeight) || 0

    return (
        <SbEditable content={content}>
            <div className="relative md:mx-4">
                <div className="overflow-auto whitespace-nowrap px-4 relative z-1 md:px-0">
                    <div className="md:flex md:flex-wrap">
                        {content.map((tabGroup, index) => (
                            <DayItemTabGroupTab
                                key={tabGroup._uid}
                                content={tabGroup}
                                isActive={activeTabGroupId === index}
                                onClick={() => onTabGroupClick(index)}
                            />
                        ))}
                    </div>
                </div>
                <div className="w-10 h-full top-0 bg-gradient-to-l from-gray-50 absolute right-0 bottom-2 z-1 pointer-events-none md:hidden" />
                <div className="border-b-2 border-gray-100 absolute bottom-0 w-full md:border-b" />
            </div>
            <div ref={contentRef}>
                <AnimatePresence initial={false}>
                    <motion.div
                        key={activeTabGroupId}
                        initial={{ opacity: 0, height: contentHeight, position: 'absolute' }}
                        animate={{ opacity: 1, height: 'auto', position: 'relative' }}
                        exit={{ opacity: 0, height: contentHeight, position: 'absolute' }}
                        transition={{ ease: 'easeOut' }}
                    >
                        {description && (
                            <RichTextFieldWithComponents
                                content={description}
                                className="text-black pt-8 px-4 md:px-8"
                            />
                        )}
                        {dayItemTabGroupItems.map((item) => (
                            <DayItemTabGroupItem
                                key={item._uid}
                                content={item}
                            />
                        ))}
                    </motion.div>
                </AnimatePresence>
            </div>
        </SbEditable>
    )
}

export default DayItemTabGroup
