import React from 'react'
import clsx from 'clsx'
import { Trans } from '@lingui/react'
import Spinner from '../utils/Spinner'
import FlightsListerAirlineCard from './FlightsListerAirlineCard'

const FlightsListerAirlineCardGroup = ({
    error,
    loading,
    destinations,
    className,
}) => {
    if (loading) {
        return (
            <div className={clsx('flex justify-center items-center', className)}>
                <Spinner color="gray" />
            </div>
        )
    }

    if (destinations.length === 0 || error) {
        return (
            <div
                className={clsx('flex justify-center items-center', className)}
            >
                <p className="text-sm md:text-base text-black opacity-70 md:text-center">
                    <Trans id="Unfortunately, there are no flights available for this group." />
                </p>
            </div>
        )
    }

    return (
        <div
            className={clsx('grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-8', className)}
        >
            {destinations.map((destination) => (
                <FlightsListerAirlineCard
                    key={destination.uuid}
                    destination={destination}
                />
            ))}
        </div>
    )
}

export default FlightsListerAirlineCardGroup
