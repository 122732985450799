import React from 'react'
import SbEditable from 'storyblok-react'
import clsx from 'clsx'
import * as constants from '@connections/constants'
import { variant } from '../../util/index'
import BulletItemWithState from '../utils/BulletItemWithState'
import BulletItemContent from './BulletItemContent'

const {
    BACKGROUND_BLUE: BLUE,
    BACKGROUND_BLACK: BLACK,
} = constants

const BulletList = ({
    list = [],
    background,
}) => (
    <ul
        className={clsx(
            'mb-8 space-y-4',
            variant(background, {
                [BLUE]: 'text-white',
                [BLACK]: 'text-white',
            }),
        )}
    >
        {list.map((content) => {
            const {
                icon,
                label,
                text,
                link,
                tags,
                tagVariant,
            } = content
            return (
                <SbEditable content={content} key={text}>
                    <BulletItemWithState
                        icon={icon}
                        label={label}
                        background={background}
                    >
                        <BulletItemContent
                            link={link}
                            text={text}
                            tags={tags}
                            tagVariant={tagVariant}
                        />
                    </BulletItemWithState>
                </SbEditable>
            )
        })}
    </ul>
)

export default BulletList
