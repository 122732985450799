import React, { useState } from 'react'
import { TOUR_ENTITY } from '@connections/constants'
import useTemplateSettings from '../hooks/useTemplateSettings'
import BookTemplateModalWithState from '../pages/book-template/BookTemplateModalWithState'
import Button from './Button'

const BookTourButtonWithState = ({
    text,
    size,
    width,
    variant,
    cmsTour,
    iconAfterName,
    iconBeforeName,
}) => {
    const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false)
    const {
        content: {
            templateSettings,
        },
    } = cmsTour
    const templateSettingsToUse = useTemplateSettings(templateSettings)

    return (
        <>
            <Button
                size={size}
                width={width}
                variant={variant}
                iconAfterName={iconAfterName}
                iconBeforeName={iconBeforeName}
                onClick={() => setIsTemplateModalOpen(true)}
            >
                {text}
            </Button>
            {isTemplateModalOpen && (
                <BookTemplateModalWithState
                    content={cmsTour}
                    type={TOUR_ENTITY}
                    templateSettings={templateSettingsToUse}
                    onClose={() => setIsTemplateModalOpen(false)}
                />
            )}
        </>
    )
}

export default BookTourButtonWithState
