import React from 'react'
import { Trans } from '@lingui/react'
import Modal from '../../utils/Modal'
import ModalCloseButton from '../../utils/ModalCloseButton'
import BookDynamicTemplateHotelsModalHotelCard from './BookDynamicTemplateHotelsModalHotelCard'

const BookDynamicTemplateHotelsModal = ({
    hotels,
    onClose,
    numberOfRooms,
    selectedHotel,
    numberOfTravelers,
    onChangeHotelRoom,
    selectedHotelProductId,
}) => (
    <Modal
        onClose={onClose}
        data-testid="BookDynamicTemplateHotelsModal"
        className="px-4 py-10 w-full self-center lg:w-[1000px] min-h-screen lg:min-h-0 lg:px-10"
    >
        <ModalCloseButton onClick={onClose} />
        <h1 className="text-lg font-medium mb-4 lg:mb-6">
            <Trans id="Change hotel" />
        </h1>
        <div className="grid gap-y-4">
            {hotels.map((hotel) => (
                <BookDynamicTemplateHotelsModalHotelCard
                    hotel={hotel}
                    key={hotel.id}
                    numberOfRooms={numberOfRooms}
                    selectedHotel={selectedHotel}
                    numberOfTravelers={numberOfTravelers}
                    onChangeHotelRoom={onChangeHotelRoom}
                    selectedHotelProductId={selectedHotelProductId}
                />
            ))}
        </div>
    </Modal>
)

export default BookDynamicTemplateHotelsModal
