import { useState } from 'react'
import { useAsyncEffect } from '@wappla/react-hooks'
import useCloseJourneyMutation from '../utils/useCloseJourneyMutation'
import useCreateJourneyMutation from '../utils/useCreateJourneyMutation'
import useNotifications from './useNotifications'

const useJourney = () => {
    const { dispatchError } = useNotifications()
    const [error, setError] = useState(null)
    const [journeyUid, setJourneyUid] = useState(null)
    const { mutate: closeJourney } = useCloseJourneyMutation()
    const { mutateAsync: createJourney } = useCreateJourneyMutation()

    useAsyncEffect(async () => {
        try {
            const data = await createJourney()
            setJourneyUid(data.createJourney.journey.uid)
        } catch (e) {
            setError(e)
            dispatchError(e.message)
        }
    }, [])

    return {
        error,
        journeyUid,
        closeJourney: () => {
            if (journeyUid) {
                closeJourney({ uid: journeyUid })
            }
        }
    }
}

export default useJourney
