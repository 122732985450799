import React from 'react'
import {
    format,
    addDays,
    isAfter,
    subDays,
    isBefore,
} from 'date-fns'
import clsx from 'clsx'
import { Trans } from '@lingui/react'
import { Listbox } from '@headlessui/react'
import { dateToTbDateString } from '@connections/utils'
import Icon from '../../utils/Icon'
import FormGroup from '../../utils/FormGroup'
import BookDynamicTemplateDateRangeCalendar from '../book-dynamic-template/BookDynamicTemplateDateRangeCalendar'
import BookTemplateGuestsSelect from './BookTemplateGuestsSelect'

function getCalendarRangeStart(firstPossibleDepartureDateString) {
    const now = new Date()
    if (firstPossibleDepartureDateString) {
        const firstPossibleDepartureDate = new Date(firstPossibleDepartureDateString)
        if (firstPossibleDepartureDate > now) {
            return firstPossibleDepartureDate
        }
    }

    return now
}

const BookTemplateModalInputs = ({
    maxChildAge,
    listOfAdults,
    numberOfRooms,
    onChangeDates,
    listOfChildren,
    availableDates,
    hasValidInputs,
    absoluteEndDate,
    currentProductId,
    onCloseGuestSelect,
    hasChosenTravelers,
    lastPossibleReturnDate,
    isFreeDateChoiceTemplate,
    endDate: selectedEndDate,
    firstPossibleDepartureDate,
    startDate: selectedStartDate,
}) => (
    <>
        {hasValidInputs ? null : (
            <p className="text-xs uppercase text-gray-400 font-medium">
                <Trans id="Please select your travel dates and the amount of travellers" />
            </p>
        )}
        <div className="flex flex-col lg:flex-row gap-4 lg:gap-0 mt-6 lg:mt-8 lg:align-center">
            <FormGroup
                isRequired
                className="flex-1"
                htmlFor="datesSelect"
                label={<Trans id="Dates" />}
            >
                {isFreeDateChoiceTemplate ? (
                    <BookDynamicTemplateDateRangeCalendar
                        labelTextLastSelected={<Trans id="Return" />}
                        labelTextFirstSelected={<Trans id="Departure" />}
                        returnDate={selectedEndDate && new Date(selectedEndDate)}
                        departureDate={selectedStartDate && new Date(selectedStartDate)}
                        rangeStartDate={getCalendarRangeStart(firstPossibleDepartureDate)}
                        dayIsDisabledCondition={(day) => {
                            const isBeforeTomorrow = isBefore(day, addDays(new Date(), 1))
                            const isAfterLastPossibleReturnDate = lastPossibleReturnDate && isAfter(day, new Date(lastPossibleReturnDate))
                            const isBeforeFirstPossibleDepartureDate = firstPossibleDepartureDate && isBefore(day, subDays(new Date(firstPossibleDepartureDate), 1))

                            return isBeforeTomorrow || isAfterLastPossibleReturnDate || isBeforeFirstPossibleDepartureDate
                        }}
                        onChange={(startDate, endDate) => onChangeDates([
                            currentProductId,
                            dateToTbDateString(startDate),
                            dateToTbDateString(endDate),
                        ])}
                    />
                ) : (
                    <Listbox
                        id="datesSelect"
                        className="mb-6 lg:mb-0"
                        onChange={(value) => onChangeDates(value.split('#'))}
                        value={selectedStartDate !== null ? `${currentProductId}#${selectedStartDate}#${selectedEndDate}` : ''}
                    >
                        {({ open }) => (
                            <div className="relative mt-1">
                                <Listbox.Button className="relative w-full cursor-default border-gray-100 hover:border-gray-300 border py-3 pl-4 pr-8 leading-[22px] rounded text-sm">
                                    <span
                                        className={clsx(
                                            'block text-left',
                                            selectedStartDate === null ? 'text-gray-400' : 'text-gray-900'
                                        )}
                                    >
                                        {selectedStartDate === null
                                            ? <Trans id="Choose your travel dates" />
                                            : `${format(new Date(selectedStartDate), 'dd/MM/yyyy')} - ${format(new Date((absoluteEndDate) || selectedEndDate), 'dd/MM/yyyy')}`}
                                    </span>
                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                        <Icon size="small" name="chevron-down" className="text-gray-400" aria-hidden="true" />
                                    </span>
                                </Listbox.Button>
                                {(open || selectedStartDate === null) && (
                                    <Listbox.Options static className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm z-popover">
                                        {availableDates?.map(({ startDate, endDate, productId }) => {
                                            const value = `${productId}#${startDate}#${endDate}`
                                            const isSelected = value === `${currentProductId}#${selectedStartDate}#${selectedEndDate}`
                                            return (
                                                <Listbox.Option
                                                    key={value}
                                                    value={value}
                                                    className={({ active }) => clsx(
                                                        'relative cursor-default select-none py-2 px-4',
                                                        active || isSelected ? 'bg-blue-bright text-white' : 'text-gray-900',
                                                    )}
                                                >
                                                    {format(new Date(startDate), 'dd/MM/yyyy')} - {format(new Date((isSelected && absoluteEndDate) || endDate), 'dd/MM/yyyy')}
                                                </Listbox.Option>
                                            )
                                        })}
                                    </Listbox.Options>
                                )}
                            </div>
                        )}
                    </Listbox>
                )}
            </FormGroup>
            <FormGroup
                isRequired
                label={<Trans id="Guests" />}
                className="relative lg:ml-8 flex-1"
            >
                <BookTemplateGuestsSelect
                    maxChildAge={maxChildAge}
                    listOfAdults={listOfAdults}
                    onClose={onCloseGuestSelect}
                    numberOfRooms={numberOfRooms}
                    listOfChildren={listOfChildren}
                    isOpen={selectedStartDate && selectedEndDate && !hasChosenTravelers}
                />
            </FormGroup>
        </div>
    </>
)

export default BookTemplateModalInputs
