import clsx from 'clsx'
import React from 'react'
import { variant } from '../../util'

const JotformHeading = ({
    item,
    className,
}) => {
    const {
        text,
        subHeader,
        textAlign,
        headerType,
    } = item
    return (
        <div
            className={clsx(
                'inline-flex flex-col col-span-2',
                variant(textAlign, {
                    AUTO: 'items-left',
                    LEFT: 'items-start',
                    CENTER: 'items-center',
                    RIGHT: 'items-end'
                }),
                className
            )}
        >
            {text && (
                <h3
                    className={clsx(
                        'font-medium',
                        variant(headerType, {
                            SMALL: 'text-xl',
                            DEFAULT: 'text-3xl',
                            LARGE: 'text-4xl mb-8',
                        }),
                        variant(textAlign, {
                            AUTO: 'text-left',
                            LEFT: 'text-left',
                            CENTER: 'text-center',
                            RIGHT: 'text-right'
                        }),
                    )}
                >
                    {text}
                </h3>
            )}
            {subHeader && (
                <p
                    className={clsx(
                        'text-gray-400',
                        variant(headerType, {
                            SMALL: 'text-sm',
                            DEFAULT: 'text-base mt-[2px]',
                            LARGE: 'text-lg mt-2 mb-6',
                        }),
                        variant(textAlign, {
                            AUTO: 'text-left',
                            LEFT: 'text-left',
                            CENTER: 'text-center',
                            RIGHT: 'text-right'
                        }),
                    )}
                >
                    {subHeader}
                </p>
            )}
        </div>
    )
}

export default JotformHeading
