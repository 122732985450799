import React from 'react'
import clsx from 'clsx'
import SbEditable from 'storyblok-react'
import Image from '../utils/Image'
import Column from '../utils/Column'
import GiftcardProductWithState from '../utils/GiftcardProductWithState'

const ProductColumn = ({
    content,
}) => {
    const { image, productType } = content
    return (
        <SbEditable content={content}>
            <Column
                position="none"
                className={clsx(
                    'relative flex items-center',
                    image && 'md:h-[36.5rem]',
                )}
            >
                {image && (
                    <div className="hidden md:block bg-gray-100 w-4/5 pt-[150%] md:pt-0 md:w-[26rem] md:h-[36.5rem] transform -rotate-1 absolute right-0">
                        <Image
                            fill
                            maxWidth={640}
                            alt={image.alt}
                            src={image.filename}
                            className="object-cover"
                        />
                    </div>
                )}
                <div className="w-full md:w-[455px] bg-white shadow-2xl z-1">
                    {(() => {
                        switch (productType) {
                            case 'giftcard':
                                return <GiftcardProductWithState />
                            default:
                                return null
                        }
                    })()}
                </div>
            </Column>
        </SbEditable>
    )
}

export default ProductColumn
