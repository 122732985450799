import React from 'react'
import clsx from 'clsx'
import SbEditable from 'storyblok-react'
import * as constants from '@connections/constants'
import { variant } from '../../util/index'
import RichTextFieldWithComponents from './RichTextFieldWithComponents'

const {
    CONTAINER_EXTRA_SMALL: EXTRA_SMALL,
    CONTAINER_SMALL: SMALL,
    CONTAINER_DEFAULT: DEFAULT,
} = constants

const RichTextSection = ({
    content,
    className,
}) => {
    const {
        text,
        background,
        container,
        spacing,
        anchorId,
    } = content
    return (
        <SbEditable content={content}>
            <section
                id={anchorId || undefined}
                data-testid="RichTextSection"
                className={clsx(
                    variant(background, {
                        white: 'bg-white',
                        'gray-50': 'bg-gray-50',
                        'gray-100': 'bg-gray-100',
                        black: 'bg-black',
                        blue: 'bg-blue',
                    }),
                    variant(spacing, {
                        small: 'py-12',
                        medium: 'py-24',
                        large: 'py-36',
                    }),
                )}
            >
                <div
                    className={clsx(
                        variant(container, {
                            [EXTRA_SMALL]: 'container-xs',
                            [SMALL]: 'container-sm',
                            [DEFAULT]: 'container',
                        }),
                        className
                    )}
                >
                    <RichTextFieldWithComponents content={text} />
                </div>
            </section>
        </SbEditable>
    )
}

export default RichTextSection
