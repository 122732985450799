import React from 'react'
import clsx from 'clsx'
import Button, { VARIANT_BLUE_FILLED } from './Button'
import { variant } from '../../util'

const JotformButton = ({
    item,
    isSubmitting,
}) => {
    const { text, buttonAlign } = item
    return (
        <div
            className={clsx(
                'inline-flex col-span-2',
                variant(buttonAlign, {
                    AUTO: 'justify-center',
                    LEFT: 'justify-start',
                    CENTER: 'justify-center',
                    RIGHT: 'justify-end'
                })
            )}
        >
            <Button
                type="submit"
                variant={VARIANT_BLUE_FILLED}
                disabled={isSubmitting}
            >
                {text}
            </Button>
        </div>
    )
}

export default JotformButton
