import { gql } from 'graphql-request'
import useGraphqlQuery from '../../hooks/useGraphqlQuery'
import BookTemplateModalActivity from '../book-template/BookTemplateModalActivity'
import BookDynamicTemplateHotelsModalHotelCard from './BookDynamicTemplateHotelsModalHotelCard'

const QUERY = gql`
    query templatePricing($id: ID!, $input: TemplatePricingSearchInput!) {
        templatePricing(id: $id, input: $input) {
            searchResultId
            templatePricing {
                id
                price
                tours {
                    name
                    price
                    endDate
                    picture
                    areaName
                    category
                    startDate
                    isAlternative
                }
                hotels {
                    name
                    price
                    endDate
                    picture
                    category
                    startDate
                    productId
                    isAlternative
                    priceLevelName
                    additionalPictures
                }
                flights {
                    id
                    baggageAllowed
                    platingCarrier
                    charge {
                        total
                    }
                    airRoutes {
                        id
                        airRouteType
                        segments {
                            id
                            carrier
                            flightTime
                            cabinClass
                            arrivalTime
                            departureTime
                            operatingFlightNumber
                            origin {
                                code
                                type
                            }
                            destination {
                                code
                                type
                            }
                        }
                    }
                }
                activities {
                    ...BookTemplateModalActivity
                }
                transports {
                    price
                    isAlternative
                    endDate
                    startDate
                    toPlace
                    toPlaceType
                    fromPlace
                    fromPlaceType
                }
                alternativeFares {
                    id
                    baggageAllowed
                    platingCarrier
                    charge {
                        total
                        adult {
                            sum
                            total
                            numberOfPersons
                            tax {
                                total
                            }
                        }
                        child {
                            sum
                            total
                            numberOfPersons
                            tax {
                                total
                            }
                        }
                        infant {
                            sum
                            total
                            numberOfPersons
                            tax {
                                total
                            }
                        }
                    }
                    airRoutes {
                        id
                        travelTime
                        airRouteType
                        segments {
                            id
                            carrier
                            flightTime
                            cabinClass
                            arrivalTime
                            departureTime
                            operatingCarrier
                            operatingFlightNumber
                            origin {
                                code
                                type
                            }
                            destination {
                                code
                                type
                            }
                        }
                    }
                }
                alternativeHotels {
                    ...BookDynamicTemplateHotelsModalHotelCard
                }
            }
        }
    }
    ${BookTemplateModalActivity.FRAGMENT}
    ${BookDynamicTemplateHotelsModalHotelCard.FRAGMENT}
`

const useBookDynamicTemplateQuery = ({
    id,
    adults,
    endDate,
    children,
    startDate,
    journeyUid,
    numberOfRooms,
    hasChosenTravelers,
}) => {
    const variables = {
        id,
        input: {
            adults,
            endDate,
            children,
            startDate,
            journeyUid,
            numberOfRooms,
        },
    }
    const options = {
        cacheTime: 0,
        enabled: !!id
            && endDate !== null
            && startDate !== null
            && adults.length > 0
            && !!hasChosenTravelers
    }
    const {
        data,
        error,
        isLoading: isFetching,
    } = useGraphqlQuery(['templatePricing', variables], QUERY, variables, options)
    const searchResult = data?.templatePricing
    return {
        error,
        isFetching,
        searchResultId: searchResult?.searchResultId,
        templatePricing: searchResult?.templatePricing,
    }
}

export default useBookDynamicTemplateQuery
