import React from 'react'
import clsx from 'clsx'
import {
    sectionBackgroundVariant,
    sectionSpacingVariant,
    sectionContainerVariant,
} from '../../util/variants'
import CardsSectionLink from './CardsSectionLink'
import CardSectionCards from './CardsSectionCards'
import CardsSectionCarousel from './CardsSectionCarousel'

const CardsSection = ({
    links = [],
    description,
    title,
    cards,
    spacing,
    spacingTop,
    spacingBottom,
    background,
    container,
    anchorId,
    columns,
    isCarousel,
    className,
}) => {
    const columnCount = parseInt(columns, 10)
    return (
        <section
            id={anchorId || undefined}
            data-testid="CardsSection"
            className={clsx(
                'relative overflow-x-hidden',
                sectionBackgroundVariant(background),
                sectionSpacingVariant({ spacing, spacingTop, spacingBottom }),
                className,
            )}
        >
            <div
                className={clsx(
                    sectionContainerVariant(container)
                )}
            >
                {(title || description) && (
                    <div className="lg:flex lg:justify-between lg:items-end mb-6">
                        <div className="max-w-4xl mr-8">
                            {title && (
                                <h3 className="text-2xl font-medium border-l-4 border-blue pl-5">
                                    {title}
                                </h3>
                            )}
                            {description && (
                                <p className="text-base font-normal text-gray-600 mt-4 mb-4 lg:mb-0">
                                    {description}
                                </p>
                            )}
                        </div>
                        {links.length > 0 && (
                            <div className="grid grid-flow-col gap-4">
                                {links.map((link) => (
                                    <CardsSectionLink
                                        key={link._uid}
                                        content={link}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                )}
            </div>
            {isCarousel ? (
                <CardsSectionCarousel
                    container={container}
                    cards={cards}
                    columnCount={columnCount}
                />
            ) : (
                <CardSectionCards
                    container={container}
                    cards={cards}
                    columnCount={columnCount}
                />
            )}
        </section>
    )
}

export default CardsSection
