import React, { useState, useEffect } from 'react'
import { MAX_BOOKING_CHILD_AGE } from '@connections/constants'
import useScreenSize from '../hooks/useScreenSize'
import GuestsModal from './GuestsModal'
import GuestsListbox from './GuestsListbox'
import GuestsSelectButton from './GuestsSelectButton'

const GuestsSelect = ({
    onClose,
    maxChildAge,
    listOfAdults,
    numberOfRooms,
    isOpen = false,
    listOfChildren,
    onChangeAdults,
    onChangeChildren,
    onChangeChildAge,
    desktopButtons = null,
    onChangeNumberOfRooms,
}) => {
    const { isSm, isMd } = useScreenSize()
    const [isModalOpen, setModalIsOpen] = useState(false)
    const [isDropdownOpen, setDropdownIsOpen] = useState(false)
    // MaxChildAge can be an empty string
    const maxChildAgeToUse = maxChildAge || MAX_BOOKING_CHILD_AGE

    useEffect(() => {
        setModalIsOpen(isOpen)
        setDropdownIsOpen(isOpen)
    }, [isOpen])

    const handleCloseDesktop = () => {
        setDropdownIsOpen(false)
        if (typeof onClose === 'function') {
            onClose()
        }
    }

    return (
        <>
            <div className="relative hidden lg:block">
                <GuestsSelectButton
                    numberOfRooms={numberOfRooms}
                    numberOfAdults={listOfAdults.length}
                    onClick={() => setDropdownIsOpen(true)}
                    numberOfChildren={listOfChildren.length}
                />
                {isDropdownOpen && (
                    <div
                        style={{ width: 'calc(100% + 1rem)' }}
                        className="absolute z-10 -top-2 -ml-2 p-2 rounded-md shadow-lg bg-white w-full"
                    >
                        <GuestsListbox
                            listOfAdults={listOfAdults}
                            numberOfRooms={numberOfRooms}
                            maxChildAge={maxChildAgeToUse}
                            listOfChildren={listOfChildren}
                            onChangeAdults={onChangeAdults}
                            onChangeChildAge={onChangeChildAge}
                            onChangeChildren={onChangeChildren}
                            onPressEscape={handleCloseDesktop}
                            onClickOutside={handleCloseDesktop}
                            onChangeNumberOfRooms={onChangeNumberOfRooms}
                            buttons={desktopButtons ? desktopButtons(() => setDropdownIsOpen(false)) : null}
                        />
                    </div>
                )}
            </div>
            {isSm || isMd ? (
                <>
                    <GuestsSelectButton
                        numberOfRooms={numberOfRooms}
                        numberOfAdults={listOfAdults.length}
                        onClick={() => setModalIsOpen(true)}
                        numberOfChildren={listOfChildren.length}
                    />
                    {isModalOpen && (
                        <GuestsModal
                            maxChildAge={maxChildAgeToUse}
                            initialListOfAdults={listOfAdults}
                            initialNumberOfRooms={numberOfRooms}
                            initialListOfChildren={listOfChildren}
                            onClose={() => setModalIsOpen(false)}
                            onConfirm={(state) => {
                                onClose(state)
                                setModalIsOpen(false)
                            }}
                        />
                    )}
                </>
            ) : null}
        </>
    )
}

export default GuestsSelect
