import React from 'react'
import { Trans } from '@lingui/react'
import useCarousel from '@dashdot/react-hooks-slider'
import Slide from '../../content/Slide'
import CarouselCount from '../../utils/CarouselCount'
import SliderContainer from '../../utils/CarouselContainer'
import BookTemplateModalHotel from './BookTemplateModalHotel'

const BookTemplateModalHotels = ({
    hotels,
    numberOfRooms,
    numberOfAdults,
    selectedHotelIds,
    numberOfChildren,
    alternativeHotels,
    onChooseHotelRoom,
    onExploreMoreHotels,
}) => {
    const {
        position,
        slideRefs,
        isSwiping,
        isDisabled,
        activeSlide,
        totalSlides,
        hasNextSlide,
        containerRef,
        swipingProps,
        handleNextSlide,
        hasPreviousSlide,
        handlePreviousSlide,
    } = useCarousel()
    const selectedHotels = hotels.filter(({ productId }) => selectedHotelIds.includes(productId))
    return (
        <>
            <div className="flex justify-between items-center mb-6">
                <h2 className="text-lg font-medium">
                    <Trans
                        values={{ amount: hotels.length }}
                        id="{amount, plural, =1 {Hotel} other {Hotels}}"
                    />
                </h2>
                {totalSlides > 1 && (
                    <CarouselCount
                        total={totalSlides}
                        current={activeSlide + 1}
                        hasNextSlide={hasNextSlide}
                        onNextSlide={handleNextSlide}
                        hasPreviousSlide={hasPreviousSlide}
                        onPreviousSlide={handlePreviousSlide}
                    />
                )}
            </div>
            <div className="overflow-hidden">
                <SliderContainer
                    position={position}
                    isDisabled={isDisabled}
                    containerRef={containerRef}
                    swipingProps={swipingProps}
                >
                    {selectedHotels.map((hotel, index) => (
                        <Slide
                            key={hotel.startDate}
                            isSwiping={isSwiping}
                            ref={(ref) => { slideRefs.current[index] = ref }}
                        >
                            <BookTemplateModalHotel
                                hotel={hotel}
                                hotels={hotels}
                                numberOfRooms={numberOfRooms}
                                numberOfAdults={numberOfAdults}
                                numberOfChildren={numberOfChildren}
                                alternativeHotels={alternativeHotels}
                                onExploreMoreHotels={onExploreMoreHotels}
                                onChooseHotelRoom={(roomId) => onChooseHotelRoom(roomId, index)}
                            />
                        </Slide>
                    ))}
                </SliderContainer>
            </div>
        </>
    )
}

export default BookTemplateModalHotels
