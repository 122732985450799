import { useEffect, useRef, useState } from 'react'
import { useMotionValue } from 'framer-motion'
import debounce from 'lodash.debounce'
import useInterval from './useInterval'

const useAutomaticScroller = ({
    direction,
    slides,
}) => {
    const x = useMotionValue(0)
    const [pageCount, setPageCount] = useState(1)
    const [interval, setInterval] = useState(25)
    const stop = () => setInterval(null)
    const containerRef = useRef(null)
    const galleryRef = useRef(null)

    const calculatePosition = () => {
        const position = x.get()
        if (galleryRef.current) {
            const galleryWidth = galleryRef.current.clientWidth

            // When page overflows to the right, reset to the left
            if (-position >= galleryWidth) {
                const diff = -position - galleryWidth
                x.set(-(diff))
            }

            // When page overflows to the left, reset to the right
            if (position >= 0) {
                x.set(-(galleryWidth - position))
            }
        }
    }

    useInterval(() => {
        if (direction === 'right') {
            x.set(x.get() + 0.5)
        } else {
            x.set(x.get() - 0.5)
        }
        calculatePosition()
    }, interval)

    const getPageCount = debounce(() => {
        if (containerRef.current !== null && galleryRef.current !== null) {
            const containerWidth = containerRef.current.clientWidth
            const galleryWidth = galleryRef.current.clientWidth
            if (galleryWidth <= 0) {
                setPageCount(0)
            } else {
                setPageCount(Math.ceil(containerWidth / galleryWidth))
            }
        }
    }, 200)

    useEffect(() => {
        getPageCount()
        window.addEventListener('resize', getPageCount)
        return () => window.removeEventListener('resize', getPageCount)
    }, [containerRef.current, galleryRef.current, slides.length])

    const handlePanEnd = () => {
        calculatePosition()
        stop()
    }

    return {
        pageCount,
        containerRef,
        galleryRef,
        x,
        onPanEnd: handlePanEnd,
    }
}

export default useAutomaticScroller
