import React from 'react'
import Textarea from './Textarea'
import FormGroup from './FormGroup'
import FormInputErrors from './FormInputErrors'

const JotformTextareaInput = ({
    item,
    formState,
    className,
}) => {
    const {
        getErrorMessages,
        getNativeInputProps,
    } = formState
    return (
        <div className={className}>
            <FormGroup
                label={item.text}
                htmlFor={item.qid}
                isRequired={item.required}
                description={item.subLabel}
            >
                <Textarea
                    id={item.qid}
                    disabled={item.readOnly}
                    placeholder={item.hint}
                    {...getNativeInputProps(item.name)}
                />
                <FormInputErrors errors={getErrorMessages(item.name)} />
            </FormGroup>
        </div>
    )
}

export default JotformTextareaInput
