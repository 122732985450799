import React from 'react'
import { Trans } from '@lingui/react'
import Modal from '../../utils/Modal'
import Button, { VARIANT_PRIMARY_FILLED, VARIANT_PRIMARY_OUTLINED } from '../../utils/Button'

const FlightSearchStaleResultModal = ({
    onClose,
    onNewSearch,
    onRepeatSearch,
}) => (
    <Modal
        onClose={onClose}
        className="px-4 py-8 w-full self-center lg:w-[500px] min-h-screen lg:min-h-0 lg:px-8"
    >
        <h2 className="text-lg mb-4">
            <Trans id="Your session has expired" />
        </h2>
        <p>
            <Trans id="Some time has elapsed since the request of these search results, so that the information displayed can no longer be guaranteed. Repeat your search to see new results." />
        </p>
        <div className="flex flex-col lg:flex-row justify-end mt-8">
            <Button
                onClick={onNewSearch}
                variant={VARIANT_PRIMARY_OUTLINED}
            >
                <Trans id="New search" />
            </Button>
            <Button
                onClick={onRepeatSearch}
                className="mt-4 lg:mt-0 lg:ml-4"
                variant={VARIANT_PRIMARY_FILLED}
            >
                <Trans id="Repeat search" />
            </Button>
        </div>
    </Modal>
)

export default FlightSearchStaleResultModal
