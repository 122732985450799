import React from 'react'
import clsx from 'clsx'
import * as constants from '@connections/constants'
import { variant } from '../../util/index'

const {
    CONTAINER_EXTRA_SMALL: EXTRA_SMALL,
    CONTAINER_SMALL: SMALL,
    CONTAINER_DEFAULT: DEFAULT,
} = constants

const FlightsListerSectionContainer = ({
    background,
    container,
    spacing,
    children,
    ...props
}) => (
    <section
        className={clsx(
            'w-full',
            variant(spacing, {
                small: 'py-12',
                medium: 'py-24',
                large: 'py-36',
            }),
            variant(background, {
                white: 'bg-white',
                'gray-50': 'bg-gray-50',
                'gray-100': 'bg-gray-100',
                black: 'bg-black',
                blue: 'bg-blue',
            })
        )}
        {...props}
    >
        <div
            className={clsx(
                variant(container, {
                    [EXTRA_SMALL]: 'container-xs',
                    [SMALL]: 'container-sm',
                    [DEFAULT]: 'container',
                })
            )}
        >
            {children}
        </div>
    </section>
)

export default FlightsListerSectionContainer
