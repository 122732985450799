import React from 'react'
import clsx from 'clsx'
import SbEditable from 'storyblok-react'
import { AnimatePresence, motion } from 'framer-motion'
import * as constants from '@connections/constants'
import { variant } from '../../util/index'
import DayItem from './DayItem'
import DayInfo from './DayInfo'

const {
    CONTAINER_EXTRA_SMALL: EXTRA_SMALL,
    CONTAINER_SMALL: SMALL,
    CONTAINER_DEFAULT: DEFAULT,
} = constants

const DayByDaySection = ({
    title,
    dayItems,
    spacing,
    background,
    container = DEFAULT,
    lastActiveDay,
    activeDayUid,
    onToggle,
    anchorId,
}) => (
    <section
        id={anchorId || undefined}
        data-testid="DayByDaySection"
        className={clsx(
            variant(spacing, {
                small: 'py-12',
                medium: 'py-24',
                large: 'py-36',
            }),
            variant(background, {
                white: 'bg-white',
                'gray-50': 'bg-gray-50',
                'gray-100': 'bg-gray-100',
                black: 'bg-black',
                blue: 'bg-blue',
            })
        )}
    >
        <div className={clsx(
            variant(container, {
                [EXTRA_SMALL]: 'md:container-xs',
                [SMALL]: 'md:container-sm',
                [DEFAULT]: 'md:container',
            })
        )}
        >
            <h3 className="hidden md:block text-center text-3xl font-medium mb-14">
                {title}
            </h3>

            <div className="md:grid grid-cols-3 gap-8">
                <div className="col-span-1 border-t border-gray-100 overflow-auto min-h-full md:h-[70vh]">
                    {dayItems.map((item) => (
                        <DayItem
                            key={item._uid}
                            content={item}
                            isOpen={activeDayUid === item._uid}
                            onToggle={() => onToggle(item._uid)}
                        />
                    ))}
                </div>
                <div className="hidden md:block col-span-2 relative">
                    {lastActiveDay !== null && (
                        <SbEditable content={lastActiveDay}>
                            <AnimatePresence>
                                <motion.div
                                    key={lastActiveDay._uid}
                                    initial={{ opacity: 0, position: 'absolute' }}
                                    animate={{ opacity: 1, position: 'relative' }}
                                    exit={{ opacity: 0, position: 'absolute' }}
                                    className="w-full"
                                >
                                    <DayInfo content={lastActiveDay} />
                                </motion.div>
                            </AnimatePresence>
                        </SbEditable>
                    )}
                </div>
            </div>
        </div>
    </section>
)

export default DayByDaySection
