import useStoryblokQuery from '../../hooks/useStoryblokQuery'

const useShopSearchPageQuery = (
    filters = {},
    { perPage = 50, page = 1 } = {},
) => {
    const filter = {}
    if (filters.code) {
        filter.postalCode = { like: `${filters.code.charAt(0)}*` }
    }
    // add filter to get only shop components
    filter.component = { in: 'Shop' }
    const params = {
        page,
        sort_by: 'content.title:asc',
        per_page: perPage,
        filter_query: filter,
        starts_with: encodeURI('reiswinkels/'),
        excluding_slugs: '_test/*,preview/*,*/hidden/*',
    }
    const {
        data,
        error,
        isLoading: isFetching,
    } = useStoryblokQuery('shops-search', 'cdn/stories/', params)

    let searchResults = []
    if (isFetching) {
        return {
            isFetching,
            searchResults,
        }
    }

    if (error) {
        return {
            error,
            isFetching,
            searchResults,
        }
    }

    const {
        total,
        data: {
            stories
        },
    } = data
    searchResults = stories

    return {
        total,
        perPage,
        isFetching,
        searchResults,
    }
}

export default useShopSearchPageQuery
