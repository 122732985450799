import React from 'react'
import clsx from 'clsx'
import * as constants from '@connections/constants'
import { variant as createVariant } from '../../util/index'
import {
    sectionBackgroundVariant,
    sectionContainerVariant,
    sectionSpacingVariant,
    sectionTextOnBackgroundVariant
} from '../../util/variants'
import CardsSectionLink from './CardsSectionLink'
import { AIRLINE, DESTINATION } from './FlightsListerGroupSection'
import FlightsListerAirlineCardGroup from './FlightsListerAirlineCardGroup'
import FlightsListerDestinationCardGroup from './FlightsListerDestinationCardGroup'

const {
    BACKGROUND_BLUE,
    BACKGROUND_BLACK,
} = constants

const FlightsListerSection = ({
    error,
    loading,
    title,
    links,
    variant,
    background,
    container,
    spacing,
    description,
    destinations,
    anchorId,
}) => (
    <section
        id={anchorId || undefined}
        data-testid="FlightsListerSection"
        className={clsx(
            'relative',
            sectionBackgroundVariant(background),
            sectionSpacingVariant({ spacing }),
        )}
    >
        <div
            className={clsx(
                'mb-4',
                sectionContainerVariant(container)
            )}
        >
            <div className="flex flex-col md:flex-row justify-between md:items-end">
                {title && (
                    <h3
                        className={clsx(
                            'text-2xl font-medium border-l-4 border-blue pl-5 mb-4',
                            sectionTextOnBackgroundVariant(background),
                            createVariant(background, {
                                [BACKGROUND_BLACK]: 'border-white',
                                [BACKGROUND_BLUE]: 'border-white',
                            }, 'border-blue')
                        )}
                    >
                        {title}
                    </h3>
                )}
                {links.length > 0 && (
                    <div className="grid grid-flow-col gap-4">
                        {links.map((link) => (
                            <CardsSectionLink
                                key={link._uid}
                                content={link}
                            />
                        ))}
                    </div>
                )}
            </div>
            {description && (
                <p
                    className={clsx(
                        'text-base font-normal mb-8',
                        sectionTextOnBackgroundVariant(background, 'text-blue'),
                    )}
                >
                    {description}
                </p>
            )}
        </div>
        <div
            className={clsx(
                sectionContainerVariant(container)
            )}
        >
            {(() => {
                if (variant === AIRLINE) {
                    return (
                        <FlightsListerAirlineCardGroup
                            error={error}
                            loading={loading}
                            destinations={destinations}
                            className="mb-8"
                        />
                    )
                }
                if (variant === DESTINATION) {
                    return (
                        <FlightsListerDestinationCardGroup
                            error={error}
                            loading={loading}
                            destinations={destinations}
                            className="mb-8"
                        />
                    )
                }
                return null
            })()}
        </div>
    </section>
)

export default FlightsListerSection
