import React from 'react'
import clsx from 'clsx'
import SbEditable from 'storyblok-react'
import {
    sectionBackgroundVariant,
    sectionContainerVariant,
    sectionSpacingVariant,
} from '../../util/variants'
import TableWithContent from './TableWithContent'

const TableSection = ({
    content,
    className,
}) => {
    const {
        table,
        isHorizontalScrollable,
        isVerticalScrollable,
        background,
        spacing,
        container,
    } = content
    return (
        <SbEditable content={content}>
            <section
                className={clsx(
                    sectionBackgroundVariant(background),
                    sectionSpacingVariant({ spacing }),
                    className,
                )}
            >
                <div
                    className={clsx(
                        sectionContainerVariant(container),
                    )}
                >
                    <TableWithContent
                        content={{
                            table,
                            isHorizontalScrollable,
                            isVerticalScrollable,
                        }}
                    />
                </div>
            </section>
        </SbEditable>
    )
}

export default TableSection
