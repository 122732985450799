import React from 'react'
import { Trans } from '@lingui/react'
import { whereId } from '@connections/utils'
import Modal from '../../utils/Modal'
import ModalCloseButton from '../../utils/ModalCloseButton'
import BookTemplateModalRoomButtonCard from '../book-template/BookTemplateModalRoomButtonCard'

const BookDynamicTemplateRoomsModal = ({
    rooms,
    onClose,
    hotelName,
    numberOfTravelers,
    onChangeHotelRoom,
    selectedHotelProductId,
}) => {
    const selectedRoom = rooms.find(whereId(selectedHotelProductId))
    return (
        <Modal
            onClose={onClose}
            className="px-4 py-10 w-full min-h-full lg:w-[800px] lg:min-h-[600px] lg:mt-24 lg:px-10"
        >
            <ModalCloseButton onClick={onClose} />
            <h1 className="text-lg font-medium">
                {hotelName}
            </h1>
            <p className="text-sm text-gray-600">
                <Trans id="Choose your room type" />
            </p>
            {rooms
                .sort((a, b) => a.priceInfo.totalPrice - b.priceInfo.totalPrice)
                .map((room) => {
                    const isSelected = room.id === selectedHotelProductId
                    const priceDifference = room.priceInfo.totalPrice - selectedRoom.priceInfo.totalPrice
                    return (
                        <BookTemplateModalRoomButtonCard
                            key={room.id}
                            value={isSelected}
                            roomName={room.roomName}
                            priceDifference={priceDifference}
                            numberOfTravelers={numberOfTravelers}
                            onChange={() => onChangeHotelRoom(room.id)}
                            availabilityStatus={room.availabilityStatus}
                            boardBaseDisplayName={room.boardBaseDisplayName}
                        />
                    )
                })}
        </Modal>
    )
}

export default BookDynamicTemplateRoomsModal
