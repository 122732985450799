import React from 'react'
import SbEditable from 'storyblok-react'
import Quote from '../utils/Quote'
import Column from '../utils/Column'

const QuoteColumn = ({
    content,
    position,
    background,
    className,
}) => {
    const {
        text,
        caption,
        size,
        variant,
        align,
        width,
        spacing,
    } = content
    return (
        <SbEditable content={content}>
            <Column
                align={align}
                position={position}
                className={className}
            >
                <Column.Container
                    width={width}
                    position={position}
                    spacing={spacing}
                >
                    <Quote
                        quote={text}
                        caption={caption}
                        size={size}
                        variant={variant}
                        width={width}
                        background={background}
                    />
                </Column.Container>
            </Column>
        </SbEditable>
    )
}

export default QuoteColumn
