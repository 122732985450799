import React, { forwardRef } from 'react'
import HeadingSectionMobile from './HeadingSectionMobile'
import HeadingSectionDesktop from './HeadingSectionDesktop'

const HeadingSection = forwardRef(({
    content,
    ...props
}, ref) => {
    const {
        anchorId,
    } = content
    return (
        <div
            id={anchorId || undefined}
            data-testid="HeadingSection"
            ref={ref}
        >
            <HeadingSectionMobile content={content} {...props} />
            <HeadingSectionDesktop content={content} {...props} />
        </div>
    )
})

export default HeadingSection
