import React, { useEffect, useState } from 'react'
import { useRouter } from 'next-nprogress-bar'
import { getTemplatePrice } from '../../../util/booking/templates'
import { getAbsoluteEndDateFromFares } from '../../../util/booking'
import { getBreakMetaData } from '../../../util/analytics/transformers'
import useJourney from '../../hooks/useJourney'
import useLogEvent from '../../hooks/useLogEvent'
import useNotifications from '../../hooks/useNotifications'
import usePathnameLocale from '../../hooks/usePathnameLocale'
import useBreakAnalyticsValues from '../../hooks/useBreakAnalyticsValues'
import useGuestsState from '../../utils/useGuestsState'
import useTemplateActivities from '../book-dynamic-template/useTemplateActivities'
import useTemplatesQuery from './useTemplatesQuery'
import BookTemplateModal from './BookTemplateModal'
import useTemplatePricingQuery from './useTemplatePricingQuery'
import useCreateBookingTemplateMutation from './useCreateBookingTemplateMutation'

const BookTemplateModalWithState = ({
    type,
    content,
    onClose,
    templateSettings,
}) => {
    const {
        uuid,
        content: {
            name,
            collection,
            maxChildAge,
            // The available dates are fetched from TravelBooster through our own api.
            // This is done during static generation of the pages containing these components.
            availableDates,
            travelBoosterTemplateId,
        }
    } = content
    const templateIds = travelBoosterTemplateId.split(',')
    const router = useRouter()
    const logEvent = useLogEvent()
    const fullLocale = usePathnameLocale(true)
    const { dispatchError } = useNotifications()
    const [modalHasError, setModalHasError] = useState(false)
    const [selectedTourId, setSelectedTourId] = useState(null)
    const [hasLoggedError, setHasLoggedError] = useState(false)
    const [selectedHotelIds, setSelectedHotelIds] = useState(null)
    const [[startDate, endDate], setDates] = useState([null, null])
    const [currentTemplateId, setCurrentTemplateId] = useState(null)
    const [hasChosenTravelers, setHasChosenTravelers] = useState(false)
    const [hasLoggedModalOpen, setHasLoggedModalOpen] = useState(false)
    const { journeyUid, closeJourney, error: journeyError } = useJourney()
    const [hasLoggedInitalSearch, sethasLoggedInitalSearch] = useState(false)
    const [hasLoggedInputSelected, setHasLoggedInputSelected] = useState(false)
    const [isCreatingBookingTemplate, setIsCreatingBookingTemplate] = useState(false)
    const { mutateAsync: createBookingTemplate } = useCreateBookingTemplateMutation()
    const { getTimingAnalyticsValues, getTemplateAnalyticsValues } = useBreakAnalyticsValues()
    const {
        adults,
        children,
        numberOfRooms,
        handleChangeGuestState,
    } = useGuestsState()
    const {
        error,
        isFetching,
        allAvailableDates,
    } = useTemplatesQuery(journeyUid, templateIds, availableDates)
    const {
        templatePricing,
        error: templatePricingError,
        isFetching: isFetchingTemplatePricing,
        searchResultId: templatePricingSearchResultId,
    } = useTemplatePricingQuery({
        adults,
        endDate,
        children,
        startDate,
        journeyUid,
        numberOfRooms,
        hasChosenTravelers,
        id: currentTemplateId,
    })
    const {
        selectedActivities,
        handleChangeActivity,
        handleChangeActivityOptions,
    } = useTemplateActivities(endDate, startDate, templatePricing?.activities)

    useEffect(() => {
        if (!hasLoggedModalOpen) {
            const metaData = getBreakMetaData({ route: !!collection || name }, 'modal-open')
            logEvent('ModalOpen', metaData, journeyUid)
            setHasLoggedModalOpen(true)
        }
    }, [])
    useEffect(() => {
        if (!hasLoggedInputSelected && hasChosenTravelers && !!endDate && !!startDate) {
            const values = {
                route: !!collection || name,
                numberOfAdults: adults.length,
                ...getTimingAnalyticsValues(endDate, startDate)
            }
            const metaData = getBreakMetaData(values, 'search-started')
            logEvent('SearchStarted', metaData, journeyUid)
            setHasLoggedInputSelected(true)
        }
    }, [endDate, startDate, hasChosenTravelers])
    useEffect(() => {
        if (journeyError || error || templatePricingError) {
            if (!hasLoggedError) {
                const values = {
                    route: !!collection || name,
                    numberOfAdults: adults.length,
                    ...getTimingAnalyticsValues(endDate, startDate)
                }
                const metaData = getBreakMetaData(values, 'search-error')
                logEvent('SearchError', metaData, journeyUid)
                setHasLoggedError(true)
            }
            setModalHasError(true)
        } else {
            setModalHasError(false)
        }
    }, [journeyError, error, templatePricingError])
    useEffect(() => {
        if (templatePricing) {
            // Set default hotel rooms
            const defaultHotels = templatePricing.hotels
                .filter(({ isAlternative }) => !isAlternative)
                .map(({ productId }) => productId)
            setSelectedHotelIds(defaultHotels)
            // Set default tour
            const defaultTourId = templatePricing.tours.find(({ isAlternative }) => !isAlternative)?.productId
            setSelectedTourId(defaultTourId)
            // Log initial search
            if (!hasLoggedInitalSearch) {
                const values = getTemplateAnalyticsValues(startDate, endDate, templatePricing, defaultHotels, defaultTourId, selectedActivities, adults, name, collection)
                const metaData = getBreakMetaData(values, 'search')
                logEvent('InitialSearch', metaData, journeyUid)
                sethasLoggedInitalSearch(true)
            }
        }
    }, [templatePricing])

    const handleCreateBookingTemplate = async () => {
        try {
            const input = {
                adults,
                endDate,
                children,
                startDate,
                journeyUid,
                cmsId: uuid,
                templateType: type,
                tourId: selectedTourId,
                hotelIds: selectedHotelIds,
                templatePricingSearchResultId,
                activities: selectedActivities,
            }
            setIsCreatingBookingTemplate(true)
            const data = await createBookingTemplate({ input })
            const values = getTemplateAnalyticsValues(startDate, endDate, templatePricing, selectedHotelIds, selectedTourId, selectedActivities, adults, name, collection)
            const metaData = getBreakMetaData(values, 'selected')
            logEvent('SelectedFlight', metaData, journeyUid)
            const { uid } = data.createBookingTemplate.booking

            router.push(`/${fullLocale}/checkout/${uid}/travelers`)
        } catch (e) {
            setIsCreatingBookingTemplate(false)
            dispatchError(e.message)
        }
    }
    const handleChooseHotelRoom = (roomId, index) => {
        const selectedHotelIdsClone = [...selectedHotelIds]
        selectedHotelIdsClone[index] = roomId
        setSelectedHotelIds(selectedHotelIdsClone)
    }
    const handleClose = () => {
        closeJourney()
        onClose()
    }

    const price = getTemplatePrice(templatePricing, selectedHotelIds, selectedTourId, selectedActivities)
    // TB takes the departure date of the returning flight as the last day of the package but it's
    // possible that the returning flight lands a day later. Connections requested we show this date
    // as the absolute last date of the package. Perhaps this is also something we can ask TB to do.
    const absoluteEndDate = getAbsoluteEndDateFromFares(templatePricing?.flights)
    return (
        <BookTemplateModal
            name={name}
            type={type}
            price={price}
            endDate={endDate}
            onClose={handleClose}
            listOfAdults={adults}
            startDate={startDate}
            isFetching={isFetching}
            listOfChildren={children}
            modalHasError={modalHasError}
            numberOfRooms={numberOfRooms}
            selectedTourId={selectedTourId}
            onChangeTour={setSelectedTourId}
            templatePricing={templatePricing}
            absoluteEndDate={absoluteEndDate}
            availableDates={allAvailableDates}
            templateSettings={templateSettings}
            selectedHotelIds={selectedHotelIds}
            currentTemplateId={currentTemplateId}
            hasChosenTravelers={hasChosenTravelers}
            selectedActivities={selectedActivities}
            onChangeActivity={handleChangeActivity}
            onChooseHotelRoom={handleChooseHotelRoom}
            isFetchingContent={isFetchingTemplatePricing}
            onCreateBookingTemplate={handleCreateBookingTemplate}
            onChangeActivityOptions={handleChangeActivityOptions}
            isCreatingBookingTemplate={isCreatingBookingTemplate}
            maxChildAge={maxChildAge && parseInt(maxChildAge, 10)}
            hasValidInputs={startDate && endDate && hasChosenTravelers}
            onCloseGuestSelect={(newGuestState) => {
                handleChangeGuestState(newGuestState)
                setHasChosenTravelers(true)
            }}
            onChangeDates={([templateId, ...dates]) => {
                setDates(dates)
                setCurrentTemplateId(templateId)
            }}
        />
    )
}

export default BookTemplateModalWithState
