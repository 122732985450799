import React from 'react'
import SbEditable from 'storyblok-react'
import CardsSection from './CardsSection'

const CardsSectionWithState = ({
    content,
    className,
}) => (
    <SbEditable content={content}>
        <CardsSection
            className={className}
            {...content}
        />
    </SbEditable>
)

export default CardsSectionWithState
