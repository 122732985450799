import React from 'react'
import SbEditable from 'storyblok-react'
import useStoryblokBridge from '../../hooks/useStoryblokBridge'
import ShopPage from './ShopPage'

const ShopPageWithState = ({
    data,
    resolveRelations
}) => {
    const { content } = useStoryblokBridge(data.pageStory, resolveRelations)

    return (
        <SbEditable content={content}>
            <ShopPage {...content} />
        </SbEditable>
    )
}

export default ShopPageWithState
