import React from 'react'
import SbEditable from 'storyblok-react'
import Icon from '../utils/Icon'
import Collapsable from '../utils/Collapsable'
import RichTextFieldWithComponents from './RichTextFieldWithComponents'

const CollapsableItem = ({
    content,
    isOpen,
    onExpand,
    className,
}) => {
    const {
        title,
        contents
    } = content
    return (
        <SbEditable content={content}>
            <div
                id={content._uid}
                className={className}
            >
                <Collapsable
                    isOpen={isOpen}
                    onExpand={onExpand}
                    className="bg-blue-light rounded-lg px-6 py-7 w-full"
                    title={(
                        <h3 className="text-lg md:text-3xl font-medium mr-2">
                            {title}
                        </h3>
                    )}
                    icon={(
                        <Icon
                            name="chevron-down"
                            color="blue"
                        />
                    )}
                >
                    <RichTextFieldWithComponents
                        content={contents}
                        className="px-6 pt-4"
                    />
                </Collapsable>
            </div>
        </SbEditable>
    )
}

export default CollapsableItem
