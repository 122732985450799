import React from 'react'
import clsx from 'clsx'
import * as constants from '@connections/constants'
import Image from '../utils/Image'
import { variant } from '../../util/index'

const {
    CONTAINER_EXTRA_SMALL: EXTRA_SMALL,
    CONTAINER_SMALL: SMALL,
    CONTAINER_MEDIUM: MEDIUM,
    CONTAINER_DEFAULT: DEFAULT,
} = constants

const FeaturedCarouselSlide = ({
    image,
    index,
    hasSkew,
    onClick,
    slideRefs,
    size,
    activeSlide,
    verticalSpacing,
    horizontalSpacing,
}) => {
    const isActive = activeSlide === index
    const differenceWithActiveSlide = activeSlide - index
    const isNextSlide = differenceWithActiveSlide < 0
    if (!image.filename) {
        return null
    }

    return (
        <div
            className={clsx(
                'flex-grow-0 flex-shrink-0 w-full flex items-center transition-all duration-500 flex-col',
                !isActive && variant(verticalSpacing, {
                    small: 'py-4',
                    medium: 'py-8',
                    large: 'py-16',
                }),
                !isActive && variant(horizontalSpacing, {
                    small: isNextSlide ? 'pl-4' : 'pr-4',
                    medium: isNextSlide ? 'pl-8' : 'pr-8',
                    large: isNextSlide ? 'pl-16' : 'pr-16',
                }),
            )}
            style={{
                transform: hasSkew
                    ? `
                        rotateY(${10 * differenceWithActiveSlide}deg)
                        translateX(${200 * differenceWithActiveSlide}px)
                    `
                    : 'rotateY(0deg) translateX(0)',
                zIndex: -Math.abs(differenceWithActiveSlide),
            }}
            // eslint-disable-next-line no-param-reassign
            ref={(ref) => { slideRefs.current[index] = ref }}
        >
            <div className="w-full h-full relative transition-all">
                <button
                    type="button"
                    onClick={onClick}
                    className="w-full h-full relative"
                >
                    <Image
                        fill
                        alt={image.alt}
                        draggable={false}
                        src={image.filename}
                        className="bg-gray-100 object-cover"
                        maxWidth={
                            variant(size, {
                                [EXTRA_SMALL]: 320,
                                [SMALL]: 480,
                                [MEDIUM]: 512,
                                [DEFAULT]: 320,
                            })
                        }
                    />
                </button>
            </div>
        </div>
    )
}

export default FeaturedCarouselSlide
