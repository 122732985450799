import React, { useState } from 'react'
import SbEditable from 'storyblok-react'
import { splitCodes } from '../../util/index'
import useCheapestDestinationsPrices from '../hooks/useCheapestDestinationsPrices'
import FlightsListerGroupSection from './FlightsListerGroupSection'

const FlightsListerGroupSectionWithState = ({
    content,
}) => {
    const {
        title,
        groups,
        variant,
        buttons,
        spacing,
        airline,
        anchorId,
        container,
        background,
        description,
    } = content
    const [activeTab, setActiveTab] = useState(0)
    const allDestinations = groups.flatMap(({ destinations = [] }) => destinations)
    const allDepartureCodes = groups.flatMap(({ departureCodes }) => splitCodes(departureCodes))
    const {
        error,
        loading,
        cheapestDestinations,
    } = useCheapestDestinationsPrices({
        airline,
        destinations: allDestinations,
        departureCodes: allDepartureCodes,
    })
    const mappedGroups = groups.map((group) => {
        const destinations = group.destinations.map((destination) => (
            cheapestDestinations.find(({ id }) => id === destination.id)
        ))
        return {
            ...group,
            destinations,
            count: destinations.length,
        }
    })
    const activeGroup = mappedGroups[activeTab]
    return (
        <SbEditable content={content}>
            <FlightsListerGroupSection
                error={error}
                title={title}
                loading={loading}
                variant={variant}
                buttons={buttons}
                spacing={spacing}
                activeTab={activeTab}
                groups={mappedGroups}
                container={container}
                background={background}
                activeGroup={activeGroup}
                description={description}
                onChangeTab={setActiveTab}
                anchorId={anchorId || undefined}
            />
        </SbEditable>
    )
}

export default FlightsListerGroupSectionWithState
