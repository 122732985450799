import React, { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import SbEditable from 'storyblok-react'
import Column from '../utils/Column'
import { getHrefFromLink } from '../../util/index'
import usePathnameLocale from '../hooks/usePathnameLocale'

const MapColumn = ({
    content,
}) => {
    const licenseCode = process.env.NEXT_PUBLIC_AMCHARTS_LICENSE_CODE
    const mapsLicenseCode = process.env.NEXT_PUBLIC_AMCHARTS_MAPS_LICENSE_CODE

    const { points = [] } = content
    const chartRef = useRef(null)
    const locale = usePathnameLocale()
    const [isLoading, setIsLoading] = useState(true)
    const initMap = async () => {
        const [
            am4core,
            am4maps,
            am4geodata_worldLow,
            am4geodata_continentsLow,
        ] = await Promise.all([
            import('@amcharts/amcharts4/core'),
            import('@amcharts/amcharts4/maps'),
            import('@amcharts/amcharts4-geodata/worldLow'),
            import('@amcharts/amcharts4-geodata/continentsLow'),
        ])
        am4core.addLicense(licenseCode)
        am4core.addLicense(mapsLicenseCode)
        if (chartRef.current) {
            chartRef.current.dispose()
        }

        const pinColor = am4core.color('#006FD6')
        const polygonColor = am4core.color('#E7EDEE')

        const chart = am4core.create('map', am4maps.MapChart)
        chart.geodata = am4geodata_worldLow.default
        chart.projection = new am4maps.projections.Mercator()
        chart.seriesContainer.draggable = false
        chart.seriesContainer.resizable = false
        chart.chartContainer.wheelable = false
        chart.maxZoomLevel = 1

        // Continents
        const continentsSeries = chart.series.push(new am4maps.MapPolygonSeries())
        continentsSeries.geodata = am4geodata_continentsLow.default
        continentsSeries.useGeodata = true
        continentsSeries.exclude = ['antarctica']

        const continentTemplate = continentsSeries.mapPolygons.template
        continentTemplate.fill = polygonColor
        continentTemplate.propertyFields.fill = pinColor

        const imageSeries = new am4maps.MapImageSeries()
        imageSeries.mapImages.template.propertyFields.longitude = 'longitude'
        imageSeries.mapImages.template.propertyFields.latitude = 'latitude'
        imageSeries.mapImages.template.tooltipText = '{title}'
        imageSeries.mapImages.template.propertyFields.url = 'url'
        imageSeries.mapImages.template.fill = pinColor

        const circle = imageSeries.mapImages.template.createChild(am4core.Circle)
        circle.radius = 7
        circle.fillOpacity = 0.2
        circle.nonScaling = true

        const circle2 = imageSeries.mapImages.template.createChild(am4core.Circle)
        circle2.radius = 3
        circle2.fillOpacity = 1
        circle2.nonScaling = true

        points.forEach(({
            longitude,
            latitude,
            link,
            name,
        }) => {
            if (longitude && latitude) {
                const url = getHrefFromLink(link, locale)
                imageSeries.data.push({
                    title: name,
                    longitude: parseFloat(longitude),
                    latitude: parseFloat(latitude),
                    color: pinColor,
                    url,
                })
            }
        })
        chart.series.push(imageSeries)

        chartRef.current = chart
        setIsLoading(false)
        return () => {
            chartRef.current.dispose()
        }
    }
    useEffect(() => {
        initMap()
    }, [points])
    return (
        <SbEditable content={content}>
            <Column position="none">
                <div
                    id="map"
                    className={clsx(
                        'h-[350px] lg:h-[500px] w-full',
                        isLoading && 'invisible'
                    )}
                />
            </Column>
        </SbEditable>
    )
}
export default MapColumn
