import React, { useState, useEffect } from 'react'
import {
    whereType,
    whereReturnDate,
    whereDirectFlight,
    whereDepartureCode,
} from '@connections/utils'
import { parseISO } from 'date-fns'
import SbEditable from 'storyblok-react'
import { useLingui } from '@lingui/react'
import * as constants from '@connections/constants'
import { splitCodes } from '../../util/index'
import QueryParamProvider from '../providers/QueryParamProvider'
import useCheapestFlightPrices from '../hooks/useCheapestFlightPrices'
import { useFlightsSearch } from './QuickSearchSectionWithState'
import DestinationFlightsListerGroupSection from './DestinationFlightsListerGroupSection'

const {
    FLIGHT_MODE_ONE_WAY: ONE_WAY,
    FLIGHT_MODE_ROUND_TRIP: ROUND_TRIP,
} = constants

const NO_STOPS = 'noStops'
const MULTIPLE_STOPS = 'multipleStops'
const ONE_WAY_FLIGHTS = 'oneWayFlights'
const CHEAPEST_FLIGHTS = 'cheapestFlights'
const PREFERRED_FLIGHTS = 'preferredFlights'
const ALTERNATIVE_AIRPORT = 'alternativeAirport'

const MAX_SHOW_ITEMS = 6

const addFlightPrice = (group, flightPrice) => {
    const flightPrices = [...group.flightPrices, flightPrice]
    return {
        ...group,
        flightPrices,
        count: flightPrices.length,
    }
}

const containsAirlineCode = (preferredAirlineCodes, airlineCode) => (
    preferredAirlineCodes.some((preferredAirlineCode) => {
        if (airlineCode.includes(preferredAirlineCode)) {
            return true
        }
        return false
    })
)

const getGroups = (i18n, flightPrices = [], preferredAirlineCodes = null) => {
    const groups = [
        {
            _uid: PREFERRED_FLIGHTS,
            type: PREFERRED_FLIGHTS,
            title: i18n._(/*i18n*/'Preferred flights'),
            flightPrices: [],
        },
        {
            _uid: CHEAPEST_FLIGHTS,
            type: CHEAPEST_FLIGHTS,
            title: i18n._(/*i18n*/'Cheapest flights'),
            flightPrices: [],
        },
        {
            _uid: NO_STOPS,
            type: NO_STOPS,
            title: i18n._(/*i18n*/'No stops'),
            flightPrices: [],
        },
        {
            _uid: MULTIPLE_STOPS,
            type: MULTIPLE_STOPS,
            title: i18n._(/*i18n*/'Multiple stops'),
            flightPrices: [],
        },
        {
            _uid: ALTERNATIVE_AIRPORT,
            type: ALTERNATIVE_AIRPORT,
            title: i18n._(/*i18n*/'Alternative airport'),
            flightPrices: [],
        },
        {
            _uid: ONE_WAY_FLIGHTS,
            type: ONE_WAY_FLIGHTS,
            title: i18n._(/*i18n*/'One way'),
            flightPrices: [],
        }
    ]
    const groupsWithFlightPrices = flightPrices.reduce((groupsAcc, flightPrice) => {
        let [
            preferred,
            cheapest,
            noStops,
            multipleStops,
            alternativeAirport,
            oneWay,
        ] = groupsAcc
        if (
            preferredAirlineCodes !== null
            && preferredAirlineCodes !== ''
            && !whereReturnDate(null)(flightPrice)
            && containsAirlineCode(preferredAirlineCodes.split(','), flightPrice.airlineCode)
            && preferred.flightPrices.length < MAX_SHOW_ITEMS
        ) {
            preferred = addFlightPrice(preferred, flightPrice)
        }
        if (!whereReturnDate(null)(flightPrice) && cheapest.flightPrices.length < MAX_SHOW_ITEMS) {
            cheapest = addFlightPrice(cheapest, flightPrice)
        }
        if (
            whereDirectFlight(true)(flightPrice)
            && !whereReturnDate(null)(flightPrice)
            && noStops.flightPrices.length < MAX_SHOW_ITEMS
        ) {
            noStops = addFlightPrice(noStops, flightPrice)
        }
        if (
            whereDirectFlight(false)(flightPrice)
            && !whereReturnDate(null)(flightPrice)
            && multipleStops.flightPrices.length < MAX_SHOW_ITEMS
        ) {
            multipleStops = addFlightPrice(multipleStops, flightPrice)
        }
        if (
            !whereDepartureCode('BRU')(flightPrice)
            && !whereReturnDate(null)(flightPrice)
            && alternativeAirport.flightPrices.length < MAX_SHOW_ITEMS
        ) {
            alternativeAirport = addFlightPrice(alternativeAirport, flightPrice)
        }
        if (whereReturnDate(null)(flightPrice) && oneWay.flightPrices.length < MAX_SHOW_ITEMS) {
            oneWay = addFlightPrice(oneWay, flightPrice)
        }
        return [
            preferred,
            cheapest,
            noStops,
            multipleStops,
            alternativeAirport,
            oneWay,
        ]
    }, groups).filter((group) => group.flightPrices.length)
    return groupsWithFlightPrices
}

const DestinationFlightsListerGroupSectionWithState = ({
    content,
}) => {
    const { i18n } = useLingui()
    const {
        openTab = PREFERRED_FLIGHTS,
        iataCode,
        departureCodes,
        preferredAirlineCodes,
    } = content
    const variables = {
        departureCodes: splitCodes(departureCodes),
        arrivalCodes: splitCodes(iataCode)
    }
    const {
        error,
        loading,
        cheapestFlightPrices,
    } = useCheapestFlightPrices(variables)
    const groups = getGroups(i18n, cheapestFlightPrices, preferredAirlineCodes)
    const [activeTab, setActiveTab] = useState(0)
    useEffect(() => {
        const activeIndex = groups.findIndex(whereType(openTab))
        setActiveTab(activeIndex)
    }, [loading])
    const activeGroup = groups[activeTab]
    const submitSearchFlights = useFlightsSearch()

    const handleSelect = ({
        directFlight,
        returnDate,
        departureCode,
        departureDate,
        departureIsMetropolitan,
        arrivalCode,
        arrivalIsMetropolitan,
    }) => {
        let flightMode = ONE_WAY
        if (departureDate && returnDate) {
            flightMode = ROUND_TRIP
        }
        submitSearchFlights({
            isDirectFlight: directFlight,
            flightMode,
            fromDestination: {
                code: departureCode,
                isMetropolitan: departureIsMetropolitan
            },
            toDestination: {
                code: arrivalCode,
                isMetropolitan: arrivalIsMetropolitan
            },
            departureDate: departureDate && parseISO(departureDate),
            returnDate: returnDate && parseISO(returnDate),
            numberOfAdults: 1,
            numberOfChildren: 0,
            numberOfInfants: 0,
        })
    }

    return (
        <SbEditable content={content}>
            <DestinationFlightsListerGroupSection
                key={activeTab}
                {...content}
                error={error}
                loading={loading}
                activeTab={activeTab}
                activeGroup={activeGroup}
                onSelect={handleSelect}
                onChangeTab={setActiveTab}
                groups={groups}
            />
        </SbEditable>
    )
}

export default (props) => (
    <QueryParamProvider>
        <DestinationFlightsListerGroupSectionWithState {...props} />
    </QueryParamProvider>
)
