import React from 'react'
import SbEditable from 'storyblok-react'
import RichTextIcon from './RichTextIcon'

const RichTextIconList = ({
    content,
}) => {
    const {
        iconListItems = []
    } = content
    return (
        <SbEditable content={content}>
            {iconListItems.length > 0 && (
                <div className="my-9">
                    {iconListItems.map((iconListItem) => (
                        <RichTextIcon
                            key={iconListItem._uid}
                            content={iconListItem}
                        />
                    ))}
                </div>
            )}
        </SbEditable>
    )
}

export default RichTextIconList
