import React from 'react'
import clsx from 'clsx'
import * as constants from '@connections/constants'
import { variant } from '../../util'
import Image from '../utils/Image'
import CustomText from '../utils/CustomText'
import DynamicButton from './DynamicButton'

const {
    CONTAINER_EXTRA_SMALL: EXTRA_SMALL,
    CONTAINER_SMALL: SMALL,
    CONTAINER_DEFAULT: DEFAULT,
    ALIGN_LEFT,
    ALIGN_CENTER,
    ALIGN_RIGHT,
} = constants

const IntroSection = ({
    subtitle,
    title,
    description,
    headImage,
    buttons = [],
    background,
    container,
    spacing,
    position,
    isVertical = false,
    className,
}) => {
    const hasImage = headImage?.filename
    return (
        <section
            data-testid="IntroSection"
            className={clsx(
                'relative',
                hasImage && 'lg:mb-20',
                variant(background, {
                    white: 'bg-white',
                    'gray-50': 'bg-gray-50',
                    'gray-100': 'bg-gray-100',
                    black: 'bg-black',
                    blue: 'bg-blue',
                }),
                variant(spacing, {
                    small: 'py-12',
                    medium: 'py-24',
                    large: 'py-36',
                }),
                className,
            )}
        >
            <div
                className={clsx(
                    variant(container, {
                        [EXTRA_SMALL]: 'container-xs',
                        [SMALL]: 'container-sm',
                        [DEFAULT]: 'container',
                    }),
                    variant(position, {
                        [ALIGN_LEFT]: 'text-left',
                        [ALIGN_CENTER]: 'text-center',
                        [ALIGN_RIGHT]: 'text-right',
                    })
                )}
            >
                {buttons.length > 0 && (
                    <div className="flex flex-wrap mb-11 space-x-4">
                        {buttons.map((button) => {
                            if (button.component === 'Button') {
                                return (
                                    <DynamicButton
                                        key={button._uid}
                                        content={button}
                                    />
                                )
                            }
                            return button
                        })}
                    </div>
                )}
                <div>
                    <div className="relative flex-1">
                        <div
                            className={clsx(
                                'flex flex-col',
                                !isVertical && 'lg:flex-row items-center',
                            )}
                        >
                            <div className="flex-1 w-full">
                                {subtitle && (
                                    <h3 className="text-xs font-medium uppercase text-gray-400 mb-6 tracking-wide">
                                        {subtitle}
                                    </h3>
                                )}
                                <CustomText
                                    as="h1"
                                    className="text-5xl md:text-6xl font-medium break-words"
                                    customText={title}
                                    customTextClassName="font-accent text-8xl"
                                />
                            </div>
                            {description && (
                                <div className="flex-1">
                                    <p className="text-base">
                                        {description}
                                    </p>
                                </div>
                            )}
                        </div>
                        <div
                            className={clsx(
                                'flex flex-col',
                                !isVertical && 'lg:flex-row',
                            )}
                        >
                            {hasImage && (
                                <div className={
                                    clsx(
                                        'flex-1 relative mt-11 md:h-64',
                                        variant(spacing, {
                                            '': 'h-48',
                                            none: 'h-48',
                                            small: 'h-48 lg:h-48',
                                            medium: 'h-48 lg:h-36',
                                            large: 'h-48 lg:h-24',
                                        }),
                                    )
                                }
                                >
                                    <div className="absolute top-0 inset-x-0 w-full h-48 md:h-64 lg:h-80">
                                        <Image
                                            fill
                                            maxWidth={1040}
                                            src={headImage.filename}
                                            className="object-cover rounded-lg"
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="flex-1" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default IntroSection
