import { gql } from 'graphql-request'
import useGraphqlQuery from './useGraphqlQuery'

const QUERY = gql`
    query cheapestFlightPrice(
        $departureCodes: [String]
        $arrivalCodes: [String]
        $airlineCodes: [String]
    ) {
        cheapestFlightPrice(
            departureCodes: $departureCodes
            arrivalCodes: $arrivalCodes
            airlineCodes: $airlineCodes
        ) {
            price
            provider
            directFlight
            returnDate
            airlineCode
            arrivalCity
            arrivalCode
            arrivalAirport
            departureCode
            departureDate
            departureCity
            departureAirport
        }
    }
`

const useCheapestFlightPrice = (variables, options) => {
    const {
        data,
        error,
        isLoading: loading,
    } = useGraphqlQuery(['cheapestFlightPrice', variables], QUERY, variables, options)
    if (loading) {
        return {
            loading,
            cheapestFlightPrice: [],
        }
    }
    if (error !== null) {
        return {
            error,
            loading,
            cheapestFlightPrice: [],
        }
    }
    return {
        error,
        loading,
        cheapestFlightPrice: data?.cheapestFlightPrice || [],
    }
}

export default useCheapestFlightPrice
