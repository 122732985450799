import React from 'react'
import * as constants from '@connections/constants'
import ShopCard from './ShopCard'
import TourCard from './TourCard'
import BreakCard from './BreakCard'
import HotelCard from './HotelCard'
import ExpertCard from './ExpertCard'
import ActivityCard from './ActivityCard'
import BlogPostCard from './BlogPostCard'
import DestinationCard from './DestinationCardWithState'
import Placeholder from './Placeholder'
import Card from './Card'
import PageCard from './PageCard'
import AirlinePricesCard from './AirlinePricesCardWithState'
import IconFeaturesCard from './IconFeaturesCard'

const {
    TOUR_CARD,
    HOTEL_CARD,
    BREAK_CARD,
    ACTIVITY_CARD,
    BLOG_POST_CARD,
    DESTINATION_CARD,
    EXPERT_CARD,
    SHOP_CARD,
    CARD,
    AIRLINE_PRICES_CARD,
    WHY_CONNECTIONS_REASON_CARD,
    ICONS_FEATURES_CARD,
    PAGE_CARD,
} = constants

const CardSectionCard = ({
    component,
    columnWidth,
    ...props
}) => {
    switch (component) {
        case DESTINATION_CARD:
            return <DestinationCard {...props} />
        case HOTEL_CARD:
            return <HotelCard {...props} />
        case BREAK_CARD:
            return <BreakCard {...props} />
        case TOUR_CARD:
            return <TourCard {...props} />
        case ACTIVITY_CARD:
            return <ActivityCard {...props} />
        case BLOG_POST_CARD:
            return <BlogPostCard columnWidth={columnWidth} {...props} />
        case EXPERT_CARD:
            return <ExpertCard {...props} />
        case SHOP_CARD:
            return <ShopCard {...props} />
        case CARD:
            return <Card {...props} />
        case PAGE_CARD:
            return <PageCard {...props} />
        case AIRLINE_PRICES_CARD:
            return <AirlinePricesCard {...props} />
        case WHY_CONNECTIONS_REASON_CARD: // Backwards compability
        case ICONS_FEATURES_CARD:
            return <IconFeaturesCard {...props} />
        default:
            return <Placeholder componentName={component} />
    }
}

export default CardSectionCard
