import * as constants from '@connections/constants'
import useStoryblokQuery from './useStoryblokQuery'

const {
    EXPERT_CONTINENTS: CONTINENTS,
    EXPERT_ENTITY,
} = constants

const RANDOM_SORTS = [
    'id',
    'uuid',
    'created_at',
    'published_at',
    'content._uid',
    'content.lastname',
    'content.firstname',
    'content.profileImage.filename'
]

const sortBy = `${RANDOM_SORTS[0]}:${Math.random() < 0.5 ? 'asc' : 'desc'}`

const useExpertsQuery = () => {
    const params = {
        sort_by: sortBy,
        content_type: EXPERT_ENTITY,
        excluding_slugs: '_test/*,preview/*,*/hidden/*',
        filter_query: {
            continentCodes: {
                in_array: CONTINENTS.join(','),
            },
        },
    }
    const {
        data,
        error,
        isLoading: isFetching,
    } = useStoryblokQuery('expert-map', 'cdn/stories/', params, 'getAll')

    let experts = []
    if (isFetching) {
        return {
            experts,
            isFetching,
        }
    }

    if (error) {
        return {
            error,
            experts,
            isFetching,
        }
    }
    const {
        data: {
            stories,
        },
    } = data
    experts = stories
    return {
        experts,
        isFetching,
    }
}

export default useExpertsQuery
