import React from 'react'
import DynamicSection from '../../content/DynamicSection'
import Page from '../../layout/Page'
import PageMain from '../../layout/PageMain'
import StickyHeader from '../../layout/StickyHeader'
import HeaderWithContent from '../../layout/HeaderWithContent'
import FooterWithContent from '../../layout/FooterWithContent'
import ExperiencePageSubHeader from './ExperiencePageSubHeader'

const ExperiencePage = ({
    name,
    body = [],
}) => (
    <Page data-testid="ExperiencePage">
        <StickyHeader>
            <HeaderWithContent />
            <ExperiencePageSubHeader name={name} />
        </StickyHeader>
        <PageMain>
            {body && body.map((content) => (
                <DynamicSection
                    content={content}
                    key={content._uid}
                />
            ))}
        </PageMain>
        <FooterWithContent />
    </Page>
)

export default ExperiencePage
