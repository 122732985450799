import React from 'react'
import Page from '../../layout/Page'
import PageMain from '../../layout/PageMain'
import StickyHeader from '../../layout/StickyHeader'
import HeaderWithContent from '../../layout/HeaderWithContent'
import FooterWithContent from '../../layout/FooterWithContent'
import Heading from '../../content/Heading'
import DynamicSection from '../../content/DynamicSection'
import BreakPageSubHeader from './BreakPageSubHeader'

const BreakPage = ({
    name,
    price,
    title,
    body = [],
    headImage,
    headVideo,
    description,
    videoEndOffset,
    videoStartOffset,
    headingOptions = [{}],
}) => {
    const [firstSection] = body
    const hasHeadingSection = firstSection?.component === 'HeadingSection'

    return (
        <Page data-testid="BreakPage">
            <StickyHeader>
                <HeaderWithContent />
                <BreakPageSubHeader name={name} />
            </StickyHeader>
            <PageMain>
                {hasHeadingSection ? null : (
                    <Heading
                        price={price}
                        title={title}
                        image={headImage}
                        video={headVideo}
                        description={description}
                        videoEndOffset={videoEndOffset}
                        videoStartOffset={videoStartOffset}
                        {...headingOptions[0]}
                    />
                )}
                {body && body.map((content) => (
                    <DynamicSection
                        content={content}
                        key={content._uid}
                    />
                ))}
            </PageMain>
            <FooterWithContent />
        </Page>
    )
}

export default BreakPage
