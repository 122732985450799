import React, { useState, useRef, useEffect } from 'react'

import useInterval from '../hooks/useInterval'
import useIsInViewport from '../hooks/useIsInViewport'
import HeadingSection from './HeadingSection'

const HeadingSectionWithState = ({
    content,
}) => {
    const headingRef = useRef(null)
    const { hasInterval, headings } = content
    const [activeHeading, setActiveHeading] = useState(0)

    const {
        resetInterval,
        stopInterval,
        startInterval,
    } = useInterval(() => {
        if (activeHeading < headings.length - 1) {
            setActiveHeading(activeHeading + 1)
        } else {
            setActiveHeading(0)
        }
    }, hasInterval ? 8000 : null)

    const isInView = useIsInViewport(headingRef.current, {
        threshold: 0.5
    })
    useEffect(() => {
        if (isInView) {
            startInterval()
        } else {
            stopInterval()
        }
    }, [isInView])

    const handleChangeHeading = (value) => {
        resetInterval()
        setActiveHeading(value)
    }

    return (
        <HeadingSection
            ref={headingRef}
            content={content}
            activeHeading={activeHeading}
            onChangeHeading={handleChangeHeading}
        />
    )
}

export default HeadingSectionWithState
