import React from 'react'

const JotformText = ({ item, className }) => {
    const { text } = item

    return (
        <div className={className} dangerouslySetInnerHTML={{ __html: text }} />
    )
}

export default JotformText
